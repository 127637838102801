function updateCache<T>(tableData: (T[] | undefined)[], movingFrom: number, movingTo: number, records: T[]): (T[] | undefined)[] {
    let returnTableData = tableData;
    let arrMovingFrom = tableData[movingFrom];
    let arrMovingTo = undefined;
    if (movingTo != -1) {
        arrMovingTo = tableData[movingTo];
    }
    let filterMovingFrom: T[] = [];
    let toMergeMovingTo: T[] = [];
    let mergeMovingTo = undefined;
    if (arrMovingFrom != undefined) {

        arrMovingFrom.forEach((ele) => {
            if (records.includes(ele)) {
                toMergeMovingTo.push(ele);
            } else {
                filterMovingFrom.push(ele);
            }
        });
    }
    if (arrMovingTo != undefined) {
        /* Merge logic of toMergeMovingTo and arrMovingTo */
        mergeMovingTo = arrMovingTo.concat(toMergeMovingTo);
    }

    returnTableData[movingFrom] = filterMovingFrom;
    if (mergeMovingTo != undefined) {
        returnTableData[movingTo] = mergeMovingTo;
    }
    return returnTableData;
}

export default updateCache;