import { Stripe, StripeCardElement, StripeElements } from "@stripe/stripe-js";
import { IBankAccountBillingRepr, ICardBillingRepr, TPaymentTypes } from "../paymentTypes";


export async function confirmSetup(stripeSDK: Stripe, stripeElements: StripeElements, type: TPaymentTypes, clientSecret: string, 
    cardBilling?: ICardBillingRepr, bankAccountBilling?: IBankAccountBillingRepr, step?: number) {
    if (type == "card" && cardBilling != undefined) {
        const stripeResponse = await stripeSDK.confirmCardSetup(clientSecret, {
            payment_method: {
                card: stripeElements.getElement("card") as StripeCardElement,
                billing_details: cardBilling
            },
        })
        if (stripeResponse.error != undefined) {
            //Handle Error
            if (stripeResponse.error.type == "card_error") {
                if (stripeResponse.error.code == "setup_attempt_failed") {
                    return { "success": false, "reason": "unexpected_error" };
                } else {
                    if (stripeResponse.error.code == "card_declined") {
                        return { "success": false, "reason": "card_declined" }
                    } else if (stripeResponse.error.code == "expired_card") {
                        return { "success": false, "reason": "expired_card" };
                    } else if (stripeResponse.error.code == "processing_error") {
                        return { "success": false, "reason": "processing_error" };
                    }
                }
            } else if (stripeResponse.error.type == "validation_error") {
                if (stripeResponse.error.code == "invalid_expiry_month_past") {
                    return { "success": false, "reason": "invalid_month" }
                } else if (stripeResponse.error.code == "invalid_expiry_year_past") {
                    return { "success": false, "reason": "invalid_year" }
                } else if (stripeResponse.error.code == "invalid_number") {
                    return { "success": false, "reason": "invalid_number" };
                } else if (stripeResponse.error.code == "incorrect_address") {
                    return { "success": false, "reason": "incorrect_address" };
                } else if (stripeResponse.error.code == "incorrect_cvc") {
                    return { "success": false, "reason": "incorrect_cvc" };
                } else if (stripeResponse.error.code == "incorrect_zip") {
                    return { "success": false, "reason": "incorrect_zip" };
                } else if (stripeResponse.error.code == "invalid_cvc") {
                    return { "success": false, "reason": "invalid_cvc" };
                } else if (stripeResponse.error.code == "invalid_expiry_month") {
                    return { "success": false, "reason": "invalid_month" };
                } else if (stripeResponse.error.code == "invalid_expiry_year") {
                    return { "success": false, "reason": "invalid_year" };
                }
            } else if (stripeResponse.error.type == "invalid_request_error") {
                if (stripeResponse.error.code == "setup_intent_authentication_failure") {
                    return { "success": false, "reason": "auth_failure" };
                }
            }
            return { "success": false, "reason": "unexpected_error" };
        } else {
            return {"success": true, "payment_method_id": stripeResponse.setupIntent.payment_method}
        }
    } else if (type == "bank_account" && step != undefined) {
        if (step == 1 && bankAccountBilling != undefined) {
            const stripeResponse = await stripeSDK.collectBankAccountForSetup({
                clientSecret: clientSecret,
                params: {
                    payment_method_type: "us_bank_account",
                    payment_method_data: {
                        billing_details: bankAccountBilling
                    }
                }
            })
            if (stripeResponse.error != undefined) {
                return { "success": false, "reason": "unexpected_error" }
            } else if (stripeResponse.setupIntent.status == "requires_payment_method") {
                return { "success": false, "reason": "cancelled_modal" }
            } else if (stripeResponse.setupIntent.status == "requires_confirmation") {
                return { "success": true, "payment_method_id": stripeResponse.setupIntent.payment_method }
            }
        } else if (step == 2) {
            const stripeResponse = await stripeSDK.confirmUsBankAccountSetup(clientSecret);
            if (stripeResponse.error != undefined) {
                return { "success": false, "reason": "unexpected_error" }
            } else if (stripeResponse.setupIntent.status == "requires_payment_method") {
                return { "success": false, "reason": "confirm_failed" }
            } else if (stripeResponse.setupIntent.status == "succeeded") {
                return { "success": true, "payment_method_id": stripeResponse.setupIntent.payment_method }
            }
        }
    }
    return {"success": false, "reason": "invalid_payment_type"}
}

export async function confirmPayment(stripeSDK: Stripe, stripeElements: StripeElements, type: TPaymentTypes, clientSecret: string, 
    cardBilling?: ICardBillingRepr, bankAccountBilling?: IBankAccountBillingRepr, step?: number) {
    if (type == "card") {
        let stripeData = undefined;
        if (cardBilling != undefined) {
            stripeData = {
                payment_method: {
                    card: stripeElements.getElement("card") as StripeCardElement,
                    billing_details: cardBilling
                }
            }
        }
        const stripeResponse = await stripeSDK.confirmCardPayment(clientSecret, stripeData);
        if (stripeResponse.error != undefined) {
            //Handle Error
            if (stripeResponse.error.type == "card_error") {
                if (stripeResponse.error.code == "setup_attempt_failed") {
                    return { "success": false, "reason": "unexpected_error" };
                } else {
                    if (stripeResponse.error.code == "card_declined") {
                        return { "success": false, "reason": "card_declined" }
                    } else if (stripeResponse.error.code == "expired_card") {
                        return { "success": false, "reason": "expired_card" };
                    } else if (stripeResponse.error.code == "processing_error") {
                        return { "success": false, "reason": "processing_error" };
                    }
                }
            } else if (stripeResponse.error.type == "validation_error") {
                if (stripeResponse.error.code == "invalid_expiry_month_past") {
                    return { "success": false, "reason": "invalid_month" }
                } else if (stripeResponse.error.code == "invalid_expiry_year_past") {
                    return { "success": false, "reason": "invalid_year" }
                } else if (stripeResponse.error.code == "invalid_number") {
                    return { "success": false, "reason": "invalid_number" };
                } else if (stripeResponse.error.code == "incorrect_address") {
                    return { "success": false, "reason": "incorrect_address" };
                } else if (stripeResponse.error.code == "incorrect_cvc") {
                    return { "success": false, "reason": "incorrect_cvc" };
                } else if (stripeResponse.error.code == "incorrect_zip") {
                    return { "success": false, "reason": "incorrect_zip" };
                } else if (stripeResponse.error.code == "invalid_cvc") {
                    return { "success": false, "reason": "invalid_cvc" };
                } else if (stripeResponse.error.code == "invalid_expiry_month") {
                    return { "success": false, "reason": "invalid_month" };
                } else if (stripeResponse.error.code == "invalid_expiry_year") {
                    return { "success": false, "reason": "invalid_year" };
                }
            } else if (stripeResponse.error.type == "invalid_request_error") {
                if (stripeResponse.error.code == "setup_intent_authentication_failure") {
                    return { "success": false, "reason": "auth_failure" };
                }
            }
            return { "success": false, "reason": "unexpected_error" };
        } else {
            return {"success": true, "payment_method_id": stripeResponse.paymentIntent.payment_method}
        }
    } else if (type == "bank_account" && step != undefined) {
        if (step == 1 && bankAccountBilling != undefined) {
            const stripeResponse = await stripeSDK.collectBankAccountForPayment({
                clientSecret: clientSecret,
                params: {
                    payment_method_type: "us_bank_account",
                    payment_method_data: {
                        billing_details: bankAccountBilling
                    }
                }
            })            
            if (stripeResponse.error != undefined) {
                return { "success": false, "reason": "unexpected_error" }
            } else if (stripeResponse.paymentIntent.status == "requires_payment_method") {
                return { "success": false, "reason": "cancelled_modal" }
            } else if (stripeResponse.paymentIntent.status == "requires_confirmation") {
                return { "success": true, "payment_method_id": stripeResponse.paymentIntent.payment_method }
            }
        } else if (step == 2) {
            const stripeResponse = await stripeSDK.confirmUsBankAccountPayment(clientSecret);
            if (stripeResponse.error != undefined) {
                return { "success": false, "reason": "unexpected_error" }
            } else if (stripeResponse.paymentIntent.status == "requires_payment_method") {
                return { "success": false, "reason": "confirm_failed" }
            } else if (stripeResponse.paymentIntent.status == "processing") {
                return { "success": true, "payment_method_id": stripeResponse.paymentIntent.payment_method }
            }
        }
    }
    return {"success": false, "reason": "invalid_payment_type"}
}

export async function fetchPaymentIntentID(stripeSDK: Stripe, clientSecret: string) {
    const stripeResponse = await stripeSDK.retrievePaymentIntent(clientSecret);
    if (stripeResponse.error != undefined) {
        //Handle error
        return {"success": false, "reason": "unexpected_error"};
    } else {
        return {"success": true, "payment_intent_id": stripeResponse.paymentIntent.id};
    }
}