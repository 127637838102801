import React from "react";
import $ from "jquery";
import { AxiosResponse } from "axios";

import ContentBoxTabTable from "../../../intelws_portal/bundles/ContentBoxTabTable";
import Loading from "../../../intelws_portal/constructs/elements/Loading";
import { IDataRepr} from "../../../intelws_portal/constructs/elements/Table";
import AddButton from "../../../intelws_portal/constructs/elements/AddButton";
import { get, getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../declarations/common";
import { getBundledAlertsUI } from "../../../declarations/common_utils";
import ModalLeadAdd from "../modal/ModalLeadAdd";
import ModalLead from "../modal/ModalLead";
import ModalLeadNotes from "../modal/ModalLeadNotes";
import { computeFallbackHeaderFormat } from "@fullcalendar/common";
import { cloneDeep, first } from "lodash";
import { confirm } from "../../../intelws_portal/constructs/elements/WindowConfirm";

interface IFetchLeads extends AxiosResponse {
    data: {
        data: IDataRepr[],
        column_names: string[],
        access_keys: string[]
    }
}

interface IPostFollowUp extends AxiosResponse {
    data: {
        data: IDataRepr
    }
}

interface IPostTransfer extends AxiosResponse {
    data: {
        data: IDataRepr
    }
}

function isStringEmpty(value: string) {
    return (value.length == 0 || !value.trim());
}

function LeadTracking(props: IPageComponentProps) {
    const ENDPOINT = "/api/leads/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [tableData, setTableData] = React.useState<(IDataRepr[] | undefined)[]>([undefined, undefined]);
    const [currTab, setCurrTab] = React.useState(0);
    const [currLeadIndex, setCurrLeadIndex] = React.useState(0);
    const [columnNames, setColumnNames] = React.useState<string[][]>([[], []]);
    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);
    const [accessKeys, setAccessKeys] = React.useState<string[][]>([[], []]);

    const [notesLeadId, setNotesLeadId] = React.useState(-1);
    const [currNotes, setCurrNotes] = React.useState("");

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phoneNum, setPhoneNum] = React.useState("");
    const [leadId, setLeadId] = React.useState("");
    const [contactWay, setContactWay] = React.useState("");
    const [refMethod, setRefMethod] = React.useState("");

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("LeadTracking");
        }
        let queryParams = {
            section: "leads_overview",
            type: "follow_up_exclude"
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchLeads>;
        requestResponse.then((response) => {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                newTableData[0] = [...response.data.data];
                return newTableData;
            })
            setColumnNames([response.data.column_names, response.data.column_names]);
            setAccessKeys([response.data.access_keys, response.data.access_keys]);
            setIsLoaded(true);
        })
    }, [])

    function tabCallback(tabName: string, index: number, prevIndex: number) {
        let updateIndex = -1;
        setCurrTab(index);
        if (tabName == "follow_up_exclude" && tableData[0] == undefined) {
            updateIndex = 0;
        } else if (tabName == "follow_up_only" && tableData[1] == undefined) {
            updateIndex = 1;
        }
        if (updateIndex > -1) {
            let queryParams = {
                section: "leads_overview",
                type: tabName
            }
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchLeads>;
            requestResponse.then((response) => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    newTableData[updateIndex] = [...response.data.data];
                    return newTableData;
                })
                setColumnNames((prevColumnNames) => {
                    let newColumnNames = [...prevColumnNames];
                    newColumnNames[updateIndex] = response.data.column_names;
                    return newColumnNames;
                })
                setAccessKeys((prevAccessKeys) => {
                    let newAccessKeys = [...prevAccessKeys];
                    newAccessKeys[updateIndex] = response.data.access_keys;
                    return newAccessKeys;
                })
            })
        }
    }

    function inputCallback(index: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (value == "follow_up") {
            setCurrLeadIndex(index);
            $("#leadDueDateModal").modal("show");
        } else if (value == "edit_notes") {
            let currTable = tableData[currTab];
            if (currTable != undefined) {
                let tableEle = currTable[index];
                setCurrNotes(tableEle["lt_notes"] as string);
                setNotesLeadId(tableEle.id);
            }
            $("#leadNotesModal").modal("show");
        } 
        else if (value == "edit_lead") {
            let currTable = tableData[currTab];
            if (currTable != undefined) {
                let tableEle = currTable[index];
                setFirstName(tableEle["lt_client_first_name"] as string);
                setLastName(tableEle["lt_client_last_name"] as string);
                setEmail(tableEle["lt_client_email"] as string);
                setPhoneNum(tableEle["lt_client_phonenum"] as string);
                setLeadId(String(tableEle.id))
                setContactWay(tableEle["lt_client_pref_contact"] as string)
                setRefMethod(tableEle["lt_client_referral_method"] as string)
            }
            $("#leadAddModal").modal("show");
        } else if (value == "transfer_lead") {
            let formData = new FormData();
            let currTable = tableData[currTab];
            if (currTable != undefined) {
                formData.append("lead_id", currTable[index].id.toString());
            }
            let queryParams = {
                section: value
            }
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
            requestResponse.then((response) => {
                setTableData((prevTableData) => {
                    let newTableData = cloneDeep(prevTableData);
                    newTableData[currTab] = newTableData[currTab]?.filter((ele) => {
                        if (currTable != undefined) {
                            return ele.id !== currTable[index].id
                        }
                    })
                    return newTableData;
                })
            })
        } else if (value == "delete_lead") {
            confirm("Are you sure you want to delete this lead?").then(() => {
                let formData = new FormData();
                let currTable = tableData[currTab];
                if (currTable != undefined) {
                    formData.append("lead_id", currTable[index].id.toString());
                }
                let queryParams = {
                    section: value
                }
                const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
                requestResponse.then(() => {
                    setTableData((prevTableData) => {
                        let newTableData = [...prevTableData];
                        newTableData[currTab] = newTableData[currTab]?.filter((ele, currIndex) => {
                            return currIndex !== index;
                        })
                        return newTableData;
                    })
                })
            })
        }
    }

    function modalFollowUpSaveCallback(date: string) {
        let formData = new FormData();
        let currTable = tableData[currTab];
        if (currTable != undefined) {
            formData.append("lead_id", currTable[currLeadIndex].id.toString());
        }
        if (!isStringEmpty(date)) {
            formData.append("lead_follow_up_date", date);
        }
        let queryParams = {
            section: "set_follow_up"
        }
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostFollowUp>;
        requestResponse.then((response) => {
            setTableData((prevTableData) => {
                let newTableData = cloneDeep(prevTableData);
                let currTable = newTableData[0];
                newTableData[0] = currTable?.filter((ele, currIndex) => {
                    return currIndex !== currLeadIndex;
                })
                let updateTable = newTableData[1];
                let shouldAdd = true;
                let updateIndex = -1;
                if (updateTable != undefined) {
                    updateTable.forEach((ele, currIndex) => {
                        if (ele.id == response.data.data.id) {
                            shouldAdd = false;
                            updateIndex = currIndex;
                        }
                    })
                    if (shouldAdd) {
                        newTableData[1] = [...updateTable, response.data.data];
                    } else {
                        updateTable[updateIndex] = {...response.data.data};
                        newTableData[1] = updateTable;
                    }
                }
                return newTableData;
            })
            $("#leadDueDateModal").modal("hide");
        })
    }

    function modalAddSaveCallback(data?: IDataRepr, success?: string[], fail?: string[]) {
        if (data != undefined) {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                let currTable = newTableData[0];
                if (currTable != undefined) {
                    newTableData[0] = [data, ...currTable];
                }
                return newTableData;
            })
        }
        if (success != undefined && fail != undefined) {
            setSuccess(success);
            setFail(fail);
        }
    }
    function modalAddSaveCallbackForEdit(data?: IDataRepr, success?: string[], fail?: string[]) {
        if (data != undefined) {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                let currTable = newTableData[currTab];
                if (currTable != undefined) {
                    newTableData[currTab] = [data, ...currTable.filter(e => e.id != data.id)];
                }
                return newTableData;
            })
        }
        if (success != undefined && fail != undefined) {
            setSuccess(success);
            setFail(fail);
        }
        
    }
    function clearModal(){
        setFirstName("")
        setLastName("")
        setEmail("")
        setPhoneNum("")
        setLeadId("")
        setContactWay("")
        setRefMethod("")
    }

    function modalNotesSaveCallback(newNotes: string) {
        setTableData((prevTableData) => {
            let newTableData = [...prevTableData];
            newTableData[currTab]?.forEach((ele) => {
                if (ele.id == notesLeadId) {
                    ele["lt_notes"] = newNotes
                }
            })
            return newTableData;
        })
        setNotesLeadId(-1);
        setCurrNotes("");
    }

    function getContent() {
        let tabs = [{target: "follow_up_exclude", display: "Outstanding", active: true},
        {target: "follow_up_only", display: "Follow Up", active: false}];
        let miscHeaders = <AddButton buttonValue={<i className="fa fa-plus"></i>}
                onClick={() => { $('#leadAddModal').modal('show'); }} />;
        return <ContentBoxTabTable tabs={tabs} miscHeaders={miscHeaders} title="Lead" hasSearch={true} hasRecords={true}
            columnNames={columnNames} accessKeys={accessKeys} contents={tableData}
            tabCallback={tabCallback} inputCallback={inputCallback} hasDownload={true}/>
    }

    function getAddModal(){

            return <ModalLeadAdd saveCallback={modalAddSaveCallbackForEdit} leadId={leadId} firstName={firstName}
            lastname = {lastName} email= {email} contact = {phoneNum} contactPref = {contactWay} contactRef ={refMethod} clearModal={clearModal}/>
    }
    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper clearfix">
                {getBundledAlertsUI(success, fail, setSuccess, setFail)}
                {getContent()}
                {getAddModal()}
                <ModalLead saveCallback={modalFollowUpSaveCallback} />
                <ModalLeadNotes saveCallback={modalNotesSaveCallback} leadId={notesLeadId} currNotes={currNotes} />
            </div>

        )
    }
}

export default LeadTracking;