import { AxiosResponse } from "axios";
import React from "react";
import * as urls from "../../../Urls";
import $ from "jquery";

import { IContentBoxTabTableProps } from "../../../../intelws_portal/bundles/ContentBoxTabTable";
import ContentBoxTabTable from "../../../../intelws_portal/bundles/ContentBoxTabTable";
import Loading from "../../../../intelws_portal/constructs/elements/Loading";
import { IDataRepr } from "../../../../intelws_portal/constructs/elements/Table";
import { ITabRepr } from "../../../../intelws_portal/constructs/elements/Tabs";
import AddButton from "../../../../intelws_portal/constructs/elements/AddButton";
import { get, getCancelToken, post } from "../../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../../declarations/common";
import { getBundledAlertsUI } from "../../../../declarations/common_utils";
import ModalManualTask from "../../modal/ModalManualTask"
import { confirm } from "../../../../intelws_portal/constructs/elements/WindowConfirm";

interface IMatchProps {
    userId: string
}

interface IFetchManualTaskConfig extends AxiosResponse {
    data: {
        tab_config: ITabRepr[],
        access_keys: string[][],
        column_names: string[][],
        is_project_add: boolean
    }
}

interface IFetchManualTask extends AxiosResponse {
    data: {
        data: IDataRepr[],
    }
}

interface IClientProjectProps<T> extends IPageComponentProps<T> {
    mutator?: {
        currentPage?: (currentPage: string) => void,
        bnsDocCallback?: (data: IDataRepr[]) => void
    },
    bnsValues?: number[],
    bnsShow?: string[]
}

function ClientProject(props: IClientProjectProps<IMatchProps>) {
    const ENDPOINT = "/api/manualtasklist/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isProjectAdd, setIsProjectAdd] = React.useState(false);
    const [accessKeys, setAccessKeys] = React.useState<string[][]>([]);
    const [columnNames, setColumnNames] = React.useState<string[][]>([]);

    const cancelTokenSource = React.useRef(getCancelToken());
    const [tabConfig, setTabConfig] = React.useState<ITabRepr[]>([]);
    const [currTab, setCurrTab] = React.useState(0);

    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);

    const [tableData, setTableData] = React.useState<(IDataRepr[] | undefined)[]>([]);

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ClientProject");
        }
        let queryParams = {
            section: "manual_task_list_client_config",
            user_id: props.match.params.userId
        }
        const configRequestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchManualTaskConfig>;
        configRequestResponse.then((configResponse) => {
            setTabConfig(configResponse.data.tab_config);
            let queryParams = {
                section: "manual_task_list_client",
                type: configResponse.data.tab_config[0].target,
                user_id: props.match.params.userId
            }
            const dataResponseRequest = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchManualTask>;
            dataResponseRequest.then((dataResponse) => {
                setTableData(() => {
                    let newTableData = new Array(configResponse.data.tab_config.length).fill(undefined);
                    newTableData[0] = [...dataResponse.data.data];
                    return newTableData;
                })
                setAccessKeys(configResponse.data.access_keys);
                setColumnNames(configResponse.data.column_names);
                setIsProjectAdd(configResponse.data.is_project_add);
                setIsLoaded(true);
            })
        })
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    function tabCallback(tabName: string, index: number, prevIndex: number) {
        setCurrTab(index);
        if (tableData[index] == undefined || tabName == "project_templates") {
            let queryParams = {
                section: "manual_task_list_client",
                type: tabName,
                user_id: props.match.params.userId
            }
            const responseRequest = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchManualTask>;
            responseRequest.then((response) => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    newTableData[index] = [...response.data.data];
                    return newTableData;
                })
            })
        }
    }

    function addToTable(data?: IDataRepr, section?: string, success?: string[], fail?: string[]) {
        if (data != undefined && section != undefined) {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                let currIndex = tabConfig.findIndex((ele) => {
                    return ele.target == section;
                })
                if (currIndex > -1) {
                    let currTable = newTableData[currIndex];
                    if (currTable != undefined) {
                        newTableData[currIndex] = [...currTable, data];
                    }
                }
                return newTableData;
            })
        }
        if (success != undefined && fail != undefined) {
            setSuccess(success);
            setFail(fail);
        }
    }

    function inputCallback(index: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (typeof value == "string") {
            if (value == "delete") {
                confirm("Are you sure you want to delete this file?").then(() => {
                    let queryParams = {
                        section: "manual_task_or_template_delete",
                        user_id: props.match.params.userId,
                    }
                    let formData = new FormData();
                    let currTable = tableData[currTab];
                    if (currTable != undefined) {
                        formData.append("task_tracking_id", currTable[index].id.toString());
                        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
                        requestResponse.then(() => {
                            setTableData((prevTableData) => {
                                let newTableData = [...prevTableData];
                                let currTable = newTableData[currTab];
                                if (currTable != undefined) {
                                    newTableData[currTab] = currTable.filter((ele, loopIndex) => loopIndex !== index);
                                }
                                return newTableData;
                            })
                        })
                    }
                })
            }
        }
    }

    function getContent() {
        let link = {
            tt_task_name: {
                baseUrl: urls.BASE_MANUAL_TASK_DETAILS,
                urlParams: ['tt_user_id', 'id'],
            }
        };
        let miscHeaders = undefined;
        if (isProjectAdd) {
            miscHeaders = <AddButton buttonValue={<i className="fa fa-plus"></i>}
                onClick={() => { $('#manualTaskAddModal').modal('show'); }} />;
        }
        let propObject: IContentBoxTabTableProps = {
            tabs: tabConfig,
            contents: tableData,
            columnNames: columnNames,
            accessKeys: accessKeys,
            title: "Projects",
            miscHeaders: miscHeaders,
            hasSearch: true,
            hasRecords: true,
            tabCallback: tabCallback,
            inputCallback: inputCallback,
            links: new Array(tabConfig.length).fill(link)
        };
        return (<ContentBoxTabTable {...propObject} />)
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper clearfix" style={{ paddingTop: "16px" }}>
                {getBundledAlertsUI(success, fail, setSuccess, setFail)}
                {getContent()}
                {(() => {
                    if (isProjectAdd && props.bnsValues != undefined && props.bnsShow != undefined && 
                            props.mutator != undefined && props.mutator.bnsDocCallback != undefined) {
                        return <ModalManualTask mutator={addToTable} userId={props.match.params.userId} 
                            bnsOptions={props.bnsShow} bnsValue={props.bnsValues} bnsDocCallback={props.mutator.bnsDocCallback} />
                    }
                })()}
            </div>
        )
    }
}

export default ClientProject;