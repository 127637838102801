import { AxiosResponse } from "axios";
import React from "react";
import { IPageComponentProps } from "../../../declarations/common";
import { getBundledAlertsUI, parseQueryResponseOnFail, parseQueryResponseOnSuccess } from "../../../declarations/common_utils";
import ContentBoxTable from "../../../intelws_portal/bundles/ContentBoxTable";
import Loading from "../../../intelws_portal/constructs/elements/Loading";
import ScrollTop from "../../../intelws_portal/constructs/elements/ScrollTop";
import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import ModalDropDown from "../../../intelws_portal/constructs/modal/ModalDropDown";
import { get, getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";

interface IFetchUserReferralReview extends AxiosResponse {
    data: {
        data: IDataRepr[]
    }
}

interface IPostReviewActions extends AxiosResponse {
    data: {
        data: IDataRepr
    }
}

function ReferralReview(props: IPageComponentProps) {
    const ENDPOINT = "/api/leads/";

    const referralAllText = React.useRef("Referral Type");
    const referralClientText = React.useRef("Client");
    const referralOptions = [referralAllText.current, "Google", "Yelp", "Facebook", "Instagram", "Linkedin", "Twitter", "Next Door", referralClientText.current, "Other"];

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [currReferralMethod, setCurrReferralMethod] = React.useState(referralAllText.current);
    const [tableData, setTableData] = React.useState<IDataRepr[]>([]);

    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);

    const cancelTokenSource = React.useRef(getCancelToken());


    const currTableData = React.useMemo(() => {
        let tableDataFilter = [...tableData];
        if (currReferralMethod != referralAllText.current) {
            return tableDataFilter.filter((ele) => {
                return ele.int_user_referral_method == currReferralMethod;
            })
        }
        return tableDataFilter;
    }, [tableData, currReferralMethod])

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ReferReview");
        }

        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, { section: "user_referral" }) as Promise<IFetchUserReferralReview>;
        requestResponse.then((response) => {
            setTableData(response.data.data);
            setIsLoaded(true);
        })
    }, [])

    function referralFilterOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrReferralMethod(e.target.value);
    }

    function inputCallback(index: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (accessKey == "action" && typeof value == "string") {
            let requestResponse = undefined;
            let formData = new FormData();
            formData.append("user_id", currTableData[index].id.toString());
            if (value == "sendReview" || value == "sendReviewReminder") {
                requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, { section: "send_review_link_email" }) as Promise<IPostReviewActions>;
            } else if (value == "markReview"){
                requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, { section: "send_review_reviewed" }) as Promise<IPostReviewActions>;
            }
            if (requestResponse != undefined) {
                requestResponse.then((response) => {
                    setTableData((prevTableData) => {
                        let newTableData = [...prevTableData];
                        newTableData = newTableData.map((ele) => {
                            if (ele.id === currTableData[index].id) {
                                return response.data.data;
                            }
                            return ele;
                        })
                        return newTableData;
                    })
                    let [success, fail] = parseQueryResponseOnSuccess(response);
                    setSuccess(success);
                    setFail(fail);
                }).catch((error) => {
                    let [success, fail, failResponse] = parseQueryResponseOnFail(error);
                    setSuccess(success);
                    setFail(fail);
                })
            }
        }
    }


    let title = <React.Fragment>
        <div className="row">
            <h6 className="col-sm-6"> Referral & Review </h6>
            <div className="col-sm-3">
                <ModalDropDown options={referralOptions} onChange={referralFilterOnChange} />
            </div>
        </div>
    </React.Fragment>

    function getContent() {
        const columnNames = ["Name", "Email", "Contact #", "Acquistion", "Review Status", "Action"];
        const accessKeys = ["user_full_name", "user_email", "user_phone_num", "user_referral_method", "user_review_status", "action"];
        return <ContentBoxTable title={title} hasSearch={true} hasRecords={true} columnNames={columnNames}
            accessKeys={accessKeys} tableData={currTableData} hasDownload={true}
            inputCallback={inputCallback}
            />
    }

    if (!isLoaded) {
        return <Loading />
    }
    return (
        <div className="body-content-wrapper clearfix">
            {getBundledAlertsUI(success, fail, setSuccess, setFail)}
            {getContent()}
            <ScrollTop />
        </div>
    )
}

export default ReferralReview;