import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './external/backPages/css/ext_single_style.css';
import './external/backPages/css/mobileScreenStyle.css';
import $ from 'jquery';
import 'bootstrap';
import App from "./components/App";

ReactDOM.render(
    <App />,
  document.getElementById('root')
);