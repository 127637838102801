import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from "axios";

import { get, post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import { isStringEmpty, parseQueryResponseOnFail, parseQueryResponseOnFailResponse, usePickPropsIfNotNull } from "../../../declarations/common_utils";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { stripePromise } from "../../Urls";
import { SetupIntentResult, StripeCardElement } from "@stripe/stripe-js";
import { IAddressRepr, IPaymentMethodRepr } from "../../../intelws_portal/utils/payments/paymentTypes";
import { confirmSetup } from "../../../intelws_portal/utils/payments/payments";

interface IPostPaymentMethod extends AxiosResponse {
    data: {
        data: {
            client_secret: string
        }
    }
}

interface IFetchPaymentMethod extends AxiosResponse {
    data: {
        data: IPaymentMethodRepr
    }
}

interface IStepRepr {
    step: number,
    redirect?: string,
    stripeSDK?: string
}

export interface IModalPaymentMethodProps {
    addressObj?: IAddressRepr,
    onSuccess: (address: IPaymentMethodRepr) => void,
    onFail: (fail: string[]) => void
}

function LogicModalPaymentMethod(props: IModalPaymentMethodProps) {
    const ENDPOINT = "/api/billing/";

    const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);

    const [addressObj, setAddressObj] = usePickPropsIfNotNull(props.addressObj, getEmptyAddressObj());
    const [cardHolderName, setCardHolderName] = React.useState("");
    const [currStep, setCurrStep] = React.useState<IStepRepr>({step: 1});
    const [redirectCounter, setRedirectCounter] = React.useState(0);
    
    const stripeSDK = useStripe();
    const stripeElements = useElements();

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        $("#paymentMethodModal").on("hidden.bs.modal", clearForm)
        return () => {
            cancelTokenSource.current.cancel();
            $("#paymentMethodModal").off("hidden.bs.modal");
        }
    }, [])

    function getEmptyErrorObj() {
        return {
            line1: {
                isError: false,
                errorText: ""
            },
            line2: {
                isError: false,
                errorText: ""
            },
            city: {
                isError: false,
                errorText: ""
            },
            state: {
                isError: false,
                errorText: ""
            },
            postalCode: {
                isError: false,
                errorText: ""
            },
            card: {
                isError: false,
                errorText: ""
            }
        }
    }

    function getEmptyAddressObj() {
        return {
            line1: "",
            line2: "",
            city: "",
            state: "",
            country: "US",
            postal_code: "",
        }
    }

    function clearForm() {
        //Test
        setCardHolderName("");
        if (stripeElements != undefined) {
            let cardElement = stripeElements.getElement("card");
            if (cardElement != undefined) {
                cardElement.clear();
            }
        }
    }

    function formChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> , 
        section: "line1" | "line2" | "city" | "state" | "postalCode" | "cardHolderName") {
        setAddressObj((prevAddressObj) => {
            let newAddressObj = { ...prevAddressObj };
            if (section == "line1") {
                newAddressObj.line1 = e.target.value;
            } else if (section == "line2") {
                newAddressObj.line2 = e.target.value;
            } else if (section == "city") {
                newAddressObj.city = e.target.value;
            } else if (section == "state") {
                newAddressObj.state = e.target.value;
            } else if (section == "postalCode") {
                newAddressObj.postal_code = e.target.value;
            }
            return newAddressObj;
        })
        if (section == "cardHolderName") {
            setCardHolderName(e.target.value);
        }
    }

    function submitForm() {
        let cardData = {
            name: cardHolderName,
            address: {...addressObj}
        }
        if (addressObj.line2 != undefined) {
            cardData.address.line2 = addressObj.line2;
        }
        setErrorObj(getEmptyErrorObj());
        if (stripeSDK != undefined && stripeElements != undefined) {
            let queryParams = {
                section: "create_payment_method"
            }
            let formData = new FormData();
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostPaymentMethod>;
            requestResponse.then((response) => {
                confirmSetup("stripe", "card", response.data.data.client_secret, stripeSDK, stripeElements, cardData).then((setupResponse) => {
                    if (setupResponse != undefined) {
                        if (setupResponse.success == false && setupResponse.reason != undefined) {
                            let errorObj = getEmptyErrorObj();
                            if (setupResponse.reason == "expired_card" ||
                                setupResponse.reason == "invalid_month" ||
                                setupResponse.reason == "invalid_year") {
                                errorObj.card.isError = true;
                                errorObj.card.errorText = "This card has expired"
                                setErrorObj(errorObj);
                            } else if (setupResponse.reason == "incorrect_address") {
                                errorObj.card.isError = true;
                                errorObj.card.errorText = "The address is incorrect"
                                setErrorObj(errorObj)
                            } else if (setupResponse.reason == "incorrect_cvc" ||
                                setupResponse.reason == "invalid_cvc") {
                                errorObj.card.isError = true;
                                errorObj.card.errorText = "The CVC is invalid"
                                setErrorObj(errorObj)
                            } else if (setupResponse.reason == "incorrect_zip") {
                                errorObj.card.isError = true;
                                errorObj.card.errorText = "The Zip Code is incorrect"
                                setErrorObj(errorObj)
                            } else if (setupResponse.reason == "invalid_number") {
                                errorObj.card.isError = true;
                                errorObj.card.errorText = "The card number is invalid"
                                setErrorObj(errorObj)
                            } else if (setupResponse.reason == "auth_failure") {
                                errorObj.card.isError = true;
                                errorObj.card.errorText = "The card authentication failed"
                                setErrorObj(errorObj)
                            } else if (setupResponse.reason == "card_declined") {
                                errorObj.card.isError = true;
                                errorObj.card.errorText = "This card was declined. Please try another card."
                                setErrorObj(errorObj)
                            } else if (setupResponse.reason == "unexpected_error") {
                                errorObj.card.isError = true;
                                errorObj.card.errorText = "There is an unexpected error. Please try again"
                                setErrorObj(errorObj)
                            } else if (setupResponse.reason == "processing_error") {
                                errorObj.card.isError = true;
                                errorObj.card.errorText = "This card cannot be processed. Please try another card."
                                setErrorObj(errorObj)
                            }
                        } else {
                            let queryParams = {
                                section: "payment_method",
                                payment_backend: "stripe",
                                payment_method_id: setupResponse.payment_method_id as string
                            }
                            const paymentMethodRequestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchPaymentMethod>
                            paymentMethodRequestResponse.then((paymentMethodResponse) => {
                                $("#paymentMethodModal").modal("hide");
                                props.onSuccess(paymentMethodResponse.data.data);
                            })
                        }
                    }
                });
            }).catch((error) => {
                let [success, fail, failResponse] = parseQueryResponseOnFail(error);
                let errorObj = getEmptyErrorObj();
                parseQueryResponseOnFailResponse(errorObj, failResponse);
                setErrorObj(errorObj);
            })
        }
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="paymentMethodModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Add Billing Contact Address"} />
                        <div className="modal-body">
                            <React.Fragment>
                                <ModalInputText title={"Card Holder Name"} value={cardHolderName} onChange={(e) => formChange(e, "cardHolderName")}
                                    borderBottom required /><br></br>
                                <div>
                                    <CardElement />
                                </div>
                                {(() => {
                                    if (errorObj.card.isError) {
                                        return (
                                            <div style={{height: 20, padding: 4, color: "#dc3545"}}>
                                                {errorObj.card.errorText}
                                            </div>
                                        )
                                    }
                                })()}
                                <ModalInputText title={"Address Line 1"} value={addressObj.line1} onChange={(e) => formChange(e, "line1")}
                                    isError={errorObj.line1.isError} errorText={errorObj.line1.errorText}
                                    borderBottom required /><br></br>
                                <ModalInputText title={"Address Line 2"} value={addressObj.line2 as string} onChange={(e) => formChange(e, "line2")}
                                    isError={errorObj.line2.isError} errorText={errorObj.line2.errorText}
                                    borderBottom /><br></br>
                                <ModalInputText title={"City"} value={addressObj.city} onChange={(e) => formChange(e, "city")}
                                    isError={errorObj.city.isError} errorText={errorObj.city.errorText}
                                    borderBottom required /><br></br>
                                <ModalInputText title={"State"} value={addressObj.state} onChange={(e) => formChange(e, "state")}
                                    isError={errorObj.state.isError} errorText={errorObj.state.errorText}
                                    borderBottom required /><br></br>
                                <ModalInputText title={"Zip Code"} value={addressObj.postal_code} onChange={(e) => formChange(e, "postalCode")}
                                    isError={errorObj.postalCode.isError} errorText={errorObj.postalCode.errorText}
                                    borderBottom required /><br></br>
                            </React.Fragment>
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={false} />
                    </div>
                </div>
            </div>
        )
    }
    
    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

function ModalPaymentMethod(props: IModalPaymentMethodProps) {
    return (
        <Elements stripe={stripePromise}>
            <LogicModalPaymentMethod {...props} />
        </Elements>
    )
}

export default ModalPaymentMethod;