import React from "react";

interface adminhomesection{
    mainHeading:string,
    subHeading:string,
    homeSectionImg:string,
    signUp:string
}
export function AdminHomeSection(props:adminhomesection)
{
return(
    <React.Fragment>
        <section id="hero" className="d-flex align-items-center">
        <div className="container d-flex flex-column align-items-center justify-content-center">
        <h1>{props.mainHeading}</h1>
        <h2>{props.subHeading}</h2>
        <a href={props.signUp} className="btn-get-started scrollto">Sign Up</a>
        <img src={props.homeSectionImg} className="img-fluid hero-img" alt="" />
        </div>
        </section>
    </React.Fragment>
)
}
