import { AxiosResponse } from "axios";
import React from "react";
import $ from "jquery";

import ContentBoxTable from "../../../../intelws_portal/bundles/ContentBoxTable";
import Loading from "../../../../intelws_portal/constructs/elements/Loading";
import ScrollTop from "../../../../intelws_portal/constructs/elements/ScrollTop";
import AddButton from "../../../../intelws_portal/constructs/elements/AddButton";
import { IButtonRepr, IDataRepr, IDropdownRepr, IMultiDropdownRepr, INumberInputRepr, ITextAreaRepr, IProgressBar }
from "../../../../intelws_portal/constructs/elements/Table";
import { get, getCancelToken, post } from "../../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../../declarations/common";
import { getBundledAlertsUI } from "../../../../declarations/common_utils";
import ModalManualSubTask from "../../modal/ModalManualSubTask"

interface IMatchProps {
    userId: number,
    taskId: number
}

export interface IManualSubTaskEditRepr extends IDataRepr {
    t_name: string,
    t_notes: ITextAreaRepr | string,
    t_current_status_name: IDropdownRepr | string,
    t_percent:  INumberInputRepr | number | IProgressBar ,
    t_emp_assigned: IMultiDropdownRepr | string,
    t_button: IButtonRepr
}

export interface IManualSubTaskRepr extends IDataRepr {
    t_name: string,
    t_notes: string,
    t_current_status_name: string,
    t_percent: number,
    t_emp_assigned: string,
    t_button: IButtonRepr
}

interface IFetchManualTaskDetails extends AxiosResponse {
    data: {
        data: IManualSubTaskEditRepr[],
        is_add: boolean,
        is_disable_action: boolean
    }
}

function ManualTaskDetailsDashboard(props: IPageComponentProps<IMatchProps>) {
    const ENDPOINT = "/api/manualtasklist/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isAdd, setIsAdd] = React.useState(false);
    const [isDisableAction, setIsDisableAction] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState(-1);
    const [tableDataEdit, setTableDataEdit] = React.useState<IManualSubTaskEditRepr[] | undefined>(undefined);
    const [tableData, setTableData] = React.useState<IManualSubTaskRepr[] | undefined>(undefined);
    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ManualTaskDetails");
        }
        let queryParams = {
            section: "manual_task_details",
            user_id: props.match.params.userId,
            task_tracking_id: props.match.params.taskId
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchManualTaskDetails>;
        requestResponse.then((response) => {
            setTableDataEdit(response.data.data);
            setIsAdd(response.data.is_add);
            setIsDisableAction(response.data.is_disable_action);
            setTableData(transferEditToView(response.data.data));
            setIsLoaded(true);
        })
    }, [])

    function transferEditToView(data: IManualSubTaskEditRepr[]) {
        let returnData: IManualSubTaskRepr[] = [];
        let editButton: IButtonRepr = {type: "button", display:  {"icon": "Edit"}, action: "custom", identifier: "editButton"}
        data.forEach((ele) => {
            let dataEle = {
                id: ele.id,
                t_name: ele.t_name,
                t_notes: "",
                t_percent: -1,
                t_current_status_name: "",
                t_emp_assigned: "",
                t_button: editButton 
            }
            if (typeof ele.t_notes == "string") {
                dataEle.t_notes = ele.t_notes;
            } else {
                dataEle.t_notes = ele.t_notes.value;
            }

            if (typeof ele.t_current_status_name == "string") {
                dataEle.t_current_status_name = ele.t_current_status_name;
            } else {
                let selectedValue = ele.t_current_status_name.value;
                let selectedIndex = -1;
                if (ele.t_current_status_name.values != undefined) {
                    selectedIndex = ele.t_current_status_name.values.findIndex((loopEle) => {
                        return loopEle == selectedValue;
                    })
                }
                dataEle.t_current_status_name = [
                    {"type": "statusdot", "status": ele.t_current_status_name.options[selectedIndex] as string},
                    {"type": "text", "value": ele.t_current_status_name.options[selectedIndex] as string}
                ] as any
            }

            if (typeof ele.t_percent == "number") {
                dataEle.t_percent = ele.t_percent;
            } else {
                dataEle.t_percent = ele.t_percent.value;
            }
            dataEle.t_percent = {type:"percent", value: dataEle.t_percent} as any

            if (typeof ele.t_emp_assigned == "string") {
                dataEle.t_emp_assigned = ele.t_emp_assigned;
            } else {
                let selectedValues = ele.t_emp_assigned.value;
                let allValues = ele.t_emp_assigned.values;
                let selectedValuesIndex: number[] = [];
                if (selectedValues != undefined) {
                    selectedValues.forEach((selectedValue) => {
                        let selectedIndex = -1;
                        if (allValues != undefined) {
                            selectedIndex = allValues.findIndex((currValue) => {
                                return currValue == selectedValue;
                            })
                        }
                        selectedValuesIndex.push(selectedIndex);
                    })
                }
                let empAssignedString = "";
                selectedValuesIndex.forEach((selectedValue, currIndex) => {
                    if (currIndex != 0) {
                        empAssignedString += ", ";
                    }
                    if (typeof ele.t_emp_assigned != "string") {
                        empAssignedString += ele.t_emp_assigned.options[selectedValue];
                    }
                })
                dataEle.t_emp_assigned = empAssignedString;
            }
            returnData.push(dataEle);
        })
        return returnData;
    }

    function inputCallback(index: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (accessKey == "t_button" && typeof value == "string") {
            if (value == "editButton") {
                setEditIndex(index);
            } else if (value == "updateButton") {
                let queryParams = {
                    section: "update_manual_sub_task",
                    user_id: props.match.params.userId,
                    task_tracking_id: props.match.params.taskId
                }
                let formData = new FormData();
                if (tableDataEdit != undefined) {
                    let rowValue = tableDataEdit[editIndex];
                    formData.append("id", rowValue.id.toString());
                    formData.append("t_name", rowValue.t_name);
                    if (typeof rowValue.t_notes == "string") {
                        formData.append("t_notes", rowValue.t_notes)
                    } else {
                        formData.append("t_notes", rowValue.t_notes.value);
                    }
                    if (typeof rowValue.t_current_status_name != "string") {
                        formData.append("t_current_status", (rowValue.t_current_status_name.value as number).toString());
                    }
                    formData.append("t_percent", (rowValue.t_percent as INumberInputRepr).value.toString());
                    if (typeof rowValue.t_emp_assigned != "string") {
                        formData.append("t_emp_assigned", JSON.stringify(rowValue.t_emp_assigned.value));
                    }
                    //formData.append("update_record", JSON.stringify(tableDataEdit[editIndex]));
                }
                const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
                requestResponse.then(() => {
                    setTableData((prevTableData) => {
                        if (prevTableData != undefined) {
                            let newTableData = [...prevTableData]
                            if (tableDataEdit != undefined) {
                                newTableData[editIndex] = transferEditToView([tableDataEdit[editIndex]])[0];
                            }
                            return newTableData;
                        }
                    })
                    setEditIndex(-1);
                })
            }
        } else if (accessKey == "t_current_status_name") {
            setTableDataEdit((prevTableDataEdit) => {
                if (prevTableDataEdit != undefined) {
                    let newTableDataEdit = [...prevTableDataEdit];
                    let editRow = newTableDataEdit[editIndex];
                    if (typeof editRow.t_current_status_name != "string" && typeof value == "string") {
                        editRow.t_current_status_name.value = parseInt(value);
                    }
                    return newTableDataEdit;
                }
            })
        } else if (accessKey == "t_percent") {
            setTableDataEdit((prevTableDataEdit) => {
                if (prevTableDataEdit != undefined) {
                    let newTableDataEdit = [...prevTableDataEdit];
                    let editRow = newTableDataEdit[editIndex];
                    if (typeof editRow.t_percent != "number" && typeof value == "string") {
                        editRow.t_percent.value = parseFloat(value);
                    }
                    return newTableDataEdit;
                }
            })
        } else if (accessKey == "t_emp_assigned") {
            setTableDataEdit((prevTableDataEdit) => {
                if (prevTableDataEdit != undefined) {
                    let newTableDataEdit = [...prevTableDataEdit];
                    let editRow = newTableDataEdit[editIndex];
                    if (typeof editRow.t_emp_assigned != "string" && typeof value != "undefined" && typeof value != "string" && typeof value != "boolean") {
                        editRow.t_emp_assigned.value = Array.from(value, options => parseInt(options.value));
                    }
                    return newTableDataEdit;
                }
            })
        } else if (accessKey == "t_notes") {
            setTableDataEdit((prevTableDataEdit) => {
                if (prevTableDataEdit != undefined) {
                    let newTableDataEdit = [...prevTableDataEdit];
                    let editRow = newTableDataEdit[editIndex];
                    if (typeof editRow.t_notes != "string" && typeof value == "string") {
                        editRow.t_notes.value = value;
                    }
                    return newTableDataEdit;
                }
            })
        }
    }

    function getContent() {
        let columnName = ["Task Name", "Status", "Progress", "Team Member", "Notes"];
        let accessKey = ["t_name", "t_current_status_name", "t_percent", "t_emp_assigned", "t_notes"];
        if (!isDisableAction) {
            columnName.push("Action");
            accessKey.push("t_button");
        }
        let miscHeaders = undefined;
        if (isAdd) {
            miscHeaders = <AddButton buttonValue={<i className="fa fa-plus"></i>}
                onClick={() => $("#manualSubTaskAddModal").modal("show")} />
        }
        let displayTableData: (IManualSubTaskEditRepr | IManualSubTaskRepr)[] = [];
        if (tableData != undefined && tableDataEdit != undefined) {
            tableData.forEach((tableEle, index) => {
                if (index == editIndex) {
                    displayTableData.push(tableDataEdit[index]);
                } else {
                    displayTableData.push(tableData[index]);
                }
            })
        }
        return <ContentBoxTable title={"Tasks"} hasSearch={true} hasRecords={true}
            columnNames={columnName} accessKeys={accessKey} tableData={displayTableData} miscHeaders={miscHeaders}
            inputCallback={inputCallback} /> 
    }

    function addToTable(data?: IManualSubTaskEditRepr, success?: string[], fail?: string[]) {
        if (data != undefined) {
            setTableDataEdit((prevTableData) => {
                if (prevTableData != undefined) {
                    return [...prevTableData, data];
                }
            })
            setTableData((prevTableData) => {
                if (prevTableData != undefined) {
                    return [...prevTableData, transferEditToView([data])[0]];
                }
            })
        }
        if (success != undefined && fail != undefined) {
            setSuccess(success);
            setFail(fail);
        }
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div>
                {getContent()}
                {getBundledAlertsUI(success, fail, setSuccess, setFail)}
                <ModalManualSubTask userId={props.match.params.userId} taskId={props.match.params.taskId} mutator={addToTable} />
                <ScrollTop />
            </div>
        )
    }
}

export default ManualTaskDetailsDashboard;