import { AxiosResponse } from "axios";
import React from "react";
import { getCancelToken, post } from "../../../../../intelws_portal/utils/backendInterface";
import { IPageComponentProps } from "../../../../../declarations/common";
import { parseQueryResponseOnFail, parseQueryResponseOnFailResponse } from "../../../../../declarations/common_utils";
import AdminFooter from "../admin_home_pages/AdminFooter";
import AuthSidePanel from "./UIComponents/AuthSidePanel";
import { IRedirectResponse } from "./Signin";

interface IUrlParams {
	adminCode: string,
	adminId: string,
	userId: string
}

interface IAdminInfoFetch extends AxiosResponse {
    data: {
        data: {
            admin_company: string
        }
    }
}

function ClientActivation(props: IPageComponentProps<IUrlParams>) {
	const ENDPOINT = "/api/authentication/";
	const [activationCode, setActivationCode] = React.useState("");
	const [countdownTimer, setCountdownTimer] = React.useState(5);
	const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
	const [companyName, setCompanyName] = React.useState("");
	const [isFetchError, setIsFetchError] = React.useState(false);
	const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);

	const cancelTokenSource = React.useRef(getCancelToken());

	React.useEffect(() => {
		return () => {
			cancelTokenSource.current.cancel();
		}
	}, [])

	function fetchedAdminInfo(isSuccess: boolean, response?: IAdminInfoFetch) {
		if (isSuccess && response != undefined) {
			setCompanyName(response.data.data.admin_company);
		} else {
			setIsFetchError(true);
		}
	}

	function getEmptyErrorObj() {
		return {
			activation_code: {
				isError: false,
				errorText: ""
			}
		}
	}

	function formFieldChange(e: React.ChangeEvent<HTMLInputElement>, section: "activationCode") {
		if (section == "activationCode") {
			setActivationCode(e.target.value);
		}
	}

	function onSuccess(response: IRedirectResponse) {
		setShowSuccessMessage(true);
		setActivationCode("");
		setInterval(() => {
			setCountdownTimer((prevCountdownTimer) => {
				let newCountdownTimer = prevCountdownTimer - 1;
				if (newCountdownTimer == 0) {
					if (typeof response.data.path == "string") {
						window.location.pathname = response.data.path;
					} else {
						window.location.hash = response.data.path[1];
						window.location.pathname = response.data.path[0];
					}
				}
				return newCountdownTimer;
			})
		}, 1000);
	}

	function formSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		let queryParams = {
			section: "client_activation"
		}
		let formData = new FormData();
		formData.append("admin_id", props.match.params.adminId);
		formData.append("user_id", props.match.params.userId);
		formData.append("activation_code", activationCode);
		formData.append("admin_code", props.match.params.adminCode);
		const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IRedirectResponse>;
		requestResponse.then((response) => {
			onSuccess(response);
		}).catch((error) => {
			let [success, fail, failResponse] = parseQueryResponseOnFail(error);
			let errorObj = getEmptyErrorObj();
			parseQueryResponseOnFailResponse(errorObj, failResponse);
			setErrorObj(errorObj);
		})
	}

	return (
		<div className="footer_section bg_with_circle">
			{(() => {
				let className = "alert alert-success w-50 fixed-bottom col-md-4 col-md-offset-4 alert-dismissible fade";
				if (showSuccessMessage) {
					className += " show";
				}
				return (
					<div className="row">
						<div className={className} role="alert">
							Thank You! Your account has been activated. Redirecting to login in {countdownTimer} ...
							<button type="button" className="close" data-dismiss="alert" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					</div>
				)
			})()}
			<div className="banner_content_area">
				<section id="mainsigin">
					<section className="loginpageSec clearfix">
						<AuthSidePanel />
						<div className="rightBgClr f-right clearfix" style={{ height: '110vh' }}>
							<div className="loginCont clearfix">
								{(() => {
									if (isFetchError) {
										return <a href="#"><b></b>Invalid Request</a>
									} else {
										return <a href="#"><b></b>{companyName}</a>
									}
								})()}
								<h2>Account Activation</h2>
								<form id="signinForm" onSubmit={formSubmit}>
									<ul className="typeInput clearfix">
										<div className="right_contact_form">
											<div className="form-group" style={{ paddingBottom: '0px', marginBottom: '0px' }}>
												<li>
													{(() => {
														let className = "form-control";
														if (errorObj.activation_code.isError) {
															className += " is-invalid";
														}
														return (
															<React.Fragment>
																<input id="user" name="user" type="text" className={className} placeholder="Enter six digit code" required
																	style={{ paddingTop: '15px', paddingBottom: '15px' }}
																	value={activationCode} onChange={(e) => formFieldChange(e, "activationCode")} />
																{(() => {
																	if (errorObj.activation_code.isError) {
																		return (
																			<div className="invalid-feedback">
																				{errorObj.activation_code.errorText}
																			</div>
																		)
																	}
																})()}
															</React.Fragment>
														)
													})()}
												</li>
											</div>
										</div>
									</ul>
								</form>
								<div className="rememberSec clearfix pt-20">
									<button id="submit" type="submit" form="signinForm" style={{ width: '40%', padding: '3% 2%' }} className="f-left btn-secondary-add-cpasage ml-0">Submit</button>
								</div>
							</div>
						</div>
					</section>
				</section>
				<AdminFooter adminCode={props.match.params.adminCode} fetchedAdminInfo={fetchedAdminInfo} />
			</div>
		</div>
	);
}

export default ClientActivation;