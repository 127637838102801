import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";

import {post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import { parseQueryResponseOnFail, parseQueryResponseOnFailResponse, parseQueryResponseOnSuccess } from '../../../declarations/common_utils';

export interface IModalClientReferProps {
    userId: string,
    mutator: (success?: string[], fail?: string[]) => void
}

function ModalClientRefer(props: IModalClientReferProps) {
    const ENDPOINT = "api/clientdashboard/";
    const [email, setEmail] = React.useState("");
    const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        $("#clientReferralModal").on("hidden.bs.modal", clearForm)
        return () => {
            cancelTokenSource.current.cancel();
            $("#clientReferralModal").off("hidden.bs.modal");
        }
    }, [])

    function clearForm() {
        setEmail("");
        setErrorObj(getEmptyErrorObj);
    }

    function getEmptyErrorObj() {
        return {
            email: {
                isError: false,
                errorText: ""
            }
        }
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement>, section: "email") {
        if (section == "email") {
            setEmail(e.target.value);
        }
    }

    function submitForm() {
        let formData = new FormData();
        formData.append("email", email);
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, { section: "client_referral", user_id: props.userId });
        requestResponse.then((response) => {
            let [success, fail] = parseQueryResponseOnSuccess(response);
            props.mutator(success, fail);
            $("#clientReferralModal").modal("hide");
        }).catch((error) => {
            let [success, fail, failResponse] = parseQueryResponseOnFail(error);
            let errorObj = getEmptyErrorObj(); 
            parseQueryResponseOnFailResponse(errorObj, failResponse);
            setErrorObj(errorObj);
        })

    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="clientReferralModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Refer a Friend"} />
                        <div className="modal-body">
                            <ModalInputText onChange={(e) => formFieldChange(e, "email")} value={email} title={"Email"} 
                                required borderBottom isError={errorObj.email.isError} errorText={errorObj.email.errorText} /> <br />
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={false} disable={false}/>
                    </div>
                </div>
            </div>
        )
    }
    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalClientRefer;