import React from "react";

export interface IModalFilePickerProps {
    disabled?: boolean,
    title?: string | JSX.Element,
    value?: File,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

function ModalFilePicker(props: IModalFilePickerProps) {
    //const fileRef = React.useRef<React.LegacyRef<HTMLInputElement | null>(null);
    const fileRef = React.useRef(null);

    React.useEffect(() => {
        if (props.value == undefined) {
            (fileRef.current as any).value = "";
        }
    }, [props.value])

    function getFileInput() {
        return <input type="file" ref={fileRef} onChange={props.onChange} disabled={props.disabled} />
    }

    if (props.title != undefined) {
        return (
            <div className="row">
                <div className="col-md-8 col-sm-12">
                    <p className="form-label">{props.title} </p>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="custom-form-group">
                        <p></p>
                        {getFileInput()}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="row">
                <div className="col-md-4 col-sm-12">
                    <div className="custom-form-group">
                        <p></p>
                        {getFileInput()}
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalFilePicker; 