import React from "react";
import * as urls from "../../../../Urls";

interface AdminNav{
    companyName?:string,
    adminCode:string
}
export function AdminNavBar(props:AdminNav)
{
return(
    <React.Fragment>
        <header id="header" className="fixed-top">
    <div className="container d-flex align-items-center justify-content-between">
      <h1 className="logo"><a href="#">{props.companyName}</a></h1>
      <a href="#" className="logo"><img src="assets/img/logo.png" alt="" className="img-fluid" /></a>

      <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link scrollto" href={urls.BASE_ADMIN_HOME + props.adminCode + "/"}>Home</a></li>
          <li><a className="nav-link scrollto" href={urls.BASE_ADMIN_ABOUTUS + props.adminCode + "/"}>About Us</a></li>
          <li><a className="nav-link scrollto" href={urls.BASE_ADMIN_SERVICES + props.adminCode + "/"}>Services</a></li>
          {/* <li><a className="nav-link scrollto" href={urls.BASE_CONTACT_US + props.adminCode + "/"}>Contact</a></li> */}
          <li><a className="nav-link scrollto" href={urls.BASE_ADMIN_SIGNIN + props.adminCode + "/"}>Sign In</a></li>
          <li><a className="nav-link scrollto" href={urls.BASE_CLIENT_SIGNUP + props.adminCode + "/"}>Sign Up</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>

    </div>
  </header>
    </React.Fragment>
)
}
