import React, { CSSProperties } from "react";

export interface IModalFooterProps {
    saveInactive: boolean,
    buttonConfig?: {
        buttonName: string,
        inactive?: boolean,
        callback?: (e: React.MouseEvent<HTMLButtonElement>) => void
    }[],
    saveCallback: (e: React.MouseEvent<HTMLButtonElement>) => void,
    disable?: boolean,
    disableSaveBtnName?: string,
    saveBtnName?: string
}

function ModalFooter(props: IModalFooterProps) {
    function makeButtons() {
        if (props.buttonConfig != undefined) {
            return props.buttonConfig.map((ele, index) => {
                return <button key={index} type="button" className="btn btn-secondary-add-cpasage ml-0" 
                    onClick={ele.callback} disabled={!(ele.inactive == false)}>
                        {ele.buttonName}
                    </button>
            })
        }
    }

    
    function makeSaveButton() {
        if (props.saveInactive) {
            return <button type="button" className="btn btn-secondary-add-cpasage pull-right" onClick={props.saveCallback}
                disabled>{props.disableSaveBtnName ?? "Saving..."}</button>
        }
        return <button type="button" className="btn btn-secondary-add-cpasage ml-0 pull-right" onClick={props.saveCallback} disabled={props.disable}>
            {props.saveBtnName ?? "Save"}</button>
    }

    let style: CSSProperties | undefined = undefined;
    if (props.buttonConfig != undefined) {
        style = {justifyContent: 'space-between'};
    }

    return (
        <div className="modal-footer" style={style}>
            {makeButtons()}
            {makeSaveButton()}
        </div>
    )
}

export default ModalFooter;
