import React from 'react';
import Chart, { ChartDataSets } from 'chart.js';

export interface IPieChartProps {
    legend: boolean,
    dataset: ChartDataSets[],
    labels?: string[],
}

function PieChart(props: IPieChartProps) {
    const chart = React.useRef<Chart>();
    const chartRef = React.useRef();
    React.useEffect(() => {
        chart.current = new Chart(chartRef.current as any, {
            type: 'doughnut',
            data: {
                labels: props.labels,
                datasets: props.dataset,
            },
            options: {
                legend: {
                    display: props.legend,
                    position: "right",
                    fullWidth: true,
                    align: "center",
                },
                cutoutPercentage: 60,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 20,
                    }
                }
            },

        }
        )
    }, [])

    React.useEffect(() => {
        if (chart.current != undefined) {
            if (props.labels != undefined) {
                chart.current.data.labels = props.labels;
            }
            chart.current.data.datasets = props.dataset;
            chart.current.update();
        }
    }, [props.labels, props.dataset])

    return (
        <canvas className="custom-charts" ref={chartRef as any} />
    )
}

export default PieChart;