import React from "react";

export interface IDatePickerProps {
    dateValue: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void

}

function DatePicker(props: IDatePickerProps) {
    return <input type="date" value={props.dateValue} onChange={props.onChange} style={{border : "none"}}/>
}

export default DatePicker;