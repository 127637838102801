import React from "react";

import Loading from "../../../../intelws_portal/constructs/elements/Loading";

import Tabs, { ITabRepr } from "../../../../intelws_portal/constructs/elements/Tabs";

import ClientDashboard from "../../common/clientDashboard/Dashboard/ClientDashboard";
import ClientCommunication from "../../common/clientDashboard/ClientCommunication";
import ClientDocuments from "../../common/clientDashboard/ClientDocument";
import ClientStatus from "../../common/clientDashboard/ClientStatus";
import ClientBusiness from "../../common/clientDashboard/ClientBusiness";
import ClientOrganizer from "../../common/clientDashboard/ClientOrganizer";
import ClientCard from "../../common/clientDashboard/Dashboard/ClientCard";
import ClientInvoice from "./ClientInvoice";
import ClientProject from "./ClientProject";
import ContentBox from "../../../../intelws_portal/constructs/elements/ContentBox";
import { IPageComponentProps } from "../../../../declarations/common";
import { IDataRepr } from "../../../../intelws_portal/constructs/elements/Table";
import { IMenuOptionsConfig } from "../../../App";
import ClientDependent from "./ClientDependent";

interface IMatchProps {
    userId: string
}

export interface IClientDashboardLanding<T> extends IPageComponentProps<T> {
    mutator: {
        currentPage?: (currentPage: string) => void,
        getMenuOptionsConfig: () => IMenuOptionsConfig
    },
}


function ClientDashboardLanding(props: IClientDashboardLanding<IMatchProps>) {
    const [bnsValues, setBnsValues] = React.useState<number[]>([]);
    const [bnsShow, setBnsShow] = React.useState<string[]>([]);
    const [clientContent, setClientContent] = React.useState<(string | undefined)[]>(() => {
        let clientContent = new Array(props.mutator.getMenuOptionsConfig().client_dashboard?.length).fill(undefined);
        clientContent[0] = (props.mutator.getMenuOptionsConfig().client_dashboard as ITabRepr[])[0].target;
        return clientContent;
    });
    const [clientTabs, setClientTabs] = React.useState<ITabRepr[]>(props.mutator.getMenuOptionsConfig().client_dashboard as ITabRepr[]);

    function resolveTargetToComponent(target?: string) {
        if (target == "clientDashboardTabContent") {
            return <ClientDashboard match={props.match} history={props.history} location={props.location} mutator={props.mutator} />;
        } else if (target == "clientCommunicationTabContent") {
            return <ClientCommunication match={props.match} history={props.history} location={props.location}
                mutator={{ getMenuOptionsConfig: props.mutator.getMenuOptionsConfig }} />;
        } else if (target == "clientDocumentTabContent") {
            return <ClientDocuments match={props.match} mutator={{ bnsDocCallback: bnsDocCallback }}
                bnsValues={bnsValues} bnsShow={bnsShow} history={props.history} location={props.location} />;
        } else if (target == "clientStatusTabContent") {
            return <ClientStatus match={props.match} history={props.history} location={props.location} />;
        } else if (target == "clientBusinessTabContent") {
            return <ClientBusiness match={props.match} mutator={{ bnsOperCallback: bnsOperCallback, getMenuOptionsConfig: props.mutator.getMenuOptionsConfig }}
                history={props.history} location={props.location} />;
        } else if (target == "clientOrganizerTabContent") {
            return <ClientOrganizer match={props.match} history={props.history} location={props.location} />;
        } else if (target == "clientInvoiceTabContent") {
            return <ClientInvoice match={props.match} history={props.history} location={props.location} />
        } else if (target == "clientProjectsTabContent") {
            return <ClientProject match={props.match} mutator={{ bnsDocCallback: bnsDocCallback }}
                bnsValues={bnsValues} bnsShow={bnsShow} history={props.history} location={props.location} />;
        }else if (target == "clientDependentTabContent") {
            return <ClientDependent match={props.match} history={props.history} location={props.location} />
        }
        
        return <Loading />
    }

    function bnsDocCallback(bnsData: IDataRepr[]) {
        let bnsShow = ["Select Business"];
        let bnsValues = [0];
        bnsData.forEach((ele) => {
            bnsShow.push(ele.bns_name as string);
            bnsValues.push(ele.id);
        })
        setBnsShow(bnsShow);
        setBnsValues(bnsValues);
    }

    function bnsOperCallback(bns: IDataRepr, oper: string) {
        setBnsValues((prevBnsValues) => {
            return [...prevBnsValues, bns.id];
        })
        setBnsShow((prevBnsShow) => {
            return [...prevBnsShow, bns.bns_name as string];
        })
    }



    function getContent() {
        let contents: { content: JSX.Element }[] = [];
        clientContent.forEach((ele) => {
            contents.push({ content: resolveTargetToComponent(ele) });
        })
        return (
            <Tabs center={true} tabs={clientTabs} contents={contents}
                tabCallback={(tabName, index) => {
                    setClientContent((prevClientContent) => {
                        let newClientContent = [...prevClientContent];
                        newClientContent[index] = clientTabs[index].target;
                        return newClientContent;
                    })
                }} />
        )
    }

    function aggregrateContent() {
        if (props.mutator.getMenuOptionsConfig().client_card) {
            return (
                <div>
                    <ClientCard userId={parseInt(props.match.params.userId)} clientContext={false} />
                    {getContent()}
                </div>
            )
        } else {
            return (
                <div>
                    {getContent()}
                </div>
            )
        }

    }

    return (
        <div className="body-content-wrapper">
            <ContentBox body={aggregrateContent()} />
        </div>
    )
}

export default ClientDashboardLanding

