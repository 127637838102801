import { AxiosResponse } from "axios";
import React from "react";
import { getCancelToken, post } from "../../../../../intelws_portal/utils/backendInterface";
import { IPageComponentProps } from "../../../../../declarations/common";
import { parseQueryResponseOnFail, parseQueryResponseOnFailResponse } from "../../../../../declarations/common_utils";
import ResetPassword from "./ResetPassword";
import AdminFooter from "../admin_home_pages/AdminFooter";
import AuthFooter from "./UIComponents/AuthFooter";
import AuthSidePanel from "./UIComponents/AuthSidePanel";

interface IPostForgotPasswordEmailValidate extends AxiosResponse {
    data: {
        data: {
            access_mark: string,
            access_id: string
        }
    }
}

interface IAdminInfoFetch extends AxiosResponse {
    data: {
        data: {
            admin_company: string
        }
    }
}

interface IUrlParams {
    adminCode: string
}

export interface IRegularForgotPassword {
    isRegularForgotPassword: boolean
}

function isRegularForgotPassword(value: any): value is IRegularForgotPassword {
	const wrappedValue = value as IRegularForgotPassword;
	return (
		wrappedValue.isRegularForgotPassword !== undefined
    )
}

function ForgotPassword(props: IPageComponentProps<IUrlParams> | IRegularForgotPassword) {
    const ENDPOINT = "/api/authentication/";
    const [email, setEmail] = React.useState("");
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
    const [showResetPassword, setShowResetPassword] = React.useState(false);
    const [accessMark, setAccessMark] = React.useState('');
    const [accessId, setAccessId] = React.useState('');
    const [isAdminSignin, setIsAdminSignin] = React.useState(false);
    const [isFetchError, setIsFetchError] = React.useState(false);
    const [companyName, setCompanyName] = React.useState("");
    const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    function fetchedAdminInfo(isSuccess: boolean, response?: IAdminInfoFetch) {
        if (isSuccess && response != undefined) {
            setCompanyName(response.data.data.admin_company);
        } else {
            setIsFetchError(true);
        }
        setIsAdminSignin(true);
    }

    function getEmptyErrorObj() {
        return {
            email: {
                isError: false,
                errorText: ""
            }
        }
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement>,
        section: "email") {
        if (section == "email") {
            setEmail(e.target.value);
        }
    }

    function clientSideValidation() {
        let errorObj = getEmptyErrorObj();
        let returnValue = true;;
        if (email == "") {
            errorObj.email.isError = true;
            errorObj.email.errorText = "Email is required";
            returnValue = false;
        }
        setErrorObj(errorObj);
        return returnValue;
    }

    function formSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (clientSideValidation()) {
            let queryParams = {
                section: "forgot_password_email_validate"
            }
            let formData = new FormData();
            formData.append("email", email);
            if (isAdminSignin && !isRegularForgotPassword(props)) {
                formData.append("admin_code", props.match.params.adminCode);
            }
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostForgotPasswordEmailValidate>;
            requestResponse.then((response) => {
                setShowSuccessMessage(true);
                setShowResetPassword(true);
                setAccessMark(response.data.data.access_mark);
                setAccessId(response.data.data.access_id);
            }).catch((error) => {
                let [success, fail, failResponse] = parseQueryResponseOnFail(error);
                let errorObj = getEmptyErrorObj();
                parseQueryResponseOnFailResponse(errorObj, failResponse);
                setErrorObj(errorObj);
            })
        }
    }

    function signupFormElements() {
        return (
            <ul className="typeInput clearfix">
                <div className="right_contact_form">
                    <div className="form-group" style={{ paddingBottom: '0px', marginBottom: '0px' }}>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                if (errorObj.email.isError) {
                                    className += " is-invalid";
                                }
                                return (
                                    <React.Fragment>
                                        <input type="email" name="user" className={className}
                                            placeholder="Email" style={{ paddingTop: '15px', paddingBottom: '15px' }}
                                            value={email} onChange={(e) => formFieldChange(e, "email")} />
                                        {(() => {
                                            if (errorObj.email.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.email.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                    </div>
                </div>
            </ul>
        )
    }

    return (
        <React.Fragment>
            {(() => {
                let className = "alert alert-success w-50 fixed-bottom col-md-4 col-md-offset-4 alert-dismissible fade";
                if (showSuccessMessage) {
                    className += " show";
                }
                return (
                    <div className="row">
                        <div className={className} role="alert">
                            Thank You! An email has been sent with the steps to reset your password.
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )
            })()}
            {(() => {
                if (showResetPassword) {
                    let propObj: { accessMark: string, accessId: string, adminCode?: string } = { accessMark: accessMark, accessId: accessId };
                    if (!isRegularForgotPassword(props)) {
                        propObj.adminCode = props.match.params.adminCode;
                    }
                    return <ResetPassword match={{ params: propObj } as unknown as any} history={{} as any} location={{} as any} />
                }
                return (
                    <div className="footer_section bg_with_circle">
                        <div className="banner_content_area">
                            <section id="mainsigin">
                                <section className="loginpageSec clearfix">
                                    <AuthSidePanel />
                                    <div className="rightBgClr f-right clearfix" style={{ height: '110vh' }}>
                                        <div className="loginCont clearfix">
                                            {(() => {
                                                if (isAdminSignin) {
                                                    if (isFetchError) {
                                                        return <a href="#"><b></b>Invalid Request</a>
                                                    } else {
                                                        return <a href="#"><b></b>{companyName}</a>
                                                    }
                                                }
                                            })()}
                                            <h2>Forgot Password</h2>
                                            <p className="italicFont">Please enter your details</p>
                                            <form style={{ height: '50%', paddingBottom: '20%' }} onSubmit={formSubmit}>
                                                {signupFormElements()}
                                                <div className="rememberSec clearfix pt-10">
                                                    <button id="signup_btn" type="submit" className="f-left btn-secondary-add-cpasage mt-0 ml-0"
                                                        style={{ width: '40%', padding: '3% 2%' }} >Submit</button>
                                                </div>
                                                <br />
                                                <br /><br />
                                            </form>
                                        </div>
                                    </div>
                                </section>
                            </section>
                            {(() => {
                                if (!isRegularForgotPassword(props)) {
                                    return <AdminFooter adminCode={props.match.params.adminCode} fetchedAdminInfo={fetchedAdminInfo} />
                                } else {
                                    return <AuthFooter />
                                }
                            })()}
                        </div>
                    </div>
                )
            })()}

        </React.Fragment>
    )
}

export default ForgotPassword;