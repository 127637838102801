import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from "axios";

import { get, post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";

import ModalDropDown from "../../../intelws_portal/constructs/modal/ModalDropDown";




function ModalDependent(props: any) {
    const ENDPOINT = "/api/clientdashboard/";
    
    const cancelTokenSource = React.useRef(getCancelToken());
    const [disable, setDisable] = React.useState(false);    
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [deptType, setdeptType] = React.useState("");
    const [deptTypeOptions, setDeptTypeOptions] = React.useState<string[]>(["Select Dependent Type", "Wife", "Son"]);
    const [deptTypeValues, setDeptTypeValues] = React.useState<number[]>([]);


    React.useEffect(() => {
      
        $("#manualSubTaskAddModal").on("hidden.bs.modal", clearForm)
        return () => {
            cancelTokenSource.current.cancel();
            $("#manualSubTaskAddModal").off("hidden.bs.modal");
        }
    }, [])

    function clearForm() {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setdeptType("");
    }

    function formChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> , section: "firstName" | "lastName" | "email" | "phone" | "deptType") {
        if (section == "firstName" ) {
            setFirstName(e.target.value);
        } else if (section == "lastName" ) {
            setLastName(e.target.value);
        } else if (section == "email" ) {
            setEmail(e.target.value);
        } else if (section == "phone" ) {
            setPhone(e.target.value);
        } else if (section == "deptType" ) {
            setdeptType(e.target.value);
        } 
    }

    function submitForm() {
        // let formData = new FormData();
        // let queryParams = {
        //     section: "add_manual_sub_task",
        //     user_id: props.userId,
        //     task_tracking_id: props.taskId
        // }
        // let subTask: ISubTaskRepr = {
        //     t_name: taskName,
        //     t_emp_assigned: []
        // }
        // teamValue.forEach((ele) => {
        //     let teamIndex = teamValues.findIndex((value) => value == ele);
        //     subTask.t_emp_assigned.push({emp_id: ele, emp_name: teamDisplay[teamIndex]});
        // })
        // formData.append("sub_task", JSON.stringify(subTask));
        // const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostAddTask>;
        // requestResponse.then((response) => {
        //     let [success, fail] = parseQueryResponseOnSuccess(response);
        //     props.mutator(response.data.data, success, fail);
        // }).catch((error) => {
        //     let [success, fail] = parseQueryResponseOnFail(error);
        //     props.mutator(undefined, success, fail);
        // })
        // .finally(() => {
             $("#manualSubTaskAddModal").modal("hide");
        // })
        
    }

    
    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="manualSubTaskAddModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Add Dependent"} />
                        <div className="modal-body">
                            <ModalInputText title={"First Name"} value={firstName} onChange={(e) => formChange(e, "firstName")}  borderBottom required/><br></br>
                            <ModalInputText title={"Last Name"} value={lastName} onChange={(e) => formChange(e, "lastName")}  borderBottom required/><br></br>
                            <ModalInputText title={"Email"} value={email} onChange={(e) => formChange(e, "email")}  borderBottom required/><br></br>
                            <ModalInputText title={"Phone"} value={phone} onChange={(e) => formChange(e, "phone")}  borderBottom required/><br></br>
                            <ModalDropDown value={deptType} options={deptTypeOptions}
                                        values={deptTypeValues}
                                        onChange={(e) => formChange(e, "deptType")} borderBottom />
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={false} disable={disable}/>
                    </div>
                </div>
            </div>
        )
    }
    
    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalDependent;