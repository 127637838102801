import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from 'axios';

import Tabs from "../../../intelws_portal/constructs/elements/Tabs";
import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { get, post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalDropdown from "../../../intelws_portal/constructs/modal/ModalDropDown";
import ModalFilePicker from "../../../intelws_portal/constructs/modal/ModalFilePicker";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";

import * as urls from "../../Urls";
import { parseQueryResponseOnFail, parseQueryResponseOnSuccess } from "../../../declarations/common_utils";
import ModalCheckbox from '../../../intelws_portal/constructs/modal/ModalCheckbox';

interface ICatConfig {
    uploadpersonal: number,
    uploadbusiness: number
}

export interface IFetchConfig extends AxiosResponse {
    data: {
        years: number[],
        per_file_types: {
            id: number,
            file_type_name: string
        }[],
        bns_file_types: {
            id: number,
            file_type_name: string
        }[],
        cat_config: ICatConfig,
        access_options: string[],
        access_values: number[],
        dep_options: string[],
        dep_values: number[],
        is_user: boolean
    }
}

interface IPostFile extends AxiosResponse {
    data: {
        data: IPostFileDataRepr,
        query_response?: {
            success?: string[],
            fail?: string[]
        }
    }
}

export interface IPostFileDataRepr {
    cat: string,
    access: string,
    l_files: IDataRepr[]
}

export interface IModalUploadProps {
    userId: number,
    mutator: (data?: IPostFileDataRepr, success?: string[], fail?: string[]) => void,
    bnsShow: string[],
    bnsValues: number[]
}

function ModalUpload(props: IModalUploadProps) {
    const ENDPOINT = "api/clientdashboard/";
    const [years, setYears] = React.useState<number[]>([]);
    const [perFileTypeShow, setPerFileTypeShow] = React.useState<string[]>([]);
    const [perFileTypeValues, setPerFileTypeValues] = React.useState<number[]>([]);
    const [bnsFileTypeShow, setBnsFileTypeShow] = React.useState<string[]>([]);
    const [bnsFileTypeValues, setBnsFileTypeValues] = React.useState<number[]>([]);
    const [catConfig, setCatConfig] = React.useState<ICatConfig>({ uploadpersonal: -1, uploadbusiness: -1 });
    const [taxYear, setTaxYear] = React.useState(0);
    const [access, setAccess] = React.useState(1);
    const [dep, setDep] = React.useState(1);
    const [bnsId, setBnsId] = React.useState(0);
    const [catId, setCatId] = React.useState(-1);
    const [fileType, setFileType] = React.useState([0]);
    const [file, setFile] = React.useState<(File | undefined)[]>([undefined]);
    const [isLock, setIsLock] = React.useState(false);
    const [accessOptions, setAccessOptions] = React.useState<string[]>([]);
    const [accessValues, setAccessValues] = React.useState<number[]>([]);
    const [depOptions, setDepOptions] = React.useState<string[]>([]);
    const [depValues, setDepValues] = React.useState<number[]>([]);
    const [inTransit, setInTransit] = React.useState(false);
    const [isUser, setIsUser] = React.useState(false);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        let queryParams = {
            section: "upload_config",
            user_id: -1
        }
        if (!Number.isNaN(props.userId)) {
            queryParams.user_id = props.userId;
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchConfig>;
        requestResponse.then((response) => {
            let perFileTypeShow: string[] = ["Select File Type"];
            let perFileTypeValues: number[] = [0];
            let bnsFileTypeShow: string[] = ["Select File Type"];
            let bnsFileTypeValues: number[] = [0];
            response.data.per_file_types.forEach((ele) => {
                perFileTypeShow.push(ele.file_type_name);
                perFileTypeValues.push(ele.id);
            })
            response.data.bns_file_types.forEach((ele) => {
                bnsFileTypeShow.push(ele.file_type_name);
                bnsFileTypeValues.push(ele.id);
            })
            setYears(response.data.years);
            setCatConfig(response.data.cat_config);
            setCatId(response.data.cat_config.uploadpersonal);
            setAccessOptions(response.data.access_options);
            setDepOptions(response.data.dep_options);
            setAccessValues(response.data.access_values);
            setDepValues(response.data.dep_values);
            setAccess(response.data.access_values[0]);
            setDep(response.data.dep_values[0]);
            setTaxYear(response.data.years[0]);
            setPerFileTypeShow(perFileTypeShow);
            setPerFileTypeValues(perFileTypeValues);
            setBnsFileTypeShow(bnsFileTypeShow);
            setBnsFileTypeValues(bnsFileTypeValues);
            setIsUser(response.data.is_user);
        })
        return () => {
            cancelTokenSource.current.cancel();
            $("#fileUploadModal").off("hidden.bs.modal");
        }
    }, [])

    React.useEffect(() => {
        $("#fileUploadModal").on("hidden.bs.modal", clearForm)
    }, [years, accessValues, depValues])

    function clearForm() {
        setTaxYear(years[0]);
        setAccess(accessValues[0]);
        setDep(depValues[0]);
        setBnsId(0);
        setFileType([0]);
        setFile([undefined]);
        setInTransit(false);
        setIsLock(false);
    }

    function formFieldChangeDropdown(e: React.ChangeEvent<HTMLSelectElement>, section: string, index?: number) {
        if (section == "taxYear" || section == "access" || section == "bnsId" || section == "dep") {
            if (section == "taxYear") {
                setTaxYear(parseInt(e.target.value));
            } else if (section == "access") {
                setAccess(parseInt(e.target.value));
                setIsLock(false);
            } else if (section == "bnsId") {
                setBnsId(parseInt(e.target.value));
            } else if (section == "dep") {
                setDep(parseInt(e.target.value));
            }
        } else {
            if (section == "fileType") {
                setFileType((prevFileType) => {
                    let newFileType = [...prevFileType];
                    if (index != undefined) {
                        newFileType[index] = parseInt(e.target.value);
                        if (newFileType[index] == 0) {
                            setFile((prevFile) => {
                                let newFile = [...prevFile];
                                newFile[index] = undefined;
                                return newFile;
                            })
                        }
                    }
                    return newFileType;
                })
            }
        }
    }

    function formFileChangeFile(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        setFile((prevFile) => {
            let newFile = [...prevFile];
            let files = e.target.files;
            if (files != undefined) {
                newFile[index] = files[0];
            }
            return newFile;
        })
    }

    function getFiles(cat: "per" | "bns") {
        let values: number[] = [];
        let options: string[] = [];
        if (cat == "per") {
            values = perFileTypeValues;
            options = perFileTypeShow;
        } else {
            values = bnsFileTypeValues;
            options = bnsFileTypeShow;
        }
        return fileType.map((ele, index) => {
            return <ModalFilePicker key={index} onChange={(e) => formFileChangeFile(e, index)}
                value={file[index]} disabled={ele == 0} title={
                    <ModalDropdown onChange={(e) => formFieldChangeDropdown(e, "fileType", index)}
                        values={values} options={options} value={fileType[index]} borderBottom required />
                } />
        })
    }

    function addFileCallback() {
        setFileType((prevFileType) => {
            return [...prevFileType, 0];
        })
        setFile((prevFile) => {
            return [...prevFile, undefined];
        })
    }

    function getAccessDropdown() {
        if (accessOptions.length > 1) {
            return (
                <div>
                    <ModalDropdown onChange={(e) => formFieldChangeDropdown(e, "access")}
                        options={accessOptions} values={accessValues} value={access} borderBottom />< br />
                </div>
            )
        }
    }

    function getDepDropdown() {
        if (depOptions.length > 1) {
            return (
                <div>
                    <ModalDropdown onChange={(e) => formFieldChangeDropdown(e, "dep")}
                        options={depOptions} values={depValues} value={dep} borderBottom />< br />
                </div>
            )
        }
    }

    function getLockfileCheckBox() {
        if (accessOptions.length > 1) {
            return (
                <div>
                    {(() => {
                        if (access == 1) {
                            return <ModalCheckbox checked={isLock} label={"Lock File(s)"} checkboxCallback={(e) => setIsLock(e.target.checked)} style={{ marginTop: "5px" }} lableCss={isLock?{color:"red"}:{color:"green"}}/>
                        }
                    })()}
                </div>
            )
        }
    }


    function getPersonalUpload() {
        return (
            <div>
                <br />
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        {getDepDropdown()}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <ModalDropdown onChange={(e) => formFieldChangeDropdown(e, "taxYear")}
                            options={years} value={taxYear} borderBottom /> <br /> </div>
                    <div className="col-md-4 col-sm-12">
                        {getAccessDropdown()}
                    </div>
                    <div className="col-md-4 col-sm-12">
                        {getLockfileCheckBox()}
                    </div>
                </div>
                {getFiles("per")}
            </div>
        )
    }

    function getBusinessUpload() {
        let businessLink = undefined;
        if (isUser) {
            businessLink = <a href={urls.CLIENT_BUSINESS}>Click here to Add a Business</a>
        } else {
            businessLink = <p>Add a Business</p>
        }
        if (props.bnsShow.length <= 1) {
            return (
                <div>
                    {businessLink}
                </div>
            )
        } else {
            return (
                <div>
                    <br />
                    <ModalDropdown options={props.bnsShow} values={props.bnsValues}
                        value={bnsId} onChange={(e) => formFieldChangeDropdown(e, "bnsId")} borderBottom /> <br />
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <ModalDropdown options={years} value={taxYear} onChange={(e) => formFieldChangeDropdown(e, "taxYear")} borderBottom /> <br /></div>
                        <div className="col-md-4 col-sm-12">
                            {getAccessDropdown()}
                        </div>
                        <div className="col-md-4 col-sm-12">
                            {getLockfileCheckBox()}
                        </div>
                    </div>
                    {getFiles("bns")}
                </div>
            )
        }
    }

    function tabCallback(tabName: string, index: number) {
        if (tabName == "uploadpersonal" || tabName == "uploadbusiness") {
            setCatId(catConfig[tabName]);
        }
        clearForm();
    }

    function saveCallback() {
        let formData = new FormData();
        formData.append("cat", catId.toString());
        formData.append("access", access.toString());
        formData.append("dep", dep.toString());
        formData.append("lock", JSON.stringify(isLock));
        formData.append("year", taxYear.toString());
        formData.append("bns_id", bnsId.toString());
        let modifiedFileType: number[] = [];
        file.forEach((ele, index) => {
            if (ele != undefined && fileType[index] != undefined) {
                formData.append("files", ele)
                modifiedFileType.push(fileType[index]);
            }
        })
        formData.append("file_type_id", JSON.stringify(modifiedFileType));
        let queryParams = {
            section: "documents",
            user_id: -1
        }
        if (!Number.isNaN(props.userId)) {
            queryParams.user_id = props.userId;
        }
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams, true) as Promise<IPostFile>;
        requestResponse.then((response) => {
            let [success, fail] = parseQueryResponseOnSuccess(response);
            props.mutator(response.data.data, success, fail);
        }).catch((error) => {
            let [success, fail] = parseQueryResponseOnFail(error);
            props.mutator(error.obj.response.data.data as IPostFileDataRepr, success, fail);
        }).finally(() => {
            $("#fileUploadModal").modal("hide");
        })
        setInTransit(true);
    }

    function getContent() {
        let tabs = [
            { target: "UploadPersonal", active: true, display: "Individual" },
            { target: "UploadBusiness", active: false, display: "Business" }
        ]
        let contents = [{ content: getPersonalUpload() }, { content: getBusinessUpload() }];
        return <Tabs tabs={tabs} contents={contents} tabCallback={tabCallback} />
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal custom-small text-center" id="fileUploadModal"
                tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Upload Documents"} />
                        <div className="modal-body" style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                            {getContent()}
                        </div>
                        <div className="card" style={{ paddingLeft: '15px' }}>
                            <h6 className="italicFont"> Supported File are: .pdf, .txt, .doc, .docx, .xls, .xlsx, .csv, .jpg, .jpeg, .png</h6>
                            <h6 className="italicFont"> Each File should not exceed 10MB</h6>
                        </div>
                        <ModalFooter saveCallback={saveCallback} saveInactive={inTransit}
                            buttonConfig={[{ buttonName: "+ Add File", callback: addFileCallback, inactive: inTransit }]} />
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalUpload;
