import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";

import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import ModalDropDown from "../../../intelws_portal/constructs/modal/ModalDropDown";
import { getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";
import { parseQueryResponseOnFail, parseQueryResponseOnFailResponse } from "../../../declarations/common_utils";
import ModalCommetText from "../../../intelws_portal/constructs/modal/ModalCommentText";
import { isLength } from "lodash";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
interface IModalBulkMessage {
    userIds: number[],
    triggerClose: () => void
}

function AdminBulkMsg(props: IModalBulkMessage) {
    const ENDPOINT = "api/bulkmessages/";
    
    const dynamicFieldsLegend = [{"display_name": "First Name", "internal_name": "FirstName"},
                                 {"display_name": "Last Name", "internal_name": "LastName"},
                                 {"display_name": "Phone Number", "internal_name": "PhoneNumber"},
                                 {"display_name": "Admin First Name", "internal_name": "AdminFirstName"},
                                 {"display_name": "Admin Last Name", "internal_name": "AdminLastName"},
                                 {"display_name": "Admin Company Name", "internal_name": "AdminCompanyName"},
                                 {"display_name": "Admin Company Url", "internal_name": "AdminCompanyUrl"}];

    const messageTypeEnum = React.useRef({message: "Message", email: "Email"});
    const messageTypeOptions = React.useRef((() => {
        let messageType: string[] = [];
        for (const [key, value] of Object.entries(messageTypeEnum.current)) {
            messageType.push(value);
        }
        return messageType;
    })());

    const [messageType, setMessageType] = React.useState(messageTypeEnum.current.message);
    const [message, setMessage] = React.useState("");
    const [subject, setSubject] = React.useState("");

    const [disable, setDisable] = React.useState(false);
    const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);
    const cancelTokenSource = React.useRef(getCancelToken());
    const [sidebarData, setsidebarData] =React.useState("");
    const [tableData, settableData] =React.useState("");
    const [sidebarBtn, setsidebarBtn] =React.useState(true);
    const [tableBtn, settableBtn] =React.useState(true);
    React.useLayoutEffect(() => {
        $("#bulkMsgSendModal").modal("show");
        return () => {
            $("#bulkMsgSendModal").modal("hide");
        }

    }, [])
   
    React.useEffect(() => {
        $("#bulkMsgSendModal").on("hidden.bs.modal", clearForm)
        return () => {
            cancelTokenSource.current.cancel();
            $("#bulkMsgSendModal").off("hidden.bs.modal");
        }
    }, [])

    function clearForm() {
        setMessageType(messageTypeEnum.current.message);
        setMessage("");
        setSubject("");
        props.triggerClose();
    }

    function getEmptyErrorObj() {
        return {
            user_ids: {
                isError: false,
                errorText: ""
            },
            message_type: {
                isError: false,
                errorText: ""
            },
            message: {
                isError: false,
                errorText: ""
            },
            subject: {
                isError: false,
                errorText: ""
            }
        }
    }

    function submitForm() {
        let formData = new FormData();
        formData.append("user_ids", JSON.stringify(props.userIds));
        formData.append("message_type", messageType);
        formData.append("message", message);
        if (messageType == messageTypeEnum.current.email) {
            formData.append("subject", subject);
        }
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, { section: "create_bulk_messages" });
        requestResponse.then((response) => {
            props.triggerClose();
        }).catch((error) => {
            let [success, fail, failResponse] = parseQueryResponseOnFail(error);
            let errorObj = getEmptyErrorObj(); 
            parseQueryResponseOnFailResponse(errorObj, failResponse);
            setErrorObj(errorObj);
        })
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement> | boolean , 
        section: "messageType" | "message" | "subject" | "dynamicMessageInsert", index?: number) {
        if (section == "messageType" && typeof e != "boolean") {
            setMessageType(e.target.value);
            setSubject("");
        } else if (section == "message" && typeof e != "boolean") {
            setMessage(e.target.value);
        } else if (section == "subject" && messageType == messageTypeEnum.current.email && typeof e != "boolean") {
            setSubject(e.target.value);
        } else if (section == "dynamicMessageInsert" && index != undefined) {
            setMessage((prevMessage) => {
                return prevMessage + "{{" + dynamicFieldsLegend[index].internal_name + "}}";
            })
        }
    }

    function getDynamicFieldLegend() {
        return dynamicFieldsLegend.map((ele, index) => {
            return (
                <React.Fragment>
                    <a href="#"  onClick={() => formFieldChange(true, "dynamicMessageInsert", index)}>{ele.display_name}</a>
                    <br />
                </React.Fragment>
            )
        })
    }

    function getSubject() {
        if (messageType == messageTypeEnum.current.email) {
            return (
                <ModalInputText onChange={(e) => formFieldChange(e, "subject")} value={subject} title={"Subject"}
                    required borderBottom isError={errorObj.subject.isError} errorText={errorObj.subject.errorText} />
            )
        }
    }

    function sidebarToggle(){
       let sidbardiv = document.getElementById("i1")
       let maindiv = document.getElementById("i2")
       let tableEle = document.getElementById("i3")
       if(sidbardiv?.innerHTML != ""){
        setsidebarData(sidbardiv?.innerHTML ?? "");
       }
       if(tableEle?.innerHTML != ""){
        settableData(tableEle?.innerHTML ?? "");
       }
       setsidebarBtn(!sidebarBtn);
       if(sidbardiv != null && maindiv!= null && tableEle!= null){           
        if(sidbardiv.className == "col-2"){
            if(tableBtn){
                maindiv.className = "col-6"
                tableEle.className = "col-6"                
            }
            else{
                maindiv.className = "col-12"
                tableEle.className = "col-0"
                tableEle.innerHTML = ""
            }
            sidbardiv.className = "col-0"
            sidbardiv.innerHTML = ""           
        }
        else {
            if(tableBtn){
                sidbardiv.className = "col-2"
                maindiv.className = "col-5"
                tableEle.className = "col-5"
                tableEle.innerHTML = tableData ?? ""
                sidbardiv.innerHTML = sidebarData ?? ""  
            }
            else{
                sidbardiv.className = "col-2"
                maindiv.className = "col-10"
                tableEle.className = "col-0"
                tableEle.innerHTML = ""
                sidbardiv.innerHTML = sidebarData ?? ""  
            }
             
        }
        }
    }
            function tableToggle(){
                let sidbardiv1 = document.getElementById("i1")
                let tableEle1 = document.getElementById("i3")
                let mainDiv1 = document.getElementById("i2")
                if(tableEle1?.innerHTML != ""){
                settableData(tableEle1?.innerHTML ?? "");
                }
                if(sidbardiv1?.innerHTML != ""){
                setsidebarData(sidbardiv1?.innerHTML ?? "");
                }
                settableBtn(!tableBtn)
                if(tableEle1 != null && sidbardiv1 != null && mainDiv1!= null ){                    
                if(tableEle1.className == "col-5" || tableEle1.className == "col-6"){
                    if(sidebarBtn){
                        mainDiv1.className = "col-10"
                        sidbardiv1.className = "col-2"
                       }
                       else{
                        mainDiv1.className = "col-12"
                        sidbardiv1.className = "col-0"
                        sidbardiv1.innerHTML = "" 
                       }
                       tableEle1.className = "col-0"
                       tableEle1.innerHTML = ""
                       
                }
                else {
                    if(sidebarBtn){
                        sidbardiv1.className = "col-2"
                        tableEle1.className = "col-5"
                        mainDiv1.className = "col-5"
                        tableEle1.innerHTML = tableData ?? ""  
                        sidbardiv1.innerHTML = sidebarData ?? "" 
                    }
                    else{
                        sidbardiv1.className = "col-0"
                        tableEle1.className = "col-6"
                        mainDiv1.className = "col-6"
                        tableEle1.innerHTML = tableData ?? ""  
                        sidbardiv1.innerHTML = "" 
                    }
                     
            }
            }}
        return (

                    <div className="body-content-wrapper">
                        <div className="m-4">
                            <div className="row">
                                <div className="col-2">
                                <button id="b1" className="btn btn-secondary-add-cpasage" onClick={sidebarToggle}><i className="fa fa-plus"></i></button>

                                </div>
                                <div className="col-8">
                                    <ModalInputText title={"To"} value={""} borderBottom={true}/>
                                </div>
                                <div className="col-2">
                                <button id="b2" className="btn btn-secondary-add-cpasage" onClick={tableToggle}><i className="fa fa-plus"></i></button>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div id="i1" className="col-2">
                            {getDynamicFieldLegend()}
                            </div>
                            <div id="i2" className="col-5">
                            <ModalDropDown options={messageTypeOptions.current} onChange={(e) => formFieldChange(e, "messageType")}
                                value={messageType} required borderBottom 
                                isError={errorObj.message_type.isError} errorText={errorObj.message_type.errorText} />
                            {getSubject()}
                            {/* <ModalCommetText onChange={(e) => formFieldChange(e, "message")} value={message} title={""} 
                                placeholder={"Type Message here"} rows={25} cols={25}
                                required isError={errorObj.message.isError} errorText={errorObj.message.errorText} /> <br /> */}
                       <Editor
                            // editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            // onEditorStateChange={this.onEditorStateChange}
                            />
                        <ModalFooter saveCallback={submitForm} saveInactive={false} disable={disable} />
                            
                            </div>
                            <div id="i3" className="col-5">
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">First</th>
                                    <th scope="col">Last</th>
                                    <th scope="col">Handle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <th scope="row">1</th>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">2</th>
                                    <td>Jacob</td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">3</th>
                                    <td>Larry</td>
                                    <td>the Bird</td>
                                    <td>@twitter</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">1</th>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">2</th>
                                    <td>Jacob</td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">3</th>
                                    <td>Larry</td>
                                    <td>the Bird</td>
                                    <td>@twitter</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">1</th>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">2</th>
                                    <td>Jacob</td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">3</th>
                                    <td>Larry</td>
                                    <td>the Bird</td>
                                    <td>@twitter</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>                            
                       
                            
                    </div>
              
        )
    }



export default AdminBulkMsg;