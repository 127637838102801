import React from "react";

import { getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../declarations/common";

function isStringEmpty(value: string) {
    return (value.length == 0 || !value.trim());
}

interface IMatchProps {
    code: string,
    userInfo?: string
}

function ContactUs(props: IPageComponentProps<IMatchProps>) {
    const ENDPOINT = "/api/leads/";
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phoneNum, setPhoneNum] = React.useState("");
    const [comments, setComments] = React.useState("");
    const [phoneLength, setPhoneLength] = React.useState(0);

    const contactAllText = React.useRef("Select Preferred Contact Method");
    const referralAllText = React.useRef("Select Referral Channel");
    const cancelTokenSource = React.useRef(getCancelToken());
    const userReferallInfo = React.useRef(() => {
        if (props.match.params.userInfo != undefined) {
            let userInfo = props.match.params.userInfo.split("_");
            return {
                isClientReferral: true,
                userId: userInfo[0],
                userName: userInfo[1]
            }
        }
        return {
            isClientReferral: false
        }
    })

    const contactOptions = [contactAllText.current, "Call", "Email"];
    const referralOptions = [referralAllText.current, "Google", "Yelp", "Facebook", "Instagram", "Linkedin", "Twitter", "Next Door", "Other"];
    
    const [contact, setContact] = React.useState(contactAllText.current);
    const [referral, setReferral] = React.useState(setReferralAttr);
    const [referralOther, setReferralOther] = React.useState("");

    function setReferralAttr() {
        if (userReferallInfo.current().isClientReferral) {
            return userReferallInfo.current().userName as string;
        }
        return referralAllText.current;
    }

    function changeFormField(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> , section: "firstName" | "lastName" | "email" | "phonenum" | "comments" | "contact" | "referral" | "referralOther") {
        if (section == "firstName") {
            setFirstName(e.target.value);
        } else if (section == "lastName") {
            setLastName(e.target.value);
        } else if (section == "email") {
            setEmail(e.target.value);
        } else if (section == "phonenum"  && isValidPhoneNumber(e.target.value)) {
            setPhoneLength(phoneNum.length)
            setPhoneNum(e.target.value);
            if ((phoneNum.length == 3 || phoneNum.length == 7) && phoneLength < phoneNum.length){
                setPhoneLength(phoneNum.length)
                setPhoneNum(phoneNum + "-")
            }
        } else if (section == "comments") {
            setComments(e.target.value);
        } else if (section == "contact") {
            setContact(e.target.value);
        } else if (section == "referral") {
            setReferral(e.target.value);
            if (e.target.value != "Other") {
                setReferralOther("")
            }
        } else if (section == "referralOther") {
            setReferralOther(e.target.value);
        }
    }
    function isValidPhoneNumber(value: string) {
        if (((value.match(/^[0-9 -]+$/)) || value == "") && value.length <= 12){
            return true
        }
        return false
    }
    function clearForm() {
        setFirstName("");
        setLastName("");
        setEmail("")
        setPhoneNum("");
        setComments("")
        setContact(contactAllText.current);
        setReferral(setReferralAttr);
    }

    function formSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        let formData = new FormData();
        formData.append("admin_code", props.match.params.code);
       
        if (!isStringEmpty(firstName)) {
            formData.append("first_name", firstName);
        }
        if (!isStringEmpty(lastName)) {
            formData.append("last_name", lastName);
        }
        if (!isStringEmpty(email)) {
            formData.append("email", email);
        }
        if (!isStringEmpty(phoneNum)) {
            formData.append("phonenum", phoneNum);
        }
        if (!isStringEmpty(comments)) {
            formData.append("comments", comments)
        }
        if (contact != contactAllText.current) {
            formData.append("pref_contact", contact);
        }
        if (referral != referralAllText.current) {
            if (referral != "Other") {
                formData.append("referral_method", referral);
            } else {
                formData.append("referral_method", referralOther);
            }
        }
        if (userReferallInfo.current().isClientReferral) {
            formData.append("user_id", userReferallInfo.current().userId as string);
        }
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token);
        requestResponse.then(() => {
            clearForm();
        })
    }

    function getOtherText() {
        if (referral == "Other") {
            return <input type="text" className="form-control" placeholder="Enter your Referral Method here"
                onChange={(e) => changeFormField(e, "referralOther")} value={referralOther} />
        }
    }

    return (      
            <div>
            <div className="contact_form">
                <div className="container" style={{maxWidth:"800px"}}>
                        <div className="right_contact_form">
                            <h2 className="cpa_page_title" style={{fontSize: "33px",fontWeight:600,color:"#4e4e4e"}}>Contact Us</h2>
                                <form className="mt-4" onSubmit={formSubmit}>
                                <div className="form-group">
                                <input type="text" className="form-control" placeholder=" First Name" 
                                onChange={(e) => changeFormField(e, "firstName")} value={firstName} />
                                </div>
                                <div className="form-group">
                                <input type="text" className="form-control" placeholder=" Last name" 
                                onChange={(e) => changeFormField(e, "lastName")} value={lastName} />
                                </div>
                                <div className="form-group">
                                <input type="email" className="form-control" placeholder=" Email" 
                                onChange={(e) => changeFormField(e, "email")} value={email} />
                                </div>
                                <div className="form-group">
                                <input type="text" className="form-control" placeholder=" Phone Number" 
                                onChange={(e) => changeFormField(e, "phonenum")} value={phoneNum} />
                                </div>
                                <div className="form-group">
                                <input type="text" className="form-control" placeholder=" Comments" 
                                onChange={(e) => changeFormField(e, "comments")} value={comments} />
                                </div>
                                <div className="form-group">
                                    <select className="form-control placeholder" onChange={(e) => changeFormField(e, "contact")} value={contact} >
                                        {contactOptions.map((ele) => {
                                            return <option>{ele}</option>
                                        })}
                                    </select>
                                </div>
                                {(() => {
                                    if (userReferallInfo.current().isClientReferral) {
                                        return <p>Referred by {userReferallInfo.current().userName}</p>
                                    }
                                    return (
                                        <div className="form-group">
                                            <select className="form-control placeholder" onChange={(e) => changeFormField(e, "referral")} value={referral} >
                                                {referralOptions.map((ele) => {
                                                    return <option>{ele}</option>
                                                })}
                                            </select>
                                        </div>
                                    )
                                })()}
                                
                                <div className="form-group">
                                {getOtherText()}
                                </div>
                                <div className="rememberSec clearfix pt-10">
                                     <button className="btn btn-secondary-add-cpasage" style={{padding:"10px 50px"}}>Submit</button>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
       
    )
}

export default ContactUs;