import React from "react";
import Select from "react-select";

export interface ISelectProps {
    className: string,
    style: {
        color: string,
        borderBottom: string
    },
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    value?: string[],
    multiple: boolean
}

export interface IModalMultiDropDownProps {
    options: number[] | string[],
    flag?: number,
    value?: (number | string)[],
    values?: number[] | string[],
    borderBottom?:boolean,
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

function ModalMultiDropDown(props: IModalMultiDropDownProps) {
    let options: {label: string, value: string}[] = [];
    let selectedValues: {label: string, value: string}[] = [];
    props.options.map((ele: number | string, index: number) => {
        if (props.value != undefined) {
            if (props.values == undefined && props.value.includes(ele)) {
                selectedValues.push({ value: ele.toString(), label: ele.toString() });
            } else if (props.values != undefined && props.value.includes(props.values[index])){
                selectedValues.push({ value: props.values[index].toString(), label: ele.toString() });
            }
        }
        if (props.values == undefined) {
            options.push({ value: ele.toString(), label: ele.toString() });
        } else {
            options.push({ value: props.values[index].toString(), label: ele.toString() });
        }
    })
    return (
        <Select onChange={(selectValue) => {
            props.onChange({target: {selectedOptions: selectValue}} as unknown as React.ChangeEvent<HTMLSelectElement>)
        }} options={options} value={selectedValues} isMulti />
    )
}

export default ModalMultiDropDown;
