import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from 'axios';

import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { post, getCancelToken, get } from "../../../intelws_portal/utils/backendInterface";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";

import { parseQueryResponseOnFail, parseQueryResponseOnFailResponse, parseQueryResponseOnSuccess } from '../../../declarations/common_utils';
import ModalDropDown from '../../../intelws_portal/constructs/modal/ModalDropDown';

export interface IFetchUserResponseRepr extends AxiosResponse {
    data: {
        data: IDataRepr,
    }
}

interface IFetchAddLeadConfig extends AxiosResponse {
    data: {
        data: {
            user_ids: number[],
            user_names: string[]
        }
    }
}

export interface IModalClientProps {
    mutator: (data: IDataRepr[], success: string[], fail: string[]) => void
}

function ModalClient(props: IModalClientProps) {
    const ADD_ENDPOINT = "api/add/";

    const referralAllText = React.useRef("Select Referral Channel");
    const referralClientText = React.useRef("Client");
    const referralOptions = [referralAllText.current, "Google", "Yelp", "Facebook", "Instagram", "Linkedin", "Twitter", "Next Door", referralClientText.current, "Other"];

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [referUserId, setReferUserId] = React.useState(-1);
    const [referral, setReferral] = React.useState(referralAllText.current);
    const [referralOther, setReferralOther] = React.useState("");
    
    const [userIds, setUserIds] = React.useState<number[]>([]);
    const [userNames, setUserNames] = React.useState<string[]>([]);

    const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);
    const cancelTokenSource = React.useRef(getCancelToken());
    const [disable, setDisable] = React.useState(true);
    const [isfnameStartwithspace, setisfnameStartwithspace] = React.useState("");
    const [islnameStartwithspace, setislnameStartwithspace] = React.useState("");
    const [isemailStartwithspace, setisemailStartwithspace] = React.useState("");
    const [isphoneStartwithspace, setisphoneStartwithspace] = React.useState("");
    const [phoneLength, setPhoneLength] = React.useState(0);

   
    function isstartwithspace(value: string, section: "firstName" | "lastName" | "email" | "phoneNumber"){
        if(value == " " ){
            if(section == "firstName"){
                setisfnameStartwithspace("please start first name with alphanumeric value ")
            }
            else if(section == "lastName"){
                setislnameStartwithspace("please start last name with alphanumeric value ")
            }
            else if(section == "email"){
                setisemailStartwithspace("please start email with alphanumeric value ")
            }
            else if(section == "phoneNumber"){
                setisphoneStartwithspace("please enter only numeric value for phone number")
            }
            
            return(false)
        }
        else{

            setisfnameStartwithspace("")
            setislnameStartwithspace("")
            setisemailStartwithspace("")
            setisphoneStartwithspace("")
            return(true)
        }
    }
    
    React.useEffect(() => {
        $("#clientAddModal").on("hidden.bs.modal", clearForm)
        const requestResponse = get(ADD_ENDPOINT, cancelTokenSource.current.token, {type: "client"}) as Promise<IFetchAddLeadConfig>;
        requestResponse.then((response) => {
            setUserIds(response.data.data.user_ids);
            setUserNames(response.data.data.user_names);
        })
        return () => {
            cancelTokenSource.current.cancel();
            $("#clientAddModal").off("hidden.bs.modal");
        }
    }, [])

    React.useEffect(() => {
        let newDisable = true;
        if (email != "" && firstName != "" && lastName != "" && phoneNumber != "" &&  referral != referralAllText.current) {
            newDisable = false;
        }
        setDisable(newDisable);
    }, [email, firstName, lastName, phoneNumber, referral]);
    
    function clearForm() {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setReferral(referralAllText.current);
        setReferralOther("");
        setErrorObj(getEmptyErrorObj);
    }

    function getEmptyErrorObj() {
        return {
            f_name: {
                isError: false,
                errorText: ""
            },
            l_name: {
                isError: false,
                errorText: ""
            },
            email: {
                isError: false,
                errorText: ""
            },
            phonenum: {
                isError: false,
                errorText: ""
            }
        }
    }

    function submitForm() {
        let formData = new FormData();
        formData.append("f_name", firstName);
        formData.append("l_name", lastName);
        formData.append("email", email);
        formData.append("phone", phoneNumber);
        if (referral != referralAllText.current) {
            if (referral == "Other") {
               formData.append("referral_method", referralOther);
            } else if (referral == referralClientText.current) {
                let userSelectedIndex = userIds.findIndex((ele) => {
                    return ele == referUserId;
                })
                formData.append("referral_method", userNames[userSelectedIndex]);
                formData.append("user_id", referUserId.toString());
            } else {
               formData.append("referral_method", referral);
            }
        }
        const requestResponse = post(ADD_ENDPOINT, formData, cancelTokenSource.current.token, { type: "client" }) as Promise<IFetchUserResponseRepr>;
        requestResponse.then((response) => {
            let [success, fail] = parseQueryResponseOnSuccess(response);
            props.mutator([response.data.data], success, fail);
            $("#clientAddModal").modal("hide");
        }).catch((error) => {
            let [success, fail, failResponse] = parseQueryResponseOnFail(error);
            let errorObj = getEmptyErrorObj(); 
            parseQueryResponseOnFailResponse(errorObj, failResponse);
            setErrorObj(errorObj);
        })
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> , section: "firstName" | "lastName" | "email" | "phoneNumber" | "referral" | "referralOther" | "referClient") {
        if (section == "firstName" && isstartwithspace(e.target.value, section)) {
            setFirstName(e.target.value);
        } else if (section == "lastName" && isstartwithspace(e.target.value, section)) {
            setLastName(e.target.value);
        } else if (section == "email" && isstartwithspace(e.target.value, section)) {
            setEmail(e.target.value);
        } else if (section == "phoneNumber" && isstartwithspace(e.target.value, section) && isValidPhoneNumber(e.target.value)) {
            setPhoneLength(phoneNumber.length)
            setPhoneNumber(e.target.value);
            if ((phoneNumber.length == 3 || phoneNumber.length == 7) && phoneLength < phoneNumber.length){
                setPhoneLength(phoneNumber.length)
                setPhoneNumber(phoneNumber + "-")
            }
        } else if (section == "referral") {
            setReferral(e.target.value);
            if (e.target.value != "Other") {
                setReferralOther("")
            }
            if (e.target.value == referralClientText.current) {
                setReferUserId(userIds[0]);
            } else {
                setReferUserId(-1);
            }
        } else if (section == "referralOther") {
            setReferralOther(e.target.value);
        } else if (section == "referClient") {
            setReferUserId(parseInt(e.target.value));
        }
    }

    function isValidPhoneNumber(value: string) {
        if (((value.match(/^[0-9 -]+$/)) || value == "") && value.length <= 12){
            return true
        }
        return false
    }
       
    function getOtherText() {
        if (referral == "Other") {
            return <ModalInputText title={"Referral Method"} value={referralOther} 
                onChange={(e) => formFieldChange(e, "referralOther")} borderBottom />
        }
    } 
    
    function getClientDropdown() {
        if (referral == referralClientText.current) {
            return <ModalDropDown options={userNames} values={userIds} value={referUserId}
                onChange={(e) => formFieldChange(e, "referClient")} borderBottom />
        }
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="clientAddModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Add Client"} />
                        <div className="modal-body">
                            <ModalInputText onChange={(e) => formFieldChange(e, "firstName")} value={firstName} title={"First Name"} isinvalidmsg={isfnameStartwithspace} 
                                required borderBottom isError={errorObj.f_name.isError} errorText={errorObj.f_name.errorText} /> <br />
                            <ModalInputText onChange={(e) => formFieldChange(e, "lastName")} value={lastName} title={"Last Name"} isinvalidmsg={islnameStartwithspace} 
                                required borderBottom isError={errorObj.l_name.isError} errorText={errorObj.l_name.errorText} /> <br />
                            <ModalInputText onChange={(e) => formFieldChange(e, "email")} value={email} title={"Email"} isinvalidmsg={isemailStartwithspace} 
                                required borderBottom isError={errorObj.email.isError} errorText={errorObj.email.errorText} /> <br />
                            <ModalInputText id={"phonenumber"} onChange={(e) => formFieldChange(e, "phoneNumber")} value={phoneNumber} title={"123-456-7890"} isinvalidmsg={isphoneStartwithspace} 
                                required borderBottom maxlength={12} isError={errorObj.phonenum.isError} errorText={errorObj.phonenum.errorText} /> <br />
                            <ModalDropDown options={referralOptions} value={referral} onChange={(e) => formFieldChange(e, "referral")} borderBottom required/><br />
                            {getOtherText()}
                            {getClientDropdown()}
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={false} disable={disable}/>
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalClient;