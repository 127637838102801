import { AxiosResponse } from "axios";
import React from "react";
import $ from "jquery";

import ContentBoxTable from "../../../../intelws_portal/bundles/ContentBoxTable";
import Loading from "../../../../intelws_portal/constructs/elements/Loading";
import ScrollTop from "../../../../intelws_portal/constructs/elements/ScrollTop";
import AddButton from "../../../../intelws_portal/constructs/elements/AddButton";
import { IButtonRepr, IDataRepr, IMultiDropdownRepr} from "../../../../intelws_portal/constructs/elements/Table";
import { get, getCancelToken, post } from "../../../../intelws_portal/utils/backendInterface";

import ModalDependent from "../../modal/ModalDependent";



export interface IManualSubTaskEditRepr extends IDataRepr {
    t_name: string,
    t_emp_assigned: IMultiDropdownRepr | string,
    t_button: IButtonRepr
}

export interface IManualSubTaskRepr extends IDataRepr {
    t_name: string,
    t_emp_assigned: string,
    t_button: IButtonRepr
}



function ClientDependent(props:any) {
    const ENDPOINT = "/api/clientdashboard/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isAdd, setIsAdd] = React.useState(true);
    const [tableData, setTableData] = React.useState<IDataRepr[]>([]);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("Dependent");
        }
        // let queryParams = {
        //     section: "dependent",
        //     user_id: props.match.params.userId,
        // }
        // const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchManualTaskDetails>;
        // requestResponse.then((response) => {
        //     setTableData(transferEditToView(response.data.data));
        //     setIsLoaded(true);
        // })
        var ob =[
            {
                id:123,
                first_name:"sagar",
                last_name:"narkhede",
                email:"sn@gmail.com",
                phone:"123456789",
                dept_type: "Son"

            }
        ]
        setTableData(ob)
    }, [])

    function getContent() {
        let columnName = ["First", "Last", "Email", "Contact #", "Type"];
        let accessKey = ["first_name", "last_name", "email", "phone", "dept_type"];
        
        let miscHeaders = undefined;
        if (isAdd) {
            miscHeaders = <AddButton buttonValue={<i className="fa fa-plus"></i>}
                onClick={() => $("#manualSubTaskAddModal").modal("show")} />
        }
       
        return <ContentBoxTable title={"Dependent"} hasSearch={true} hasRecords={true}
            columnNames={columnName} accessKeys={accessKey} tableData={tableData} miscHeaders={miscHeaders}
            /> 
    }

    
    // if (!isLoaded) {
    //     return <Loading />
    // } else {
        return (
            <div className="body-content-wrapper clearfix">
                {getContent()}
                <ModalDependent userId={props.match.params.userId} taskId={props.match.params.taskId}  />
                <ScrollTop />
            </div>
        )
    // }
}

export default ClientDependent;