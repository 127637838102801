import { AxiosResponse } from "axios";
import React from "react";
import ContentBoxTable from "../../../intelws_portal/bundles/ContentBoxTable";
import Loading from "../../../intelws_portal/constructs/elements/Loading";
import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { get, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import { IPageComponentProps } from "../../../declarations/common";

interface IApplicationLeadsFetch extends AxiosResponse {
    data: {
        data: IDataRepr[],
        access_keys: string[],
        column_names: string[]
    }
}

function SuperAdminLeads(props: IPageComponentProps) {
    const ENDPOINT = "api/leads/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [tableData, setTableData] = React.useState<IDataRepr[]>([]);
    const [accessKeys, setAccessKeys] = React.useState<string[]>([]);
    const [columnNames, setColumnNames] = React.useState<string[]>([]);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("saLeads");
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, {section: "application_leads_overview"}) as Promise<IApplicationLeadsFetch>;
        requestResponse.then((response) => {
            setTableData(response.data.data);
            setAccessKeys(response.data.access_keys);
            setColumnNames(response.data.column_names);
        })
    }, [])

    function getContent() {
        return (
            <div className="container-fluid bottom-sec shadow">
                <ContentBoxTable tableData={tableData}
                    columnNames={columnNames} accessKeys={accessKeys} title="Leads"
                    hasSearch={true} hasRecords={true}
                />
            </div>
        )
    }

    if (!isLoaded) {
        return getContent()
    } else {
        return <Loading />
    }
}

export default SuperAdminLeads;