import React from 'react';

import AddButton from '../../../../../intelws_portal/constructs/elements/AddButton';
import ContentBox from '../../../../../intelws_portal/constructs/elements/ContentBox';

export interface INotesProps {
    text?: string,
    isTitle?: boolean
    updateCallback: (updatedText?: string) => void
}

function Notes(props: INotesProps) {
    const [disabled, setDisabled] = React.useState(true);
    const [text, setText] = React.useState(props.text);

    function getAddButton() {
        if (disabled) {
            return <AddButton buttonValue={<i className="fa fa-edit"></i>} 
                onClick={() => setDisabled(false)}/>
        } else {
            return <AddButton buttonValue={<i className="fa fa-floppy-o"></i>}
                onClick={() => {
                    props.updateCallback(text);
                    setDisabled(true);
                }} />
        }
    }

    function getTitle() {
        if (props.isTitle == undefined || props.isTitle) {
            return <h6>Notes</h6>
        }
        return <h6></h6>
    }

    function getCardHeader() {
        return (
            <div className="notesBox">
                <div className="heading-box">
                    {getTitle()}
                    {getAddButton()}
                </div>
                <div className="notesBoxarea">
                    <textarea disabled={disabled} cols={30} rows={10} placeholder="Add notes...."
                        onChange={(e) => setText(e.target.value)} value={text} />
                </div>
            </div>
        )
    }

    return (
        <ContentBox header={getCardHeader()} />
    )
}

export default Notes;
