import React, { CSSProperties } from "react";

export interface IModalCheckboxProps {
    checked: boolean,
    label: string,
    checkboxCallback: (e: React.ChangeEvent<HTMLInputElement>) => void,
    style?: CSSProperties,
    lableCss?: CSSProperties
}

function ModalCheckbox(props: IModalCheckboxProps) {
    return (
        <div className="form-group" style={props.style}>
            <label className="custom-checkbox-radio">
                <input checked={props.checked} onChange={props.checkboxCallback}
                    type="checkbox" className="checkbox" />
                <p className="label--checkbox" style={props.lableCss}>{props.label}</p>
            </label>
        </div>
    )
}

export default ModalCheckbox;