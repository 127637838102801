import { AxiosResponse } from "axios";
import React from "react";
import $ from "jquery";
import { cloneDeep } from "lodash";

import ContentBoxTabTable from "../../../../intelws_portal/bundles/ContentBoxTabTable";
import AddButton from "../../../../intelws_portal/constructs/elements/AddButton";
import Loading from "../../../../intelws_portal/constructs/elements/Loading";
import ScrollTop from "../../../../intelws_portal/constructs/elements/ScrollTop";
import { ICheckboxRepr, IDataRepr } from "../../../../intelws_portal/constructs/elements/Table";
import { ITabRepr } from "../../../../intelws_portal/constructs/elements/Tabs";
import { get, getCancelToken, post } from "../../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../../declarations/common";
import { getBundledAlertsUI } from "../../../../declarations/common_utils";
import ModalManualTaskUpload from "../../modal/ModalManualTaskUpload";
import { confirm } from "../../../../intelws_portal/constructs/elements/WindowConfirm";


interface IMatchProps {
    userId: number,
    taskId: number
}

interface IFetchManualTaskDocumentsConfig extends AxiosResponse {
    data: {
        column_names: string[][],
        access_keys: string[][],
        tab_config: ITabRepr[]
    }
}

interface IFetchManualTaskDocuments extends AxiosResponse {
    data: {
        data: IDataRepr[]
    }
}

interface IPostManualTaskDocumentsLockToggle extends AxiosResponse {
    data: {
        data: IDataRepr
    }
}

function ManualTaskDocuments(props: IPageComponentProps<IMatchProps>) {
    const ENDPOINT = "/api/manualtasklist/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [currTab, setCurrTab] = React.useState(0);
    const [columnNames, setColumnNames] = React.useState<string[][]>([]);
    const [accessKeys, setAccessKeys] = React.useState<string[][]>([]);
    const [tabConfig, setTabConfig] = React.useState<ITabRepr[]>([]);
    const [tableData, setTableData] = React.useState<(IDataRepr[] | undefined)[]>([]);
    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ProjectDocuments");
        }
        let queryParams = {
            section: "manual_documents_config"
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchManualTaskDocumentsConfig>;
        requestResponse.then((response) => {
            setColumnNames(response.data.column_names);
            setAccessKeys(response.data.access_keys);
            setTabConfig(response.data.tab_config);
            setTableData((prevTableData) => {
                return new Array(response.data.tab_config.length).fill(undefined);
            })
            fetchData(response.data.tab_config[0].target.toLowerCase(), 0);
            setIsLoaded(true);
        })
    }, [])

    function fetchData(uploadType: string, tabIndex: number) {
        let queryParams = {
            section: "manual_documents",
            user_id: props.match.params.userId,
            task_tracking_id: props.match.params.taskId,
            upload_type: uploadType
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchManualTaskDocuments>;
        requestResponse.then((response) => {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                newTableData[tabIndex] = response.data.data;
                return newTableData;
            })
        })
    }

    function tableUpdate(data?: IDataRepr[], access?: string, success?: string[], fail?: string[]) {
        if (data != undefined && access != undefined) {
            let index = tabConfig.findIndex((ele) => {
                return access == ele.target;
            })
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                let currTableData = newTableData[index];
                if (currTableData != undefined) {
                    newTableData[index] = [...currTableData, ...data];
                }
                return newTableData;
            })
        }
        if (success != undefined && fail != undefined) {
            setSuccess(success);
            setFail(fail);
        }
    }

    function tabCallback(tabName: string, index: number, prevIndex: number) {
        if (tableData[index] == undefined) {
            fetchData(tabName, index);
        }
        setCurrTab(index);
    }

    function inputCallback(rowIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (value == "delete") {
            confirm("Are you sure you want to delete this file?").then(() => {
                let formData = new FormData();
                let currTable = tableData[currTab];
                if (currTable != undefined) {
                    formData.append("file_id", currTable[rowIndex].id.toString());
                }
                let queryParams = {
                    section: "manual_task_documents_delete",
                    user_id: props.match.params.userId,
                    task_tracking_id: props.match.params.taskId
                }
                const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
                requestResponse.then(() => {
                    setTableData((prevTableData) => {
                        let newTableData = cloneDeep(prevTableData);
                        let currTable = newTableData[currTab]
                        if (currTable != undefined) {
                            currTable.splice(rowIndex, 1);
                        }
                        return newTableData;
                    })
                })
            })
        } else if (value == "lock" || value == "unlock") {
            let formData = new FormData();
            let currTable = tableData[currTab];
            if (currTable != undefined) {
                formData.append("file_id", currTable[rowIndex].id.toString());
            }
            let queryParams = {
                section: "manutal_task_documents_toggle_lock",
                user_id: props.match.params.userId,
                task_tracking_id: props.match.params.taskId
            }
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostManualTaskDocumentsLockToggle>;
            requestResponse.then((response) => {
                setTableData((prevTableData) => {
                    let newTableData = cloneDeep(prevTableData);
                    let currTable = newTableData[currTab];
                    if (currTable != undefined) {
                        currTable[rowIndex] = response.data.data;
                    }
                    return newTableData;
                })
            })
        } else {
            if (typeof value == "boolean") {
                let formData = new FormData();
                let currTable = tableData[currTab];
                if (currTable != undefined) {
                    formData.append("file_id", currTable[rowIndex].id.toString());
                    formData.append("value", JSON.stringify(value));
                }
                let queryParams = {
                    section: "manual_task_documents_check",
                    user_id: props.match.params.userId,
                    task_tracking_id: props.match.params.taskId
                }
                const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
                requestResponse.then(() => {
                    setTableData((prevTableData) => {
                        let newTableData = [...prevTableData];
                        let currTable = newTableData[currTab];
                        if (currTable != undefined) {
                            (currTable[rowIndex][accessKey] as ICheckboxRepr).checked = value;
                        }
                        return newTableData;
                    })
                })
            }
        }
    }

    function getContent() {
        let miscHeaders = <AddButton buttonValue={<i className="fa fa-plus"></i>}
            onClick={() => $("#manualTaskUploadModal").modal("show")} />
        return <ContentBoxTabTable title="Documents" hasSearch={true} hasRecords={true}
            columnNames={columnNames} accessKeys={accessKeys} tabs={tabConfig}
            contents={tableData} tabCallback={tabCallback} miscHeaders={miscHeaders}
            inputCallback={inputCallback} />
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div>
                {getContent()}
                {getBundledAlertsUI(success, fail, setSuccess, setFail)}
                <ModalManualTaskUpload userId={props.match.params.userId} taskId={props.match.params.taskId}
                    mutator={tableUpdate} />
                <ScrollTop />
            </div>
        )
    }
}

export default ManualTaskDocuments;