import React, { Component, CSSProperties } from "react";

export interface IContextBoxAdvProps {
    title: string | JSX.Element,
    body: JSX.Element | JSX.Element[]
    hStyle?: CSSProperties,
    buttonValue?: string | JSX.Element,
    callBack?: () => void,
}

function ContentBoxAdv(props: IContextBoxAdvProps) {
    return (
        <div className="container-fluid bottom-sec clearfix">
            <div className="heading-box" style={props.hStyle}>
                {(()=>{
                    if(props.buttonValue != undefined && props.callBack != undefined){
                        return(
                        <React.Fragment>
                            <div style={{display:"inline"}}>
                            <h6 style={{display:"inline", paddingRight:"0px"}}>{props.title}</h6>
                            <button className="btn btn-secondary-add-cpasage" style={{marginTop:"-2px"}} onClick={props.callBack}>{props.buttonValue}</button>
                            </div>
                        </React.Fragment>
                        )
                    }
                    else{
                        return(
                            <h6>{props.title}</h6>
                        )
                    }
                })()}
                
            </div>
            {props.body}
        </div>
    )
}

export default ContentBoxAdv;