import React, { Component } from 'react';
import * as urls from '../../Urls';
import { Link } from 'react-router-dom';
import dashboardActiveLogo from '../../../external/backPages/img/images/dashboard-active.svg';
import dashboardInactiveLogo from '../../../external/backPages/img/images/dashboard-inactive.svg';
import teamActiveLogo from '../../../external/backPages/img/images/team-active.svg';
import teamInactiveLogo from '../../../external/backPages/img/images/team-inactive.svg';

export interface ISuperAdminMenuMainProps {
    currentPage: string,
    initials?: string
} 

function SuperAdminMenuMain(props: ISuperAdminMenuMainProps) {
    function getClassName(menuOption: string) {
        if (menuOption == props.currentPage) {
            return "flecx active";
        } else {
            return "flecx";
        }
    }

    return (
        <nav className="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar static-top">
            <div className="container-fluid">
                <ul className="top-left-menu-item-container">
                    <div className="top-left-menu-item">
                        <Link to={urls.SUPER_ADMIN_DASHBOARD} id="admin_main_dashboard" className={getClassName("saDashboard")}>
                            <div className="menu-icon">
                                <img src={dashboardActiveLogo} className="active" alt="" />
                                <img src={dashboardInactiveLogo} className="inactive" alt="" />
                            </div>
                            <span>Dashboard</span>
                        </Link>
                    </div>
                    <div className="top-left-menu-item">
                        <Link to={urls.ADMIN_EMP_ACTION} id="teamActions" className={getClassName("TeamAction")}>
                            <div className="menu-icon">
                                <img src={teamActiveLogo} className="active" alt="" />
                                <img src={teamInactiveLogo} className="inactive" alt="" />
                            </div>
                            <span>Team</span>
                        </Link>
                    </div>
                    <div className="top-left-menu-item">
                        <Link to={urls.SUPER_ADMIN_LEADS} id="teamActions" className={getClassName("saLeads")}>
                            <div className="menu-icon">
                                <img src={teamActiveLogo} className="active" alt="" />
                                <img src={teamInactiveLogo} className="inactive" alt="" />
                            </div>
                            <span>Leads</span>
                        </Link>
                    </div>
                </ul>
            </div>
            <div className="top-right-menu-item-container">
                <div className="top-right-menu-item dropdown">
                    <a id="profileDropdown" href="#" className="active"> {props.initials} </a>
                    <div className="dropdown-content dropdown-menu dropdown-menu-right widthIc" style={{right:"0",width:"50px",marginTop:"-0.5rem"}}>
                    <div><h6 className="dropdown-header shadow-sm" style={{marginBottom:"5px"}}>Profile</h6></div>
                        <Link className="contHover" id="navigateTickets" to={urls.TICKETS} style={{borderBottom:"solid 0px #ddd",padding:"5px 0px"}}>
                            <div className="secoWidth" style={{paddingLeft:"15px"}}>
                                <span style={{fontWeight:400, color: "rgb(65 65 66 / 99%)", fontSize:"13px"}}>Tickets</span>
                            </div>
                        </Link>
                        <a className="contHover" id="changePassword" href="#" style={{borderBottom:"solid 0px #ddd",padding:"5px 0px"}}>
                            <div className="secoWidth" style={{paddingLeft:"15px"}}>
                                <span style={{fontWeight:400, color: "rgb(65 65 66 / 99%)", fontSize:"13px"}}> Change Password</span>
                            </div>
                        </a>
                        <a className="contHover" id="logout" href="/logout/" style={{borderBottom:"solid 0px #ddd",padding:"5px 0px"}}>
                            <div className="secoWidth" style={{paddingLeft:"15px"}}>
                                <span style={{fontWeight:400, color: "rgb(65 65 66 / 99%)", fontSize:"13px"}}> Logout</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </nav >
    )
}

export default SuperAdminMenuMain;
