import React from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";

import { findDOMNode } from "react-dom";
import $ from 'jquery';

import Checkbox from './Checkbox';
import Button, { isIIconRepr } from "./Button";
import StatusCode from "./StatusCode";
import ProgressBar from "./ProgressBar";
import DatePicker from './DatePicker';
import Badge from './Badge';
import ModalDropDown from '../modal/ModalDropDown';
import ModalMultiDropDown from '../modal/ModalMultiDropDown';

import { IIconProps } from '../icons/Icon';
import ModalInputText from '../modal/ModalInputText';
import ModalCommetText from '../modal/ModalCommentText';

/*
These interfaces represent the HTML Component that is included as a row element 
*/
export interface IHtmlComponentRepr {
    type: string
}

export interface ICheckboxRepr extends IHtmlComponentRepr {
    type: "checkbox",
    checked: boolean,
    disabled: boolean,
    isRadio: boolean
}

export interface IButtonTextRepr {
    text: string
}

export interface IButtonRepr extends IHtmlComponentRepr {
    type: "button",
    display: string | IIconProps | IButtonTextRepr,
    action: "newWindow" | "sameWindow" | "custom" | "disabled",
    url?: string,
    identifier?: string
}

export interface IDropdownRepr extends IHtmlComponentRepr {
    type: "dropdown",
    options: number[] | string[],
    value?: number | string,
    values?: number[] | string[]
}

export interface IMultiDropdownRepr extends IHtmlComponentRepr {
    type: "multidropdown",
    options: number[] | string[],
    value?: number[] | string[],
    values?: number[] | string[]
}

export interface ITextAreaRepr extends IHtmlComponentRepr {
    type: "textarea",
    placeholder: string,
    value: string,
}

export interface INumberInputRepr extends IHtmlComponentRepr {
    type: "numberinput",
    value: number
}

export interface ILinkRepr extends IHtmlComponentRepr {
    type: "link",
    action: "newWindow" | "sameWindow",
    url: string,
    display?: string,
}

export interface IDateRepr extends IHtmlComponentRepr {
    type: "date",
    value: string,
}

export interface ITextRepr extends IHtmlComponentRepr {
    type: "text",
    value: string,
    textColorType?: "warning" | "danger" | "info" | "primary" | "success"
}

export interface IBadgeRepr extends IHtmlComponentRepr {
    type: "badge",
    badgeType: "warning" | "danger" | "info" | "primary" | "success",
    value: string
}

export interface IStatusDotRepr extends IHtmlComponentRepr {
    type: "statusdot",
    status: string
}

export interface IProgressBar extends IHtmlComponentRepr {
    type: "percent",
    value: number
}

/*
This type represents all possible types for a data element in the data object
*/
export type TDataElement = number | string | IHtmlComponentRepr | IHtmlComponentRepr[]

/*
These interfaces are for link configurations that are not part of the table row. These represent the global link configuration
*/
export interface IAnchorRepr {
    baseUrl: string,
    urlParams?: string[],
    isActualAnchor?: boolean
}

export interface ICustomAnchorRepr {
    onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, accessKey: string, value: TDataElement, id: number) => void
}

export interface ILinksRepr {
    [accessKey: string]: IAnchorRepr | ICustomAnchorRepr
}

export interface IDataRepr {
    id: number,
    [accessKey: string]: TDataElement,
}

export interface ITableProps {
    searchString: string,
    showRecords: boolean,
    columnNames: (string | JSX.Element)[],
    accessKeys: string[],
    data: IDataRepr[],
    hasDownload?: boolean,
    hasSnapshot?: boolean,
    recordsRef?: React.RefObject<HTMLDivElement>,
    links?: ILinksRepr,
    inputCallback?: (absoluteIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) => void,
    snapshotCallback?: (data: IDataRepr[]) => void
}

export function isIButtonTextRepr(obj: any): obj is IButtonTextRepr {
    return (obj as IButtonTextRepr).text != undefined;
}

export function isICheckboxRepr(htmlObj: IHtmlComponentRepr): htmlObj is ICheckboxRepr {
    const asICheckboxRepr = htmlObj as ICheckboxRepr;
    return (
        (asICheckboxRepr.type == "checkbox") &&
        (asICheckboxRepr.checked != undefined) &&
        (asICheckboxRepr.disabled != undefined)
    )
}

export function isIButtonRepr(htmlObj: IHtmlComponentRepr): htmlObj is IButtonRepr {
    const asIButtonRepr = htmlObj as IButtonRepr;
    return (
        (asIButtonRepr.type == "button") &&
        (asIButtonRepr.action != undefined) &&
        (asIButtonRepr.display != undefined)
    )
}

export function isIDropdownRepr(htmlObj: IHtmlComponentRepr): htmlObj is IDropdownRepr {
    const asIDropdownRepr = htmlObj as IDropdownRepr;
    return (
        (asIDropdownRepr.type == "dropdown") &&
        (asIDropdownRepr.options != undefined)
    )
}

export function isIMultiDropdownRepr(htmlObj: IHtmlComponentRepr): htmlObj is IMultiDropdownRepr {
    const asIMultiDropdownRepr = htmlObj as IMultiDropdownRepr;
    return (
        (asIMultiDropdownRepr.type == "multidropdown") &&
        (asIMultiDropdownRepr.options != undefined)
    )
}

export function isITextAreaRepr(htmlObj: IHtmlComponentRepr): htmlObj is ITextAreaRepr {
    const asITextAreaRepr = htmlObj as ITextAreaRepr;
    return (
        (asITextAreaRepr.type == "textarea") &&
        (asITextAreaRepr.value != undefined) &&
        (asITextAreaRepr.placeholder != undefined)
    )
}

export function isINumberInputRepr(htmlObj: IHtmlComponentRepr): htmlObj is INumberInputRepr {
    const asINumberInputRepr = htmlObj as INumberInputRepr;
    return (
        (asINumberInputRepr.type == "numberinput") &&
        (asINumberInputRepr.value != undefined)
    )
}

export function isILinkRepr(htmlObj: IHtmlComponentRepr): htmlObj is ILinkRepr {
    const asILinkRepr = htmlObj as ILinkRepr;
    return (
        (asILinkRepr.type == "link") &&
        (asILinkRepr.action != undefined) &&
        (asILinkRepr.url != undefined)
    )
}

export function isIDateRepr(htmlObj: IHtmlComponentRepr): htmlObj is IDateRepr {
    const asIDateRepr = htmlObj as IDateRepr;
    return (
        (asIDateRepr.type == "date") &&
        (asIDateRepr.value != undefined)
    )
}

export function isITextRepr(htmlObj: IHtmlComponentRepr): htmlObj is ITextRepr {
    const asITextRepr = htmlObj as ITextRepr;
    return (
        (asITextRepr.type == "text") &&
        (asITextRepr.value != undefined)
    )
}

export function isIBadgeRepr(htmlObj: IHtmlComponentRepr): htmlObj is IBadgeRepr {
    const asIBadgeRepr = htmlObj as IBadgeRepr;
    return (
        (asIBadgeRepr.type == "badge") &&
        (asIBadgeRepr.badgeType != undefined) &&
        (asIBadgeRepr.value != undefined)
    )
}

export function isIStatusDotRepr(htmlObj: IHtmlComponentRepr): htmlObj is IStatusDotRepr {
    const asIStatusDotRepr = htmlObj as IStatusDotRepr;
    return (
        (asIStatusDotRepr.type == "statusdot") &&
        (asIStatusDotRepr.status != undefined)
    )
}

export function isIProgressBar(htmlObj: IHtmlComponentRepr): htmlObj is IProgressBar {
    const asIProgressBarRepr = htmlObj as IProgressBar;
    return (
        (asIProgressBarRepr.type == "percent") &&
        (asIProgressBarRepr.value != undefined)
    )
}
export function isDataElementString(dataEle: TDataElement): dataEle is string {
    return typeof dataEle == "string";
}

export function isDataElementNumber(dataEle: TDataElement): dataEle is number {
    return typeof dataEle == "number";
}

export function isIHtmlComponentReprArray(dataEle: TDataElement): dataEle is IHtmlComponentRepr[] {
    const asIHtmlComponentReprArray = dataEle as IHtmlComponentRepr[];
    console.log(dataEle);
    return (
        (asIHtmlComponentReprArray[0] != undefined) &&
        (asIHtmlComponentReprArray[0].type != undefined)
    )
}

export function isIAnchorRepr(obj: any): obj is IAnchorRepr {
    const asIAnchorRepr = obj as IAnchorRepr;
    return (
        (asIAnchorRepr.baseUrl != undefined) &&
        (asIAnchorRepr.urlParams != undefined)
    )
}

function Table(props: ITableProps) {
    const displayData = React.useMemo(search, [props.data, props.searchString]);
    React.useLayoutEffect(() => {
        if (props.recordsRef != undefined && props.recordsRef.current != undefined && props.showRecords) {
            let domNode = findDOMNode(props.recordsRef.current);
            if (domNode != undefined) {
                $(domNode as JQuery.PlainObject).find("#tableCount").text(displayData.length.toString());
            }
        }
    }, [displayData.length, props.recordsRef, props.showRecords])

    function search() {
        return props.data.filter((ele) => {
            let include = false;
            for (const [key, value] of Object.entries(ele)) {
                if (!include) {
                    let filterValue: string = "";
                    if (typeof value == "string" || typeof value == "number") {
                        filterValue = value.toString();
                    } else {
                        if (!isIHtmlComponentReprArray(value) && isIButtonRepr(value)) {
                            if (!isIIconRepr(value.display)) {
                                if (isIButtonTextRepr(value.display)) {
                                    filterValue = value.display.text;
                                } else {
                                    filterValue = value.display
                                }
                            }
                        } else {
                            if (isIHtmlComponentReprArray(value)) {
                                value.forEach((ele) => {
                                    if (isITextRepr(ele) || isIBadgeRepr(ele)) {
                                        filterValue += ele.value
                                    }
                                })
                            }
                        }
                    }
                    include = (
                        (filterValue != undefined) &&
                        (filterValue.toString().toLowerCase().indexOf(props.searchString.toLowerCase()) > -1)
                    )
                }
            }
            return include;
        })
    }

    function inputCallback(absoluteIndex: number, accessKey: string, value?: string | boolean | HTMLCollectionOf<HTMLOptionElement>) {
        if (props.inputCallback != undefined) {
            props.inputCallback(absoluteIndex, accessKey, value);
        }
    }

    function getCheckbox(accessKey: string, absoluteIndex: number, tableEle: ICheckboxRepr, jsxKey?: number) {
        if (jsxKey == undefined) {
            return <Checkbox accessKey={accessKey} disabled={tableEle.disabled}
                checked={tableEle.checked} isRadio={tableEle.isRadio}
                onChange={(e) => inputCallback(absoluteIndex, accessKey, e.target.checked)} />
        } else {
            return <Checkbox key={jsxKey} disabled={tableEle.disabled}
                checked={tableEle.checked} isRadio={tableEle.isRadio}
                onChange={(e) => inputCallback(absoluteIndex, accessKey, e.target.checked)} />
        }
    }

    function getDropdown(accessKey: string, absoluteIndex: number, tableEle: IDropdownRepr, jsxKey?: number) {
        if (jsxKey == undefined) {
            return <ModalDropDown flag={1} options={tableEle.options}
                values={tableEle.values} value={tableEle.value}
                onChange={(e) => inputCallback(absoluteIndex, accessKey, e.target.value)} borderBottom={false} />
        } else {
            return <ModalDropDown key={jsxKey} flag={1} options={tableEle.options}
                values={tableEle.values} value={tableEle.value}
                onChange={(e) => inputCallback(absoluteIndex, accessKey, e.target.value)} borderBottom={false} />
        }
    }

    function getMultiDropdown(accessKey: string, absoluteIndex: number, tableEle: IMultiDropdownRepr, jsxKey?: number) {
        if (jsxKey == undefined) {
            return <ModalMultiDropDown flag={1} options={tableEle.options}
                values={tableEle.values} value={tableEle.value}
                onChange={(e) => inputCallback(absoluteIndex, accessKey, e.target.selectedOptions)} />
        } else {
            return <ModalMultiDropDown key={jsxKey} flag={1} options={tableEle.options}
                values={tableEle.values} value={tableEle.value}
                onChange={(e) => inputCallback(absoluteIndex, accessKey, e.target.selectedOptions)} />
        }
    }

    function getTextArea(accessKey: string, absoluteIndex: number, tableEle: ITextAreaRepr, jsxKey?: number) {
        if (jsxKey == undefined) {
            return <ModalCommetText rows={15} cols={10} title="" placeholder={tableEle.placeholder} value={tableEle.value} onChange={(e) => inputCallback(absoluteIndex, accessKey, e.target.value)} />
        } else {
            return <ModalCommetText key={jsxKey} rows={15} cols={10} title="" placeholder={tableEle.placeholder} value={tableEle.value} onChange={(e) => inputCallback(absoluteIndex, accessKey, e.target.value)} />
        }
    }

    function getNumberInput(accessKey: string, absoluteIndex: number, tableEle: INumberInputRepr, jsxKey?: number) {
        if (jsxKey == undefined) {
            return <ModalInputText inputType="number" title={""} value={tableEle.value} onChange={(e) => inputCallback(absoluteIndex, accessKey, e.target.value)} />
        } else {
            return <ModalInputText key={jsxKey} inputType="number" title={""} value={tableEle.value} onChange={(e) => inputCallback(absoluteIndex, accessKey, e.target.value)} />
        }
    }

    function getButton(accessKey: string, absoluteIndex: number, tableEle: IButtonRepr, jsxKey?: number) {

        if (tableEle.action == "newWindow") {
            if (jsxKey == undefined) {
                return <Button onClick={() => window.open(tableEle.url, "_blank")} buttonValue={tableEle.display} table_btn_flag={true} style={{ fontSize: 12, paddingTop: "2px", paddingBottom: "2px" }} />
            } else {
                return <Button key={jsxKey} onClick={() => window.open(tableEle.url, "_blank")} buttonValue={tableEle.display} table_btn_flag={true} style={{ fontSize: 12, paddingTop: "2px", paddingBottom: "2px" }} />
            }
        } else if (tableEle.action == "sameWindow") {
            if (jsxKey == undefined) {
                return <Button onClick={() => window.open(tableEle.url, "_self")} buttonValue={tableEle.display} table_btn_flag={true} style={{ fontSize: 12, paddingTop: "2px", paddingBottom: "2px" }} />
            } else {
                return <Button key={jsxKey} onClick={() => window.open(tableEle.url, "_self")} buttonValue={tableEle.display} table_btn_flag={true} style={{ fontSize: 12, paddingTop: "2px", paddingBottom: "2px" }} />
            }
        } else if (tableEle.action == "disabled") {
            if (jsxKey == undefined) {
                return <Button buttonValue={tableEle.display} active={false} table_btn_flag={true} style={{ fontSize: 12, paddingTop: "2px", paddingBottom: "2px" }} />
            } else {
                return <Button key={jsxKey} buttonValue={tableEle.display} active={false} table_btn_flag={true} style={{ fontSize: 12, paddingTop: "2px", paddingBottom: "2px" }} />
            }
        } else {
            if (jsxKey == undefined) {
                return <Button onClick={() => inputCallback(absoluteIndex, accessKey, tableEle.identifier as string)} buttonValue={tableEle.display} table_btn_flag={true} style={{ fontSize: 12, paddingTop: "2px", paddingBottom: "2px" }} />
            } else {
                return <Button key={jsxKey} onClick={() => inputCallback(absoluteIndex, accessKey, tableEle.identifier as string)} buttonValue={tableEle.display} table_btn_flag={true} style={{ fontSize: 12, paddingTop: "2px", paddingBottom: "2px" }} />
            }
        }
    }

    function getDate(accessKey: string, absoluteIndex: number, tableEle: IDateRepr, jsxKey?: number) {
        if (jsxKey == undefined) {
            return <DatePicker dateValue={tableEle.value} onChange={(e) => inputCallback(absoluteIndex, accessKey, e.target.value)} />
        } else {
            return <DatePicker key={jsxKey} dateValue={tableEle.value} onChange={(e) => inputCallback(absoluteIndex, accessKey, e.target.value)} />
        }
    }

    function getLink(tableEle: ILinkRepr, jsxKey?: number) {
        let display = tableEle.url;
        if (tableEle.display != undefined) {
            display = tableEle.display;
        }
        if (tableEle.action == "newWindow") {
            if (jsxKey == undefined) {
                return <a href={tableEle.url} target="_blank">{display}</a>
            } else {
                return <a key={jsxKey} href={tableEle.url} target="_blank">{display}</a>
            }
        } else {
            if (jsxKey == undefined) {
                return <a href={tableEle.url}>{display}</a>
            } else {
                return <a key={jsxKey} href={tableEle.url}>{display}</a>
            }
        }
    }

    function getStatusDot(tableEle: IStatusDotRepr, jsxKey?: number) {
        if (jsxKey == undefined) {
            return <StatusCode status={tableEle.status} />
        } else {
            return <StatusCode status={tableEle.status} key={jsxKey} />
        }
    }

    function getProgressBar(tableEle: IProgressBar, jsxKey?: number) {
        if (jsxKey == undefined) {
            return <ProgressBar percent={tableEle.value} />
        } else {
            return <ProgressBar percent={tableEle.value} key={jsxKey} />
        }
    }
    
    function getText(tableEle: ITextRepr) {
        if (tableEle.textColorType != undefined) {
            return <div className={"text-" + tableEle.textColorType}>{tableEle.value}</div>
        }
        return tableEle.value;
    }

    function getBadge(tableEle: IBadgeRepr, jsxKey?: number) {
        if (jsxKey == undefined) {
            return <Badge badgeType={tableEle.badgeType} value={tableEle.value} />
        } else {
            return <Badge key={jsxKey} badgeType={tableEle.badgeType} value={tableEle.value} />
        }
    }

    function buildTableRow(tableDataRow: IDataRepr, rowIndex: number) {
        return props.accessKeys.map((accessKey, accessKeyIndex) => {
            let tableEle = tableDataRow[accessKey];
            if (isDataElementString(tableEle) || isDataElementNumber(tableEle)) {
                if (props.links == undefined || props.links[accessKey] == undefined) {
                    return <td key={accessKeyIndex}>{tableEle}</td>
                }
                const link = props.links[accessKey];
                if (isIAnchorRepr(link)) {
                    let finalLink = link.baseUrl;
                    if (link.urlParams != undefined) {
                        link.urlParams.forEach((linkAccessKey) => {
                            finalLink += tableDataRow[linkAccessKey].toString() + "/";
                        })
                    }
                    if (link.isActualAnchor != undefined && link.isActualAnchor) {
                        return <td key={accessKeyIndex}><a href={finalLink}>{tableEle}</a></td>
                    }
                    return <td key={accessKeyIndex}><Link to={finalLink}>{tableEle}</Link></td>
                } else {
                    return (
                        <td key={accessKeyIndex}>
                            <a href="#" onClick={(e) => link.onClick(e, accessKey, tableEle, tableDataRow.id)}>{tableEle}</a>
                        </td>
                    )
                }
            } else {
                let absoluteIndex = props.data.findIndex((ele) => {
                    return ele.id == tableDataRow.id
                })
                if (isIHtmlComponentReprArray(tableEle)) {
                    return (
                        <td key={accessKeyIndex}>
                            {tableEle.map((ele, htmlComponentIndex) => {
                                if (isICheckboxRepr(ele)) {
                                    return getCheckbox(accessKey, absoluteIndex, ele, accessKeyIndex + htmlComponentIndex);
                                } else if (isIButtonRepr(ele)) {
                                    return getButton(accessKey, absoluteIndex, ele, accessKeyIndex + htmlComponentIndex);
                                } else if (isITextRepr(ele)) {
                                    return getText(ele);
                                } else if (isIBadgeRepr(ele)) {
                                    return getBadge(ele, accessKeyIndex + htmlComponentIndex);
                                } else if (isIStatusDotRepr(ele)) {
                                    return getStatusDot(ele, accessKeyIndex + htmlComponentIndex);
                                } else if (isILinkRepr(ele)) {
                                    return getLink(ele, accessKeyIndex + htmlComponentIndex);
                                }
                            })}
                        </td>
                    )
                } else {
                    if (isICheckboxRepr(tableEle)) {
                        return (
                            <td key={accessKeyIndex}>{getCheckbox(accessKey, absoluteIndex, tableEle)}</td>
                        )
                    } else if (isIDropdownRepr(tableEle)) {
                        return (
                            <td key={accessKeyIndex}>{getDropdown(accessKey, absoluteIndex, tableEle)}</td>
                        )
                    } else if (isIMultiDropdownRepr(tableEle)) {
                        return (
                            <td key={accessKeyIndex}>{getMultiDropdown(accessKey, absoluteIndex, tableEle)}</td>
                        )
                    } else if (isINumberInputRepr(tableEle)) {
                        return (
                            <td key={accessKeyIndex}>{getNumberInput(accessKey, absoluteIndex, tableEle)}</td>
                        )
                    } else if (isITextAreaRepr(tableEle)) {
                        return (
                            <td key={accessKeyIndex}>{getTextArea(accessKey, absoluteIndex, tableEle)}</td>
                        )
                    } else if (isILinkRepr(tableEle)) {
                        return (
                            <td key={accessKeyIndex}>{getLink(tableEle)}</td>
                        )
                    } else if (isIButtonRepr(tableEle)) {
                        return (
                            <td key={accessKeyIndex}>{getButton(accessKey, absoluteIndex, tableEle)}</td>
                        )
                    } else if (isIDateRepr(tableEle)) {
                        return (
                            <td key={accessKeyIndex}>{getDate(accessKey, absoluteIndex, tableEle)}</td>
                        )
                    } else if (isIStatusDotRepr(tableEle)) {
                        return (
                            <td key={accessKeyIndex}>{getStatusDot(tableEle)}</td>
                        )
                    } else if (isIProgressBar(tableEle)) {
                        return <td key={accessKeyIndex}>{getProgressBar(tableEle)}</td>
                     }else {
                        return <td key={accessKeyIndex}></td>
                    }
                }
            }
        })
    }
   
    function getTableHeader() {
        return (
            <thead>
                <tr>
                    {props.columnNames.map((ele, index) => {
                        return <th key={index}>{ele}</th>
                    })}
                    {(() => {
                        if (props.hasDownload != undefined && props.hasDownload) {
                            let headers: any[] = [];
                            props.accessKeys.forEach((accessKey, index) => {
                                let columnName = props.columnNames[index];
                                if (typeof columnName == "string") {
                                    headers.push({label: columnName, key: accessKey});
                                }
                            })
                            return (
                                <th>
                                    <CSVLink data={displayData} headers={headers}>
                                        <span className="tooltip1"> <i className="fa fa-download"></i> <span className="tooltiptext1">Download CSV</span></span>
                                    </CSVLink>
                                </th>
                            )
                        }
                    })()}
                    {(() => {
                        if (props.hasSnapshot != undefined && props.hasSnapshot) {
                            return (
                                <th>
                                    <Button buttonValue={"Send Message"} onClick={() => {
                                        if (props.snapshotCallback != undefined) {
                                            props.snapshotCallback(displayData);
                                        }
                                    }} />
                                </th>
                            )
                        }
                    })()}
                </tr>
            </thead>
        )
    }

    function getDownloadCol() {
        if (props.hasDownload != undefined && props.hasDownload) {
            return <td></td>
        }
    }

    function getSnapshotCol() {
        if (props.hasSnapshot != undefined && props.hasSnapshot) {
            return <td></td>
        }
    }

    function getTableBody() {
        return (
            <tbody>
                {displayData.map((ele, index) => {
                    let trClassName = ""
                    if (("__strike" in ele) && (typeof ele["__strike"] == "string") && (ele["__strike"] == "True")) {
                        trClassName = "strikeout";
                    }
                    return <tr key={ele.id} className={trClassName}>
                        {buildTableRow(ele, index)}
                        {getDownloadCol()}
                        {getSnapshotCol()}
                    </tr>
                })}
            </tbody>
        )
    }

    return (
        <table className="table table-hover">
            {getTableHeader()}
            {getTableBody()}
        </table>
    )
}


export default Table;