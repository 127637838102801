import React from "react";
import $ from "jquery";
import HelloSign from "hellosign-embedded";
import * as urls from "../../../Urls";
import axios, { AxiosResponse } from "axios";
import cloneDeep from "lodash/cloneDeep";

import ContentBoxMultiTabTable, { IContentBoxMultiTabTableTabRepr } from "../../../../intelws_portal/bundles/ContentBoxMultiTabTable";
import Loading from '../../../../intelws_portal/constructs/elements/Loading';
import AddButton from "../../../../intelws_portal/constructs/elements/AddButton";
import ScrollTop from "../../../../intelws_portal/constructs/elements/ScrollTop";
import { ICheckboxRepr, IDataRepr } from "../../../../intelws_portal/constructs/elements/Table";
import { get, getCancelToken, post } from "../../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../../declarations/common";
import { getBundledAlertsUI } from "../../../../declarations/common_utils";
import ModalUpload, { IPostFileDataRepr } from "../../modal/ModalUpload";
import ModalRaiseEsign from "../../modal/ModalRaiseEsign";
import { HELLO_SIGN_CLIENT_ID } from "../../../Urls";
import { confirm } from "../../../../intelws_portal/constructs/elements/WindowConfirm";
import ModalTransferFile from "../../modal/ModalTransferFile";


interface IMatchProps {
    userId: string
}

interface IWSResponseRepr {
    file_id: number,
    is_delete: boolean
}

interface IBackendTabConfig {
    tab_name: string,
    target: string,
    column_names: string[][],
    access_keys: string[][],
    children: {
        tab_name: string,
        target: string
    }[]
}

export interface IEsignSigners {
    email_address: string,
    name: string
}

interface IFetchSessionId extends AxiosResponse {
    data: {
        session: string
    }
}

interface IFetchConfig extends AxiosResponse {
    data: {
        tab_config: IBackendTabConfig[],
        years: number[]
    }
}

interface IFetchBusiness extends AxiosResponse {
    data: {
        doc_business: IDataRepr[]
    }
}

interface IFetchDocuments extends AxiosResponse {
    data: {
        data: IDataRepr[]
    }
}

interface IFetchEsignInfo extends AxiosResponse {
    data: {
        is_esign_raise: boolean,
        data?: IEsignSigners[]
    }
}

interface IFetchEsignSignUrl extends AxiosResponse {
    data: {
        sign_url: string
    }
}

interface IPostToggleLock extends AxiosResponse {
    data: {
        data: IDataRepr
    }
}

export interface IClientDocumentProps<T> extends IPageComponentProps<T> {
    mutator: {
        currentPage?: (currentPage: string) => void,
        bnsDocCallback: (data: IDataRepr[]) => void
    },
    bnsValues: number[],
    bnsShow: string[]
}

function isIWSResponseRepr(value: any): value is IWSResponseRepr {
    return value.file_id != undefined;
}

function ClientDocument(props: IClientDocumentProps<IMatchProps>) {
    const ENDPOINT = "api/clientdashboard/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [tableData, setTableData] = React.useState<(IDataRepr[] | undefined)[][]>([]);
    const [tabConfig, setTabConfig] = React.useState<IBackendTabConfig[]>([]);
    const [years, setYears] = React.useState<(number | string)[]>([]);
    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);
    const [currYear, setCurrYear] = React.useState<number | string>(-1);
    const [currBusiness, setCurrBusiness] = React.useState("");
    const [topLevelIndex, setTopLevelIndex] = React.useState(0);
    const [secondLevelIndex, setSecondLevelIndex] = React.useState(0);

    const [isEsignRaise, setIsEsignRaise] = React.useState(false);
    const [esignDefaultSignersData, setDefaultEsignSignersData] = React.useState<IEsignSigners[]>([]);
    const [currEsignId, setCurrEsignId] = React.useState(-1);

    const [transferFileId, setTransferFileId] = React.useState(-1);

    const cancelTokenSource = React.useRef(getCancelToken());
    const yearAllText = React.useRef("Tax Year");
    const businessAllText = React.useRef("Select Business");

    const displayTableData = React.useMemo(filter, [tableData, currYear, currBusiness, tabConfig]);
    
    const WS_ENDPOINT = urls.WS_HOSTNAME + `api/clientdashboard/${props.match.params.userId}/doc/`;

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ClientDocuments");
        }
        fetchConfig((tabConfig, years) => {
            let queryParams = {
                section: "documents_esign_info",
                user_id: props.match.params.userId
            }
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchEsignInfo>;
            requestResponse.then((response) => {
                if (response.data.is_esign_raise && response.data.data != undefined) {
                    setIsEsignRaise(response.data.is_esign_raise);
                    setDefaultEsignSignersData(response.data.data);
                }
            })
            fetchRecords(tabConfig[0].target.toLowerCase(), tabConfig[0].children[0].target.toLowerCase(),
                (data) => {
                    setTableData((prevTableData) => {
                        let newTableData = [...prevTableData];
                        tabConfig.forEach((ele) => {
                            newTableData.push(new Array(ele.children.length).fill(undefined));
                        })
                        newTableData[0][0] = [...data];
                        return newTableData;
                    })
                    let dropdownYears: (number | string)[] = years;
                    dropdownYears.unshift(yearAllText.current);
                    setYears(dropdownYears);
                    setCurrYear(dropdownYears[1]);
                    setCurrBusiness(businessAllText.current);
                    setTabConfig(tabConfig);
                    setIsLoaded(true);
                })
        })
        const ws = new WebSocket(WS_ENDPOINT);
            ws.onmessage = (streamedData) => {
                const parsedData: IDataRepr | IWSResponseRepr  = JSON.parse(streamedData.data);
                if (isIWSResponseRepr(parsedData)) {
                    if (!parsedData.is_delete) {
                        ws.send(JSON.stringify({section: "emit_file_obj", file_id: parsedData.file_id}));
                    } else {
                        setTableData((prevTableData) => {
                            let newTableData = cloneDeep(prevTableData);
                            let currTable = newTableData[topLevelIndex][newTableData.length - 1];
                            if (currTable != undefined) {
                                newTableData[topLevelIndex][newTableData.length - 1] = currTable.filter(value => value.id != parsedData.file_id);
                            }
                            return newTableData;
                        })
                    }
                } else {
                    setTableData((prevTableData) => {
                        let newTableData = cloneDeep(prevTableData);
                        let currTable = newTableData[topLevelIndex].at(-1);
                        if (currTable != undefined) {
                            let updateIndex = -1;
                            currTable.forEach((ele, index) => {
                                if (ele.id == parsedData.id) {
                                    updateIndex = index;
                                }
                            })
                            if (updateIndex == -1) {
                                newTableData[topLevelIndex][newTableData.length - 1] = [...currTable, parsedData];
                            } else {
                                currTable[updateIndex] = parsedData;
                            }
                        }
                        return newTableData;
                    })
                }
            }
        return () => {
            ws.close();
            cancelTokenSource.current.cancel();
        }
    }, [])

    function filter() {
        let returnTableData = cloneDeep(tableData);
        returnTableData = returnTableData.map((ele, topLevelIndex) => {
            return ele.map((childEle) => {
                if (childEle == undefined) {
                    return undefined;
                } else {
                    if (currYear != yearAllText.current && typeof currYear == "number") {
                        childEle = childEle.filter((tableEle) => {
                            return tableEle["f_year"] == currYear;
                        })
                    }
                    if (currBusiness != businessAllText.current && tabConfig.length > 0 && tabConfig[topLevelIndex].target == "business") {
                        childEle = childEle.filter((tableEle) => {
                            return tableEle["f_bns_name"] == currBusiness;
                        })
                    }
                    return childEle;
                }
            })
        })
        return returnTableData;
    }

    function fetchConfig(successCallback: (data: IBackendTabConfig[], years: number[]) => void) {
        const tabConfigRequestResponse = get(ENDPOINT, cancelTokenSource.current.token, { section: "document_tab_config" }) as Promise<IFetchConfig>;
        const docBusinessRequestResponse = get(ENDPOINT, cancelTokenSource.current.token, { section: "doc_business", "user_id": props.match.params.userId }) as Promise<IFetchBusiness>;
        Promise.all([tabConfigRequestResponse, docBusinessRequestResponse]).then(([tabConfigResponse, docBusinessResponse]) => {
            successCallback(tabConfigResponse.data.tab_config, tabConfigResponse.data.years);
            props.mutator.bnsDocCallback(docBusinessResponse.data.doc_business);
        })
    }

    function fetchRecords(cat: string, uploadType: string, successCallback: (data: IDataRepr[]) => void) {
        let queryParams = {
            section: "documents",
            cat: cat,
            upload_type: uploadType,
            user_id: props.match.params.userId
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchDocuments>;
        requestResponse.then((response) => {
            successCallback(response.data.data);
        })
    }

    function tabCallback(prevTopLevelIndex: number, prevSecondLevelIndex: number, topLevelIndex: number, secondLevelIndex: number, contentName: string) {
        if (tableData[topLevelIndex][secondLevelIndex] == undefined) {
            let contentSplit = contentName.split("_");
            fetchRecords(contentSplit[0], contentSplit[1], (data) => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    newTableData[topLevelIndex][secondLevelIndex] = [...data];
                    return newTableData;
                })
            })
        }
        setTopLevelIndex(topLevelIndex);
        setSecondLevelIndex(secondLevelIndex);
    }

    function inputCallback(rowIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        let absoluteRowIndex = -1;
        let currTable = tableData[topLevelIndex][secondLevelIndex];
        let displayCurrTable = displayTableData[topLevelIndex][secondLevelIndex];
        if (accessKey == "strikeCheckbox" || value == "delete" || value == "raiseEsign" || value == "signEsign" || value == "cancelEsign" || value == "lock" || value == "unlock" || value == "transfer" || typeof value == "boolean") {
            if (currTable != undefined) {
                absoluteRowIndex = currTable.findIndex((ele) => {
                    if (displayCurrTable != undefined) {
                        return ele.id == displayCurrTable[rowIndex].id
                    }
                })
            }
        }
        if (value == "delete") {
            confirm("Are you sure you want to delete this file?").then(() => {
                let formData = new FormData();
                let currTable = tableData[topLevelIndex][secondLevelIndex];
                if (currTable != undefined) {
                    formData.append("file_id", currTable[absoluteRowIndex].id.toString());
                }
                let queryParams = {
                    section: "documents_delete",
                    user_id: props.match.params.userId
                }
                const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
                requestResponse.then(() => {
                    setTableData((prevTableData) => {
                        let newTableData = cloneDeep(prevTableData);
                        let currTable = newTableData[topLevelIndex][secondLevelIndex];
                        if (currTable != undefined) {
                            currTable.splice(absoluteRowIndex, 1);
                        }
                        return newTableData;
                    })
                })
            })
        } else if (value == "raiseEsign") {
            if (currTable != undefined) {
                setCurrEsignId(currTable[absoluteRowIndex].id);
                $("#raiseEsignModal").modal("show")
            }
        } else if (value == "signEsign") {
            if (currTable != undefined) {
                let queryParams = {
                    section: "documents_sign_url",
                    file_id: currTable[absoluteRowIndex].id
                }
                const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchEsignSignUrl>;
                requestResponse.then((response) => {
                    new HelloSign().open(response.data.sign_url, { clientId: HELLO_SIGN_CLIENT_ID});
                })
            }
        } else if (value == "cancelEsign") {
            if (currTable != undefined) {
                let queryParams = {
                    section: "documents_cancel_esign",
                    user_id: props.match.params.userId
                }
                let formData = new FormData();
                formData.append("file_id", currTable[absoluteRowIndex].id.toString());
                const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
            }
        } else if (value == "lock" || value == "unlock") {
            if (currTable != undefined) {
                let queryParams = {
                    section: "documents_toggle_lock",
                    user_id: props.match.params.userId
                }
                let formData = new FormData();
                formData.append("file_id", currTable[absoluteRowIndex].id.toString());
                const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostToggleLock>;
                requestResponse.then((response) => {
                    setTableData((prevTableData) => {
                        let newTableData = [...prevTableData];
                        let currTable = newTableData[topLevelIndex][secondLevelIndex];
                        if (currTable != undefined) {
                            currTable[absoluteRowIndex] = response.data.data;
                        }
                        return newTableData;
                    })
                })
            }
        } else if (value == "transfer") {
            if (currTable != undefined) {
                setTransferFileId(currTable[absoluteRowIndex].id);
                $("#fileTransferModal").modal("show")
            }
        } else if (accessKey == "strikeCheckbox") {
            if (currTable != undefined) {
                let queryParams = {
                    section: "documents_strike",
                    user_id: props.match.params.userId
                }
                let formData = new FormData();
                formData.append("file_id", currTable[absoluteRowIndex].id.toString());
                const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostToggleLock>;
                requestResponse.then((response) => {
                    setTableData((prevTableData) => {
                        let newTableData = [...prevTableData];
                        let currTable = newTableData[topLevelIndex][secondLevelIndex];
                        if (currTable != undefined) {
                            currTable[absoluteRowIndex] = response.data.data;
                        }
                        return newTableData;
                    })
                })
            }
        } else {
            if (typeof value == "boolean") {
                let formData = new FormData();
                let currTable = tableData[topLevelIndex][secondLevelIndex];
                if (currTable != undefined) {
                    formData.append("file_id", currTable[absoluteRowIndex].id.toString());
                    formData.append("value", value.toString());
                }
                let queryParams = {
                    section: "documents_check",
                    user_id: props.match.params.userId
                }
                const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
                requestResponse.then(() => {
                    setTableData((prevTableData) => {
                        let newTableData = [...prevTableData];
                        let currTable = newTableData[topLevelIndex][secondLevelIndex];
                        if (currTable != undefined) {
                            (currTable[absoluteRowIndex][accessKey] as ICheckboxRepr).checked = value;
                        }
                        return newTableData;
                    })
                })
            }
        }
    }

    function getIndexWithFileData(data: IPostFileDataRepr) {
        let firstLevelIndex: number = -1;
        let secondLevelIndex: number = -1;
        tabConfig.forEach((topLevelTabConfig, topLevelTabIndex) => {
            if (topLevelTabConfig.target == data.cat) {
                firstLevelIndex = topLevelTabIndex;
                topLevelTabConfig.children.forEach((secondLevelTabConfig, secondLevelTabIndex) => {
                    if (secondLevelTabConfig.target == data.access) {
                        secondLevelIndex = secondLevelTabIndex;
                    }
                })
            }
        })
        return [firstLevelIndex, secondLevelIndex];
    }

    function modalUpdateData(data?: IPostFileDataRepr, success?: string[], fail?: string[]) {
        if (data != undefined) {
            let [firstLevelIndex, secondLevelIndex] = getIndexWithFileData(data);
            if (firstLevelIndex != -1 && secondLevelIndex != -1) {
                let currTable = tableData[firstLevelIndex][secondLevelIndex];
                if (currTable != undefined) {
                    setTableData((prevTableData) => {
                        let newTableData = [...prevTableData];
                        let currTable = newTableData[firstLevelIndex][secondLevelIndex];
                        if (currTable != undefined) {
                            newTableData[firstLevelIndex][secondLevelIndex] = [...data.l_files, ...currTable];
                        }
                        return newTableData;
                    })
                }
            }
        }
        if (success != undefined) {
            setSuccess(success); 
        }
        if (fail != undefined) {
            setFail(fail);
        }
    }

    function modalTransferDataUpdate(file: IDataRepr, prevFileData: IPostFileDataRepr, currFileData: IPostFileDataRepr) {
        let [prevFirstLevelIndex, prevSecondLevelIndex] = getIndexWithFileData(prevFileData);
        let [currFirstLevelIndex, currSecondLevelIndex] = getIndexWithFileData(currFileData);

        if (prevFirstLevelIndex != -1 && prevSecondLevelIndex != -1 && currFirstLevelIndex != -1 && currSecondLevelIndex != -1) {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                if (prevFirstLevelIndex == currFirstLevelIndex && prevSecondLevelIndex == currSecondLevelIndex) {
                    //File is not being moved to new tab; replace old record with new record
                    let currTable = newTableData[currFirstLevelIndex][currSecondLevelIndex]
                    if (currTable != undefined) {
                        let replaceIndex = currTable.findIndex((ele) => ele.id == file.id);
                        if (replaceIndex != -1) {
                            currTable[replaceIndex] = file;
                        }
                    }
                } else {
                    //Delete file record from previous tabs; If the table for curr is not undefined then add it
                    let prevTable = newTableData[prevFirstLevelIndex][prevSecondLevelIndex];
                    if (prevTable != undefined) {
                        newTableData[prevFirstLevelIndex][prevSecondLevelIndex] = prevTable.filter((ele) => ele.id != file.id);
                    }
                    let currTable = newTableData[currFirstLevelIndex][currSecondLevelIndex];
                    if (currTable != undefined) {
                        newTableData[currFirstLevelIndex][currSecondLevelIndex] = [...currTable, file];
                    }
                }
                return newTableData;
            })
        }
    }

    function yearDropdownCallback(selectedYear: string) {
        if (selectedYear == yearAllText.current) {
            setCurrYear(selectedYear);
        } else {
            setCurrYear(parseInt(selectedYear));
        }
    }

    function businessDropdownCallback(selectedBusiness: string) {
        setCurrBusiness(selectedBusiness);
    }

    function getContent() {
        let tabs: IContentBoxMultiTabTableTabRepr[] = [];
        tabConfig.forEach((ele, index) => {
            let yearDropdownConfig = {
                type: "dropdown",
                value: currYear,
                options: years,
                callback: yearDropdownCallback 
            }
            let businessDropdownConfig = {
                type: "dropdown",
                value: currBusiness,
                options: props.bnsShow,
                callback: businessDropdownCallback 
            }
            let structToPush: IContentBoxMultiTabTableTabRepr = {
                target: ele.target,
                display: ele.tab_name,
                accessKeys: ele.access_keys,
                columnNames: ele.column_names,
                data: displayTableData[index],
                active: index == 0,
                children: [],
                miscHeaders: [yearDropdownConfig]
            }
            if (ele.target == "business" && structToPush.miscHeaders != undefined) {
                structToPush.miscHeaders.push(businessDropdownConfig);
            }
            ele.children.forEach((childEle, childIndex) => {
                structToPush.children.push({
                    target: ele.target + "_" + childEle.target,
                    display: childEle.tab_name,
                    active: childIndex == 0
                })
            })
            tabs.push(structToPush);
        })
        return (
            <div className="body-content-wrapper clearfix" style={{ paddingTop: "16px" }}>
                <ContentBoxMultiTabTable tabs={tabs} hasSearch={true} title="Tax Docs"
                    tabCallback={tabCallback} inputCallback={inputCallback}
                    miscHeaders={<AddButton buttonValue={<i className="fa fa-plus"></i>}
                        onClick={() => $("#fileUploadModal").modal("show")} />} />
            </div>
        )
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div>
                {getBundledAlertsUI(success, fail, setSuccess, setFail)}
                <div>
                    {getContent()}
                </div>
                <ModalUpload userId={parseInt(props.match.params.userId)} mutator={modalUpdateData} 
                    bnsValues={props.bnsValues} bnsShow={props.bnsShow} />
                <ModalTransferFile userId={parseInt(props.match.params.userId)} fileId={transferFileId} 
                    bnsShow={props.bnsShow} bnsValues={props.bnsValues} mutator={modalTransferDataUpdate}
                    onClose={() => setTransferFileId(-1)} />
                {(() => {
                    if (isEsignRaise) {
                        return <ModalRaiseEsign defaultSigners={esignDefaultSignersData} userId={props.match.params.userId}
                            fileId={currEsignId} />
                    }
                })()}
                <ScrollTop />
            </div>
        )
    }
}

export default ClientDocument;
