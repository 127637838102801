import { AxiosResponse } from "axios";
import React from "react";
import { Link } from "react-router-dom";
import ModalInputText from "../../../../../intelws_portal/constructs/modal/ModalInputText";
import { getCancelToken, post } from "../../../../../intelws_portal/utils/backendInterface";
import { IPageComponentProps } from "../../../../../declarations/common";
import { parseQueryResponseOnFail, parseQueryResponseOnFailResponse } from "../../../../../declarations/common_utils";
import * as urls from "../../../../Urls";
import AdminFooter from "../admin_home_pages/AdminFooter";
import AuthSidePanel from "./UIComponents/AuthSidePanel";

interface IUrlParams {
    adminCode: string,
    userInfo?: string
}

interface IAdminInfoFetch extends AxiosResponse {
    data: {
        data: {
            admin_company: string
        }
    }
}

function ClientSignup(props: IPageComponentProps<IUrlParams>) {
    const ENDPOINT = "/api/authentication/";

    const userReferallInfo = React.useRef(() => {
        if (props.match.params.userInfo != undefined) {
            let userInfo = props.match.params.userInfo.split("_");
            return {
                isClientReferral: true,
                userId: userInfo[0],
                userName: userInfo[1]
            }
        }
        return {
            isClientReferral: false
        }
    })

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const referralAllText = React.useRef("Select Referral Channel");
    const referralOptions = [referralAllText.current, "Google", "Yelp", "Facebook", "Instagram", "Linkedin", "Twitter", "Next Door", "Other"];
    const [referral, setReferral] = React.useState(setReferralAttr);
    const [referralOther, setReferralOther] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [rePassword, setRePassword] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [isFetchError, setIsFetchError] = React.useState(false);
    const [passwordShow, setPasswordShow] = React.useState([false, false]);
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
    const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);
    const [phoneLength, setPhoneLength] = React.useState(0);

    const cancelTokenSource = React.useRef(getCancelToken());
    

    React.useEffect(() => {
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    function setReferralAttr() {
        if (userReferallInfo.current().isClientReferral) {
            return userReferallInfo.current().userName as string;
        }
        return referralAllText.current;
    }

    function fetchedAdminInfo(isSuccess: boolean, response?: IAdminInfoFetch) {
        if (isSuccess && response != undefined) {
            setCompanyName(response.data.data.admin_company);
        } else {
            setIsFetchError(true);
        }
    }

    function getEmptyErrorObj() {
        return {
            first_name: {
                isError: false,
                errorText: ""
            },
            last_name: {
                isError: false,
                errorText: ""
            },
            phone_number: {
                isError: false,
                errorText: ""
            },
            referral_method: {
                isError: false,
                errorText: ""
            },
            email: {
                isError: false,
                errorText: ""
            },
            password: {
                isError: false,
                errorText: ""
            },
            re_password: {
                isError: false,
                errorText: ""
            }
        }
    }

    function clearForm() {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setPassword("");
        setRePassword("");
        setCompanyName("");
        setReferral(setReferralAttr);
        setReferralOther("");
        setPasswordShow([false, false]);
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
        section: "firstName" | "lastName" | "phoneNumber" | "email" | "password" | "rePassword" | "referral" | "referralOther") {
        if (section == "firstName") {
            setFirstName(e.target.value);
        } else if (section == "lastName") {
            setLastName(e.target.value);
        } else if (section == "phoneNumber"  && isValidPhoneNumber(e.target.value)) {
           setPhoneLength(phoneNumber.length)
            setPhoneNumber(e.target.value);
            if ((phoneNumber.length == 3 || phoneNumber.length == 7) && phoneLength < phoneNumber.length){
                setPhoneLength(phoneNumber.length)
                setPhoneNumber(phoneNumber + "-")
            }
        } else if (section == "email") {
            setEmail(e.target.value);
        } else if (section == "password") {
            setPassword(e.target.value);
        } else if (section == "rePassword") {
            setRePassword(e.target.value);
        } else if (section == "referral") {
            setReferral(e.target.value);
            if (e.target.value != "Other") {
                setReferralOther("")
            }
        } else if (section == "referralOther") {
            setReferralOther(e.target.value);
        }
    }

    function isValidPhoneNumber(value: string) {
        if (((value.match(/^[0-9 -]+$/)) || value == "") && value.length <= 12){
            return true
        }
        return false
    }

    function toggleVisibilityPassword(index: number) {
        setPasswordShow((prevPasswordShow) => {
            let newPasswordShow = [...prevPasswordShow];
            if (newPasswordShow[index]) {
                newPasswordShow[index] = false;
            } else {
                newPasswordShow[index] = true;
            }
            return newPasswordShow;
        })
    }

    function clientSideValidation() {
        let errorObj = getEmptyErrorObj();
        let returnValue = true;;
        if (firstName == "") {
            errorObj.first_name.isError = true;
            errorObj.first_name.errorText = "First Name is required";
            returnValue = false;
        }
        if (lastName == "") {
            errorObj.last_name.isError = true;
            errorObj.last_name.errorText = "Last Name is required";
            returnValue = false;
        }
        if (phoneNumber == "") {
            errorObj.phone_number.isError = true;
            errorObj.phone_number.errorText = "Phone Number is required";
            returnValue = false;
        }
        if (referral == referralAllText.current) {
            errorObj.referral_method.isError = true;
            errorObj.referral_method.errorText = "Referral Method is required";
            returnValue = false;
        }
        if (email == "") {
            errorObj.email.isError = true;
            errorObj.email.errorText = "Email is required";
            returnValue = false;
        }
        if (!holisticValidatePassword()) {
            errorObj.password.isError = true;
            errorObj.password.errorText = "Password doesn't match all criterias";
            returnValue = false;
        }
        setErrorObj(errorObj);
        return returnValue;
    }

    function formSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setShowSuccessMessage(false);
        if (clientSideValidation()) {
            let queryParams = {
                section: "client_signup"
            }
            let formData = new FormData();
            formData.append("admin_code", props.match.params.adminCode);
            formData.append("first_name", firstName);
            formData.append("last_name", lastName);
            formData.append("email", email);
            formData.append("phone_number", phoneNumber);
            formData.append("password", password);
            formData.append("re_password", rePassword);
            if (userReferallInfo.current().isClientReferral) {
                formData.append("refer_user_id", userReferallInfo.current().userId as string);
            }
            if (referral != referralAllText.current) {
                if (referral != "Other") {
                    formData.append("referral_method", referral);
                } else {
                    formData.append("referral_method", referralOther);
                }
            }
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
            requestResponse.then((response) => {
                clearForm();
                setShowSuccessMessage(true);
            }).catch((error) => {
                let [success, fail, failResponse] = parseQueryResponseOnFail(error);
                let errorObj = getEmptyErrorObj();
                parseQueryResponseOnFailResponse(errorObj, failResponse);
                setErrorObj(errorObj);
            })
        }
    }

    function validatePassword(section: "charLimitCase" | "upperCase" | "numberCase" | "specialCharCase" | "passMatchCase") {
        let returnValue = false;
        if (section == "charLimitCase") {
            returnValue = (password.length >= 8 && password.length <= 20);
        } else if (section == "upperCase") {
            returnValue = (/[A-Z]/.test(password));
        } else if (section == "numberCase") {
            returnValue = (/[0-9]/.test(password));
        } else if (section == "specialCharCase") {
            returnValue = (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password));
        } else if (section == "passMatchCase") {
            returnValue = (password !== "" && password == rePassword);
        }
        if (returnValue) {
            return "visible";
        }
        return "hidden";
    }

    function holisticValidatePassword() {
        let arrCases = ["charLimitCase", "upperCase", "numberCase", "specialCharCase", "passMatchCase"];
        let returnValue = true;
        arrCases.forEach((currCase) => {
            if (validatePassword(currCase as any) == "hidden") {
                returnValue = false;
            }
        })
        return returnValue;
    }

    function getOtherText() {
        if (referral == "Other") {
            return <input type="text" name="referral_other" className={"form-control"}
                placeholder="Referral Method" style={{ paddingTop: '15px', paddingBottom: '15px' }}
                value={referralOther} onChange={(e) => formFieldChange(e, "referralOther")} />
        }
    } 

    function signupFormElements() {
        return (
            <ul className="typeInput clearfix">
                <div className="right_contact_form">
                    <div className="form-group" style={{ paddingBottom: '0px', marginBottom: '0px' }}>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                if (errorObj.first_name.isError) {
                                    className += " is-invalid";
                                }
                                return (
                                    <React.Fragment>
                                        <input type="text" name="f_name" className={className}
                                            placeholder="First Name" style={{ paddingTop: '15px', paddingBottom: '15px' }}
                                            value={firstName} onChange={(e) => formFieldChange(e, "firstName")} />
                                        {(() => {
                                            if (errorObj.first_name.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.first_name.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                if (errorObj.last_name.isError) {
                                    className += " is-invalid";
                                }
                                return (
                                    <React.Fragment>
                                        <input type="text" name="l_name" className={className}
                                            placeholder="Last name" style={{ paddingTop: '15px', paddingBottom: '15px' }}
                                            value={lastName} onChange={(e) => formFieldChange(e, "lastName")} />
                                        {(() => {
                                            if (errorObj.last_name.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.last_name.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                if (errorObj.email.isError) {
                                    className += " is-invalid";
                                }
                                return (
                                    <React.Fragment>
                                        <input type="email" name="user" className={className}
                                            placeholder="Email" style={{ paddingTop: '15px', paddingBottom: '15px' }}
                                            value={email} onChange={(e) => formFieldChange(e, "email")} />
                                        {(() => {
                                            if (errorObj.email.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.email.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                if (errorObj.phone_number.isError) {
                                    className += " is-invalid";
                                }
                                return (
                                    <React.Fragment>
                                        <input type="text" name="phone_number" className={className}
                                            placeholder="Phone Number" style={{ paddingTop: '15px', paddingBottom: '15px' }}
                                            value={phoneNumber} onChange={(e) => formFieldChange(e, "phoneNumber")} />
                                        {(() => {
                                            if (errorObj.phone_number.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.phone_number.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </React.Fragment>
                                )
                            })()}
                        </li>

                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                if (userReferallInfo.current().isClientReferral) {
                                    return <p>Referred by {userReferallInfo.current().userName}</p>
                                }
                                let className = "form-control";
                                if (errorObj.referral_method.isError) {
                                    className += " is-invalid";
                                }
                                return (
                                    <React.Fragment>
                                        <select value={referral} className={className} style={{ paddingTop: '15px', paddingBottom: '15px' }}
                                            onChange={(e) => formFieldChange(e, "referral")}>
                                            {referralOptions.map((ele, index) => {
                                                return <option key={index} value={ele}>{ele}</option>
                                            })}
                                        </select>
                                        {(() => {
                                            if (errorObj.referral_method.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.referral_method.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </React.Fragment>
                                )
                            })()}
                        </li>

                        <li>
                            {getOtherText()}
                        </li>

                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                let inputType = "password";
                                let eyeClassName = "fa fa-eye fa-lg";
                                if (errorObj.password.isError) {
                                    className += " is-invalid";
                                }
                                if (passwordShow[0]) {
                                    inputType = "text";
                                    eyeClassName = "fa fa-eye-slash fa-lg"
                                }
                                return (
                                    <React.Fragment>
                                        <input type={inputType} name="password" id="password" className={className}
                                            placeholder="Password" style={{ paddingTop: '15px', paddingBottom: '15px' }}
                                            value={password} onChange={(e) => formFieldChange(e, "password")} />
                                        {(() => {
                                            if (errorObj.password.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.password.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                        <a href="#" onClick={(e) => toggleVisibilityPassword(0)}>
                                            <span className="eyeIcon">
                                                <i id="eye" className={eyeClassName} />
                                            </span>
                                        </a>
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                let inputType = "password";
                                let eyeClassName = "fa fa-eye fa-lg";
                                if (errorObj.re_password.isError) {
                                    className += " is-invalid";
                                }
                                if (passwordShow[1]) {
                                    inputType = "text";
                                    eyeClassName = "fa fa-eye-slash fa-lg"
                                }
                                return (
                                    <React.Fragment>
                                        <input type={inputType} name="r_password" id="repassword" className={className}
                                            placeholder="Re-Enter password" title="" style={{ paddingTop: '15px', paddingBottom: '15px' }}
                                            value={rePassword} onChange={(e) => formFieldChange(e, "rePassword")} />
                                        {(() => {
                                            if (errorObj.re_password.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.re_password.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                        <a href="#" onClick={(e) => toggleVisibilityPassword(1)}>
                                            <span className="eyeIcon">
                                                <i id="eye" className={eyeClassName} />
                                            </span>
                                        </a>
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                    </div>
                </div>
            </ul>
        )
    }

    function passwordRequirements() {
        return (
            <div className="card shadow clearfix">
                <div className="col-12 ">
                    <p className="italicFont mt-0 mb-0">Password Requirements:</p>
                    <ul className="pl-0">
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("charLimitCase")
                            }}>✓</h3>
                            8-20 characters
                        </div>
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("upperCase")
                            }}>✓</h3>
                            At least one uppercase letter
                        </div>
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("numberCase")
                            }}>✓</h3>
                            At least one number
                        </div>
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("specialCharCase")
                            }}>✓</h3>
                            At least one special character: !@#$%^&amp;*()
                        </div>
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("passMatchCase")
                            }}>✓</h3>
                            Password Match
                        </div>
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className="footer_section bg_with_circle">
            {(() => {
                let className = "alert alert-success w-50 fixed-bottom col-md-4 col-md-offset-4 alert-dismissible fade";
                if (showSuccessMessage) {
                    className += " show";
                }
                return (
                    <div className="row">
                        <div className={className} role="alert">
                            Thank You! Your account has been created. An email has been sent with steps to activate your account.
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )
            })()}
            <div className="banner_content_area">
                <section id="mainsigin">
                    <section className="loginpageSec clearfix">
                        <AuthSidePanel />
                        <div className="rightBgClr f-right clearfix" style={{ height: '110vh' }}>
                            <div className="loginCont clearfix">
                                {(() => {
                                    if (isFetchError) {
                                        return <a href="#"><b></b>Invalid Request</a>
                                    } else {
                                        return <a href="#"><b></b>{companyName}</a>
                                    }
                                })()}
                                <h2>Sign Up</h2>
                                <p className="italicFont">Please enter your details</p>
                                <form style={{ height: '50%', paddingBottom: '20%' }} onSubmit={formSubmit}>
                                    {signupFormElements()}
                                    <div className="rememberSec clearfix pt-10">
                                        <button id="signup_btn" type="submit" className="f-left btn-secondary-add-cpasage mt-0 ml-0"
                                            style={{ width: '40%', padding: '3% 2%' }} >Sign up</button>
                                        <Link to={urls.BASE_ADMIN_SIGNIN + props.match.params.adminCode + "/"} className="f-right exitUs pt-10"><span>Existing User? Login</span></Link>
                                    </div>
                                    <br />
                                    {passwordRequirements()}
                                    <br /><br />
                                </form>
                            </div>
                        </div>
                    </section>
                </section>
                <AdminFooter adminCode={props.match.params.adminCode} fetchedAdminInfo={fetchedAdminInfo} />
            </div>
        </div>
    )
}

export default ClientSignup;