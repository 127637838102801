import React from "react";

export interface IModalInputTextProps {
    title: string,
    value: string | number,
    inputType?: string,
    disabled?: boolean,
    style?: React.CSSProperties,
    borderBottom?: boolean,
    step?: string,
    min?: number,
    id?: string
    maxlength?: number
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    required?: boolean,
    isinvalidmsg?: String,
    isError?: boolean,
    errorText?: string
}

function ModalInputText(props: IModalInputTextProps) {
    function getBorderBottomStyle() {
        if (props.borderBottom == undefined || !props.borderBottom) {
            return { color: "black", borderBottom: "solid 0px #2c2c2c" };
        } else {
            return { color: "black", borderBottom: "solid 1px #2c2c2c" };
        }
    }
    function getAsterisk()
    {
        if(props.required == true)
        {
            return <span className="asterisk_input" style={{float:"right"}}>  </span> 
        }
    }
    function isvalid(){
         if(props.isinvalidmsg != ""){
            return <p style={{position:"absolute", marginLeft:"1%", color:"red", fontSize:"12px",backgroundColor:"white"}}>{props.isinvalidmsg}</p>
        }
    }

    let inputType = "text";
    let style = undefined;
    let inputClassName = "form-control";
    if (props.style != undefined) {
        style = {...props.style, ...getBorderBottomStyle() };
    } else {
        style = getBorderBottomStyle();
    }

    if (props.isError != undefined && props.isError) {
        inputClassName += " is-invalid";
    }

    if (props.inputType != undefined) {
        inputType = props.inputType;
    }
    return (
        <div className="form-group">
            {getAsterisk()}
            <input id={props.id} type={inputType} onChange={props.onChange}
                placeholder={props.title} value={props.value} disabled={props.disabled}
                className={inputClassName} style={style} required={(props.required == undefined || props.required)}
                step={props.step} min={props.min} maxLength={props.maxlength}/>
            {(() => {
                if (props.errorText != undefined && props.isError != undefined && props.isError) {
                    return (
                        <div className="invalid-feedback">
                            {props.errorText}
                        </div>
                    )
                }
            })()}
            {isvalid()}
                
        </div>
    )
}

export default ModalInputText; 
