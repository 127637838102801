import React from "react";
import headerLogo from "../../../../../../external/backPages/img/images/auth/login-logo.svg";
import smallBubble from "../../../../../../external/backPages/img/images/small-circle.png";

function AuthSidePanel() {
    return (
        <div className="leftBgClr f-left clearfix" style={{ height: '110vh' }}>
            <div className="loginLogo">
                <img src={headerLogo} alt="login" />
            </div>
            <div className="background-circle-animation">
                <div className="bubbleBase">
                    <img src={smallBubble} alt="" className="circle-opacity" />
                </div>
                <div className="bubbleBase2">
                    <img src={smallBubble} alt="" className="circle-opacity" />
                </div>
                <div className="bubbleBase3">
                    <img src={smallBubble} alt="" className="circle-opacity" />
                </div>
                <div className="bubbleBase4">
                    <img src={smallBubble} alt="" className="circle-opacity" />
                </div>
                <div className="bubbleBase5">
                    <img src={smallBubble} alt="" className="circle-opacity" />
                </div>
                <div className="bubbleBase6">
                    <img src={smallBubble} alt="" className="circle-opacity" />
                </div>
            </div>
        </div>
    )
}

export default AuthSidePanel;