import React from "react";

export interface ISelectProps {
    className: string,
    style: {
        color: string,
        borderBottom: string
    },
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    value?: number | string,
    disabled?: boolean
}

export interface IModalDropDownProps {
    options: number[] | string[],
    flag?: number,
    value?: number | string,
    values?: number[] | string[],
    borderBottom?: boolean,
    disabled?: boolean,
    required?:boolean,
    isError?: boolean,
    errorText?: string
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

function ModalDropDown(props: IModalDropDownProps) {

    let selectClassName = "form-control";
    let divClassName = "form-group";
    if (props.flag != undefined && props.flag == 1) {
        selectClassName += " form-control-dropdown";
        divClassName = "form-group";
    }

    if (props.isError != undefined && props.isError) {
        selectClassName += " is-invalid"
    }

    let selectProps: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>  = {
        className: selectClassName,
        style: props.borderBottom? {color: "black", borderBottom: "solid 1px #2c2c2c"}:{color: "black", borderBottom: "solid 0px #2c2c2c"},
        onChange: props.onChange,
        required: props.required
    }
    if (props.value != undefined) {
        selectProps.value = props.value;
    }
    function getAsterisk()
    {
        if(props.required == true)
        {
            return <span className="asterisk_dropdown" style={{float:"right"}}>  </span> 
        }
    }
    return (
        <div className={divClassName}>
            {getAsterisk()}
            <select {...selectProps}>
                {props.options.map((ele: number | string, index: number) => {
                    if (props.values == undefined) {
                        return <option key={ele + "" + index} value={ele}>{ele}</option>
                    } else {
                        return <option key={ele + "" + index} value={props.values[index]}>{ele}</option>
                    }
                })}
            </select>
            {(() => {
                if (props.errorText != undefined && props.isError != undefined && props.isError) {
                    return (
                        <div className="invalid-feedback">
                            {props.errorText}
                        </div>
                    )
                }
            })()}
        </div>
    )
}

export default ModalDropDown;
