import React, { Component } from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from 'axios';

import Table, { ILinksRepr, IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import ContentBox from "../../../intelws_portal/constructs/elements/ContentBox";
import Loading from "../../../intelws_portal/constructs/elements/Loading";
import { get, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";

interface IFetchDashboard extends AxiosResponse {
    data: {
        column_names: string[],
        access_keys: string[],
        link: ILinksRepr,
        data: IDataRepr[],
        title: string
    }
}

interface IModalDashbaordProps {
    chartName: string,
    dateName: string,
    datasetName: string,
    dateRange: string,
    closeCallback: () => void
}

function isStringEmpty(str: string | undefined) {
    return ((str == undefined) || (str.length == 0 || !str.trim()));
}

function ModalDashboard(props: IModalDashbaordProps) {
    const ENDPOINT = "api/dashboard/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [columnNames, setColumnNames] = React.useState<string[]>([]);
    const [accessKeys, setAccessKeys] = React.useState<string[]>([]);
    const [tableData, setTableData] = React.useState<IDataRepr[]>([]);
    const [link, setLink] = React.useState<ILinksRepr>();
    const [title, setTitle] = React.useState("");

    const [searchText, setSearchText] = React.useState("");

    const cancelTokenSource = React.useRef(getCancelToken());
    const tableCountRef = React.useRef(null);

    React.useEffect(() => {
        $("#dashboardModal").on("hidden.bs.modal", props.closeCallback);
        return () => {
            cancelTokenSource.current.cancel();
            $("#dashboardModal").off("hidden.bs.modal");
        }
    }, [])
    React.useEffect(() => {
        let shouldNotExecute = isStringEmpty(props.chartName) || isStringEmpty(props.dateName) || isStringEmpty(props.dateRange);

        if (props.chartName == "todo_summary") {
            shouldNotExecute = shouldNotExecute || isStringEmpty(props.datasetName);
        }
        if (!shouldNotExecute) {
            setIsLoaded(false);
            $("#dashboardModal").modal("show")
            let queryParams = {
                section: props.chartName,
                detail: JSON.stringify(true),
                dateName: props.dateName,
                date_range: props.dateRange,
                datasetName: props.datasetName
            }
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchDashboard>;
            requestResponse.then((response) => {
                setColumnNames(response.data.column_names);
                setAccessKeys(response.data.access_keys);
                setTableData(response.data.data);
                setTitle(response.data.title)
                setLink(response.data.link);
                setIsLoaded(true);
            })
        }
    }, [props.chartName, props.dateName, props.dateRange, props.datasetName])

    function getModalHeader() {
        return (
            <div className="searchWithCount">
                <div className="right-search-box pull-left">
                    <input type="search" placeholder="Search..."
                        onChange={(e) => setSearchText(e.target.value)} value={searchText} />
                </div>
                <div className="table-list-count pull-left" ref={tableCountRef}>
                    <span id="tableCount"></span>
                </div>
            </div>
        )
    }

    function getModalTable() {
        return (
            <React.Fragment>
                <Table searchString={searchText}
                    showRecords={true} columnNames={columnNames}
                    accessKeys={accessKeys} data={tableData}
                    recordsRef={tableCountRef} links={link} hasDownload={true}/>
            </React.Fragment>
        )
    }

    function getModalContent() {
        if (!isLoaded) {
            return <Loading />;
        } else {
            return (
                <React.Fragment>
                    <ModalHeader title={title} miscHeader={getModalHeader()} />
                    <div className="modal-body">
                        <div className="tableFixHead table-responsive custom-table">
                            <div className="col-sm-12 tabSec">
                                <ContentBox body={getModalTable()} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal custom-small text-center" id="dashboardModal" tabIndex={-1}>
                <div className="modal-dialog text-left modal-xl">
                    <div className="modal-content">
                        {getModalContent()}
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById("modal") as Element);
}

export default ModalDashboard;
