import React from "react";
import { ChartDataSets } from "chart.js";
import { AxiosResponse } from "axios";

import BarChart from '../../../intelws_portal/constructs/chart/BarChart';
import LineChart from '../../../intelws_portal/constructs/chart/LineChart';
import Loading from '../../../intelws_portal/constructs/elements/Loading';
import ChartContentBox from "../../../intelws_portal/constructs/elements/ChartContentBox";
import { get, getCancelToken } from "../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../declarations/common";
import ModalDashboard from "../modal/ModalDashboard";

interface IFetchDataRepr {
    first_name?: string,
    task_list_labels?: string[],
    task_list?: number[],
    show_task?: boolean,
    project_list_labels?: string[],
    project_list?: number[],
    show_project?: boolean,
    file_upload_labels?: string[],
    file_upload?: number[],
    show_file?: boolean,
    project_file_upload_labels?: string[],
    project_file_upload?: number[],
    show_project_file?: boolean,
    client_growth_labels?: string[],
    client_growth?: number[],
    show_client?: boolean,
    todo_labels?: string[],
    todo?: {
        [key: string]: {
            data: number[],
            color: string
        }
    },
    show_todo?: boolean,
    invoice_growth_labels?: string[],
    invoice_growth?: {
        [key: string]: {
            data: number[],
            color: string
        } 
    },
    show_invoice?: boolean
}

interface IFetchData extends AxiosResponse {
    data: IFetchDataRepr
}

function AdminDashboard(props: IPageComponentProps) {
    const ENDPOINT = "api/dashboard/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [taskLabels, setTaskLabels] = React.useState<string[]>([]);
    const [taskDataset, setTaskDataset] = React.useState<ChartDataSets[]>([]);
    const [taskDropdown, setTaskDropdown] = React.useState("1week");
    const [showTask, setShowTask] = React.useState(false);
    const [projectLabels, setProjectLabels] = React.useState<string[]>([]);
    const [projectDataset, setProjectDataset] = React.useState<ChartDataSets[]>([]);
    const [projectDropdown, setProjectDropdown] = React.useState("1week");
    const [showProject, setShowProject] = React.useState(false);
    const [todoLabels, setTodoLabels] = React.useState<string[]>([]);
    const [todoDataset, setTodoDataset] = React.useState<ChartDataSets[]>([]);
    const [todoDropdown, setTodoDropdown] = React.useState("1week");
    const [showTodo, setShowTodo] = React.useState(false);
    const [fileLabels, setFileLabels] = React.useState<string[]>([]);
    const [fileDataset, setFileDataset] = React.useState<ChartDataSets[]>([]);
    const [fileDropdown, setFileDropdown] = React.useState("1week");
    const [showFile, setShowFile] = React.useState(false);
    const [projectFileLabels, setProjectFileLabels] = React.useState<string[]>([]);
    const [projectFileDataset, setProjectFileDataset] = React.useState<ChartDataSets[]>([]);
    const [projectFileDropdown, setProjectFileDropdown] = React.useState("1week");
    const [showProjectFile, setShowProjectFile] = React.useState(false);
    const [invoiceLabels, setInvoiceLabels] = React.useState<string[]>([]);
    const [invoiceDataset, setInvoiceDataset] = React.useState<ChartDataSets[]>([]);
    const [invoiceDropdown, setInvoiceDropdown] = React.useState("1week");
    const [showInvoice, setShowInvoice] = React.useState(false);
    const [clientLabels, setClientLabels] = React.useState<string[]>([]);
    const [clientDataset, setClientDataset] = React.useState<ChartDataSets[]>([]);
    const [clientDropdown, setClientDropdown] = React.useState("1week");
    const [showClient, setShowClient] = React.useState(true);
    const [firstName, setFirstName] = React.useState("");

    const [chartName, setChartName] = React.useState("");
    const [dateName, setDateName] = React.useState("");
    const [datasetName, setDatasetName] = React.useState("");
    const [dateRange, setDateRange] = React.useState("");
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("Dashboard");
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token) as Promise<IFetchData>;
        requestResponse.then((response) => {
            if (response.data.first_name != undefined) {
                setFirstName(response.data.first_name);
            }

            if (response.data.task_list_labels != undefined) {
                setTaskLabels(response.data.task_list_labels);
            }
            setTaskDataset(getTaskDataset(response.data));
            if (response.data.show_task != undefined) {
                setShowTask(response.data.show_task);
            }

            if (response.data.project_list_labels != undefined) {
                setProjectLabels(response.data.project_list_labels);
            }
            setProjectDataset(getProjectDataset(response.data));
            if (response.data.show_project != undefined) {
                setShowProject(response.data.show_project);
            }
 
            if (response.data.todo_labels != undefined) {
                setTodoLabels(response.data.todo_labels);
            }
            setTodoDataset(getTodoDataset(response.data));
            if (response.data.show_todo != undefined) {
                setShowTodo(response.data.show_todo);
            }
            if (response.data.file_upload_labels != undefined) {
                setFileLabels(response.data.file_upload_labels);
            }
            setFileDataset(getFileDataset(response.data));
            if (response.data.show_file != undefined) {
                setShowFile(response.data.show_file);
            }
            if (response.data.project_file_upload_labels != undefined) {
                setProjectFileLabels(response.data.project_file_upload_labels);
            }
            setProjectFileDataset(getProjectFileDataset(response.data));
            if (response.data.show_project_file != undefined) {
                setShowProjectFile(response.data.show_project_file);
            }
            if (response.data.invoice_growth_labels != undefined) {
                setInvoiceLabels(response.data.invoice_growth_labels);
            }
            setInvoiceDataset(getInvoiceDataset(response.data));
            if (response.data.show_invoice != undefined) {
                setShowInvoice(response.data.show_invoice);
            }
            if (response.data.client_growth_labels != undefined) {
                setClientLabels(response.data.client_growth_labels);
            }
            setClientDataset(getClientDataset(response.data));
            if (response.data.show_client != undefined) {
                setShowClient(response.data.show_client);
            }
            setIsLoaded(true);
        })
    }, [])

    function getTaskDataset(result: IFetchDataRepr): ChartDataSets[] {
        if (result.task_list != undefined) {
            return [{ data: result.task_list, backgroundColor: "#2C4B9B", borderColor: "#2C4B9B" }];
        }
        return [];
    }

    function getProjectDataset(result: IFetchDataRepr): ChartDataSets[] {
        if (result.project_list != undefined) {
            return [{ data: result.project_list, backgroundColor: "#2C4B9B", borderColor: "#2C4B9B" }];
        }
        return [];
    }


    function getClientDataset(result: IFetchDataRepr): ChartDataSets[] {
        if (result.client_growth != undefined) {
            return [{ data: result.client_growth, backgroundColor: "#2C4B9B", borderColor: "#2C4B9B", fill: false }];
        }
        return [];
    }

    function getFileDataset(result: IFetchDataRepr): ChartDataSets[] {
        if (result.file_upload != undefined) {
            return [{ data: result.file_upload, backgroundColor: "#2C4B9B", borderColor: "#2C4B9B" }];
        }
        return [];
    }

    function getProjectFileDataset(result: IFetchDataRepr): ChartDataSets[] {
        if (result.project_file_upload != undefined) {
            return [{ data: result.project_file_upload, backgroundColor: "#2C4B9B", borderColor: "#2C4B9B" }];
        }
        return [];
    }

    function getInvoiceDataset(result: IFetchDataRepr): ChartDataSets[] {
        let dataset: ChartDataSets[] = [];
        if (result.invoice_growth != undefined) {
            for (let [key, value] of Object.entries(result.invoice_growth)) {
                dataset.push({
                    data: value.data,
                    label: key,
                    backgroundColor: value.color,
                    borderColor: value.color,
                    fill: false
                })
            }
        }
        return dataset;
    }

    function getTodoDataset(result: IFetchDataRepr): ChartDataSets[] {
        let dataset: ChartDataSets[] = [];
        if (result.todo != undefined) {
            for (let [key, value] of Object.entries(result.todo)) {
                dataset.push({
                    data: value.data,
                    label: key,
                    backgroundColor: value.color,
                    borderColor: value.color
                })
            }
        }
        return dataset;
    }

    function getDropdown(value: string, section: string) {
        return (
            <select className="form-control" value={value} onChange={(e) => onChangeDropdown(e, section)}>
                <option value="1week">1 Week</option>
                <option value="1month">1 Month</option>
                <option value="3month">3 Month</option>
            </select>
        )
    }

    function onChangeDropdown(e: React.ChangeEvent<HTMLSelectElement>, section: string) {
        if (section == "task_summary") {
            setTaskDropdown(e.target.value);
        } else if (section == "project_summary") {
            setProjectDropdown(e.target.value);
        } else if (section == "todo_summary") {
            setTodoDropdown(e.target.value);
        } else if (section == "file_upload_summary") {
            setFileDropdown(e.target.value);
        } else if (section == "project_file_upload_summary") {
            setProjectFileDropdown(e.target.value);
        } else if (section == "client_growth") {
            setClientDropdown(e.target.value);
        } else if (section == "invoice_growth") {
            setInvoiceDropdown(e.target.value);
        }
        let queryParams = {
            section: section,
            date_range: e.target.value
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchData>;
        requestResponse.then((response) => {
            if (section == "task_summary") {
                if (response.data.task_list_labels != undefined) {
                    setTaskLabels(response.data.task_list_labels);
                    setTaskDataset(getTaskDataset(response.data));
                }
            } else if (section == "project_summary") {
                if (response.data.project_list_labels != undefined) {
                    setProjectLabels(response.data.project_list_labels);
                    setProjectDataset(getProjectDataset(response.data));
                }
            } else if (section == "todo_summary") {
                if (response.data.todo_labels != undefined) {
                    setTodoLabels(response.data.todo_labels);
                    setTodoDataset(getTodoDataset(response.data));
                }
            } else if (section == "file_upload_summary") {
                if (response.data.file_upload_labels != undefined) {
                    setFileLabels(response.data.file_upload_labels);
                    setFileDataset(getFileDataset(response.data));
                }
            } else if (section == "project_file_upload_summary") {
                if (response.data.project_file_upload_labels != undefined) {
                    setProjectFileLabels(response.data.project_file_upload_labels);
                    setProjectFileDataset(getProjectFileDataset(response.data));
                }
            } else if (section == "client_growth") {
                if (response.data.client_growth_labels != undefined) {
                    setClientLabels(response.data.client_growth_labels);
                    setClientDataset(getClientDataset(response.data));
                }
            } else if (section == "invoice_growth") {
                if (response.data.invoice_growth_labels != undefined) {
                    setInvoiceLabels(response.data.invoice_growth_labels);
                    setInvoiceDataset(getInvoiceDataset(response.data));
                }
            }
        })
    }

    function chartCallback(chartName: string, dateName: string, datasetName: string) {
        if (chartName == "task_summary") {
            let taskDropdown = "";
            setTaskDropdown((prevTaskDropdown) => {
                taskDropdown = prevTaskDropdown;
                return prevTaskDropdown;
            })
            setDateRange(taskDropdown);
        }
        else if (chartName == "project_summary") {
            let projectDropdown= "";
            setProjectDropdown((prevProjectDropdown) => {
                projectDropdown = prevProjectDropdown;
                return prevProjectDropdown;
            })
            setDateRange(projectDropdown);
        }
        else if (chartName == "todo_summary") {
            let todoDropdown= "";
            setTodoDropdown((prevTodoDropdown) => {
                todoDropdown = prevTodoDropdown;
                return prevTodoDropdown;
            })
            setDateRange(todoDropdown);
        }
        else if (chartName == "file_upload_summary") {
            let fileDropdown = "";
            setFileDropdown((prevFileDropdown) => {
                fileDropdown = prevFileDropdown;
                return prevFileDropdown;
            })
            setDateRange(fileDropdown);
        }
        else if (chartName == "project_file_upload_summary") {
            let projectFileDropdown = "";
            setProjectFileDropdown((prevprojectFileDropdown) => {
                projectFileDropdown = prevprojectFileDropdown;
                return prevprojectFileDropdown;
            })
            setDateRange(projectFileDropdown);
        }
        setDatasetName(datasetName);
        setChartName(chartName);
        setDateName(dateName);
    }

    if (!isLoaded) {
        return <Loading />;
    } else {
        return (
            <div className="body-content-wrapper">
                <div className="dashboard-chart">
                    <div className="heading-box">
                        <h6 className="col-sm-6 f-left" >Hello {firstName},</h6>
                    </div>
                    <div className="chart-container row">
                        {(() => {
                            if (showTask) {
                                return (
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="chart-item top-line active">
                                            <ChartContentBox title="TaxFlow"
                                                dropDown={getDropdown(taskDropdown, "task_summary")}
                                                body={<BarChart labels={taskLabels}
                                                    dataset={taskDataset}
                                                    chartCallback={(date, datasetName) => chartCallback("task_summary", date, datasetName)}
                                                    legend={false} />} />
                                        </div>
                                    </div>
                                )
                            }
                        })()}
                        {(() => {
                            if (showProject) {
                                return (
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="chart-item top-line active">
                                            <ChartContentBox title="ProjectFlow"
                                                dropDown={getDropdown(projectDropdown, "project_summary")}
                                                body={<BarChart labels={projectLabels}
                                                    dataset={projectDataset}
                                                    chartCallback={(date, datasetName) => chartCallback("project_summary", date, datasetName)}
                                                    legend={false} />} />
                                        </div>
                                    </div>
                                )
                            }
                        })()}
                        {(() => {
                            if (showTodo) {
                                return (
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="chart-item top-line">
                                            <ChartContentBox title="Task"
                                                dropDown={getDropdown(todoDropdown, "todo_summary")}
                                                body={<BarChart labels={todoLabels}
                                                    dataset={todoDataset}
                                                    chartCallback={(date, datasetName) => chartCallback("todo_summary", date, datasetName)}
                                                    legend={true} />} />
                                        </div>
                                    </div>
                                )
                            }
                        })()}
                        {(() => {
                            if (showFile) {
                                return (
                                    <div className="col-lg-4 col-md-4 col-sm-12 pt-25">
                                        <div className="chart-item top-line active">
                                            <ChartContentBox title="Tax Docs"
                                                dropDown={getDropdown(fileDropdown, "file_upload_summary")}
                                                body={<BarChart labels={fileLabels}
                                                    dataset={fileDataset}
                                                    chartCallback={(date, datasetName) => chartCallback("file_upload_summary", date, datasetName)}
                                                    legend={false} />} />
                                        </div>
                                    </div>
                                )
                            }
                        })()}
                        {(() => {
                            if (showProjectFile) {
                                return (
                                    <div className="col-lg-4 col-md-4 col-sm-12 pt-25">
                                        <div className="chart-item top-line active">
                                            <ChartContentBox title="Project Docs"
                                                dropDown={getDropdown(projectFileDropdown, "project_file_upload_summary")}
                                                body={<BarChart labels={projectFileLabels}
                                                    dataset={projectFileDataset}
                                                    chartCallback={(date, datasetName) => chartCallback("project_file_upload_summary", date, datasetName)}
                                                    legend={false} />} />
                                        </div>
                                    </div>
                                )
                            }
                        })()}
                       {(() => {
                            if (showInvoice) {
                                return (
                                    <div className="col-lg-4 col-md-3 col-sm-12 pt-25">
                                        <div className="chart-item top-line">
                                            <ChartContentBox title="Invoice"
                                                dropDown={getDropdown(invoiceDropdown, "invoice_growth")}
                                                body={<LineChart labels={invoiceLabels}
                                                    dataset={invoiceDataset}
                                                    legend={true} />} />
                                        </div>
                                    </div>
                                )
                            }
                        })()}
                        {(() => {
                            if (showClient) {
                                return (
                                    <div className="col-lg-4 col-md-3 col-sm-12 pt-25">
                                        <div className="chart-item top-line active">
                                            <ChartContentBox title="Client"
                                                dropDown={getDropdown(clientDropdown, "client_growth")}
                                                body={<LineChart labels={clientLabels}
                                                    dataset={clientDataset}
                                                    legend={false} />} />
                                        </div>
                                    </div>
                                )
                            }
                        })()}
                    </div>
                </div>
                <ModalDashboard
                    chartName={chartName}
                    dateName={dateName}
                    datasetName={datasetName}
                    dateRange={dateRange}
                    closeCallback={() => {
                        setChartName("");
                        setDateName("");
                        setDatasetName("");
                        setDateRange("");
                    }
                    }
                />
            </div>
        )
    }
}

export default AdminDashboard;
