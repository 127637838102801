import React from "react";
import * as urls from "../../Urls";
import moment from "moment";
import $ from "jquery";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import { EventInput, EventSourceInput, isPropsEqual, preventSelection } from "@fullcalendar/common";
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import bootstrapPlugin from "@fullcalendar/bootstrap";
import ModalEventAdd from "../modal/ModalEventAdd";
import { get, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import { AxiosResponse } from "axios";
import Loading from "../../../intelws_portal/constructs/elements/Loading";
import { getBundledAlertsUI } from "../../../declarations/common_utils";
import ModalEventDetails from "../modal/ModalEventDetails";
import { IPageComponentProps } from "../../../declarations/common";
import { IGoogleIntegration } from "../admin/AdminSettings";
import { Link } from "react-router-dom";

export interface ICalendarEventRepr {
    id: number,
    start: string,
    end: string,
    title: string
}

interface ICalendarEvents extends AxiosResponse {
    data: {
        data: ICalendarEventRepr[],
        is_add: boolean
    }
}

function Calendar(props: IPageComponentProps) {
    const SETTINGS_ENDPOINT = "api/settings/";
    const CALENDAR_FEED_ENDPOINT = "api/calendarfeed/";

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isIntegrated, setIsIntegrated] = React.useState(false);
    const [isAdd, setIsAdd] = React.useState(false);
    const [events, setEvents] = React.useState<EventInput>([]);
    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);

    const [currAppointmentId, setCurrApointmentId] = React.useState(-1);

    const cancelTokenSource = React.useRef(getCancelToken());
    const calendarRef = React.useRef<FullCalendar>();

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("Calendar");
        }
        const requestResponse = get(SETTINGS_ENDPOINT, cancelTokenSource.current.token, { section: "google_integration_status" }) as Promise<IGoogleIntegration>;
        requestResponse.then((response) => {
            setIsIntegrated(response.data.is_integrated);
            setIsLoaded(true);
            if (response.data.is_integrated) {
                const requestResponse = get(CALENDAR_FEED_ENDPOINT, cancelTokenSource.current.token) as Promise<ICalendarEvents>;
                requestResponse.then((response) => {
                    setEvents(response.data.data);
                    setIsAdd(response.data.is_add);
                })
            }
        })
    }, [])

    function eventAdd(data: ICalendarEventRepr[], success: string[], fail: string[]) {
        setEvents((prevEvents) => {
            return [...prevEvents as ICalendarEventRepr[], ...data];
        })
        setSuccess(success);
        setFail(fail);
    }

    function eventDelete(appointmentId: number) {
        setEvents((prevEvents) => {
            return (prevEvents as ICalendarEventRepr[]).filter((currEvent) => currEvent.id !== appointmentId);
        })
    }

    if (!isLoaded) {
        return <Loading />
    }
    if (!isIntegrated) {
        return <h1>Please integrate your google account in <Link to={urls.ADMIN_SETTINGS + "#integrations"}>Settings --&gt; Integrations</Link></h1>
    }

    let customButtons = {};
    let headerToolbar = {
        left: "prev next today",
        center: "title",
        right: ""
    }
    if (isAdd) {
        customButtons = {
            "addEventBtn": {
                text: "Add Event",
                click: () => {
                    $("#eventAddModal").modal("show");
                }
            }
        }
        headerToolbar.left += " addEventBtn";
    }
    return (
        <React.Fragment>

            <div className="body-content-wrapper clearfix">
                {getBundledAlertsUI(success, fail, setSuccess, setFail)}
                <FullCalendar
                    ref={calendarRef as any}
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    selectable
                    events={events}
                    eventClick={(arg) => {
                        arg.jsEvent.preventDefault();
                        setCurrApointmentId(parseInt(arg.event.id));
                        $("#eventDetailsModal").modal("show");
                    }}
                    customButtons={customButtons}
                    headerToolbar={headerToolbar}
                    dayMaxEvents={3}
                    height={1000}

                />
                {(() => {
                    if (isAdd) {
                        return <ModalEventAdd mutator={eventAdd} />
                    }
                })()}
                <ModalEventDetails appointmentId={currAppointmentId} cancelEventCallback={eventDelete} />
            </div>

        </React.Fragment>
    )
}

export default Calendar;