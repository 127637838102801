import { Stripe, StripeElements } from "@stripe/stripe-js";
import { IBankAccountBillingRepr, ICardBillingRepr, TPaymentBackendTypes, TPaymentTypes } from "./paymentTypes";
import * as stripeBackend from "./stripe/payments";

export async function confirmSetup(backend: TPaymentBackendTypes, type: TPaymentTypes, clientSecret: string, stripeSDK?: Stripe, stripeElements?: StripeElements, 
    cardBilling?: ICardBillingRepr, bankAccountBilling?: IBankAccountBillingRepr, step?: number) {
    if (backend == "stripe" && stripeSDK != undefined && stripeElements != undefined) {
        return await stripeBackend.confirmSetup(stripeSDK, stripeElements, type, clientSecret, cardBilling, bankAccountBilling, step);
    }
}

export async function confirmPayment(backend: TPaymentBackendTypes, type: TPaymentTypes, clientSecret: string, stripeSDK?: Stripe | null, 
    stripeElements?: StripeElements | null, cardBilling?: ICardBillingRepr, bankAccountBilling?: IBankAccountBillingRepr, step?: number) {
    if (backend == "stripe" && stripeSDK != undefined && stripeElements != undefined) {
        return await stripeBackend.confirmPayment(stripeSDK, stripeElements, type, clientSecret, cardBilling, bankAccountBilling, step);
    }
}

export async function fetchPaymentIntentID(backend: TPaymentBackendTypes, clientSecret: string, stripeSDK?: Stripe | null) {
    if (backend == "stripe" && stripeSDK != undefined) {
        return await stripeBackend.fetchPaymentIntentID(stripeSDK, clientSecret);
    }
}