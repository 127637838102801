import { AxiosResponse } from "axios";
import React from "react";
import Loading from "../../../../../intelws_portal/constructs/elements/Loading";
import { get, getCancelToken} from "../../../../../intelws_portal/utils/backendInterface";
import { IPageComponentProps } from "../../../../../declarations/common";
import * as urls from "../../../../Urls";
import { AdminNavBar } from "./AdminNavBar";
import AdminFooter from "./AdminFooter";
import { AdminHomeSection } from "./AdminHomeSection";
import HeroSectionImg from "../../../../../external/backPages/img/images/AdminHomeImgs/hero-img.png";
import AdminServices from "./AdminServices";
interface IUrlParams {
    adminCode: string
}

interface IAdminInfoFetch extends AxiosResponse {
    data: {
        data: {
            admin_company: string,
            admin_company_url: string
        }
    }
}

function AdminHomePage(props: IPageComponentProps<IUrlParams>) {
    const ENDPOINT = "/api/authentication/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [companyName, setCompanyName] = React.useState("");
    const [companyUrl, setCompanyUrl] = React.useState("");
    const [isFetchError, setIsFetchError] = React.useState(false);
    const [adminCode, setAdminCode] = React.useState(props.match.params.adminCode);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        let queryParams = {
            section: "admin_code_info",
            admin_code: adminCode
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IAdminInfoFetch>;
        requestResponse.then((response) => {
            setIsLoaded(true);
            setCompanyName(response.data.data.admin_company);
            setCompanyUrl(response.data.data.admin_company_url);
            document.title = response.data.data.admin_company;
        }).catch(() => {
            setIsFetchError(true);
            setIsLoaded(true);
        })
    }, [])


    if (!isLoaded) {
        return <Loading />
    }

    return (
        <div className="footer_section bg_with_circle">
            <div className="banner_content_area">
                {(() => {
                    if (isFetchError) {
                        return <a href="#"><b></b>Invalid Request</a>
                    } else {
                        return (
                            <React.Fragment>
                                <AdminNavBar companyName={companyName} adminCode={adminCode} />
                                <AdminHomeSection mainHeading={"Lorem Ipsum has been the industry's standard"} subHeading={"when an unknown printer took a galley of type and scrambled it to make a type specimen book"} homeSectionImg={HeroSectionImg} signUp={urls.BASE_CLIENT_SIGNUP + adminCode + "/"} />
                                <AdminServices adminCode={adminCode}/>
                                <div className="contain">
                                <iframe src={urls.BASE_CONTACT_US + adminCode + "/"} height={640} style={{width:"100%"}}/>
                                </div>
                                {/* <AdminAboutUs aboutUsHeading={"Corporis temporibus maiores provident"} adminInfo={"Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"} adminImg={AdminImg} /> */}
                                <AdminFooter adminCode={adminCode} />
                            </React.Fragment>
                        )
                    }
                })()}
            </div>
        </div>
    )
}

export default AdminHomePage;