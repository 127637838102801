import React, { Component } from "react";

export interface IModalCommentText {
    title: string,
    placeholder: string,
    value: string,
    rows?: number,
    cols?: number,
    disabled?: boolean,
    required?:boolean,
    isinvalidmsg?: String,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    isError?: boolean,
    errorText?: string
}

function ModalCommetText(props: IModalCommentText) {
    function getAsterisk()
    {
        if(props.required == true)
        {
            return <span className="asterisk_input" style={{float:"right"}}>  </span> 
        }
    }
    function isvalid(){
         if(props.isinvalidmsg != ""){
            return <p style={{position:"absolute", marginLeft:"1%", color:"red", fontSize:"12px",backgroundColor:"white"}}>{props.isinvalidmsg}</p>

        }
    }
    let inputClassName = "form-control";

    if (props.isError != undefined && props.isError) {
        inputClassName += " is-invalid";
    }

    return (
        <div className="form-group">
            <p className="form-label">{props.title}</p>
            {getAsterisk()}
            <textarea placeholder={props.placeholder} rows={props.rows} cols={props.cols} disabled={props.disabled}
                onChange={props.onChange} className={inputClassName} style={{ color: "black", borderBottom: "solid 1px #2c2c2c" }} value={props.value}
                required={props.required}>
            </textarea>
            {(() => {
                if (props.errorText != undefined && props.isError != undefined && props.isError) {
                    return (
                        <div className="invalid-feedback">
                            {props.errorText}
                        </div>
                    )
                }
            })()}
            {isvalid()}
        </div>
    )
}

export default ModalCommetText;