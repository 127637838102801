import { AxiosResponse } from "axios";
import React from "react";
import $ from "jquery";
import ReactDOM from "react-dom";
import { parseQueryResponseOnFail, parseQueryResponseOnFailResponse, usePickPropsIfNotNull } from "../../../declarations/common_utils";
import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import { get, getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";

interface IPostAddReviewLink extends AxiosResponse {
    data: {
        data: IDataRepr
    }
}

interface IModalReviewLinkAddProps {
    reviewIndex?: number,
    reviewLink?: string,
    reviewType?: string,
    mutator: (data: IDataRepr) => void
}

function ModalReviewLinkAdd(props: IModalReviewLinkAddProps) {
    const ENDPOINT = "api/settings/";
    const [reviewIndex, setReviewIndex] = usePickPropsIfNotNull(props.reviewIndex, -1);
    const [reviewName, setReviewName] = usePickPropsIfNotNull(props.reviewType, '');
    const [reviewLink, setReviewLink] = usePickPropsIfNotNull(props.reviewLink, '');

    const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);
    const cancelTokenSource = React.useRef(getCancelToken());
    const [disable, setDisable] = React.useState(true);
   
    React.useEffect(() => {
        $("#reviewLinkAddModal").on("hidden.bs.modal", clearForm)
        return () => {
            cancelTokenSource.current.cancel();
            $("#reviewLinkAddModal").off("hidden.bs.modal");
        }
    }, [])

    React.useEffect(() => {
        let newDisable = true;
        if (reviewName != "" && reviewLink != "") {
            newDisable = false;
        }
        setDisable(newDisable);
    }, [reviewName, reviewLink]);

    function clearForm() {
        setReviewName("");
        setReviewLink("");
        setErrorObj(getEmptyErrorObj);
    }

    function getEmptyErrorObj() {
        return {
            review_name: {
                isError: false,
                errorText: ""
            },
            review_link: {
                isError: false,
                errorText: ""
            }
        }
    }

    function submitForm() {
        let formData = new FormData();
        formData.append("review_type", reviewName);
        formData.append("review_link", reviewLink);
        if (reviewIndex > -1){
            formData.append("review_index", reviewIndex.toString());
        }
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, { section: "set_review_links" }) as Promise<IPostAddReviewLink>;
        requestResponse.then((response) => {
            props.mutator(response.data.data);
            $("#reviewLinkAddModal").modal("hide");
        }).catch((error) => {
            let [success, fail, failResponse] = parseQueryResponseOnFail(error);
            let errorObj = getEmptyErrorObj(); 
            parseQueryResponseOnFailResponse(errorObj, failResponse);
            setErrorObj(errorObj);
        })
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> , section: "reviewName" | "reviewLink") {
        if (section == "reviewName") {
            setReviewName(e.target.value);
        } else if (section == "reviewLink") {
            setReviewLink(e.target.value);
        }
    }
    
    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="reviewLinkAddModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Add Review Link"} />
                        <div className="modal-body">
                            <ModalInputText onChange={(e) => formFieldChange(e, "reviewName")} value={reviewName} title={"Review Name"}
                                required borderBottom isError={errorObj.review_name.isError} errorText={errorObj.review_name.errorText} /> <br />
                            <ModalInputText onChange={(e) => formFieldChange(e, "reviewLink")} value={reviewLink} title={"Review Link"} 
                                required borderBottom isError={errorObj.review_link.isError} errorText={errorObj.review_link.errorText} /> <br />
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={false} disable={disable}/>
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalReviewLinkAdd;