import React, { useState } from "react";
import ModalInputText from "../constructs/modal/ModalInputText";
import ModalFilePicker from "../constructs/modal/ModalFilePicker";
import userLogo from '../../external/backPages/img/images/name-profile-active.svg';

interface IFilesRepr {
    fileName: string,
    fileLink: string
}

export interface ITicketDisplayProps {
    subject: string,
    create_time: string,
    backUrl: string,
    msg: string[],
    msgSent: string[],
    msgTimestamp: string[],
    msgFiles?: IFilesRepr[][],
    showClose?: boolean,
    showBack?: boolean,
    showAddFiles?: boolean
    // flag:boolean,
    formSubmitCallback: (message: string, files: (File | undefined)[]) => void
}

function TicketDisplay(props: ITicketDisplayProps) {
    const [message, setMessage] = React.useState("");
    const [files, setFiles] = React.useState<(File | undefined)[]>([]);
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    
    function getCloseButton() {
        if (props.showClose == undefined || props.showClose) {
            return <button onClick={formSubmitCallback} className="btn-secondary-add-cpasage mr-4" style={{padding: "12px 40px"}}>Close</button>
        }
    }

    function getBackButton() {
        if (props.showBack == undefined || props.showBack) {
            return <a href={props.backUrl} className="btn-secondary-add-cpasage" style={{padding: "12px 40px"}}>Back</a>;
        }
    }

    function getAddFileButton() {
        if (props.showAddFiles == undefined || props.showAddFiles) {
            return <button onClick={(e) => formFieldChange(e as any, "addFile")} className="btn-secondary-add-cpasage mr-4" style={{width: "12%",padding: "10px 20px"}}>+ File</button>
        }
    }
    
    function getHeader() {
        return (
            <div style={{height:"50px"}}>
            <h5 className="card-title" style={{display:"inline"}}> {props.subject}</h5>
            <div style={{float:"right"}}>
            {getCloseButton()}
            {getBackButton()}
            </div>
          </div>
        )
    }
    function getElementHeader(sent: string, timeStamp: string) {
        return (
            <p  className="card-title" style={{display:"inline",color:"#554e4e",fontSize:"12px"}}> <img src={userLogo} alt="user icon " style={{marginBottom:"5px",height: "25px",width: "25px",display: "inline-block",padding:"2px"}}></img>&nbsp;&nbsp;<b>{sent}</b> &nbsp;&nbsp;commented on &nbsp; {timeStamp}
            </p>
        )
    }

    function getElementFiles(files: IFilesRepr[]) {
        return (
            <div className="card-tools mr-3">
                {files.map((ele) => {
                    return <div ><a style={{color:"#4b5ca9c5",fontSize:"12px"}}href={ele.fileLink}><i className="fa fa-paperclip" style={{color:"#554e4e"}} aria-hidden="true"></i>&nbsp;&nbsp;{ele.fileName}</a><br /></div>
                })}
            </div>
        )
    }

    function getElementMsg(msg: string) {
        return (
            <div className="card-body">
                <p>{msg}</p>
            </div>
        )
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement>, section: "message" | "file" | "addFile", index?: number) {
        if (section == "message") {
            setMessage(e.target.value);
        } else if (section == "file" && index != undefined) {
            setFiles((prevFiles) => {
                let newFiles = [...prevFiles];
                let fileList = e.target.files;
                if (fileList != undefined) {
                    newFiles[index] = fileList[0];
                }
                return newFiles;
            })
        } else if (section == "addFile") {
            setFiles((prevFiles) => {
                return [...prevFiles, undefined];
            })
        }
    }

    function clearForm() {
        setMessage("");
        setFiles([]);
    }
    
    function formSubmitCallback() {
        props.formSubmitCallback(message, files);
        clearForm();
    }

    function getFormFiles() {
        return files.map((ele, index) => {
            return <ModalFilePicker onChange={(e) => formFieldChange(e, "file", index)} value={ele} />
        })
    }
    
    function getBody() {
        return props.msg.map((ele, index) => {
            return (
                <div className="pt-2 pb-2">
                <div className="card shadow-sm" >
                    <div className="card-header" style={{background:"#e7e7e7",padding: ".40rem 1.25rem"}}>
                        {getElementHeader(props.msgSent[index], props.msgTimestamp[index])}
                        <div style={{float:"right"}}>
                            <button className="btn" style={{color:"white"}} onClick={toggleReadMore}></button>
                        </div>
                    </div>
                
                <div className="card-body">
                <p className="text" style={{fontSize:"14px"}}>
                    {isReadMore ? ele : ele.slice(0, 150)}
                </p>
                {(() => {
                    if (props.msgFiles != undefined) {
                        return getElementFiles(props.msgFiles[index])
                    }
                })()}
                </div>
            </div>
        </div>
            )
        })
    }
    

    function getFooter() {
        return (
            <div className="card-footer">
                <a href={props.backUrl}>Back link here to Ticket System Page</a>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="container pt-4">
                {getHeader()}
                {getBody()} 
                
                <div className="card pt-4">
                <div className="card-body">
                    <ModalInputText title="Message" value={message} onChange={(e) => formFieldChange(e, "message")}/>
                    {getFormFiles()}
                    <div className="pl-0 pt-3">
                        {getAddFileButton()}
                        <button onClick={formSubmitCallback} className="btn-secondary-add-cpasage" style={{width: "12%",padding: "10px 20px"}}>Reply...</button>
                    </div>
                    </div>
            </div>
            </div>
            
        </React.Fragment>
    )
}

export default TicketDisplay;