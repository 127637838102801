import React from "react";

export interface IBadgeProps {
    badgeType: "warning" | "danger" | "info" | "primary" | "success" | "secondary",
    value: string
}

function Badge(props: IBadgeProps) {
    if (props.badgeType == "warning") {
        return (
            <span> &nbsp;&nbsp; 
                <span className="badge badge-warning" style={{ display: "table-caption", justifyContent: "center", width: "60px" }}> {props.value} </span>
            </span>
        )
    } else if (props.badgeType == "info") {
        return (
            <span> &nbsp;&nbsp; 
                <span className="badge badge-info" style={{ display: "table-caption", justifyContent: "center", width: "60px" }}> {props.value} </span>
            </span>
        )
    } else if (props.badgeType == "primary") {
        return (
            <span> &nbsp;&nbsp; 
                <span className="badge badge-primary" style={{ display: "table-caption", justifyContent: "center", width: "80px" }}> {props.value} </span>
            </span>
        )
    } else if (props.badgeType == "success") {
        return (
            <span> &nbsp;&nbsp; 
                <span className="badge badge-success" style={{ display: "table-caption", justifyContent: "center", width: "60px" }}> {props.value} </span>
            </span>
        )
    } else if (props.badgeType == "secondary") {
        return (
            <span> &nbsp;&nbsp; 
                <span className="badge badge-secondary" style={{ display: "table-caption", justifyContent: "center", width: "80px" }}> {props.value} </span>
            </span>
        )
    } else {
        return (
            <span> &nbsp;&nbsp; 
                <span className="badge badge-danger" style={{ display: "table-caption", justifyContent: "center", width: "60px" }}> {props.value} </span>
            </span>
        )
    }
}

export default Badge;