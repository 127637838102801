import React from "react";
import $ from "jquery";
import { AxiosResponse } from "axios";

import ContentBoxTable from "../../../../intelws_portal/bundles/ContentBoxTable";
import Loading from '../../../../intelws_portal/constructs/elements/Loading';
import AddButton from "../../../../intelws_portal/constructs/elements/AddButton";
import ScrollTop from "../../../../intelws_portal/constructs/elements/ScrollTop";
import { IDataRepr } from "../../../../intelws_portal/constructs/elements/Table";
import { get, getCancelToken } from "../../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../../declarations/common";
import { IMenuOptionsConfig } from "../../../App";
import { getBundledAlertsUI } from "../../../../declarations/common_utils";
import ModalBusiness from "../../modal/ModalBusiness";

interface IMatchProps {
    userId: string
}

interface IFetchClientBusinessDetails extends AxiosResponse {
    data: {
        data: {
            bns_id: number,
            bns_name: string,
            bns_ein: string,
            bns_type: number,
            incorp_state: string,
            incorp_date: string,
            bk: boolean,
            bk_freq: number,
            bk_month: number,
            bk_days: number,
            bk_emps: number[],
            pr: boolean,
            pr_freq: number,
            pr_month: number,
            pr_days: number,
            pr_emps: number[],
            st: boolean,
            st_freq: number,
            st_month: number,
            st_days: number,
            st_emps: number[],
            tp: boolean,
            tp_freq: number,
            tp_month: number,
            tp_days: number,
            tp_emps: number[]
        }
    }
}

interface IFetchClientBusinessTableData extends AxiosResponse {
    data: {
        data: IDataRepr[],
        is_edit_business: boolean
    }
}

interface IClientBusinessProps<T> extends IPageComponentProps<T> {
    mutator: {
        currentPage?: (currPage: string) => void,
        getMenuOptionsConfig: () => IMenuOptionsConfig 
        bnsOperCallback: (data: IDataRepr, oper: string) => void
    }
}

function ClientBusiness(props: IClientBusinessProps<IMatchProps>) {
    const ENDPOINT = "api/clientdashboard/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isEditBusiness, setIsEditBusiness] = React.useState(false);
    const [tableData, setTableData] = React.useState<IDataRepr[]>([]);
    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);

    const [bnsId, setBnsId] = React.useState<number>();
    const [bnsName, setBnsName] = React.useState<string>();
    const [bnsEIN, setBnsEIN] = React.useState<string>();
    const [bnsType, setBnsType] = React.useState<number>();
    const [incorpState, setIncorpState] = React.useState<string>();
    const [incorpDate, setIncorpDate] = React.useState<string>();
    const [bk, setBk] = React.useState<boolean>();
    const [bkFreq, setBkFreq] = React.useState<number>();
    const [bkMonth, setBkMonth] = React.useState<number>();
    const [bkDays, setBkDays] = React.useState<number>();
    const [bkEmps, setBkEmps] = React.useState<number[]>();
    const [pr, setPr] = React.useState<boolean>();
    const [prFreq, setPrFreq] = React.useState<number>();
    const [prMonth, setPrMonth] = React.useState<number>();
    const [prDays, setPrDays] = React.useState<number>();
    const [prEmps, setPrEmps] = React.useState<number[]>();
    const [st, setSt] = React.useState<boolean>();
    const [stFreq, setStFreq] = React.useState<number>();
    const [stMonth, setStMonth] = React.useState<number>();
    const [stDays, setStDays] = React.useState<number>();
    const [stEmps, setStEmps] = React.useState<number[]>();
    const [tp, setTp] = React.useState<boolean>();
    const [tpFreq, setTpFreq] = React.useState<number>();
    const [tpMonth, setTpMonth] = React.useState<number>();
    const [tpDays, setTpDays] = React.useState<number>();
    const [tpEmps, setTpEmps] = React.useState<number[]>();
    const cancelTokenSource = React.useRef(getCancelToken())

    React.useEffect(() => {
        if (props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ClientBusiness");
        }
        let queryParams = {
            user_id: props.match.params.userId,
            section: "business"
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchClientBusinessTableData>;
        requestResponse.then((response) => {
            setIsLoaded(true);
            setTableData(response.data.data);
            setIsEditBusiness(response.data.is_edit_business);
        })
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    function onModalClose() {
        setBnsId(undefined);
        setBnsName(undefined);
        setBnsEIN(undefined);
        setBnsType(undefined);
        setIncorpState(undefined);
        setIncorpDate(undefined);

        setBk(undefined);
        setBkFreq(undefined);
        setBkMonth(undefined);
        setBkDays(undefined);
        setBkEmps(undefined);

        setPr(undefined);
        setPrFreq(undefined);
        setPrMonth(undefined);
        setPrDays(undefined);
        setPrEmps(undefined);

        setSt(undefined);
        setStFreq(undefined);
        setStMonth(undefined);
        setStDays(undefined);
        setStEmps(undefined);

        setTp(undefined);
        setTpFreq(undefined);
        setTpMonth(undefined);
        setTpDays(undefined);
        setTpEmps(undefined);
    }

    function inputCallback(absoluteIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (accessKey == "editButton" && typeof value == "string") {
            if (value == "editBusiness") {
                let queryParams = {
                    section: "business_details",
                    user_id: props.match.params.userId,
                    business_id: tableData[absoluteIndex].id
                }
                const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchClientBusinessDetails>;
                requestResponse.then((response) => {
                    setBnsId(response.data.data.bns_id);
                    setBnsName(response.data.data.bns_name);
                    setBnsEIN(response.data.data.bns_ein);
                    setBnsType(response.data.data.bns_type);
                    setIncorpState(response.data.data.incorp_state);
                    setIncorpDate(response.data.data.incorp_date);

                    setBk(response.data.data.bk);
                    setBkFreq(response.data.data.bk_freq);
                    setBkMonth(response.data.data.bk_month);
                    setBkDays(response.data.data.bk_days);
                    setBkEmps(response.data.data.bk_emps);

                    setPr(response.data.data.pr);
                    setPrFreq(response.data.data.pr_freq);
                    setPrMonth(response.data.data.pr_month);
                    setPrDays(response.data.data.pr_days);
                    setPrEmps(response.data.data.pr_emps);

                    setSt(response.data.data.st);
                    setStFreq(response.data.data.st_freq);
                    setStMonth(response.data.data.st_month);
                    setStDays(response.data.data.st_days);
                    setStEmps(response.data.data.st_emps);

                    setTp(response.data.data.tp);
                    setTpFreq(response.data.data.tp_freq);
                    setTpMonth(response.data.data.tp_month);
                    setTpDays(response.data.data.tp_days);
                    setTpEmps(response.data.data.tp_emps);
                    $("#BusinessAddModal").modal("show");
                })
            }
        }
    }

    function getContent() {
        let columnNames = ["Name", "EIN", "Type", "Date", "State", "Book-Keeping", "Payroll", "Sales Tax", "Tax Planning"];
        let accessKeys = ["bns_name", "bns_ein", "bns_type_name", "bns_incorp_date", "bns_incorp_state", "bns_bk_freq", 
                "bns_pr_freq", "bns_st_freq", "bns_tp_freq"];
        if (isEditBusiness) {
            columnNames.push("Action");
            accessKeys.push("editButton");
        }
        let miscHeaders = undefined;
        if (props.mutator.getMenuOptionsConfig()?.business_edit) {
            miscHeaders = <AddButton buttonValue={<i className="fa fa-plus"></i>}
                onClick={() => $("#BusinessAddModal").modal("show")} />
        }
        return (
            <div className="body-content-wrapper clearfix" style={{ paddingTop: "16px" }}>
                <ContentBoxTable tableData={tableData} columnNames={columnNames} accessKeys={accessKeys}
                    title="Business" hasSearch={true} hasRecords={true} miscHeaders={miscHeaders}
                    inputCallback={inputCallback} />
            </div>
        )
    }

    function businessAddCallback(data?: IDataRepr, success?: string[], fail?: string[]) {
        if (data != undefined) {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                let updateIndex = newTableData.findIndex((ele) => ele.id == data.id);
                if (updateIndex > -1) {
                    newTableData[updateIndex] = data;
                } else {
                    newTableData = [...newTableData, data];
                }
                return newTableData;
            })
        }
        if (success != undefined && fail != undefined) {
            setSuccess(success);
            setFail(fail);
        }
    }

    function getBusinessModal() {
        if (props.mutator.getMenuOptionsConfig().business_edit) {
            return <ModalBusiness userId={parseInt(props.match.params.userId)} mutator={businessAddCallback}
                    bnsOperCallback={props.mutator.bnsOperCallback} bnsId={bnsId}
                    bnsName={bnsName} bnsEIN={bnsEIN} bnsType={bnsType} incorpState={incorpState}
                    incorpDate={incorpDate} onClose={onModalClose} 
                    bk={bk} bkFreq={bkFreq} bkMonth={bkMonth} bkDays={bkDays} bkEmps={bkEmps}
                    pr={pr} prFreq={prFreq} prMonth={prMonth} prDays={prDays} prEmps={prEmps}
                    st={st} stFreq={stFreq} stMonth={stMonth} stDays={stDays} stEmps={stEmps}
                    tp={tp} tpFreq={tpFreq} tpMonth={tpMonth} tpDays={tpDays} tpEmps={tpEmps} />
        }
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div>
                {getBundledAlertsUI(success, fail, setSuccess, setFail)}
                {getContent()}
                {getBusinessModal()}
                <ScrollTop />
            </div>
        )
    }
}

export default ClientBusiness;