import { isPropsEqual } from "@fullcalendar/common";
import React from "react";

//Types
export interface IButtonProps {
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    buttonValue: string | JSX.Element,
    active?: boolean,
    toolTipText?: string
}


function AddButton(props: IButtonProps) {
        if(props.toolTipText != undefined){
            return (
                    <button onClick={props.onClick} className="btn btn-secondary-add-cpasage tooltip1"
                        disabled={!(props.active == undefined || props.active == true)}>{props.buttonValue}
                        <span className="tooltiptext1">{props.toolTipText}</span>
                    </button>
                )
        }
        else{
            return(
            <button onClick={props.onClick} className="btn btn-secondary-add-cpasage"
                    disabled={!(props.active == undefined || props.active == true)}>{props.buttonValue}</button>
            )
        }
    
    
}

export default AddButton;