import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from 'axios';

import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { post, getCancelToken, get } from "../../../intelws_portal/utils/backendInterface";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";

import { parseQueryResponseOnFail, parseQueryResponseOnSuccess } from '../../../declarations/common_utils';
import ModalDropDown from '../../../intelws_portal/constructs/modal/ModalDropDown';
import ModalDatePicker from '../../../intelws_portal/constructs/modal/ModalDatePicker';
import { ICalendarEventRepr } from '../common/Calendar';

interface ICalendarConfig extends AxiosResponse {
    data: {
        host_options: string[],
        host_values: number[],
        user_options: string[],
        user_values: number[],
        duration_options: string[],
        duration_values: number[]
    }
}

interface ICalendarAdd extends AxiosResponse {
    data: {
        data: ICalendarEventRepr
    }
}

export interface IModalEventAddProps {
    mutator: (data: ICalendarEventRepr[], success: string[], fail: string[]) => void
}

function ModalEventAdd(props: IModalEventAddProps) {
    const CALENDAR_ENDPOINT = "api/calendar/";

    const [hostOptions, setHostOptions] = React.useState<string[]>([]);
    const [hostValues, setHostValues] = React.useState<number[]>([]);
    const [userOptions, setUserOptions] = React.useState<string[]>([]);
    const [userValues, setUserValues] = React.useState<number[]>([]);
    const [durationOptions, setDurationOptions] = React.useState<string[]>([]);
    const [durationValues, setDurationValues] = React.useState<number[]>([]);

    const [userId, setUserId] = React.useState(1);
    const [hostId, setHostId] = React.useState(1);
    const [eventTitle, setEventTitle] = React.useState("");
    const [startDate, setStartDate] = React.useState("");
    const [duration, setDuration] = React.useState(15);

    const cancelTokenSource = React.useRef(getCancelToken());

    function clearForm() {
        setUserId(userValues[0]);
        setHostId(hostValues[0]);
        setEventTitle("");
        setStartDate("");
        setDuration(durationValues[0]);
    }

    React.useEffect(() => {
        let queryParams = {
            section: "calendar_add_config"
        }
        const requestResponse = get(CALENDAR_ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<ICalendarConfig>
        requestResponse.then((response) => {
            setHostOptions(response.data.host_options);
            setHostValues(response.data.host_values);
            setUserOptions(response.data.user_options);
            setUserValues(response.data.user_values);
            setDurationOptions(response.data.duration_options);
            setDurationValues(response.data.duration_values);
            setUserId(response.data.user_values[0]);
            setHostId(response.data.host_values[0]);
            setDuration(response.data.duration_values[0]);
        })
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    React.useEffect(() => {
        $("#eventAddModal").on("hidden.bs.modal", clearForm)
        return () => {
            $("#eventAddModal").off("hidden.bs.modal");
        }
    }, [userValues, hostValues, durationValues])

    function formFieldChange(type: "userId" | "hostId" | "eventTitle" | "startDate" | "duration", value: string) {
        if (type == "userId") {
            setUserId(parseInt(value));
        } else if (type == "hostId") {
            setHostId(parseInt(value));
        } else if (type == "eventTitle") {
            setEventTitle(value);
        } else if (type == "startDate") {
            setStartDate(value);
        } else if (type == "duration") {
            setDuration(parseInt(value));
        }
    }

    function submitForm() {
        let formData = new FormData();
        formData.append("user_id", userId.toString());
        formData.append("start_date_time", startDate);
        formData.append("title", eventTitle);
        formData.append("duration", duration.toString());
        formData.append("host", hostId.toString());
        let queryParams = {
            section: "calendar_add"
        }
        const requestResponse = post(CALENDAR_ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<ICalendarAdd>;
        requestResponse.then((response) => {
            let [success, fail] = parseQueryResponseOnSuccess(response);
            props.mutator([response.data.data], success, fail);
        }).catch((error) => {
            let [success, fail] = parseQueryResponseOnFail(error);
            props.mutator([], success, fail);
        }).finally(() => {
            $("#eventAddModal").modal("hide");
        })

    }
    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="eventAddModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Add Event"} />
                        <div className="modal-body">
                            <ModalInputText title={"Event Title"} value={eventTitle} borderBottom={true}
                                onChange={(e) => formFieldChange("eventTitle", e.target.value)} /><br/>
                            <ModalDropDown options={userOptions} values={userValues} value={userId} borderBottom={true}
                                onChange={(e) => formFieldChange("userId", e.target.value)} /><br/>
                            <ModalDropDown options={hostOptions} values={hostValues} value={hostId} borderBottom={true}
                                onChange={(e) => formFieldChange("hostId", e.target.value)} /><br/>
                            <ModalDatePicker isDateTime value={startDate} 
                                onChange={(e) => formFieldChange("startDate", e.target.value)} /><br/>
                            <ModalDropDown options={durationOptions} values={durationValues} borderBottom={true}
                                value={duration} onChange={(e) => formFieldChange("duration", e.target.value)} />
                            
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={false} disable={false}/>
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalEventAdd;