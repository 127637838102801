export type TPaymentBackendTypes = "stripe";
export type TPaymentTypes = "card" | "bank_account";
export type TPaymentMethodTypes = "card" | "ach_debit";
export type TCardBrandTypes = "amex" | "diners" | "discover" | "jcb" | "mastercard" | "unionpay" | "visa" | "unknown"

export interface IAddressRepr {
    line1: string,
    line2?: string,
    city: string,
    state: string
    country: string,
    postal_code: string,
}

export interface ICardBillingRepr {
    name: string,
    address: IAddressRepr
}

export interface IBankAccountBillingRepr {
    name: string,
    email: string
}

export interface IPaymentMethodRepr {
    id: number,
    type: TPaymentTypes,
    payment_method_type: TPaymentMethodTypes
}

export interface ICardPaymentMethodRepr extends IPaymentMethodRepr {
    type: "card",
    brand: TCardBrandTypes,
    last4: string 
    exp_month: number,
    exp_year: number
}

export interface IBankAccountPaymentMethodRepr extends IPaymentMethodRepr {
    type: "bank_account",
    bank_name: string,
    last4: string
}

export function isCard(paymentMethod: IPaymentMethodRepr): paymentMethod is ICardPaymentMethodRepr {
    const asICardPaymentMethodRepr = paymentMethod as ICardPaymentMethodRepr;
    return (
        (asICardPaymentMethodRepr.id != undefined) &&
        (asICardPaymentMethodRepr.type == "card") &&
        (asICardPaymentMethodRepr.brand != undefined) &&
        (asICardPaymentMethodRepr.last4 != undefined) &&
        (asICardPaymentMethodRepr.exp_month != undefined) &&
        (asICardPaymentMethodRepr.exp_year != undefined)
    )
}

export function isBankAccount(paymentMethod: IPaymentMethodRepr): paymentMethod is IBankAccountPaymentMethodRepr {
    const asIBankAccountPaymentMethodRepr = paymentMethod as IBankAccountPaymentMethodRepr;
    return (
        (asIBankAccountPaymentMethodRepr.id != undefined) &&
        (asIBankAccountPaymentMethodRepr.type == "bank_account") &&
        (asIBankAccountPaymentMethodRepr.bank_name != undefined) &&
        (asIBankAccountPaymentMethodRepr.last4 != undefined)
    )
}