import React from "react";
import { AxiosResponse } from "axios";
import { AdminNavBar } from "./AdminNavBar";
import { IPageComponentProps } from "../../../../../declarations/common";
import Loading from "../../../../../intelws_portal/constructs/elements/Loading";
import { get, getCancelToken} from "../../../../../intelws_portal/utils/backendInterface";
import AdminFooter from "./AdminFooter";

interface IUrlParams {
  adminCode: string
}

interface IAdminInfoFetch extends AxiosResponse {
  data: {
      data: {
          admin_company: string,
          admin_company_url: string
      }
  }
}
function AdminServicesPage(props: IPageComponentProps<IUrlParams>) {
  const ENDPOINT = "/api/authentication/";
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [companyName, setCompanyName] = React.useState("");
  const [companyUrl, setCompanyUrl] = React.useState("");
  const cancelTokenSource = React.useRef(getCancelToken());

  React.useEffect(() => {
      let queryParams = {
          section: "admin_code_info",
          admin_code: props.match.params.adminCode
      }
      const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IAdminInfoFetch>;
      requestResponse.then((response) => {
          setIsLoaded(true);
          setCompanyName(response.data.data.admin_company);
          setCompanyUrl(response.data.data.admin_company_url);
          document.title = response.data.data.admin_company;
      }).catch(() => {
          setIsLoaded(true);
      })
  }, [])


  if (!isLoaded) {
      return <Loading />
  }

    return (
        <React.Fragment>
          <AdminNavBar adminCode={props.match.params.adminCode} companyName={companyName}/>
        <section id="aboutUs" className="aboutUs" style={{marginTop:"50px"}}>
            <div className="container">
            <div className="section-title">
                <h2>Services</h2>
                <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
            </div>
        
      </div>
      </section>
      <AdminFooter adminCode={props.match.params.adminCode} />
      </React.Fragment>

    )
}

export default AdminServicesPage;