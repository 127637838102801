import React from "react";
export interface ICheckboxProps {
    accessKey?: string,
    checked: boolean,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    disabled?: boolean,
    isRadio?: boolean
}


function Checkbox(props: ICheckboxProps) {
    let inputType = "checkbox";

    if (props.isRadio != undefined && props.isRadio) {
        inputType = "radio"
    }

    if((props.disabled && props.checked) && (props.accessKey == "uploadCompleteCheckbox" || props.accessKey == "ut_upload_complete" || props.accessKey == "extensionCheckbox" || props.accessKey == "paymentComplited"))
    {
        return(
            <label className="custom-checkbox-radio" style={{padding : "0px 0px 0px 20px"}}> 
                <input type={inputType} className="checkbox"
                checked={props.checked}
                disabled={(props.disabled == undefined || props.disabled == true)}/>
                </label>
        )
     }
    return (
        <input type={inputType}
            onChange={props.onChange}
            checked={props.checked}
            disabled={(props.disabled != undefined && props.disabled == true)}>
        </input>
    )
}

export default Checkbox;