import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from "axios";

import { get, post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import ModalMultiDropDown from "../../../intelws_portal/constructs/modal/ModalMultiDropDown";

import { parseQueryResponseOnFail, parseQueryResponseOnSuccess } from "../../../declarations/common_utils";
import { IManualSubTaskEditRepr } from "../../pages/common/manualTask/ManualTaskDetailsDashboard";

interface IFetchConfig extends AxiosResponse {
    data: {
        emps: {
            id: number,
            emp_full_name: string
        }[]
    }
}

interface IPostAddTask extends AxiosResponse {
    data: {
        data: IManualSubTaskEditRepr
    }
}

interface ISubTaskRepr {
    t_name: string,
    t_emp_assigned: {
        emp_id: number,
        emp_name: string
    }[]
}

export interface IModalManualSubTaskProps {
    userId: number,
    taskId: number,
    mutator: (data?: IManualSubTaskEditRepr, success?: string[], fail?: string[]) => void
}

function ModalManualSubTask(props: IModalManualSubTaskProps) {
    const ENDPOINT = "/api/manualtasklist/";
    const [teamValues, setTeamValues] = React.useState<number[]>([]);
    const [teamDisplay, setTeamDisplay] = React.useState<string[]>([]);
    
    const [taskName, setTaskName] = React.useState("");
    const [teamValue, setTeamValue] = React.useState<number[]>([]);

    const cancelTokenSource = React.useRef(getCancelToken());
    const [disable, setDisable] = React.useState(true);

    const [istasknameStartwithspace, setistasknameStartwithspace] = React.useState("");
    
    function isstartwithspace(value: string, section: "taskName"){
        if(value == " " ){
            if(section == "taskName"){
                setistasknameStartwithspace("please start task name with alphanumeric value ")
            }
            return(false)
        }
        else{
            setistasknameStartwithspace("")
            return(true)
        }
    }
    React.useEffect(() => {
        let queryParams = {
            section: "manual_sub_task_add_config",
            user_id: props.userId,
            task_tracking_id: props.taskId
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchConfig>;
        requestResponse.then((response) => {
            let teamValues: number[] = [];
            let teamDisplay: string[] = [];
            response.data.emps.forEach((ele) => {
                teamValues.push(ele.id);
                teamDisplay.push(ele.emp_full_name);
            })
            setTeamValues(teamValues);
            setTeamDisplay(teamDisplay);
        })
        $("#manualSubTaskAddModal").on("hidden.bs.modal", clearForm)
        return () => {
            cancelTokenSource.current.cancel();
            $("#manualSubTaskAddModal").off("hidden.bs.modal");
        }
    }, [])

    React.useEffect(() => {
        let newDisable = true;
        if (taskName != "") {
            newDisable = false;
        }
        setDisable(newDisable);
    }, [taskName]);


    function clearForm() {
        setTaskName("");
        setTeamValue([]);
    }

    function formChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> , section: "taskName" | "team") {
        if (section == "taskName"  && isstartwithspace(e.target.value, section)) {
            setTaskName(e.target.value);
        } else if (section == "team") {
            setTeamValue(Array.from((e as React.ChangeEvent<HTMLSelectElement>).target.selectedOptions, options => parseInt(options.value)));
        }
    }

    function submitForm() {
        let formData = new FormData();
        let queryParams = {
            section: "add_manual_sub_task",
            user_id: props.userId,
            task_tracking_id: props.taskId
        }
        let subTask: ISubTaskRepr = {
            t_name: taskName,
            t_emp_assigned: []
        }
        teamValue.forEach((ele) => {
            let teamIndex = teamValues.findIndex((value) => value == ele);
            subTask.t_emp_assigned.push({emp_id: ele, emp_name: teamDisplay[teamIndex]});
        })
        formData.append("sub_task", JSON.stringify(subTask));
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostAddTask>;
        requestResponse.then((response) => {
            let [success, fail] = parseQueryResponseOnSuccess(response);
            props.mutator(response.data.data, success, fail);
        }).catch((error) => {
            let [success, fail] = parseQueryResponseOnFail(error);
            props.mutator(undefined, success, fail);
        })
        .finally(() => {
            $("#manualSubTaskAddModal").modal("hide");
        })
    }

    function getEmpDropdown() {
        if (teamValues.length > 0) {
            return <ModalMultiDropDown options={teamDisplay} value={teamValue}
                values={teamValues} onChange={(e) => formChange(e, "team")} borderBottom />
        }
    }
   
    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="manualSubTaskAddModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Create Task"} />
                        <div className="modal-body">
                            <ModalInputText title={"Task Name"} value={taskName} onChange={(e) => formChange(e, "taskName")} isinvalidmsg={istasknameStartwithspace} borderBottom required/><br></br>
                            {getEmpDropdown()}
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={false} disable={disable}/>
                    </div>
                </div>
            </div>
        )
    }
    
    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalManualSubTask;