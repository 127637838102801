import React from "react";

export interface IAlertProps {
    message: string,
    isSuccess: boolean
}

function Alert(props: IAlertProps) {
    let className = "alert alert-warning w-50 fixed-bottom col-md-4 col-md-offset-4 alert-dismissible fade show"
    if (props.isSuccess) {
        className = "alert alert-success w-50 fixed-bottom col-md-4 col-md-offset-4 alert-dismissible fade show"
    }
    return (
        <div className="row">
            <div className={className} role="alert">
                {props.message}
            </div>
        </div>
    )
}

export default Alert;