import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from "axios";


import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import Button from "../../../intelws_portal/constructs/elements/Button";
import Checkbox from "../../../intelws_portal/constructs/elements/Checkbox";
import { post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalDatePicker from "../../../intelws_portal/constructs/modal/ModalDatePicker";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import ModalCommentText from "../../../intelws_portal/constructs/modal/ModalCommentText";

import { isStringEmpty, parseQueryResponseOnFail, parseQueryResponseOnSuccess } from "../../../declarations/common_utils";
import { usePickPropsIfNotNull } from "../../../declarations/common_utils";
import ClientCard from "../common/clientDashboard/Dashboard/ClientCard";
import { floor } from "lodash";
import { IPaymentMethodsRepr } from "../common/clientDashboard/ClientInvoice";

interface IPostInvoiceAdd extends AxiosResponse {
    data: {
        data: IDataRepr,
        transfer_to_paid: boolean
    }
}

export interface IModalInvoice {
    userId: string,
    isAddView: boolean,
    defaultPaymentMethods: IPaymentMethodsRepr,
    isUser: boolean,
    invoiceDate?: string,
    invoiceDueDate?: string,
    invoiceTitle?: string,
    notes?: string,
    paymentNotes?: string,
    paymentMethod?: string,
    paymentMethods?: IPaymentMethodsRepr,
    invoiceId?: number,
    salesTax?: number,
    description?: string[],
    qty?: number[],
    price?: number[],
    totalPrice?: number[],
    isEdit?: boolean,
    allowEdit?: boolean,
    isPaid?: boolean,
    asPaid?: boolean,
    mutator: (data?: IDataRepr, transferToPaid?: boolean, success?: string[], fail?: string[]) => void,
    onEditClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    onClose?: () => void
}



function ModalInvoice(props: IModalInvoice) {
    const ENDPOINT = "/api/clientdashboard/";
    const [invoiceDate, setInvoiceDate] = usePickPropsIfNotNull(props.invoiceDate, new Date().toISOString().slice(0, 10));
    const [dueDate, setDueDate] = usePickPropsIfNotNull(props.invoiceDueDate, "");
    const [notes, setNotes] = usePickPropsIfNotNull(props.notes, "");
    const [paymentNotes, setPaymentNotes] = usePickPropsIfNotNull(props.paymentNotes, "");
    const [invoiceTitle, setInvoiceTitle] = usePickPropsIfNotNull(props.invoiceTitle, "Add Invoice");
    const [isEdit, setIsEdit] = usePickPropsIfNotNull(props.isEdit, true);
    const [invoiceId, setInvoiceId] = usePickPropsIfNotNull(props.invoiceId, -1);

    const [asPaid, setAsPaid] = usePickPropsIfNotNull(props.asPaid, false);
    const [paymentMethod, setPaymentMethod] = usePickPropsIfNotNull(props.paymentMethod, "");
    const [paymentMethods, setPaymentMethods] = usePickPropsIfNotNull(props.paymentMethods, props.defaultPaymentMethods);

    const [salesTax, setSalesTax] = usePickPropsIfNotNull(props.salesTax, 0);

    const [description, setDescription] = usePickPropsIfNotNull(props.description, new Array(2).fill(""));
    const [qty, setQty] = usePickPropsIfNotNull(props.qty, new Array(2).fill(0));
    const [price, setPrice] = usePickPropsIfNotNull(props.price, new Array(2).fill(0));
    const [totalPrice, setTotalPrice] = usePickPropsIfNotNull(props.totalPrice, new Array(2).fill(0));

    const cancelTokenSource = React.useRef(getCancelToken());
    const salesTaxDescription = React.useRef("Sales Tax");

    const [disable, setDisable] = React.useState(true);

    React.useEffect(() => {
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    React.useEffect(() => {
        $("#invoiceModal").on("hidden.bs.modal", clearForm)
        return () => {
            $("#invoiceModal").off("hidden.bs.modal");
        }
    }, [isEdit])

    React.useEffect(() => {
        let newDisable = true;
        if (invoiceDate != "" && dueDate != "") {
            newDisable = false;
        }
        setDisable(newDisable);
    }, [invoiceDate, dueDate]);

    function clearForm() {
        if (isEdit) {
            setInvoiceDate("");
            setDueDate("");
            setSalesTax(0);
            setDescription(new Array(2).fill(""));
            setQty(new Array(2).fill(0));
            setPrice(new Array(2).fill(0));
            setTotalPrice(new Array(2).fill(0));
            setNotes("");
            setPaymentNotes("");
            setPaymentMethods(props.defaultPaymentMethods);
        }
        if (props.onClose != undefined) {
            props.onClose();
        }
    }

    function getPaidDisabled() {
        if (props.isPaid == undefined && isEdit) {
            return false;
        }
        if (!isEdit) {
            return true;
        }
        if (props.isPaid != undefined && props.isPaid) {
            return true;
        }
        return false;
    }
   

    function getModalDateInputs() {
        return (
            
                <div className="custom-form-group align-items-end mt-5">
                <div className="justify-content-md-center">
                   <div className="row" >
                       <div className="col-4 pr-0">
                       <p className="form-label " style={{float: "left",color: "#707070", fontWeight: 600}}>Invoice Number :</p>
                       </div>
                       <div className="col-6 ">
                       <p className="form-label " style={{ float: "left", fontSize: "15px" }}>
                            {(() => {
                                if (props.invoiceId != undefined) {
                                    return props.invoiceId;
                                } else {
                                    return "N/A"
                                }
                            })()}
                       </p>
                       </div>
                       <div className="w-100"></div>
                       <div className="col-4">
                       <p className="form-label " style={{float: "left",color: "#707070", fontWeight: 600}}>Invoice Date :</p>
                       </div>
                       <div className="col-6 " style={{paddingRight:"40px"}}>
                       <div className="custom-date-picker">
                                                <ModalDatePicker onChange={(e) => setInvoiceDate(e.target.value)} value={invoiceDate}
                                        disabled={getPaidDisabled()} style={{ color: "#5a5a5a", padding:"0px"}} required/>
                        </div>
                       </div>
                       <div className="w-100"></div>
                       <div className="col-4">
                       <p className="form-label " style={{float: "left",color: "#707070", fontWeight: 600}}>Due On :</p>
                       </div>
                       <div className="col-6" style={{paddingRight:"40px"}}>
                       <div className="custom-date-picker">
                                    <ModalDatePicker onChange={(e) => setDueDate(e.target.value)} min={new Date().toISOString().slice(0, 10)}
                                        value={dueDate} disabled={getPaidDisabled()} style={{ color: "#5a5a5a", padding:"0px"}} required/>
                    
                         </div>
                       </div>
                   </div>
                    
                </div>                            
                </div>
               
        )
    }
    function getModalMomoInputs() {
        return (
            <div className="row" style={{marginLeft: "20px"}}>
                
                <div className="col-12">
                <p className="form-label mb-0" style={{float: "left",color: "#707070", fontWeight: 600}}>Client Memo</p>
                </div>
                <div className="col-12" >
                <div className="custom-date-picker">
                                    <ModalCommentText disabled={!isEdit} title="" placeholder="Enter Memo..." value={notes}
                                        onChange={(e) => setNotes(e.target.value)}/>
                                </div>
                </div>
                 
            </div>
        )
    }
    function getModalNoteInputs() {
        if (!props.isUser) {
            return (
                <div className="row" style={{ marginLeft: "20px" }}>
                    <div className="col-12">
                        <p className="form-label mb-0" style={{ float: "left", color: "#707070", fontWeight: 600 }}>Internal Notes</p>
                    </div>

                    <div className="col-12">
                        <div className="custom-date-picker">
                            <ModalCommentText disabled={!isEdit} title="" placeholder="Enter Memo..." value={paymentNotes}
                                onChange={(e) => setPaymentNotes(e.target.value)} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    function getAsPaid() {
        let returnValue: JSX.Element[] = [];
        if (!props.isAddView) {
            returnValue.push(
                <React.Fragment>
                    <div className="row" style={{ marginLeft: "20px", marginRight: "20px", marginBottom: "20px" }}>
                        <div className="col-6 row">
                            {(() => {
                                if (asPaid) {
                                    return (
                                        <React.Fragment>

                                            <div className="col-auto">
                                                <strong style={{ float: "left", color: "#707070", fontWeight: 600 }}>Payment Method: </strong>
                                            </div>
                                            <div className="col-3">
                                                <ModalInputText disabled={!isEdit} value={paymentMethod} title="Payment Method"
                                                    onChange={(e) => setPaymentMethod(e.target.value)} />
                                            </div>
                                        </React.Fragment>)
                                }
                            })()}
                        </div>
                        <div className="col-1"></div>
                        <div className="col-5 row">
                            <div className="col-1"></div>
                            <div className="col-auto">
                                <span style={{ marginRight: "10px", float: "left", color: "#707070", fontWeight: 600 }}>Status : </span>
                            </div>
                            <div className="col-5">
                                {(() => {
                                    if (asPaid) {
                                        return <span style={{ fontSize: "15px", marginRight: "20px", float: "left" }}>Paid</span>
                                    } else {
                                        return <span style={{ fontSize: "15px", marginRight: "20px", float: "left" }}>Unpaid</span>
                                    }
                                })()}
                                <span style={{ float: "left" }}>
                                    <Checkbox accessKey={"paymentComplited"} checked={asPaid} disabled={getPaidDisabled()} onChange={(e) => setAsPaid(e.target.checked)} /></span>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        if (!props.isPaid) {
            returnValue.push(
                <div className="row " style={{ marginLeft: "50px" }}>
                    <div className="col-12 row p-0">
                        <div className="col-2 p-0">
                            <strong style={{ float: "left", color: "#707070", fontWeight: 600 }}>Payment Method: </strong>
                        </div>
                        <div className="col-10 p-0">
                            <div className="row">

                                <div className="col-auto p-0">
                                    <Checkbox checked={paymentMethods.card} disabled={!isEdit || asPaid} onChange={(e) => paymentMethodCheckboxChange("card", e.target.checked)} />
                                </div>
                                <div className="col-2 p-0">
                                    <p className="form-label" style={{ marginLeft: "10px", float: "left", color: "rgb(68 68 68)", fontSize: "15px" }}>Card</p>
                                </div>
                                
                                <div className="col-auto p-0">
                                    <Checkbox checked={paymentMethods.ach_debit} disabled={!isEdit || asPaid} onChange={(e) => paymentMethodCheckboxChange("ach_debit", e.target.checked)} />
                                </div>
                                <div className="col-2 p-0">
                                    <p className="form-label" style={{ marginLeft: "10px", float: "left", color: "rgb(68 68 68)", fontSize: "15px" }}>ACH Debit</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return returnValue;
    }

    function getModalTable() {
        if (description.length == qty.length && qty.length == price.length && price.length == totalPrice.length) {
            return (
                <table className="table" style={{margin:"0px 50px 20px 20px",width:"96.5%", textAlign: "left"}}>
                    <thead>
                            <th className="" style={{color: "#707070", fontWeight: 600}}>Description</th>
                            <th className="w-20" style={{color: "#707070", fontWeight: 600}}>Qty</th>
                            <th className="w-20" style={{color: "#707070", fontWeight: 600}}>Price</th>
                            <th className="w-25" style={{color: "#707070", fontWeight: 600}}>Total Price</th>
                            {(() => {
                                if (!getPaidDisabled()) {
                                    return <th className="w-15" style={{color: "#707070", fontWeight: 600}}>Action</th>
                                }
                            })()}
                            {(() => {
                                if ((props.allowEdit != undefined && props.allowEdit) && (isEdit != undefined && !isEdit)) {
                                    return <th><Button buttonValue={"Edit"} onClick={props.onEditClick}/></th>
                                }
                            })()} 
                    </thead>
                    <tbody>
                        {description.map((ele, index) => {
                            return (
                                <tr key={index}>
                                <td className="text-center w-20 pt-0 pb-0" key={index.toString() + "description"} >
                                    <ModalCommentText title="" placeholder="Description" value={ele}
                                        onChange={(e) => modalTableFormFieldChange("description", index, e.target.value)} disabled={getPaidDisabled()}/>
                                    </td>
                                    <td className="text-center " key={index.toString() + "qty"} style={{width:"75px"}}>
                                        <ModalInputText title="Qty" value={qty[index].toString()} inputType="number"
                                            onChange={(e) => modalTableFormFieldChange("qty", index, e.target.value)}
                                            disabled={getPaidDisabled()} style={{color: "#707070", fontWeight: 600, paddingRight:"0px", paddingLeft:"0px"}}/>
                                    </td>
                                    <td className="text-center w-20" key={index.toString() + "price"} style={{width:"100px"}}>
                                        <ModalInputText title="Price" value={price[index].toString()} inputType="number"
                                            onChange={(e) => modalTableFormFieldChange("price", index, e.target.value)}
                                            disabled={getPaidDisabled()} style={{color: "#707070", fontWeight: 600, paddingRight:"0px", paddingLeft:"0px"}}/>
                                    </td>
                                    <td className="text-center w-10 " key={index.toString() + "totalprice"} style={{width:"100px"}}>
                                        <ModalInputText title="Total Price" disabled={true} value={totalPrice[index].toString()}
                                            inputType="number" style={{color: "#707070", fontWeight: 600, paddingRight:"0px", paddingLeft:"0px"}}/>
                                    </td>
                                    <td className="text-center p-0" key={index.toString() + "action"} style={{ width : "100px"}}>
                                        {(() => {
                                            if (!getPaidDisabled() && description.length > 1) {
                                                return (
                                                    <Button buttonValue={{"icon": "Delete"}} onClick={() => modalTableFormFieldChange("delete", index)} style={{marginLeft : "0px"}} />
                                                    //      <span key={index.toString() + "action delete"} className="delt pl-10 mr-2" onClick={() => modalTableFormFieldChange("delete", index)}>
                                                    //     <img src={deleteIcon} alt="eye" />
                                                    // </span> 
                                                      )
                                            }
                                        })()}
                                        {(() => {
                                            if (!getPaidDisabled()) {
                                                return (
                                                    <Button buttonValue={"+"} onClick={() => modalTableFormFieldChange("add", index)} style={{marginLeft : "10px"}} />
                                                    // <span key={index.toString() + "action add"} className="delt pl-10" onClick={() => modalTableFormFieldChange("add", index)}>
                                                    //     +
                                                    // </span>
                                                )
                                            }
                                        })()}
                                    </td>
                             </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }
    }

    function paymentMethodCheckboxChange(type: "card" | "ach_debit", value: boolean) {
        if (type == "card") {
            setPaymentMethods((prevPaymentMethods) => {
                let newPaymentMethods = {...prevPaymentMethods};
                newPaymentMethods.card = value;
                return newPaymentMethods;
            })
        } else if (type == "ach_debit") {
            setPaymentMethods((prevPaymentMethods) => {
                let newPaymentMethods = {...prevPaymentMethods};
                newPaymentMethods.ach_debit = value;
                return newPaymentMethods;
            })
        }
    }

    function modalTableFormFieldChange(type: "description" | "qty" | "price" | "delete" | "add", index: number, value?: string) {
        if (type == "description" && value != undefined) {
            setDescription((prevDescription) => {
                let newDescription = [...prevDescription];
                newDescription[index] = value;
                return newDescription;
            })
        } else if (type == "qty" && value != undefined) {
            setQty((prevQty) => {
                let newQty = [...prevQty];
                newQty[index] = parseInt(value);
                return newQty;
            })
            setTotalPrice((prevTotalPrice) => {
                let newTotalPrice = [...prevTotalPrice];
                newTotalPrice[index] = parseInt(value) * price[index];
                return newTotalPrice;
            })
        } else if (type == "price" && value != undefined) {
            setPrice((prevPrice) => {
                let newPrice = [...prevPrice];
                newPrice[index] = parseInt(value);
                return newPrice;
            })
            setTotalPrice((prevTotalPrice) => {
                let newTotalPrice = [...prevTotalPrice];
                newTotalPrice[index] = parseInt(value) * qty[index];
                return newTotalPrice;
            })
        } else if (type == "add") {
            setDescription((prevDescription) => {
                let newDescription = [...prevDescription];
                newDescription.splice(index + 1, 0, "");
                return newDescription;
            })
            setQty((prevQty) => {
                let newQty = [...prevQty];
                newQty.splice(index + 1, 0, 0);
                return newQty;
            })
            setPrice((prevPrice) => {
                let newPrice = [...prevPrice];
                newPrice.splice(index + 1, 0, 0);
                return newPrice;
            })
            setTotalPrice((prevTotalPrice) => {
                let newTotalPrice = [...prevTotalPrice];
                newTotalPrice.splice(index + 1, 0, 0);
                return newTotalPrice;
            })
        } else if (type == "delete") {
            setDescription((prevDescription) => {
                let newDescription = [...prevDescription];
                return newDescription.filter((value, loopIndex) => index !== loopIndex);
            })
            setQty((prevQty) => {
                let newQty = [...prevQty];
                return newQty.filter((value, loopIndex) => index !== loopIndex);
            })
            setPrice((prevPrice) => {
                let newPrice = [...prevPrice];
                return newPrice.filter((value, loopIndex) => index !== loopIndex);
            })
            setTotalPrice((prevTotalPrice) => {
                let newTotalPrice = [...prevTotalPrice];
                return newTotalPrice.filter((value, loopIndex) => index !== loopIndex);
            })
        }
    }

    function submitForm() {
        let formData = new FormData();
        if (!isStringEmpty(invoiceDate)) {
            formData.append("invoice_date", invoiceDate);
        }
        if (!isStringEmpty(dueDate)) {
            formData.append("invoice_due_date", dueDate);
        }
        if (invoiceId > -1) {
            formData.append("invoice_id", invoiceId.toString());
        }
        formData.append("invoice_notes", notes);
        formData.append("invoice_payment_notes", paymentNotes);
        formData.append("invoice_payment_method", paymentMethod);
        formData.append("invoice_payment_methods", JSON.stringify(paymentMethods));
        formData.append("invoice_set_paid", JSON.stringify(asPaid));
        formData.append("invoice_sales_tax", salesTax.toString());
        formData.append("invoice_description", JSON.stringify(description));
        formData.append("invoice_qty", JSON.stringify(qty));
        formData.append("invoice_price", JSON.stringify(price));
        formData.append("invoice_total_price", JSON.stringify(totalPrice));

        let queryParams = {
            section: "invoice_add",
            user_id: props.userId
        }

        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostInvoiceAdd>;
        requestResponse.then((response) => {
            let [success, fail] = parseQueryResponseOnSuccess(response);
            props.mutator(response.data.data, response.data.transfer_to_paid, success, fail);
        }).catch((error) => {
            let [success, fail] = parseQueryResponseOnFail(error);
            props.mutator(undefined, undefined, success, fail);
        })
        .finally(() => {
            $('#invoiceModal').modal("hide");
        })
    }

    function getModalFooterDetails() {
        let globalSubTotalPrice = 0;
        let globalTotalPrice = 0;
        let salesTaxPrice = 0;
        totalPrice.forEach((ele) => {
            globalSubTotalPrice += ele;
            globalTotalPrice += ele;
        })
        salesTaxPrice = globalSubTotalPrice * (salesTax/100);
        globalTotalPrice += salesTaxPrice;
        return (
            <div className="container mb-20">
                <div className="row" style={{ alignItems: "center" }}>
                    <div className="col-4" style={{ textAlign: "left" }}>
                        <strong style={{ color: "#707070", fontWeight: 600 }}>Sub Total</strong>
                    </div>
                    <div className="col-3">
                    </div>
                    <div className="col-3" style={{ textAlign: "left", color: "#707070", fontWeight: 600 }}>
                        ${globalSubTotalPrice.toFixed(2)}
                    </div>
                    <div className="w-100"></div>
                    <div className="col-4" style={{ textAlign: "left" }}>
                        <strong style={{ color: "#707070", fontWeight: 600 }}>Sales Tax (in %)</strong>
                    </div>
                    <div className="col-3" style={{ textAlign: "left" }}>
                        <ModalInputText disabled={getPaidDisabled()} title="Sales Tax Percentage" value={salesTax.toString()} inputType="number"
                            onChange={(e) => setSalesTax(parseFloat(e.target.value))} style={{ color: "black" }}
                            step={"0.01"} min={0} borderBottom />
                    </div>
                    <div className="col-3" style={{ textAlign: "left", color: "#707070", fontWeight: 600 }}>
                        ${salesTaxPrice.toFixed(2)}
                    </div>
                    <div className="w-100"></div>
                    <div className="col-4" style={{ textAlign: "left" }}>
                        <strong style={{ color: "#707070", fontWeight: 600 }}>Grand Total</strong>
                    </div>
                    <div className="col-3">
                    </div>
                    <div className="col-3" style={{ textAlign: "left", color: "#707070", fontWeight: 600 }}>
                        ${globalTotalPrice.toFixed(2)}
                    </div>
                </div>
            </div>
        )
    }
    function getClientCard()
    {
        return(<div style={{marginTop:"30px"}}><ClientCard userId={parseInt(props.userId)} clientContext={true} InvoiceCard={true}/></div>)
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal custom-small text-center" id="invoiceModal" tabIndex={-1}>
                <div className="modal-dialog text-center modal-xl">
                    <div className="modal-content">
                    <ModalHeader title={invoiceTitle} />
                        <div className="row " style={{marginBottom: "20px"}}>
                            <div className="col-6" >
                           {getClientCard()}
                           </div>
                           <div className="col-1"></div>
                           <div className="col-5" >
                           {getModalDateInputs()}
                            </div>
                            <div className="col-12">
                            {getAsPaid()}
                            </div>
                            <div className="col-12">
                            <div className="popupTable clearfix">
                            {getModalTable()}
                            </div></div>
                            <div className="col-6">
                            {getModalMomoInputs()}
                            </div>
                            <div className="col-6">
                            {getModalFooterDetails()}
                            </div>
                            <div className="col-12">
                                {getModalNoteInputs()}
                            </div>
                        </div>
                        {(() => {
                                if (isEdit) {
                                    return <ModalFooter saveCallback={submitForm} saveInactive={false} disable={disable}/>
                                }
                            })()}
                        {/* {getModalContent()}             */}
                    </div>
                </div>
            </div>
        )
    }
    return ReactDOM.createPortal(relativeRender(), document.getElementById("modal") as Element);
}

export default ModalInvoice;