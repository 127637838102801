import React from "react";
import { Link } from "react-router-dom";
import Loading from "../../../../../intelws_portal/constructs/elements/Loading";
import { get, getCancelToken, post } from "../../../../../intelws_portal/utils/backendInterface";
import { IPageComponentProps } from "../../../../../declarations/common";
import { parseQueryResponseOnFail, parseQueryResponseOnFailResponse } from "../../../../../declarations/common_utils";
import * as urls from "../../../../Urls";
import AdminFooter, { IAdminInfoFetch } from "../admin_home_pages/AdminFooter";
import AuthFooter from "./UIComponents/AuthFooter";
import AuthSidePanel from "./UIComponents/AuthSidePanel";

interface IUrlParams {
	adminCode: string
}

export interface IRedirectResponse {
	data: {
		type: "redirect" | "redirect_external",
		path: string | string[]
	}
}

export interface IRegularSigninProps {
	isRegularSignin: boolean
}

function isRegularSignin(value: any): value is IRegularSigninProps {
	const wrappedValue = value as IRegularSigninProps;
	return (
		wrappedValue.isRegularSignin !== undefined
	)
}

function Signin(props: IPageComponentProps<IUrlParams> | IRegularSigninProps) {
	const ENDPOINT = "/api/authentication/";
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [showPassword, setShowPassword] = React.useState(false);
	const [isAdminSignin, setIsAdminSignin] = React.useState(false);
	const [companyName, setCompanyName] = React.useState("");
	const [isFetchError, setIsFetchError] = React.useState(false);
	const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);

	const cancelTokenSource = React.useRef(getCancelToken());

	React.useEffect(() => {
		return () => {
			cancelTokenSource.current.cancel();
		}
	}, [])

	function fetchedAdminInfo(isSuccess: boolean, response?: IAdminInfoFetch) {
		if (isSuccess && response != undefined) {
			setIsAdminSignin(true);
			setCompanyName(response.data.data.admin_company);
		} else {
			setIsAdminSignin(true);
			setIsFetchError(true);
		}
	}

	function getEmptyErrorObj() {
		return {
			email: {
				isError: false,
				errorText: ""
			},
			password: {
				isError: false,
				errorText: ""
			}
		}
	}

	function toggleVisibilityPassword() {
		if (showPassword) {
			setShowPassword(false);
		} else {
			setShowPassword(true);
		}
	}

	function formFieldChange(e: React.ChangeEvent<HTMLInputElement>, section: "email" | "password") {
		if (section == "email") {
			setEmail(e.target.value);
		} else if (section == "password") {
			setPassword(e.target.value);
		}
	}

	function formSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		let queryParams = {
			section: "signin"
		}
		let formData = new FormData();
		formData.append("email", email);
		formData.append("password", password);
		if (isAdminSignin && !isRegularSignin(props)) {
			formData.append("admin_code", props.match.params.adminCode);
		}
		const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IRedirectResponse>;
		requestResponse.then((response) => {
			if (typeof response.data.path == "string") {
				window.location.pathname = response.data.path;
			} else {
				window.location.hash = response.data.path[1];
				window.location.pathname = response.data.path[0];
			}
			//window.location.pathname = response.data.path; 
		}).catch((error) => {
			let [success, fail, failResponse] = parseQueryResponseOnFail(error);
			let errorObj = getEmptyErrorObj();
			parseQueryResponseOnFailResponse(errorObj, failResponse);
			setErrorObj(errorObj);
		})
	}

	return (
		<div className="footer_section bg_with_circle">
			<div className="banner_content_area">
				<section id="mainsigin">
					<section className="loginpageSec clearfix">
						<AuthSidePanel />
						<div className="rightBgClr f-right clearfix" style={{ height: '110vh' }}>
							<div className="loginCont clearfix">
								{(() => {
									if (isAdminSignin) {
										if (isFetchError) {
											return <a href="#"><b></b>Invalid Request</a>
										} else {
											return <a href="#"><b></b>{companyName}</a>
										}
									}
                                })()}
								{(() => {
									if (isAdminSignin){
										return <h2 style={{fontSize:"2em"}}>Login</h2>
									}
									else {
										return <h2 style={{fontSize:"2em"}}>Tax Office Login</h2>
									}
								})()}
								<form id="signinForm" onSubmit={formSubmit}>
									<ul className="typeInput clearfix">
										<div className="right_contact_form">
											<div className="form-group" style={{ paddingBottom: '0px', marginBottom: '0px' }}>
												<li>
													{(() => {
														let className = "form-control";
														if (errorObj.email.isError) {
															className += " is-invalid";
														}
														return (
															<React.Fragment>
																<input id="user" name="user" type="email" className={className} placeholder="Email Address" required
																	style={{ paddingTop: '15px', paddingBottom: '15px' }}
																	value={email} onChange={(e) => formFieldChange(e, "email")} />
																{(() => {
																	if (errorObj.email.isError) {
																		return (
																			<div className="invalid-feedback">
																				{errorObj.email.errorText}
																			</div>
																		)
																	}
																})()}
															</React.Fragment>
														)
													})()}
												</li>
												<li>
													{(() => {
														let className = "form-control";
														let inputType = "password";
														let eyeClassName = "fa fa-eye fa-lg";
														if (errorObj.password.isError) {
															className += " is-invalid";
														}
														if (showPassword) {
															inputType = "text";
															eyeClassName = "fa fa-eye-slash fa-lg"
														}
														return (
															<React.Fragment>
																<input id="password" name="password" type={inputType} placeholder="Password" className={className} required
																	style={{ paddingTop: '15px', paddingBottom: '15px' }}
																	value={password} onChange={(e) => formFieldChange(e, "password")} />
																{(() => {
																	if (errorObj.password.isError) {
																		return (
																			<div className="invalid-feedback">
																				{errorObj.password.errorText}
																			</div>
																		)
																	}
																})()}
																<a href="#" onClick={toggleVisibilityPassword}>
																	<span className="eyeIcon" style={{top:"0px"}}>
																		<i id="eye" className={eyeClassName} />
																	</span> 
																</a>
															</React.Fragment>
														)
													})()}
												</li>
											</div>
										</div>
									</ul>
									<div className="rememberSec clearfix row">
										<div className="col-md-6 col-sm-12">
											<div className="custom-form-group pb-0">
												<label className="custom-checkbox-radio pl-0">
													<input type="checkbox" className="checkbox" />
													<p className="label--checkbox" style={{fontSize:"0.82em"}}>Remember Me</p>
												</label>
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="custom-form-group pb-0">
												<p className="exitUs">
													{(() => {
														if (isAdminSignin && !isRegularSignin(props)) {
															return <Link to={urls.BASE_ADMIN_FORGOT_PASSWORD + props.match.params.adminCode + "/"}><span>Forgot Password?</span></Link>
														} else {
															return <Link to={urls.FORGOT_PASSWORD}><span>Forgot Password?</span></Link>

														}
													})()}
												</p>
											</div>
										</div>
									</div>
								</form>
								<div className="rememberSec clearfix pt-20">
									<div className="row">
										<div className="col-md-6 col-sm-12">
										<button id="submit" type="submit" form="signinForm" style={{ width: '80%', padding: '3% 2%' }} className="btn-secondary-add-cpasage ml-0">Login</button>
										</div>
										<div className="col-md-6 col-sm-12">
										{(() => {
										if (!isAdminSignin && isRegularSignin(props)) {
											return <button onClick={() => window.location.href = urls.ADMIN_SIGNUP} id="registerNewMember" style={{ width: '80%', padding: '3% 2%' }} className="ml-0 btn-secondary-add-cpasage">Sign Up</button>
										} else {
											if (!isRegularSignin(props)) {
												return <button onClick={() => window.location.href = urls.BASE_CLIENT_SIGNUP + props.match.params.adminCode + "/"} id="registerNewMember" style={{ width: '80%', padding: '3% 2%' }} className="ml-0 btn-secondary-add-cpasage">Sign Up</button>
											}
										}
											})()}
										</div>
									</div>
									
								</div>
							</div>
						</div>
					</section>
				</section>
				{(() => {
					if (!isRegularSignin(props)) {
                        return <AdminFooter adminCode={props.match.params.adminCode} fetchedAdminInfo={fetchedAdminInfo} />
					} else {
						return <AuthFooter />
					}
				})()}
			</div>
		</div>
	);
}

export default Signin;