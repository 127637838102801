import React from "react"; 
import IconView from "./IconView";
import IconDownload from "./IconDownload";
import IconDelete from "./IconDelete";

export interface IIconProps {
    icon: string,
    isToolTipDisplay?: boolean
}

const Icon = (props: IIconProps) => {    
    if (props.icon == "View" || props.icon == "View Details") {
        return <IconView />
    } else if (props.icon == "Download") {
        return <IconDownload />
    } else if (props.icon == "Delete") {
        return <IconDelete />
    } else if (props.icon == "Follow Up") {
        return <i className="fa fa-calendar"></i>
    } else if (props.icon == "Transfer as Client") {
        return <i className="fa fa-exchange"></i>
    } else if (props.icon == "Delete Lead") {
        return <IconDelete />
    } else if (props.icon == "History Status") {
        return <i className="fa fa-history"></i>
    } else if (props.icon == "Existing Client") {
        return <i className="fa fa-user"></i>
    } else if (props.icon == "Edit") {
        return <i className="fa fa-edit"></i>
    } else if (props.icon == "Update") {
        return <i className="fa fa-floppy-o"></i>
    } else if (props.icon == "Edit Invoice" || props.icon == "Edit Leads") {
        return <i className="fa fa-edit"></i>
    } else if (props.icon == "Mark as Paid") {
        return <i className="fa fa-check-circle"></i>
    } else if (props.icon == "Reset Password") {
        return <i className="fa fa-unlock-alt"></i>
    } else if (props.icon == "Unlock") {
        return <i className="fa fa-lock" style={{color:"red"}}></i>
    } else if (props.icon == "Lock") {
        return <i className="fa fa-unlock" style={{color:"green"}}></i>
    } else if (props.icon == "Edit Notes") {
        return <i className="fa fa-edit"></i>
    } else if (props.icon == "Send Review") {
        return <i className="fa fa-share-square"></i>
    } else if (props.icon == "Send Review Reminder") {
        return <i className="fa fa-bell"></i>
    } else if (props.icon == "Mark as Reviewed") {
        return <i className="fa fa-check"></i>
    }
    else if (props.icon == "Pay") {
        return <i className="fa fa-arrow-up"></i>
    }
    

    return <React.Fragment>{props.icon}</React.Fragment>
}

export default Icon;