import React, { Component } from 'react';
import $ from "jquery";

import Loading from '../../../../../intelws_portal/constructs/elements/Loading';
import AddButton from "../../../../../intelws_portal/constructs/elements/AddButton";

import ModalTodo from "../../../modal/ModalTodo";

export interface ITodoListRepr {
    id: number,
    td_task: string,
    td_created_date: string,
    td_duedate: string,
    is_completed: boolean,
    is_auto: boolean,
    td_completed_date?: string,
    td_tag_css?: string,
    td_tag?: string
}

export interface ITodoListProps {
    editMode: boolean,
    userId: number,
    todoList?: ITodoListRepr[],
    todoCheckboxCallback: (checkboxId: number, movementMark: number, index: number) => void,
    mutator: (data: ITodoListRepr) => void,
}

function TodoList(props: ITodoListProps) {
    function getCardHeader() {
        if (props.editMode) {
            return (
                <div className="heading-box">
                    <h6>
                        Todo Task
                        <AddButton buttonValue="+" onClick={() => $("#todoAddModal").modal("show")} />
                    </h6>
                </div>
            )
        }
        return (
            <div className="heading-box">
                <h6>Todo Task</h6>
            </div>
        )
    }

    function getTodoCheckbox(ele: ITodoListRepr, index: number) {
        return (
            <div>
                <label className="custom-checkbox-radio">
                    <input type="checkbox" className="checkbox" checked={ele.is_completed}
                        onChange={() => props.todoCheckboxCallback(ele.id, 0, index)} />
                </label>
            </div>
        )
    }

    function getTodoCompletedCheckbox(ele: ITodoListRepr, index: number) {
        if (props.editMode) {
            return (
                <label className="switch">
                    <input type="checkbox" onChange={() => props.todoCheckboxCallback(ele.id, 1, index)} />
                    <span className="slider round"></span>
                </label>
            )
        }
    }

    function getTodoMetaInfo(ele: ITodoListRepr, index: number) {
        if (ele.is_completed) {
            return (
                <p>
                    <i>Created: </i> &nbsp;{ele.td_created_date} &nbsp;&nbsp;&nbsp;&nbsp;
                    <i>Due: </i> &nbsp;{ele.td_duedate} &nbsp;&nbsp;&nbsp;&nbsp;
                    <i>Completed: </i> &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className={"badge badge-success"}>
                        {ele.td_completed_date} </span>&nbsp;&nbsp;&nbsp;&nbsp;
                    {(() => {
                        if (ele.is_auto) {
                            return (
                                <span className={"badge badge-"
                                    + ele.td_tag_css}> {ele.td_tag}</span>
                            )
                        } else {
                            return null;
                        }
                    })()}
                    &nbsp;&nbsp;
                    {getTodoCompletedCheckbox(ele, index)}
                </p>
            )
        } else {
            return (
                <p>
                    <i>Created: </i> &nbsp;{ele.td_created_date} &nbsp;&nbsp;&nbsp;&nbsp;
                    <i>Due: </i> &nbsp;{ele.td_duedate} &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className={"badge badge-warning"}>
                        Pending </span>&nbsp;&nbsp;&nbsp;&nbsp;
                    {(() => {
                        if (ele.is_auto) {
                            return (
                                <span className={"badge badge-"
                                    + ele.td_tag_css}> {ele.td_tag}</span>
                            )
                        } else {
                            return null;
                        }
                    })()}
                </p>

            )
        }
    }

    function getCardBody() {
        if (props.todoList != undefined) {
            return (
                <div className="col-sm-12 tabSec task-container">
                    <div className="innertab clearfix">
                        {props.todoList.map((ele, index) =>
                            <div className="task-item" key={ele.id}>
                                <div className="task-checkbox">
                                    <div className="custom-form-group">
                                        {getTodoCheckbox(ele, index)}
                                    </div>
                                </div>
                                <div className="task-details">
                                    <div className="left-task-details">
                                        <div className="task-content">
                                            <h6>
                                                {ele.td_task}
                                            </h6>
                                            {getTodoMetaInfo(ele, index)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>
                </div>

            )
        }
        return <Loading />
    }

    return (
        <div className="container-fluid bottom-sec clearfix">
            {getCardHeader()}
            {getCardBody()}
            {(() => {
                if (props.editMode) {
                    return (
                        <ModalTodo userId={props.userId} mutator={props.mutator} />
                    )
                }
            })()}

        </div>
    )
}

export default TodoList;
