import React from "react";

import Alert from "../constructs/elements/Alert";

export interface IBundledAlertsProps {
    messages: string[],
    isSuccess: boolean,
    timeoutFunction: React.Dispatch<React.SetStateAction<string[]>>
}

function BundledAlerts(props: IBundledAlertsProps) {
    React.useEffect(() => {
        const timer = setTimeout(() => props.timeoutFunction([]), 5000);
        return () => {
            clearTimeout(timer);
        }
    }, [props.messages])

    return (
        <div>
            {props.messages.map((ele, index) => {
                return (
                    <Alert message={ele} key={index} isSuccess={props.isSuccess} />
                )
            })}
        </div>
    )
}

export default BundledAlerts;