import React from "react";

export interface IModalDatePickerProps {
    value: string,
    title?: string,
    required?: boolean,
    disabled?: boolean,
    isDateTime?: boolean,
    min?: string,
    max?: string,
    style?:any,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

function ModalDatePicker(props: IModalDatePickerProps) {
    function getTitle() {
        if (props.title != undefined) {
            return <p className="form-label" style={{ fontSize: "12px" }}>{props.title}</p>
        }
    }
    function getAsterisk()
    {
        if(props.required == true)
        {
            return <span className="asterisk_date" style={{float:"right"}}>  </span> 
        }
    }
    let typeString = "date";
    if (props.isDateTime != undefined && props.isDateTime) {
        typeString = "datetime-local";
    }

    if(props.style != undefined)
    {
        return (
            <div className="form-group">
                {getTitle()}
                {getAsterisk()}
                <input type={typeString} className="form-control" style={props.style}
                    value={props.value} onChange={props.onChange} required={props.required} min={props.min} max={props.max}
                    disabled={props.disabled} />
            </div>
        )
    }
    return (
        <div className="form-group">
            {getTitle()}
            {getAsterisk()}
            <input type={typeString} className="form-control" style={{ color: "black", borderBottom: "solid 1px #2c2c2c" }}
                value={props.value} onChange={props.onChange} required={props.required} min={props.min} max={props.max}
                disabled={props.disabled} />
        </div>
    )
}

export default ModalDatePicker; 