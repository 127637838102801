import React from "react";

export interface IProgressBarProps {
    percent: number
}

function ProgressBar(props: IProgressBarProps) {
    let progressPercent = props.percent;

    if (progressPercent <= 25) {
        return <div className="progress" style={{ borderRadius: "50px" }}>
            <div className="progress-bar " role="progressbar"
                style={{ width: `${progressPercent}%`, color: "white", background: "#4169E1", borderRadius: "50px" }}
                aria-valuenow={progressPercent} aria-valuemin={0} aria-valuemax={100}>{progressPercent}
            </div>
        </div>
    }
    else if (progressPercent >= 26 && progressPercent <= 50) {
        return <div className="progress" style={{ borderRadius: "50px" }}>
            <div className="progress-bar " role="progressbar"
                style={{ width: `${progressPercent}%`, color: "white", background: "#1164B0", borderRadius: "50px" }}
                aria-valuenow={progressPercent} aria-valuemin={0} aria-valuemax={100}>{progressPercent}
            </div>
        </div>
    } 
    else if (progressPercent >= 51 && progressPercent <= 75) {
        return <div className="progress" style={{ borderRadius: "50px" }}>
            <div className="progress-bar " role="progressbar"
                style={{ width: `${progressPercent}%`, color: "white", background: "#4B0082", borderRadius: "50px" }}
                aria-valuenow={progressPercent} aria-valuemin={0} aria-valuemax={100}>{progressPercent}
            </div>
        </div>
    } 
    else if (progressPercent >= 76 && progressPercent <= 100) {
        return <div className="progress" style={{ borderRadius: "50px" }}>
            <div className="progress-bar " role="progressbar"
                style={{ width: `${progressPercent}%`, color: "white", background: "#4B5CA9", borderRadius: "50px" }}
                aria-valuenow={progressPercent} aria-valuemin={0} aria-valuemax={100}>{progressPercent}
            </div>
        </div>
    } else {
        return <div className="progress" style={{ borderRadius: "50px" }}>
            <div className="progress-bar " role="progressbar"
                style={{ width: "100%", color: "white", background: "#4B5CA9", borderRadius: "50px" }}
                aria-valuenow={progressPercent} aria-valuemin={0} aria-valuemax={100}>100
            </div>
        </div>
    }
}

export default ProgressBar;
