import { AxiosResponse } from "axios";
import React from "react";
import $ from "jquery";

import ContentBoxTabTable from "../../../../intelws_portal/bundles/ContentBoxTabTable";
import { IDataRepr } from "../../../../intelws_portal/constructs/elements/Table";
import Loading from "../../../../intelws_portal/constructs/elements/Loading";
import ScrollTop from "../../../../intelws_portal/constructs/elements/ScrollTop";
import AddButton from "../../../../intelws_portal/constructs/elements/AddButton";
import { get, getCancelToken } from "../../../../intelws_portal/utils/backendInterface";

import * as urls from "../../../Urls";
import ModalTickets from "../../modal/ModalTickets";

interface IFetchTicketsOverview extends AxiosResponse {
    data: {
        data: IDataRepr[],
        allow_add: boolean
    }
}

function TicketsMainPage() {
    const ENDPOINT = "/api/tickets/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [tableData, setTableData] = React.useState<(IDataRepr[] | undefined)[]>([undefined, undefined]);
    const [allowAdd, setAllowAdd] = React.useState(false);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        let queryParams = {
            section: "tickets_overview",
            type: "open"
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchTicketsOverview>;
        requestResponse.then((response) => {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                newTableData[0] = [...response.data.data];
                return newTableData;
            })
            setAllowAdd(response.data.allow_add);
            setIsLoaded(true);
        })
    }, [])

    function tabCallback(tabName: string, index: number, prevIndex: number) {
        let tabIndex = -1;
        if (tabName == "open") {
            tabIndex = 0;
        } else if (tabName == "closed") {
            tabIndex = 1;
        }
        if (tabIndex > -1 && tableData[tabIndex] == undefined) {
            let queryParams = {
                section: "tickets_overview",
                type: "closed"
            }
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchTicketsOverview>;
            requestResponse.then((response) => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    newTableData[tabIndex] = [...response.data.data];
                    return newTableData;
                })
            })
        }
    }

    function updateTable(data: IDataRepr) {
        setTableData((prevTableData) => {
            let newTableData = [...prevTableData];
            let currTable = newTableData[0];
            if (currTable != undefined) {
                newTableData[0] = [...currTable, data];
            }
            return newTableData;
        })
    }

    function getContent() {
        let columnName = ["id"];
        let accessKey = ["id"];
        let columnNames = [columnName, columnName];
        let accessKeys = [accessKey, accessKey];
        let tabConfig = [{ target: "open", display: "Open", active: true },
                         { target: "closed", display: "Closed", active: false }]
        let link = {
            id: {
                baseUrl: urls.BASE_TICKETS_DETAILS,
                urlParams: ['id']
            }
        };
        let miscHeaders = undefined;
        if (allowAdd) {
            miscHeaders = <AddButton buttonValue={<i className="fa fa-plus"></i>}
                onClick={() => { $('#ticketAddModal').modal('show'); }} />
        }

        return <ContentBoxTabTable title="Support Tickets" columnNames={columnNames} accessKeys={accessKeys} contents={tableData}
            tabs={tabConfig} hasSearch={false} hasRecords={false} tabCallback={tabCallback} links={[link, link]}
            miscHeaders={miscHeaders} />
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper clearfix">
                {getContent()}
                <ModalTickets mutator={updateTable} />
                <ScrollTop />
            </div>
        )

    }
}

export default TicketsMainPage;