import React from "react";
import footerLogo from "../../../../../../external/backPages/img/images/auth/footer-Logo.png";

function AuthFooter() {
    return (
        <div className="footer_container pb-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-sm-12 footer_logo">
                        <div className="footer_logo_img">
                            <a href="https://www.intelws.com" target="_blank">
                                <img className="headerLogo" src={footerLogo} alt="" style={{ width: '50%', margin: '-15% 0%', marginLeft: '-25px' }} />
                            </a>
                        </div>
                        <div className="footer_media_links">
                            <a href="https://www.facebook.com/Intel-Workspace-104968428663949" className="media_btn" target="_blank"><i className="fab fa-facebook" /></a>
                            <a href="https://www.linkedin.com/in/intel-workspace-b36032225/" className="media_btn" target="_blank"><i className="fab fa-linkedin-in" /></a>
                            <a href="https://twitter.com/IntelWS" className="media_btn" target="_blank"><i className="fab fa-twitter" /></a>
                        </div>
                    </div>
                    <div className="col-md-7 col-sm-12 footer_links">
                    <h6 style={{textAlign:"center", marginRight:"33%"}}>Quick Links</h6>
                        <div className="row">
                        <div className="col-md-6 col-sm-12 footer_links pt-0">
                            <p><a href="https://www.accountantws.com/">Home</a></p>
                            <p><a href="https://www.accountantws.com/features">Features</a></p>
                            <p><a href="https://www.accountantws.com/pricing">Pricing</a></p>
                            <p><a href="https://www.accountantws.com/contact-us">Contact Us</a></p>
                            <p><a href="https://www.accountantws.com/blogs">Blogs</a></p>
                        </div>
                        <div className="col-md-6 col-sm-12 footer_links pt-0">
                            <p><a href="https://www.accountantws.com/about-us">About Us</a></p>
                            <p><a href="/signin/">Sign In</a></p>
                            <p><a href="https://www.accountantws.com/terms">Terms and Conditions</a></p>
                            <p><a href="https://www.accountantws.com/privacy">Privacy Policy</a></p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 footer_logo">
                    </div>
                    <div className="col-md-5 col-sm-12 footer_links">
                        <p>© Copyright <strong><span>Intelligent Workspace</span></strong>. All Rights Reserved</p>
                    </div>
                    <div className="col-md-3 col-sm-12 footer_links">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthFooter;