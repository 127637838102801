import React from "react";

function Splashscreen() {
    const pageLoaders={
        width:"100%",
        height: "100vh",
        backgroundColor: "rgba(252, 255, 255, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: "0",
        left: "0"

    };
    return (
        <div className="loading d-flex justify-content-center align-items-center" style={pageLoaders}>
              <img src="/static/pm/images/home_page_imgs/CPA_Loader.gif" alt="loader" />
        </div>
    )
}

export default Splashscreen;