import React, { Component } from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from 'axios';

import ContentBox from "../../../intelws_portal/constructs/elements/ContentBox";
import Loading from "../../../intelws_portal/constructs/elements/Loading";
import Table, { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { get, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";

import * as urls from "../../Urls";
import ModalBulkMessage from './ModalBulkMessage';

interface IFetchDashboard extends AxiosResponse {
    data: {
        column_names: string[],
        access_keys: string[],
        data: IDataRepr[],
        title: string,
        field_user_id: string
    }
}

interface IModalInsightProps {
    sectionName: string,
    chartName: string,
    filterYear: number,
    statusId?: number,
    mgrId?: number,
    clientAnalysisYear?: string,
    clientAnalysisCategory?: string,
    clientAcquisitionType?: string,
    businessType?: string,
    businessService?: string,
    invoiceCategory?: string,
    growthCategory?: string,
    growthYear?: string,
    setUserIds:React.Dispatch<React.SetStateAction<number[] | undefined>>,
    closeCallback: () => void
}

function isStringEmpty(str: string | undefined) {
    return ((str == undefined) || (str.length == 0 || !str.trim()));
}

function ModalInsight(props: IModalInsightProps) {
    const ENDPOINT = "api/insight/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [columnNames, setColumnNames] = React.useState<string[]>([]);
    const [accessKeys, setAccessKeys] = React.useState<string[]>([]);
    const [tableData, setTableData] = React.useState<IDataRepr[]>([]);
    const [title, setTitle] = React.useState("");
    const [fieldUserId, setFieldUserId] = React.useState("");

    const [userIds, setUserIds] = React.useState<number[]>();

    const [searchText, setSearchText] = React.useState("");

    const cancelTokenSource = React.useRef(getCancelToken());
    const tableCountRef = React.useRef(null);

    React.useEffect(() => {
        $("#insightModal").on("hidden.bs.modal", props.closeCallback);
        return () => {
            cancelTokenSource.current.cancel();
            $("#insightModal").off("hidden.bs.modal");
        }
    }, [])

    React.useEffect(() => {
        let shouldNotExecute = isStringEmpty(props.sectionName) || isStringEmpty(props.chartName);

        if ((!shouldNotExecute) && (props.sectionName == "practice")) {

            if (props.statusId != undefined) {
                shouldNotExecute = shouldNotExecute || (props.statusId == -1);
            }
            else {
                shouldNotExecute = shouldNotExecute || true;
            }

            if (props.chartName == "Employee Follow Up") {
                if (props.mgrId != undefined) {
                    shouldNotExecute = shouldNotExecute || (props.mgrId == -1);
                }
                else {
                    shouldNotExecute = shouldNotExecute || true;
                }
            }
        }

        if ((!shouldNotExecute) && (props.sectionName == "client")) {
            if (props.chartName == "Client Analysis") {
                if (props.clientAnalysisYear != undefined) {
                    shouldNotExecute = shouldNotExecute || (props.clientAnalysisYear == "");
                }
                else {
                    shouldNotExecute = shouldNotExecute || true;
                }
                if (props.clientAnalysisCategory != undefined) {
                    shouldNotExecute = shouldNotExecute || (props.clientAnalysisCategory == "");
                }
                else {
                    shouldNotExecute = shouldNotExecute || true;
                }
            }

            if (props.chartName == "Client Acquisition") {
                if (props.clientAcquisitionType != undefined) {
                    shouldNotExecute = shouldNotExecute || (props.clientAcquisitionType == "");
                }
                else {
                    shouldNotExecute = shouldNotExecute || true;
                }
            }

            if (props.chartName == "Business Type") {
                if (props.businessType != undefined) {
                    shouldNotExecute = shouldNotExecute || (props.businessType == "");
                }
                else {
                    shouldNotExecute = shouldNotExecute || true;
                }
            }

            if (props.chartName == "Business Service") {
                if (props.businessService != undefined) {
                    shouldNotExecute = shouldNotExecute || (props.businessService == "");
                }
                else {
                    shouldNotExecute = shouldNotExecute || true;
                }
            }
        }

        if ((!shouldNotExecute) && (props.sectionName == "growth")) {
            if (props.chartName == "Invoice Breakdown") {
                if (props.invoiceCategory != undefined) {
                    shouldNotExecute = shouldNotExecute || (props.invoiceCategory == "");
                }
                else {
                    shouldNotExecute = shouldNotExecute || true;
                }
            }

            if (props.chartName == "Growth Progression") {
                if (props.growthCategory != undefined) {
                    shouldNotExecute = shouldNotExecute || (props.growthCategory == "");
                }
                else {
                    shouldNotExecute = shouldNotExecute || true;
                }

                if (props.growthYear != undefined) {
                    shouldNotExecute = shouldNotExecute || (props.growthYear == "");
                }
                else {
                    shouldNotExecute = shouldNotExecute || true;
                }
            }
        }

        if (!shouldNotExecute) {
            setIsLoaded(false);
            $("#insightModal").modal("show")
            let queryParams: any = {}

            queryParams = {
                section: props.sectionName,
                detail: JSON.stringify(true),
                chart_title: props.chartName,
                filter_year: props.filterYear
            }

            if (props.sectionName == "practice") {
                queryParams.status_id = props.statusId;
                queryParams.mgr_id = props.mgrId;
            }

            if (props.sectionName == "client") {
                if (props.chartName == "Client Analysis") {
                    queryParams.client_analysis_category = props.clientAnalysisCategory;
                    queryParams.client_analysis_year = props.clientAnalysisYear;
                }
                if (props.chartName == "Client Acquisition") {
                    queryParams.client_acquisition_type = props.clientAcquisitionType
                }
                if (props.chartName == "Business Type") {
                    queryParams.business_type = props.businessType
                }
                if (props.chartName == "Business Service") {
                    queryParams.business_service = props.businessService
                }
            }

            if (props.sectionName == "growth") {
                if (props.chartName == "Invoice Breakdown") {
                    queryParams.invoice_category = props.invoiceCategory;
                }
                if (props.chartName == "Growth Progression") {
                    queryParams.growth_category = props.growthCategory;
                    queryParams.growth_year = props.growthYear;
                }
            }

            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchDashboard>;
            requestResponse.then((response) => {
                setColumnNames(response.data.column_names);
                setAccessKeys(response.data.access_keys);
                setTableData(response.data.data);
                setTitle(response.data.title);
                setFieldUserId(response.data.field_user_id);
                setIsLoaded(true);
            })
        }
    }, [props.sectionName, props.chartName, props.filterYear, props.statusId, props.mgrId,
    props.clientAnalysisYear, props.clientAnalysisCategory, props.clientAcquisitionType, props.businessType, props.businessService,
    props.invoiceCategory, props.growthCategory, props.growthYear])

    function getModalHeader() {
        return (
            <div className="searchWithCount">
                <div className="right-search-box pull-left">
                    <input type="search" placeholder="Search..."
                        onChange={(e) => setSearchText(e.target.value)} value={searchText} />
                </div>
                <div className="table-list-count pull-left" ref={tableCountRef}>
                    <span id="tableCount"></span>
                </div>
            </div>
        )
    }

    function snapshotCallback(data: IDataRepr[]) {
        let userIds: number[] = [];
        data.forEach((ele) => {
            if (userIds.find(value => value === ele[fieldUserId]) == undefined) {
                userIds.push(ele[fieldUserId] as number);
            }
        })
        props.setUserIds(userIds);
        $("#insightModal").modal("hide");
    }

    function getModalTable() {
        let link = {
            ut_user_name: {
                baseUrl: urls.BASE_COMMON_CLIENT_DASHBOARD,
                urlParams: ['ut_user_id'],
                isActualAnchor: true
            }
        }
        return (
            <React.Fragment>
                <Table searchString={searchText}
                    showRecords={true} columnNames={columnNames}
                    accessKeys={accessKeys} data={tableData}
                    recordsRef={tableCountRef} links={link} hasDownload={true}
                    hasSnapshot={!isStringEmpty(fieldUserId)} snapshotCallback={snapshotCallback} />
            </React.Fragment>
        )
    }

    function getModalContent() {
        if (!isLoaded) {
            return <Loading />;
        } else {
            return (
                <React.Fragment>
                    <ModalHeader title={title} miscHeader={getModalHeader()} />
                    <div className="modal-body">
                        <div className="tableFixHead table-responsive custom-table">
                            <div className="col-sm-12 tabSec">
                                <ContentBox body={getModalTable()} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }

    function relativeRender() {
        return (
            <React.Fragment>
            <div className="modal fade custom-modal custom-small text-center" id="insightModal" tabIndex={-1}>
                <div className="modal-dialog text-left modal-xl">
                    <div className="modal-content">
                        {getModalContent()}
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById("modal") as Element);
}

export default ModalInsight;
