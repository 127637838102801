import React from 'react';
import ReactDOM from "react-dom";
import $, { Callbacks } from "jquery";
import { AxiosResponse } from 'axios';

import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { post, getCancelToken, get } from "../../../intelws_portal/utils/backendInterface";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";

import { parseQueryResponseOnFail, parseQueryResponseOnSuccess } from '../../../declarations/common_utils';
import ModalDropDown from '../../../intelws_portal/constructs/modal/ModalDropDown';
import ModalDatePicker from '../../../intelws_portal/constructs/modal/ModalDatePicker';
import { ICalendarEventRepr } from '../common/Calendar';
import Loading from '../../../intelws_portal/constructs/elements/Loading';
import StatusCode from '../../../intelws_portal/constructs/elements/StatusCode';

interface IAttendeesRepr {
    [attendeeEmail: string]: string
}

interface IEventDetails extends AxiosResponse {
    data: {
        appointment_name: string,
        host_name: string,
        datetimes: {
            start: {
                date: string,
                time: string
            },
            end: {
                date: string,
                time: string
            }
        },
        meeting_info: {
            meeting_icon: string,
            meeting_link: string
        },
        attendees: IAttendeesRepr[],
        is_delete: boolean
    }
}

export interface IModalEventDetailsProps {
    appointmentId: number,
    cancelEventCallback: (appointmentId: number) => void
}

function ModalEventDetails(props: IModalEventDetailsProps) {
    const CALENDAR_ENDPOINT = "api/calendar/";

    const [appointmentName, setAppointmentName] = React.useState("");
    const [startDate, setStartDate] = React.useState("");
    const [startTime, setStartTime] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [endTime, setEndTime] = React.useState("");
    const [hostName, setHostName] = React.useState("");
    const [attendees, setAttendees] = React.useState<IAttendeesRepr[]>([]);
    const [isDelete, setIsDelete] = React.useState(false);
    const [meetingIcon, setMeetingIcon] = React.useState("");
    const [meetingLink, setMeetingLink] = React.useState("");

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.appointmentId != -1) {
            let queryParams = {
                section: "fetch_event_detail",
                appointment_id: props.appointmentId
            }
            const requestResponse = get(CALENDAR_ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IEventDetails>;
            requestResponse.then((response) => {
                setAppointmentName(response.data.appointment_name);
                setStartDate(response.data.datetimes.start.date);
                setStartTime(response.data.datetimes.start.time);
                setEndDate(response.data.datetimes.end.date);
                setEndTime(response.data.datetimes.end.time);
                setHostName(response.data.host_name);
                setAttendees(response.data.attendees);
                setMeetingIcon(response.data.meeting_info.meeting_icon);
                setMeetingLink(response.data.meeting_info.meeting_link);
                setIsDelete(response.data.is_delete);
            })
        }
    }, [props.appointmentId])

    function cancelEventOperation() {
        let formData = new FormData();
        formData.append("appointment_id", props.appointmentId.toString());
        let queryParams = {
            section: "cancel_event"
        }
        const requestResponse = post(CALENDAR_ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
        requestResponse.then(() => {
            $("#eventDetailsModal").modal("hide");
            props.cancelEventCallback(props.appointmentId);
        })
    }

    function submitForm() {
    }
   
    function getAppointmentNameUI() {
        return (
            <div className="row">
                <div className="col-1">
                <i className="fa fa-calendar" style={{fontSize:"20px", color:"rgb(74 74 74 / 87%)", textAlign:"center", marginTop:"50%"}}></i>
                </div>
                <div className="col-9">
                <span className="getAppointmentNameUIcss">{appointmentName}</span>
                {getAppointmentDateTimeUI()}
                </div>
                <div className="col-1">
                {getCancelButtonUI()}
                </div>
                
            </div>
        )
    }

    function getAppointmentDateTimeUI() {
        if (startDate == endDate) {
            return (
                <React.Fragment>
                        <p className="getAppointmentDateTimeUIcss">Date: {startDate} &nbsp;&nbsp;&nbsp;{startTime} - {endTime}</p>                    
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="row">
                        <p>{startDate} {startTime} - {endDate} {endTime}</p>
                    </div>
                </React.Fragment>
            )
        }
    }

    function getHostUI() {
        return (
            <div className="row">
                <div className="col-1">
                <i className="fa fa-user" style={{fontSize:"30px", color:"rgb(74 74 74 / 87%)", textAlign:"center", marginTop:"15%"}}></i>
                </div>
                <div className="col-11">
                <p className="mb-0">Host: &nbsp; </p><p className="getAppointmentDateTimeUIcss">{hostName}</p>
                </div>
            </div>
        )
    }

    function rawStatusToStatus(rawStatus: string) {
        if (rawStatus == "needsAction") {
             return "Unconfirmed"
        } else if (rawStatus == "declined") {
             return "Declined"
        } else if (rawStatus == "accepted") {
             return "Confirmed"
        } else if (rawStatus == "tentative") {
            return "Maybe"
        }
    }

    function getAttendeesUI() {
        return (
            <div className="row">
                <div className="col-1">
                <i className="fa fa-users" style={{fontSize:"20px", color:"rgb(74 74 74 / 87%)", textAlign:"center"}}></i>
                </div>
                <div className="col-11">
                <p>
                    Attendees
                    <ul style={{listStyleType:"none"}}>
                        {attendees.map((attendee, index) => {
                            return (
                                <li style={{marginLeft:"50px"}} key={index}>{Object.keys(attendee)[0]} - {rawStatusToStatus(Object.values(attendee)[0])}</li>
                            )
                        })}
                    </ul>
                </p>
                </div>
            </div>
        )
    }

    function getMeetingInfoUI() {
        return (
            <div className="row">
                <div className="col-1">
                <img src={meetingIcon} style={{height:"30px",width:"30px", textAlign:"center",marginTop:"30%"}}/>
                </div>
                <div className="col-11">
                <a className="btn btn-primary" target="_blank" href={meetingLink} style={{marginTop:"1.5%",marginRight:"20px"}}>Join with Google Meet</a>
                
                </div>
                
                
            </div>

        )
    }

    function getCancelButtonUI() {
        if (isDelete) {
            return (
                // <div className="row">
                <button className="btn btn-secondary-add-cpasage" style={{ marginTop: "1.5%" }} onClick={cancelEventOperation}><i className="fa fa-trash" style={{ fontSize: "20px", color: "#5060AB" }}></i></button>
                // </div>
            )
        }
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="eventDetailsModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Event Title"} />
                        <div className="modal-body">
                            {(() => {
                                if (props.appointmentId == -1) {
                                    return <Loading />
                                } else {
                                    return (
                                        <React.Fragment>
                                            {getAppointmentNameUI()}
                                            {getHostUI()}
                                            {getAttendeesUI()}
                                            {getMeetingInfoUI()}
                                        </React.Fragment>
                                    )
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalEventDetails;