import { AxiosResponse } from "axios";
import React from "react";

import BundledAlerts from "../intelws_portal/bundles/BundledAlerts";

export function isStringEmpty(value: string) {
    return (value.length == 0 || !value.trim());
}

export function usePickPropsIfNotNull<T>(propsAttr: T | undefined, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [value, setValue] = React.useState(() => {
        if (propsAttr != undefined) {
            return propsAttr;
        }
        return defaultValue;
    })

    React.useEffect(() => {
        if (propsAttr != undefined) {
            setValue(propsAttr);
        } else {
            setValue(defaultValue);
        }
    }, [propsAttr])

    return [value, setValue];
}

export function parseQueryResponseOnFailResponse<T>(errorObj: T, failResponse: T) {
    Object.keys(failResponse as any).forEach((errorKey) => {
        (errorObj as any)[errorKey].isError = true;
        (errorObj as any)[errorKey].errorText = (failResponse as any)[errorKey] as string;
    })
}

export function parseQueryResponseOnSuccess(response: AxiosResponse) {
    let success: string[] = [];
    let fail: string[] = []
    if (response.data.query_response != undefined) {
        if (response.data.query_response.success != undefined) {
            success = response.data.query_response.success;
        }
        if (response.data.query_response.fail != undefined) {
            fail = response.data.query_response.fail;
        }
    }
    return [success, fail];
}

export function parseQueryResponseOnFail(error: any) {
    let success: string[] = [];
    let fail: string[] = [];
    let failResponse: any = {};
    if (error.status != undefined && error.status == 412) {
        success = error.success;
        fail = error.fail;
        failResponse = error.failResponse;
    }
    return [success, fail, failResponse];
}

export function getBundledAlertsUI(success: string[], fail: string[], setSuccess: React.Dispatch<React.SetStateAction<string[]>>, setFail: React.Dispatch<React.SetStateAction<string[]>>) {
    if (success.length > 0 || fail.length > 0) {
        return (
            <React.Fragment>
                <BundledAlerts messages={success} timeoutFunction={setSuccess} isSuccess={true} />
                <BundledAlerts messages={fail} timeoutFunction={setFail} isSuccess={false} />
            </React.Fragment>
        )
    }
}

export function ordinal_suffix_of(i: number) {
    let j = i % 10, k = i % 100;

    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

export function isalphanum(value: string){
    if (!(value.match(/^[a-z0-9A-Z\s]+$/) || value == "")){
        return(false)
    }
    else{
        return(true)
    }
}

export function isstartwithspace(value: string){
    if(value == " " ){
        return(false)
    }
    else{
        return(true)
    }
}
