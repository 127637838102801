import React from "react";

export interface IChartContentBoxProps {
    title: string,
    dropDown: JSX.Element,
    body: JSX.Element
}

function ChartContentBox(props: IChartContentBoxProps) {
    return (
        <div>
            <div className="chart-header">
                <h4 className="chart-title">
                    {props.title}
                </h4>
                <div className="form-group">
                    <div className="select">
                        {props.dropDown}
                    </div>
                </div>
            </div>
            <div className="chart-body">
                {props.body}
            </div>
        </div>
    )
}

export default ChartContentBox;
