import axios, { CancelToken } from "axios";
import { noConflict } from "jquery";

import { HOSTNAME } from "../../components/Urls";

interface IFailResponse {
    key: string,
    value: string
}

interface IQueryParams {
    query_response?: {
        success?: string[],
        fail?: string[],
        fail_response?: IFailResponse[]
    }
}

//Axios Settings
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.baseURL = HOSTNAME;

axios.interceptors.request.use(
    (config) => {
        config.withCredentials = true;
        return config;
    }
)

axios.interceptors.response.use(
    undefined,
    (error) => {
        if (!axios.isCancel(error)) {
            if (error.response.status == 401) {
                window.location.href = "/signin/";
            } else if (error.response.status == 412) {
                let errorData = error.response.data as IQueryParams;
                let success: string[] = [];
                let fail: string[] = [];
                let failResponse: any = {};
                if (errorData.query_response != undefined) {
                    if (errorData.query_response.success != undefined) {
                        success = errorData.query_response.success;
                    }
                    if (errorData.query_response.fail != undefined) {
                        fail = errorData.query_response.fail
                    }
                    if (errorData.query_response.fail_response != undefined) {
                        errorData.query_response.fail_response.forEach((obj) => {
                            failResponse[obj.key] = obj.value;
                        })
                    }
                }
                let responseStruct = {
                    status: 412,
                    success: success,
                    fail: fail,
                    failResponse: failResponse,
                    obj: {...error}
                }
                return Promise.reject(responseStruct);
            }
        }
        return Promise.reject(error);
    }
)

export function getCancelToken() {
    return axios.CancelToken.source()
}

export function get(fetchUrl: string, cancelToken?: CancelToken, queryParams?: any) {
    return axios.get(fetchUrl, {
        cancelToken: cancelToken,
        params: queryParams
    })
}

export function post(postUrl: string, formData: FormData, cancelToken?: CancelToken, queryParams?: any, isFile?: boolean) {
    if (isFile != undefined && isFile) {
        return axios.post(postUrl, formData, {
            cancelToken: cancelToken,
            params: queryParams,
            headers: {
                "content-type": "multipart/form-data"
            }
        })
    }
    return axios.post(postUrl, formData, {
        cancelToken: cancelToken,
        params: queryParams,
    })
}