import { preventContextMenu } from "@fullcalendar/common";
import { AxiosResponse } from "axios";
import React from "react";
import { Link } from "react-router-dom";
import * as urls from "../../../Urls";
import { IPageComponentProps } from "../../../../declarations/common";
import AddButton from "../../../../intelws_portal/constructs/elements/AddButton";
import Loading from "../../../../intelws_portal/constructs/elements/Loading";
import ModalDropDown from "../../../../intelws_portal/constructs/modal/ModalDropDown";
import ModalMultiDropDown from "../../../../intelws_portal/constructs/modal/ModalMultiDropDown";
import { IDropdownRepr, IMultiDropdownRepr, ITextAreaRepr } from "../../../../intelws_portal/constructs/elements/Table";
import { get, getCancelToken, post } from "../../../../intelws_portal/utils/backendInterface";
import { ordinal_suffix_of } from "../../../../declarations/common_utils";
import ModalCommetText from "../../../../intelws_portal/constructs/modal/ModalCommentText";

type TKeyType = "name" | "employee" | "freq" | "monthNum" | "dayNum";

interface IMatchProps {
    userId: number,
    taskId: number
}

interface IUpdateValue {
    name?: string,
    employee?: number[],
    freq?: number,
    month_num?: number,
    day_num?: number
}

interface IFetchManualTaskOverview extends AxiosResponse {
    data: {
        data: {
            task_name?: ITextAreaRepr | string,
            business_name?: string,
            employee?: IMultiDropdownRepr | string,
            freq?: IDropdownRepr,
            month_config?: {options: string[], values: number[]},
            day_config?: number[],
            month_num?: number,
            day_num?: number
        },
        show_edit: boolean
    }
}

function ManualTaskSettingsTemplates(props: IPageComponentProps<IMatchProps>) {
    const ENDPOINT = "/api/manualtasklist/";
    const [isLoaded, setIsLoaded] = React.useState(false);

    const [taskName, setTaskName] = React.useState<ITextAreaRepr | string>();
    const [businessName, setBusinessName] = React.useState<string>();

    const [employeeData, setEmployeeData] = React.useState<IMultiDropdownRepr | string>();

    const [freq, setFreq] = React.useState<IDropdownRepr>();

    const [monthConfig, setMonthConfig] = React.useState<{options: string[], values: number[]}>();
    const [dayConfig, setDayConfig] = React.useState<number[]>();

    const [monthNum, setMonthNum] = React.useState<number>();
    const [dayNum, setDayNum] = React.useState<number>();

    const [showEdit, setShowEdit] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        let queryParams = {
            section: "manual_task_overview",
            user_id: props.match.params.userId,
            task_tracking_id: props.match.params.taskId
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchManualTaskOverview>;
        requestResponse.then((response) => {
            setTaskName(response.data.data.task_name);
            setBusinessName(response.data.data.business_name);
            setEmployeeData(response.data.data.employee);
            setShowEdit(response.data.show_edit);
            setFreq(response.data.data.freq);
            setMonthConfig(response.data.data.month_config);
            setDayConfig(response.data.data.day_config);
            setMonthNum(response.data.data.month_num);
            setDayNum(response.data.data.day_num);
            setIsLoaded(true);
        })
    }, [])

    function inputCallback(key: TKeyType, value: string | HTMLCollectionOf<HTMLOptionElement>) {
        if (typeof value == "string") {
            let processedValue: string | number = value;
            if (!isNaN(+value)) {
                processedValue = parseInt(processedValue);
            }
            if (key == "freq" && typeof processedValue == "number") {
                if (monthConfig != undefined) {
                    setMonthNum(monthConfig.values[0]);
                }
                if (dayConfig != undefined) {
                    setDayNum(dayConfig[0])
                }
                setFreq((prevFreq) => {
                    if (prevFreq != undefined) {
                        let newFreq = { ...prevFreq };
                        newFreq.value = processedValue;
                        return newFreq;
                    }
                })
            } else if (key == "name") {
                setTaskName((prevTaskName) => {
                    if (prevTaskName != undefined && typeof prevTaskName != "string") {
                        let newTaskName = {...prevTaskName};
                        newTaskName.value = value;
                        return newTaskName;
                    }
                })
            } else if (key == "monthNum" && typeof processedValue == "number") {
                setMonthNum(processedValue);
            } else if (key == "dayNum" && typeof processedValue == "number") {
                setDayNum(processedValue)
            }
        } else {
            if (key == "employee") {
                setEmployeeData((prevEmployeeData) => {
                    if (prevEmployeeData != undefined && typeof prevEmployeeData != "string") {
                        let newEmployeeData = {...prevEmployeeData};
                        newEmployeeData.value = Array.from(value, options => parseInt(options.value));
                        return newEmployeeData;
                    }
                })
            }
        }
    }

    function buttonCallback(actionType: "edit" | "update") {
        if (actionType == "edit") {
            setIsEdit(true);
        } else {
            let queryParams = {
                section: "update_manual_task_list_overview",
                user_id: props.match.params.userId,
                task_tracking_id: props.match.params.taskId,
            }
            let formData = new FormData();
            let updateValue: IUpdateValue = {}
            
            if (taskName != undefined && typeof taskName != "string" && taskName.value != undefined) {
                //updateValue.name = taskName.value;
                formData.append("name", taskName.value);
            }

            if (employeeData != undefined && typeof employeeData != "string" && employeeData.value != undefined) {
                //updateValue.employee = employeeData.value as number[];
                formData.append("employee", JSON.stringify(employeeData.value as number[]));
            }

            if (freq != undefined && freq.value != undefined) {
                //updateValue.freq = freq.value as number;
                formData.append("freq", freq.value as string);
            }

            if (monthNum != undefined) {
                //updateValue.month_num = monthNum;
                formData.append("month_num", monthNum.toString());
            } else {
                //updateValue.month_num = 1;
                formData.append("month_num", "1");
            }

            if (dayNum != undefined) {
                //updateValue.day_num = dayNum;
                formData.append("day_num", dayNum.toString());
            }

            //formData.append("update_value", JSON.stringify(updateValue));
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
            requestResponse.then(() => {
                setIsEdit(false);
            })
        }
    }

    function getDropdown(ele: IDropdownRepr, key: TKeyType) {
        return <ModalDropDown flag={1} options={ele.options}
            values={ele.values} value={ele.value}
            onChange={(e) => inputCallback(key, e.target.value)} borderBottom={false} />
    }

    function getMultiSelectDropdown(ele: IMultiDropdownRepr, key: TKeyType) {
        return <ModalMultiDropDown flag={1} options={ele.options}
            values={ele.values} value={ele.value}
            onChange={(e) => inputCallback(key, e.target.selectedOptions)} />
    }
    
    function getText(ele: ITextAreaRepr, key: TKeyType) {
        return <ModalCommetText title="" {...ele} onChange={(e) => inputCallback(key, e.target.value)} />
    }

    function getButton() {
        if (showEdit) {
            if (isEdit) {
                return <AddButton buttonValue={<i className="fa fa-floppy-o"></i>} onClick={() => buttonCallback("update")} />
            } else {
                return <AddButton buttonValue={<i className="fa fa-edit"></i>} onClick={() => buttonCallback("edit")} />
            }
        }
    }

    function getHeader()
    {
        return (
            <div className="" style={{ minHeight: "0px", width: "100%" }}>
            </div>
        )
    }
    
    function getTaskNameContent() {
        let displayElement = undefined;
        if (taskName != undefined && typeof taskName != "string") {
            if (isEdit) {
                displayElement = getText(taskName, "name");
            } else {
                displayElement = taskName.value;
            }
        } else {
            displayElement = taskName;
        }
        if (taskName != undefined) {
            return (
                <div className="col">
                    <div className="contact-box" style={{ padding: "0", display: "block", }}>
                        {getHeader()}
                        <div className="card-body" style={{ marginBottom: "auto" }}>
                            <div className="card-title cpasage-project-card">Project Name</div>
                            <div className="card-text" style={{color: "#424242ed"}}>
                                {displayElement}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    function getBusinessNameContent() {
        if (businessName != undefined) {
            return (
                <div className="col">
                    <div className="contact-box" style={{padding:"0", display:"block"}}>
                    {getHeader()}
                        <div className="card-body" style={{ marginBottom: "auto" }}>
                            <div className="card-title"><b>Business Name</b></div>
                            <div className="card-text">
                                {businessName}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    function getEmployeeContent() {
        let displayElement: string | JSX.Element | undefined = undefined;
        if (employeeData != undefined && typeof employeeData != "string") {
            if (isEdit) {
                displayElement = getMultiSelectDropdown(employeeData, "employee");
            } else {
                displayElement = "";
                if (employeeData.value != undefined) {
                    employeeData.value.forEach((empValue, index) => {
                        if (employeeData.values != undefined) {
                            let valueIndex = employeeData.values.findIndex((currEmpValue) => {
                                return empValue == currEmpValue;
                            })
                            if (index > 0) {
                                displayElement += ", ";
                            }
                            displayElement += employeeData.options[valueIndex] as string;
                        }
                    })
                }
            }
        } else {
            displayElement = employeeData;
        }
        if (employeeData != undefined) {
            return (
                <div className="col">
                    <div className="contact-box" style={{padding:"0", display:"block"}}>
                        {getHeader()}
                        <div className="card-body" style={{ marginBottom: "auto" }}>
                            <div className="card-title"><b>Team Member</b></div>
                            <div className="card-text">
                                {displayElement}
                            </div>
                        </div>
                    </div>
                </div>
            
            )
        }
    }

    function getFreqContent() {
        let displayElement: (JSX.Element | string)[] = [];
        if (isEdit) {
            if (freq?.value == 1) {
                //Monthly
                let dayDropdownRepr: IDropdownRepr = {
                    type: "dropdown",
                    options: dayConfig as number[],
                    value: dayNum
                }
                displayElement.push(getDropdown(freq, "freq"));
                displayElement.push(getDropdown(dayDropdownRepr, "dayNum"));
            } else if (freq?.value == 2 || freq?.value == 3) {
                //Quarterly/Yearly
                let monthDropdownRepr: IDropdownRepr = {
                    type: "dropdown",
                    options: monthConfig?.options as string[],
                    values: monthConfig?.values as number[],
                    value: monthNum
                }
                let dayDropdownRepr: IDropdownRepr = {
                    type: "dropdown",
                    options: dayConfig as number[],
                    value: dayNum
                }
                displayElement.push(getDropdown(freq, "freq"));
                displayElement.push(getDropdown(monthDropdownRepr, "monthNum"));
                displayElement.push(getDropdown(dayDropdownRepr, "dayNum"));
            }
        } else {
            if (freq?.value == 1) {
                displayElement.push(ordinal_suffix_of(dayNum as number) + " of every month");
            } else if (freq?.value == 2) {
                if (monthNum != undefined) {
                    displayElement.push(ordinal_suffix_of(dayNum as number) + " " + monthConfig?.options[monthNum - 1] + " repeating every quarter");
                }
            } else if (freq?.value == 3) {
                if (monthNum != undefined) {
                    displayElement.push(ordinal_suffix_of(dayNum as number) + " " + monthConfig?.options[monthNum - 1] + " repeating every year");
                }
            }
        }
        if (freq != undefined) {
            return (
                <div className="col">
                    <div className="contact-box" style={{padding:"0", display:"block"}}>
                        {getHeader()}
                        <div className="card-body" style={{ marginBottom: "auto" }}>
                            <div className="card-title"><b>Frequency</b></div>
                            <div className="card-text">
                                {displayElement}
                            </div>
                        </div>
                    </div>
                </div>
            
            )
        }
    }

    function getClientDashboardLink() {
        return <Link className="btn btn-secondary-add-cpasage" to={urls.BASE_COMMON_CLIENT_DASHBOARD + props.match.params.userId + "/"} style={{marginRight:"10px"}}>Client Dashboard</Link>
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="card" style={{ border: "0px", marginBottom: "20px" }}>
                <div className="heading-box" style={{ marginBottom: "5px" }}>
                    <h6>Project Template Details
                        {getButton()}
                    </h6>
                        {getClientDashboardLink()}
                </div>
                <div className="row">
                    {getTaskNameContent()}
                    {getBusinessNameContent()}
                    {getEmployeeContent()}
                    {getFreqContent()}
                </div>
            </div>
        )
    }
}

export default ManualTaskSettingsTemplates;