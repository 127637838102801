import { preventContextMenu } from "@fullcalendar/common";
import { AxiosResponse } from "axios";
import React from "react";
import { Link } from "react-router-dom";
import * as urls from "../../../Urls";
import { IPageComponentProps } from "../../../../declarations/common";
import { isStringEmpty } from "../../../../declarations/common_utils";
import AddButton from "../../../../intelws_portal/constructs/elements/AddButton";
import Loading from "../../../../intelws_portal/constructs/elements/Loading";
import ModalDatePicker from "../../../../intelws_portal/constructs/modal/ModalDatePicker";
import ModalDropDown from "../../../../intelws_portal/constructs/modal/ModalDropDown";
import ModalMultiDropDown from "../../../../intelws_portal/constructs/modal/ModalMultiDropDown";
import { IDateRepr, IDropdownRepr, IMultiDropdownRepr, isITextRepr, ITextAreaRepr, ITextRepr } from "../../../../intelws_portal/constructs/elements/Table";
import { get, getCancelToken, post } from "../../../../intelws_portal/utils/backendInterface";
import ModalInputText from "../../../../intelws_portal/constructs/modal/ModalInputText";
import ModalCommetText from "../../../../intelws_portal/constructs/modal/ModalCommentText";

type TKeyType = "status" | "dueDate" | "employee" | "name";

interface IMatchProps {
    userId: number,
    taskId: number
}

interface IUpdateValue {
    name?: string,
    status?: number,
    due_date?: string,
    employee?: number[]
}


interface IFetchManualTaskOverview extends AxiosResponse {
    data: {
        data: {
            task_name?: string,
            business_name?: string,
            status?: IDropdownRepr | ITextRepr,
            due_date?: IDateRepr | string,
            employee?: IMultiDropdownRepr | string
        },
        show_edit: boolean
    }
}

export interface IManualTaskSettingsProps<T> extends IPageComponentProps<T> {
    isUser: boolean
}

function ManualTaskSettings(props: IManualTaskSettingsProps<IMatchProps>) {
    const ENDPOINT = "/api/manualtasklist/";
    const [isLoaded, setIsLoaded] = React.useState(false);

    const [taskName, setTaskName] = React.useState<ITextAreaRepr | string>();
    const [businessName, setBusinessName] = React.useState<string>();

    const [statusData, setStatusData] = React.useState<IDropdownRepr | string>();

    const [dueDateData, setDueDateData] = React.useState<IDateRepr | string>();

    const [employeeData, setEmployeeData] = React.useState<IMultiDropdownRepr | string>();

    const [showEdit, setShowEdit] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        let queryParams = {
            section: "manual_task_overview",
            user_id: props.match.params.userId,
            task_tracking_id: props.match.params.taskId
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchManualTaskOverview>;
        requestResponse.then((response) => {
            setTaskName(response.data.data.task_name);
            setBusinessName(response.data.data.business_name);
            if (response.data.data.status != null && isITextRepr(response.data.data.status)) {
                setStatusData(response.data.data.status.value);
            } else {
                setStatusData(response.data.data.status);
            }
            setDueDateData(response.data.data.due_date);
            setEmployeeData(response.data.data.employee);
            setShowEdit(response.data.show_edit);
            setIsLoaded(true);
        })
    }, [])

    function inputCallback(key: TKeyType, value: string | HTMLCollectionOf<HTMLOptionElement>) {
        if (typeof value == "string") {
            let processedValue: string | number = value;
            if (!isNaN(+value)) {
                processedValue = parseInt(processedValue);
            }
            if (key == "status") {
                setStatusData((prevStatusData) => {
                    if (prevStatusData != undefined && typeof prevStatusData != "string") {
                        let newStatusData = { ...prevStatusData };
                        newStatusData.value = processedValue;
                        return newStatusData;
                    }
                })
            } else if (key == "dueDate") {
                setDueDateData((prevDueDateData) => {
                    if (prevDueDateData != undefined && typeof prevDueDateData != "string") {
                        let newDueDateData = { ...prevDueDateData };
                        newDueDateData.value = value;
                        return newDueDateData;
                    }
                })
            } else if (key == "name") {
                setTaskName((prevTaskName) => {
                    if (prevTaskName != undefined && typeof prevTaskName != "string") {
                        let newTaskName = {...prevTaskName};
                        newTaskName.value = value;
                        return newTaskName;
                    }
                })
            }
        } else {
            if (key == "employee") {
                setEmployeeData((prevEmployeeData) => {
                    if (prevEmployeeData != undefined && typeof prevEmployeeData != "string") {
                        let newEmployeeData = { ...prevEmployeeData };
                        newEmployeeData.value = Array.from(value, options => parseInt(options.value));
                        return newEmployeeData;
                    }
                })
            }
        }
    }

    function buttonCallback(actionType: "edit" | "update") {
        if (actionType == "edit") {
            setIsEdit(true);
        } else {
            let queryParams = {
                section: "update_manual_task_list_overview",
                user_id: props.match.params.userId,
                task_tracking_id: props.match.params.taskId,
            }
            let formData = new FormData();
            let updateValue: IUpdateValue = {}
            if (taskName != undefined && typeof taskName != "string" && taskName.value != undefined) {
                //updateValue.name = taskName.value;
                formData.append("name", taskName.value);
            }
            if (statusData != undefined && typeof statusData != "string" && statusData.value != undefined) {
                //updateValue.status = statusData.value as number
                formData.append("status", statusData.value as string);
            }
            if (dueDateData != undefined && typeof dueDateData != "string") {
                //updateValue.due_date = dueDateData.value;
                formData.append("due_date", dueDateData.value);
            }

            if (employeeData != undefined && typeof employeeData != "string" && employeeData.value != undefined) {
                //updateValue.employee = employeeData.value as number[];
                formData.append("employee", JSON.stringify(employeeData.value as number[]))
            }
            //formData.append("update_value", JSON.stringify(updateValue));
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
            requestResponse.then(() => {
                setIsEdit(false);
            })
        }
    }

    function getDropdown(ele: IDropdownRepr, key: TKeyType) {
        return <ModalDropDown flag={1} options={ele.options}
            values={ele.values} value={ele.value}
            onChange={(e) => inputCallback(key, e.target.value)} borderBottom={false} />
    }

    function getText(ele: ITextAreaRepr, key: TKeyType) {
        return <ModalCommetText title="" {...ele} onChange={(e) => inputCallback(key, e.target.value)} />
    }

    function getDatePicker(ele: IDateRepr, key: TKeyType) {
        return <ModalDatePicker value={ele.value} onChange={(e) => inputCallback(key, e.target.value)} />
    }

    function getMultiSelectDropdown(ele: IMultiDropdownRepr, key: TKeyType) {
        return <ModalMultiDropDown flag={1} options={ele.options}
            values={ele.values} value={ele.value}
            onChange={(e) => inputCallback(key, e.target.selectedOptions)} />
    }

    function getButton() {
        if (showEdit) {
            if (isEdit) {
                return <AddButton buttonValue={<i className="fa fa-floppy-o"></i>} onClick={() => buttonCallback("update")} />
            } else {
                return <AddButton buttonValue={<i className="fa fa-edit"></i>} onClick={() => buttonCallback("edit")} />
            }
        }
    }

    function getHeader() {
        return (
            <div className="" style={{ minHeight: "0px", width: "100%" }}>
            </div>
        )
    }

    function getTaskNameContent() {
        let displayElement = undefined;
        if (taskName != undefined && typeof taskName != "string") {
            if (isEdit) {
                displayElement = getText(taskName, "name");
            } else {
                displayElement = taskName.value;
            }
        } else {
            displayElement = taskName;
        }
        if (taskName != undefined) {
            return (
                <div className="col">
                    <div className="contact-box" style={{ padding: "0", display: "block", }}>
                        {getHeader()}
                        <div className="card-body" style={{ marginBottom: "auto" }}>
                            <div className="card-title cpasage-project-card">Project Name</div>
                            <div className="card-text" style={{color: "#424242ed"}}>
                                {displayElement}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    function getBusinessNameContent() {
        if (businessName != undefined) {
            return (
                <div className="col">
                    <div className="contact-box" style={{ padding: "0", display: "block" }}>
                        {getHeader()}
                        <div className="card-body" style={{ marginBottom: "auto" }}>
                            <div className="card-title cpasage-project-card">Business Name</div>
                            <div className="card-text" style={{color: "#424242ed"}}>
                                {businessName}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    function getStatusContent() {
        let displayElement = undefined;
        if (statusData != undefined && typeof statusData != "string") {
            if (isEdit) {
                displayElement = getDropdown(statusData, "status");
            } else {
                if (statusData.values != undefined) {
                    let valueIndex = statusData.values.findIndex((ele) => {
                        return ele == statusData.value;
                    })
                    displayElement = statusData.options[valueIndex];
                }
            }
        } else {
            displayElement = statusData;
        }
        if (statusData != undefined) {
            return (
                <div className="col">
                    <div className="contact-box" style={{ padding: "0", display: "block" }}>
                        {getHeader()}
                        <div className="card-body" style={{ marginBottom: "auto" }}>
                            <div className="card-title cpasage-project-card">Status</div>
                            <div className="card-text" style={{color: "#424242ed"}}>
                                {displayElement}
                            </div>
                        </div>
                    </div>
                </div>

            )
        }
    }

    function getDueDateContent() {
        let displayElement = undefined;
        if (dueDateData != undefined && typeof dueDateData != "string") {
            if (isEdit) {
                displayElement = getDatePicker(dueDateData, "dueDate");
            } else {
                if (isStringEmpty(dueDateData.value)) {
                    displayElement = "No Due Date Set";
                } else {
                    displayElement = dueDateData.value;
                }
            }
        } else {
            displayElement = dueDateData;
        }
        if (dueDateData != undefined) {
            return (
                <div className="col">
                    <div className="contact-box" style={{ padding: "0", display: "block" }}>
                        {getHeader()}
                        <div className="card-body" style={{ marginBottom: "auto" }}>
                            <div className="card-title cpasage-project-card">Due On</div>
                            <div className="card-text" style={{color: "#424242ed"}}>
                                {displayElement}
                            </div>
                        </div>
                    </div>
                </div>

            )
        }
    }

    function getEmployeeContent() {
        let displayElement: string | JSX.Element | undefined = undefined;
        if (employeeData != undefined && typeof employeeData != "string") {
            if (isEdit) {
                displayElement = getMultiSelectDropdown(employeeData, "employee");
            } else {
                displayElement = "";
                if (employeeData.value != undefined) {
                    employeeData.value.forEach((empValue, index) => {
                        if (employeeData.values != undefined) {
                            let valueIndex = employeeData.values.findIndex((currEmpValue) => {
                                return empValue == currEmpValue;
                            })
                            if (index > 0) {
                                displayElement += ", ";
                            }
                            displayElement += employeeData.options[valueIndex] as string;
                        }
                    })
                }
            }
        } else {
            displayElement = employeeData;
        }
        if (employeeData != undefined) {
            return (
                <div className="col">
                    <div className="contact-box" style={{ padding: "0", display: "block" }}>
                        {getHeader()}
                        <div className="card-body" style={{ marginBottom: "auto" }}>
                            <div className="card-title cpasage-project-card">Team Member</div>
                            <div className="card-text" style={{color: "#424242ed"}}>
                                {displayElement}
                            </div>
                        </div>
                    </div>
                </div>

            )
        }
    }

    function getClientDashboardLink() {
        if (!props.isUser) {
            return <Link className="btn btn-secondary-add-cpasage" to={urls.BASE_COMMON_CLIENT_DASHBOARD + props.match.params.userId + "/"} style={{ marginRight: "10px" }}>Client Dashboard</Link>
        }
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="card" style={{ border: "0px", marginBottom: "20px" }}>
                <div className="heading-box" style={{ marginBottom: "5px" }}>
                    <h6 className="col-sm-6 f-left">Project Details
                        {getButton()}
                    </h6>
                    <div className="col-sm-6 rightFlex pr-5">
                        {getClientDashboardLink()}
                    </div>
                </div>
                <div className="row col-sm-12">
                    {getTaskNameContent()}
                    {getBusinessNameContent()}
                    {getStatusContent()}
                    {getDueDateContent()}
                    {getEmployeeContent()}
                </div>
            </div>
        )
    }
}

export default ManualTaskSettings;