import React from "react";

export interface IContentBoxProps {
    body?: JSX.Element,
    header?: JSX.Element
}

function ContentBox(props: IContentBoxProps) {
    return (
        <div className="container-fluid bottom-sec clearfix">
            {props.header}
            {props.body}
        </div>
    )
}

export default ContentBox;