import React from 'react';

export interface INotifRepr {
    notifNumber?: number,
    notifType?: "warning" | "danger" | "info" | "primary" | "success"
}

export interface ITabRepr {
    target: string,
    display: string,
    notif?: INotifRepr,
    active: boolean
}

export interface IHeaderRepr {
    target: string,
    content?: JSX.Element | JSX.Element[]
}

export interface IContentRepr {
    content: JSX.Element | JSX.Element[]
}

export interface ITabsProps {
    tabs: ITabRepr[],
    contents: IContentRepr[],
    miscHeaders?: IHeaderRepr[],
    tabCallback?: (tabName: string, index: number) => void,
    center?: boolean,
    flag?: number
}

function Tabs(props: ITabsProps) {
    const [currTabIndex, setCurrTabIndex] = React.useState(() => {
        return props.tabs.findIndex((ele) => {
            return ele.active;
        })
    });

    function tabClassName(val: boolean) {
        if (val) {
            return "nav-link active";
        } else {
            return "nav-link"
        }
    }

    function contentClassName(val: boolean) {
        if (val) {
            return "active tab-pane in show";
        } else {
            return "tab-pane in";
        }
    }

    function ULClassName() {
        if (props.center == true) {
            return "nav nav-tabs justify-content-center";
        }
        return "nav nav-tabs";
    }

    function getCurrMiscHeader() {
        if (props.miscHeaders != undefined && props.miscHeaders[currTabIndex] != undefined) {
            return props.miscHeaders[currTabIndex].content;
        }
        return undefined;
    }

    function tabCallback(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) {
        setCurrTabIndex(index);
        let tabName = e.currentTarget.hash.substr(1).toLowerCase();
        if (props.tabCallback != undefined) {
            props.tabCallback(tabName, index);
        }
    }

    function getTabNotifNumber(notif?: INotifRepr) {
        let notifColor = "badge badge-danger";
        let notifNumber = " ";
        let style: React.CSSProperties | undefined = {width: 1};
        if (notif != undefined) {
            if (notif.notifType != undefined) {
                notifColor = "badge badge-" + notif.notifType;
            }
            if (notif.notifNumber != undefined) {
                notifNumber = notif.notifNumber.toString();
                style = undefined;
            }
            return <span className={notifColor} style={style}>{notifNumber}</span>
        }
    }

    function getTabLinks(ele: ITabRepr, index: number) {
        return (
            <a onClick={(e) => tabCallback(e, index)}
                className={tabClassName(ele.active)}
                href={"#" + ele.target} data-toggle="tab" role="tab">
                {ele.display} {getTabNotifNumber(ele.notif)}
            </a>
        )
    }

    function getTabContent() {
        return (
            <div className="innertab clearfix">
                <ul className={ULClassName()} role="tablist">
                    {props.tabs.map((ele, index) =>
                        <li className="nav-item" key={index.toString()}>
                            {getTabLinks(ele, index)}
                        </li>
                    )}
                    {getCurrMiscHeader()}
                </ul>
                <div className="tab-content">
                    {props.contents.map((ele, index) =>
                        <div key={index + props.tabs.length}
                            className={contentClassName(props.tabs[index].active)}
                            id={props.tabs[index].target}>
                            {ele.content}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    if (props.flag != undefined) {
        if (props.flag == 1) {
            return getTabContent();
        }
    }
    return (
        <div className="col-sm-12 tabSec bottom-sec">
            {getTabContent()}
        </div>
    )
}

export default Tabs;