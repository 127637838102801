import React from 'react';
import Chart, { ChartDataSets } from 'chart.js';

export interface IBarChartProps {
    legend: boolean,
    dataset: ChartDataSets[],
    labels?: string[],
    chartCallback?: (date: string, datasetName: string) => void
}

function BarChart(props: IBarChartProps) {
    const chart = React.useRef<Chart>();
    const chartRef = React.useRef();
    React.useEffect(() => {
        chart.current = new Chart(chartRef.current as any, {
            type: "bar",
            data: {
                labels: props.labels,
                datasets: props.dataset
            },
            options: {
                legend: {
                    display: props.legend
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                onClick: chartCallback
            }
        })
    }, [])

    React.useEffect(() => {
        if (chart.current != undefined) {
            if (props.labels != undefined) {
                chart.current.data.labels = props.labels;
            }
            chart.current.data.datasets = props.dataset;
            chart.current.update();
        }
    }, [props.labels, props.dataset])

    function chartCallback (e?: MouseEvent) {
        let activeElement: any = chart.current?.getElementAtEvent(e)[0];
        let labels = activeElement._xScale.ticks;
        let index = activeElement._index;
        let datasetIndex = activeElement._datasetIndex;
        let datasetName = activeElement._chart.legend.legendItems[datasetIndex].text;
        let date = String(labels[index]);
        if (props.chartCallback != undefined) {
            props.chartCallback(date, datasetName);
        }
    }

    return (
        <canvas className="custom-charts" ref={chartRef as any} />
    )
}

export default BarChart;