import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";

import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import ModalDropDown from "../../../intelws_portal/constructs/modal/ModalDropDown";
import { getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";
import { parseQueryResponseOnFail, parseQueryResponseOnFailResponse } from "../../../declarations/common_utils";
import ModalCommetText from "../../../intelws_portal/constructs/modal/ModalCommentText";

interface IModalBulkMessage {
    userIds: number[],
    triggerClose: () => void
}

function ModalBulkMessage(props: IModalBulkMessage) {
    const ENDPOINT = "api/bulkmessages/";

    const dynamicFieldsLegend = [{"display_name": "First Name", "internal_name": "FirstName"},
                                 {"display_name": "Last Name", "internal_name": "LastName"},
                                 {"display_name": "Phone Number", "internal_name": "PhoneNumber"},
                                 {"display_name": "Admin First Name", "internal_name": "AdminFirstName"},
                                 {"display_name": "Admin Last Name", "internal_name": "AdminLastName"},
                                 {"display_name": "Admin Company Name", "internal_name": "AdminCompanyName"},
                                 {"display_name": "Admin Company Url", "internal_name": "AdminCompanyUrl"}];

    const messageTypeEnum = React.useRef({message: "Message", email: "Email"});
    const messageTypeOptions = React.useRef((() => {
        let messageType: string[] = [];
        for (const [key, value] of Object.entries(messageTypeEnum.current)) {
            messageType.push(value);
        }
        return messageType;
    })());

    const [messageType, setMessageType] = React.useState(messageTypeEnum.current.message);
    const [message, setMessage] = React.useState("");
    const [subject, setSubject] = React.useState("");

    const [disable, setDisable] = React.useState(false);
    const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useLayoutEffect(() => {
        $("#bulkMsgSendModal").modal("show");
        return () => {
            $("#bulkMsgSendModal").modal("hide");
        }

    }, [])
   
    React.useEffect(() => {
        $("#bulkMsgSendModal").on("hidden.bs.modal", clearForm)
        return () => {
            cancelTokenSource.current.cancel();
            $("#bulkMsgSendModal").off("hidden.bs.modal");
        }
    }, [])

    function clearForm() {
        setMessageType(messageTypeEnum.current.message);
        setMessage("");
        setSubject("");
        props.triggerClose();
    }

    function getEmptyErrorObj() {
        return {
            user_ids: {
                isError: false,
                errorText: ""
            },
            message_type: {
                isError: false,
                errorText: ""
            },
            message: {
                isError: false,
                errorText: ""
            },
            subject: {
                isError: false,
                errorText: ""
            }
        }
    }

    function submitForm() {
        let formData = new FormData();
        formData.append("user_ids", JSON.stringify(props.userIds));
        formData.append("message_type", messageType);
        formData.append("message", message);
        if (messageType == messageTypeEnum.current.email) {
            formData.append("subject", subject);
        }
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, { section: "create_bulk_messages" });
        requestResponse.then((response) => {
            props.triggerClose();
        }).catch((error) => {
            let [success, fail, failResponse] = parseQueryResponseOnFail(error);
            let errorObj = getEmptyErrorObj(); 
            parseQueryResponseOnFailResponse(errorObj, failResponse);
            setErrorObj(errorObj);
        })
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement> | boolean , 
        section: "messageType" | "message" | "subject" | "dynamicMessageInsert", index?: number) {
        if (section == "messageType" && typeof e != "boolean") {
            setMessageType(e.target.value);
            setSubject("");
        } else if (section == "message" && typeof e != "boolean") {
            setMessage(e.target.value);
        } else if (section == "subject" && messageType == messageTypeEnum.current.email && typeof e != "boolean") {
            setSubject(e.target.value);
        } else if (section == "dynamicMessageInsert" && index != undefined) {
            setMessage((prevMessage) => {
                return prevMessage + "{{" + dynamicFieldsLegend[index].internal_name + "}}";
            })
        }
    }

    function getDynamicFieldLegend() {
        return dynamicFieldsLegend.map((ele, index) => {
            return (
                <React.Fragment>
                    <a href="#" className="btn btn-secondary-add-cpasage" onClick={() => formFieldChange(true, "dynamicMessageInsert", index)}>{ele.display_name}</a>
                    
                </React.Fragment>
            )
        })
    }

    function getSubject() {
        if (messageType == messageTypeEnum.current.email) {
            return (
                <ModalInputText onChange={(e) => formFieldChange(e, "subject")} value={subject} title={"Subject"}
                    required borderBottom isError={errorObj.subject.isError} errorText={errorObj.subject.errorText} />
            )
        }
    }
    
    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="bulkMsgSendModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Send Bulk Message"} />
                        <div className="modal-body">
                            {getDynamicFieldLegend()}
                            <br /><br />
                            <ModalDropDown options={messageTypeOptions.current} onChange={(e) => formFieldChange(e, "messageType")}
                                value={messageType} required borderBottom 
                                isError={errorObj.message_type.isError} errorText={errorObj.message_type.errorText} />
                            {getSubject()}
                            <ModalCommetText onChange={(e) => formFieldChange(e, "message")} value={message} title={""} 
                                placeholder={"Type Message here"} rows={25} cols={25}
                                required isError={errorObj.message.isError} errorText={errorObj.message.errorText} /> <br />
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={false} disable={disable} />
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalBulkMessage;