import React from "react";
import { AxiosResponse } from "axios";

import ContentBoxTabChartTable, { IContentBoxChartTableRepr } from '../../../intelws_portal/bundles/ContentBoxTabChartTable'
import Loading from '../../../intelws_portal/constructs/elements/Loading';
import { ITabRepr } from "../../../intelws_portal/constructs/elements/Tabs";
import { getCancelToken, get } from "../../../intelws_portal/utils/backendInterface";
import ModalDropDown from "../../../intelws_portal/constructs/modal/ModalDropDown";

import { IPageComponentProps } from "../../../declarations/common";
import ModalInsight from "../modal/ModalInsight";
import ModalBulkMessage from "../modal/ModalBulkMessage";

const INSIGHT_ENDPOINT = "api/insight/";
interface IFetchInsightTabHeader {
    header?: ITabRepr,
    content?: IContentBoxChartTableRepr[]
}

interface IFetchInsightTabs extends AxiosResponse {
    data: {
        tab_config: IFetchInsightTabHeader[],
        tax_years: number[],
        actual_years: number[]
    }
}

interface IFetchInsightData extends AxiosResponse {
    data: {
        content: IContentBoxChartTableRepr[]
    }
}

function AdminInsight(props: IPageComponentProps) {

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [tabConfig, setTabConfig] = React.useState<IFetchInsightTabHeader[]>([]);
    const [currTab, setCurrTab] = React.useState(0);

    const [sectionName, setSectionName] = React.useState("");
    const [chartName, setChartName] = React.useState("");
    const [statusId, setStatusId] = React.useState(-1);
    const [mgrId, setMgrId] = React.useState(-1);
    const [clientAnalysisYear, setClientAnalysisYear] = React.useState("")
    const [clientAnalysisCategory, setClientAnalysisCategory] = React.useState("")
    const [clientAcquisitionType, setClientAcquisitionType] = React.useState("")
    const [businessType, setbusinessType] = React.useState("")
    const [businessService, setBusinessService] = React.useState("")
    const [invoiceCategory, setInvoiceCategory] = React.useState("")
    const [growthCategory, setGrowthCategory] = React.useState("")
    const [growthYear, setGrowthYear] = React.useState("")

    const [userIds, setUserIds] = React.useState<number[]>();

    const [yearConfigs, setYearConfigs] = React.useState<number[][]>([]);
    const [currYear, setCurrYear] = React.useState<number[]>([]);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("Insight");
        }
        let queryParams = { section: "config" }
        const requestResponse = get(INSIGHT_ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchInsightTabs>;
        requestResponse.then((response) => {
            setTabConfig(response.data.tab_config);
            let numOfTaxYearsTab = response.data.tab_config.length - 1;
            setYearConfigs((prevYearConfigs) => {
                let newYearConfigs = new Array(numOfTaxYearsTab).fill(response.data.tax_years);
                newYearConfigs.push(response.data.actual_years);
                return newYearConfigs;
            })
            let newCurrYear: number[] = [];
            setCurrYear((prevCurrYear) => {
                newCurrYear = new Array(numOfTaxYearsTab).fill(response.data.tax_years[0]);
                newCurrYear.push(response.data.actual_years[0]);
                return newCurrYear;
            })
            setIsLoaded(true);
        })
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    React.useEffect(() => {
        setTabConfig((tabConfig) => {
            setCurrTab((currTab) => {
                if (tabConfig[currTab] != undefined) {
                    let currTabConfig = tabConfig[currTab].header;
                    if (currTabConfig != undefined) {
                        fetchData(currTabConfig.target.toLowerCase(), currTab, currYear[currTab]);
                    }
                }
                return currTab;
            })

            return tabConfig;
        })

    }, [currYear])

    function fetchData(tabName: string, currTabIndex: number, filterYear: number) {
        let queryParams = { section: tabName, filter_year: filterYear }
        const requestResponse = get(INSIGHT_ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchInsightData>;
        requestResponse.then((response) => {
            setTabConfig((prevTabConfig) => {
                let newTabConfig = [...prevTabConfig];
                newTabConfig[currTabIndex].content = response.data.content;
                return newTabConfig;
            })
        })
    }

    function tabCallback(tabName: string, index: number) {
        setCurrTab(index);
        if (tabConfig[index].content == undefined) {
            fetchData(tabName, index, currYear[index])
        }
    }

    function yearDropdownOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrYear((prevCurYear) => {
            let newCurrYear = [...prevCurYear];
            newCurrYear[currTab] = parseInt(e.target.value);
            return newCurrYear;
        })
    }

    function chartTableCallback(chartTitle: string, rowIndex: number, colIndex: number, sideHeading: string[], topHeading: string[] | undefined) {
        let currContent = tabConfig[currTab].content;

        let sectionName = ""

        let statusId = -1;
        let mgrId = -1;

        let clientAnalysisYear = ""
        let clientAnalysisCategory = ""
        let clientAcquisitionType = ""
        let businessType = ""
        let businessService = ""

        let invoiceCategory = ""
        let growthCategory = ""
        let growthYear = ""

        if (chartTitle == "Admin Task") {
            if (currContent != undefined) {
                statusId = (currContent[0] as any).statusIds[rowIndex];
            }
            sectionName = "practice";
        }
        else if (chartTitle == "Team Task") {
            if (currContent != undefined) {
                statusId = (currContent[1] as any).statusIds[colIndex];
                mgrId = (currContent[1] as any).mgrIds[rowIndex];
            }
            sectionName = "practice";
        }
        else if (chartTitle == "Client Finalization") {
            if (currContent != undefined) {
                statusId = (currContent[2] as any).statusIds[rowIndex];
            }
            sectionName = "practice";
        }
        else if (chartTitle == "Client Finalized") {
            if (currContent != undefined) {
                statusId = (currContent[3] as any).statusIds[rowIndex];
            }
            sectionName = "practice";
        }
        else if (chartTitle == "Client Analysis") {
            sectionName = "client";
            clientAnalysisYear = sideHeading[rowIndex]
            if (topHeading != undefined) {
                clientAnalysisCategory = topHeading[colIndex]
            }
        }
        else if (chartTitle == "Client Acquisition") {
            sectionName = "client";
            clientAcquisitionType = sideHeading[rowIndex]
        }
        else if (chartTitle == "Business Type") {
            sectionName = "client";
            businessType = sideHeading[rowIndex]
        }
        else if (chartTitle == "Business Service") {
            sectionName = "client";
            businessService = sideHeading[rowIndex]
        }
        else if (chartTitle == "Invoice Breakdown") {
            sectionName = "growth";
            invoiceCategory = sideHeading[rowIndex]
        }
        else if (chartTitle == "Growth Progression") {
            sectionName = "growth";
            growthYear = sideHeading[rowIndex]
            if (topHeading != undefined) {
                growthCategory = topHeading[colIndex]
            }
        }

        /* Set the chart name */
        setChartName(chartTitle);

        /* Set the section name */
        if (sectionName != "") {
            setSectionName(sectionName);
        }

        /* Set the status index */
        if (statusId != -1) {
            setStatusId(statusId)
        }

        /* Set the manager index */
        if (mgrId != -1) {
            setMgrId(mgrId)
        }

        if (clientAnalysisYear != "") {
            setClientAnalysisYear(clientAnalysisYear);
        }

        if (clientAnalysisCategory != "") {
            setClientAnalysisCategory(clientAnalysisCategory);
        }

        if (clientAcquisitionType != "") {
            setClientAcquisitionType(clientAcquisitionType);
        }

        if (businessType != "") {
            setbusinessType(businessType);
        }

        if (businessService != "") {
            setBusinessService(businessService);
        }

        if (invoiceCategory != "") {
            setInvoiceCategory(invoiceCategory);
        }

        if (growthCategory != "") {
            setGrowthCategory(growthCategory);
        }

        if (growthYear != "") {
            setGrowthYear(growthYear);
        }
    }

    let title = <React.Fragment>
        <div className="row">
            <h6 className="col-sm-2">Insight </h6>
            <div className="col-sm-2">
                <ModalDropDown options={yearConfigs[currTab]} value={currYear[currTab]} onChange={yearDropdownOnChange} />
            </div>
        </div>
    </React.Fragment>


    function getContent() {
        return (
            <ContentBoxTabChartTable title={title} tabs={tabConfig}
                tabCallback={tabCallback} chartTableCallback={chartTableCallback} />
        )
    }


    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper">
                {getContent()}
                {(() => {
                    if (userIds != undefined) {
                        return <ModalBulkMessage userIds={userIds} triggerClose={() => setUserIds(undefined)} />
                    }
                })()}
                <ModalInsight
                    sectionName={sectionName}
                    chartName={chartName}
                    filterYear={currYear[currTab]}
                    statusId={statusId}
                    mgrId={mgrId}
                    clientAnalysisYear={clientAnalysisYear}
                    clientAnalysisCategory={clientAnalysisCategory}
                    clientAcquisitionType={clientAcquisitionType}
                    businessType={businessType}
                    businessService={businessService}
                    invoiceCategory={invoiceCategory}
                    growthCategory={growthCategory}
                    growthYear={growthYear}
                    closeCallback={() => {
                        setChartName("");
                        setSectionName("");
                        setStatusId(-1);
                        setMgrId(-1);
                        setClientAnalysisYear("");
                        setClientAnalysisCategory("");
                        setClientAcquisitionType("");
                        setbusinessType("");
                        setBusinessService("");
                        setInvoiceCategory("");
                        setGrowthCategory("");
                        setGrowthYear("");
                    }
                    }
                    setUserIds={setUserIds}
                />
            </div>
        )
    }
}

export default AdminInsight;