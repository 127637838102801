import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from "axios";

import { get, post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import { isStringEmpty, parseQueryResponseOnFail, parseQueryResponseOnFailResponse } from "../../../declarations/common_utils";
import { IAddressRepr } from "../../../intelws_portal/utils/payments/paymentTypes";

interface IPostBillingContact extends AxiosResponse {
    data: {
        data: IAddressRepr
    }
}

export interface IModalBillingContactProps {
    onSuccess: (address: IAddressRepr) => void
}

function ModalBillingContact(props: IModalBillingContactProps) {
    const ENDPOINT = "/api/billing/";

    const [line1, setLine1] = React.useState("");
    const [line2, setLine2] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [postalCode, setPostalCode] = React.useState("");
    const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        $("#billingContactModal").on("hidden.bs.modal", clearForm)
        return () => {
            cancelTokenSource.current.cancel();
            $("#billingContactModal").off("hidden.bs.modal");
        }
    }, [])

    function getEmptyErrorObj() {
        return {
            line1: {
                isError: false,
                errorText: ""
            },
            line2: {
                isError: false,
                errorText: ""
            },
            city: {
                isError: false,
                errorText: ""
            },
            state: {
                isError: false,
                errorText: ""
            },
            postalCode: {
                isError: false,
                errorText: ""
            }
        }
    }

    function clearForm() {
        setLine1("");
        setLine2("");
        setCity("");
        setState("");
        setPostalCode("");
    }

    function formChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> , 
        section: "line1" | "line2" | "city" | "state" | "postalCode") {
        if (section == "line1" ) {
            setLine1(e.target.value);
        } else if (section == "line2" ) {
            setLine2(e.target.value);
        } else if (section == "city" ) {
            setCity(e.target.value);
        } else if (section == "state" ) {
            setState(e.target.value);
        } else if (section == "postalCode") {
            setPostalCode(e.target.value);
        }
    }

    function submitForm() {
        let queryParams = {
            section: "create_billing_contact"
        }
        let formData = new FormData();
        formData.append("line1", line1);
        formData.append("city", city);
        formData.append("state", state);
        formData.append("postal_code", postalCode);
        if (!isStringEmpty(line2)) {
            formData.append("line2", line2);
        }
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostBillingContact>
        requestResponse.then((response) => {
            props.onSuccess(response.data.data);
            $("#billingContactModal").modal("hide");
        }).catch((error) => {
            let [success, fail, failResponse] = parseQueryResponseOnFail(error);
            let errorObj = getEmptyErrorObj(); 
            parseQueryResponseOnFailResponse(errorObj, failResponse);
            setErrorObj(errorObj);
        })
    }

    
    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="billingContactModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Add Billing Contact Address"} />
                        <div className="modal-body">
                            <ModalInputText title={"Address Line 1"} value={line1} onChange={(e) => formChange(e, "line1")}  
                                isError={errorObj.line1.isError} errorText={errorObj.line1.errorText}
                                borderBottom required/><br></br>
                            <ModalInputText title={"Address Line 2"} value={line2} onChange={(e) => formChange(e, "line2")}  
                                isError={errorObj.line2.isError} errorText={errorObj.line2.errorText}
                                borderBottom /><br></br>
                            <ModalInputText title={"City"} value={city} onChange={(e) => formChange(e, "city")}  
                                isError={errorObj.city.isError} errorText={errorObj.city.errorText}
                                borderBottom required/><br></br>
                            <ModalInputText title={"State"} value={state} onChange={(e) => formChange(e, "state")}  
                                isError={errorObj.state.isError} errorText={errorObj.state.errorText}
                                borderBottom required/><br></br>
                            <ModalInputText title={"Zip Code"} value={postalCode} onChange={(e) => formChange(e, "postalCode")}  
                                isError={errorObj.postalCode.isError} errorText={errorObj.postalCode.errorText}
                                borderBottom required/><br></br>
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={false} />
                    </div>
                </div>
            </div>
        )
    }
    
    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalBillingContact;