import React from "react";
import ReactDOM from "react-dom";
import { AxiosResponse } from "axios";
import $ from "jquery";

import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalFilePicker from "../../../intelws_portal/constructs/modal/ModalFilePicker";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";

function isStringEmpty(value: string) {
    return (value.length == 0 || !value.trim());
}

interface IPostTickets extends AxiosResponse {
    data: IDataRepr
}

export interface IModalTicketsProps {
    mutator: (data: IDataRepr) => void
}

function ModalTickets(props: IModalTicketsProps) {
    const ENDPOINT = "api/tickets/";
    const [subject, setSubject] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [files, setFiles] = React.useState<(File | undefined)[]>([]);
    const cancelTokenSource = React.useRef(getCancelToken());
    const [disable, setDisable] = React.useState(true);

    React.useEffect(() => {
        $("#ticketAddModal").on("hidden.bs.modal", clearForm)
        return () => {
            cancelTokenSource.current.cancel();
            $("#ticketAddModal").off("hidden.bs.modal");
        }
    }, [])

    React.useEffect(() => {
        let newDisable = true;
        if (subject != "" && message != "") {
            newDisable = false;
        }
        setDisable(newDisable);
    }, [subject, message]);

    function clearForm() {
        setSubject("");
        setMessage("");
        setFiles([]);
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement>, section: "subject" | "message" | "files", index?: number) {
        if (section == "subject") {
            setSubject(e.target.value);
        } else if (section == "message") {
            setMessage(e.target.value);
        } else if (section == "files" && index != undefined) {
            setFiles((prevFiles) => {
                let newFiles = [...prevFiles];
                let newFileList = e.target.files;
                if (newFileList != undefined) {
                    newFiles[index] = newFileList[0]
                }
                return newFiles;
            })
        }
    }

    function submitForm() {
        let formData = new FormData();
        let queryParams = {
            section: "tickets_add"
        }
        if (!isStringEmpty(subject)) {
            formData.append("subject", subject);
        }
        if (!isStringEmpty(message)) {
            formData.append("message", message);
        }
        if (files.length > 0) {
            files.forEach((ele) => {
                if (ele != undefined) {
                    formData.append("file", ele);
                }
            })
        }
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostTickets>;
        requestResponse.then((response) => {
            props.mutator(response.data);
            $('#ticketAddModal').modal("hide");
        })
    }

    function addFileCallback(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setFiles((prevFiles) => {
            return [...prevFiles, undefined];
        })
    }

    function getFiles() {
        if (files.length > 0) {
            return files.map((ele, index) => {
                return <ModalFilePicker key={index} onChange={(e) => formFieldChange(e, "files", index)} value={ele} />
            })
        }
    }
    
    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="ticketAddModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Add Ticket"} />
                        <div className="modal-body">
                            <ModalInputText onChange={(e) => formFieldChange(e, "subject")} value={subject} title={"Subject"} required borderBottom/> <br />
                            <ModalInputText onChange={(e) => formFieldChange(e, "message")} value={message} title={"Message"} required borderBottom/> <br />
                            {getFiles()}
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={false}
                            buttonConfig={[{ buttonName: "+ File", callback: addFileCallback, inactive: false}]} disable={disable}/>
                    </div>
                </div>
            </div>
        )
    }
    
    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalTickets;