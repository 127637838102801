import React from 'react';

function ScrollTop() {
    return (
        <div>
            <a className="border rounded d-inline scroll-to-top-cpasage" href="#page-top">
                <i className="fa fa-angle-up"> </i>
            </a>
        </div>
    )
}
export default ScrollTop;