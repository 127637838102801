import React, { SetStateAction } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from "axios";

import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { post, getCancelToken, get } from "../../../intelws_portal/utils/backendInterface";
import ModalDropDown from "../../../intelws_portal/constructs/modal/ModalDropDown";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";

import { isStringEmpty, parseQueryResponseOnFail, parseQueryResponseOnSuccess, usePickPropsIfNotNull } from "../../../declarations/common_utils";
import ModalCommetText from "../../../intelws_portal/constructs/modal/ModalCommentText";

interface IPostLeadAdd extends AxiosResponse {
    data: {
        data: IDataRepr
    }
}

interface IFetchAddLeadConfig extends AxiosResponse {
    data: {
        data: {
            user_ids: number[],
            user_names: string[]
        }
    }
}

export interface IModalLeadProps {
    saveCallback: (data?: IDataRepr, success?: string[], fail?: string[]) => void
    leadId?: string
    firstName?:string
    lastname?:string
    email?:string
    contact?:string
    contactPref?: string
    contactRef?: string
    clearModal?: () => void

}

function ModalLeadAdd(props: IModalLeadProps) {
    const ENDPOINT = "/api/leads/";
    const [firstName, setFirstName] = usePickPropsIfNotNull(props.firstName,"");
    const [lastName, setLastName] = usePickPropsIfNotNull(props.lastname,"");
    const [email, setEmail] = usePickPropsIfNotNull(props.email,"");
    const [phoneNum, setPhoneNum] = usePickPropsIfNotNull(props.contact,"");
    const [comments, setComments] = React.useState("")
    const [leadId, setLeadId] = usePickPropsIfNotNull(props.leadId, "");
    const [referUserId, setReferUserId] = React.useState(-1);
    const [userIds, setUserIds] = React.useState<number[]>([]);
    const [userNames, setUserNames] = React.useState<string[]>([]);
    
    const contactAllText = React.useRef("Select Preferred Contact Method");
    const referralAllText = React.useRef("Select Referral Channel");
    const referralClientText = React.useRef("Client");
    const cancelTokenSource = React.useRef(getCancelToken());

    const contactOptions = [contactAllText.current, "Call", "Email"];
    const referralOptions = [referralAllText.current, "Google", "Yelp", "Facebook", "Instagram", "Linkedin", "Twitter", "Next Door", referralClientText.current, "Other"];
    
    const [contact, setContact] = usePickPropsIfNotNull(props.contactPref, contactAllText.current);
    const [referral, setReferral] = usePickPropsIfNotNull(props.contactRef, referralAllText.current);
    const [referralOther, setReferralOther] = React.useState("");
    const [disable, setDisable] = React.useState(true);

    const [isfnameStartwithspace, setisfnameStartwithspace] = React.useState("");
    const [islnameStartwithspace, setislnameStartwithspace] = React.useState("");
    const [isemailStartwithspace, setisemailStartwithspace] = React.useState("");
    const [isphoneStartwithspace, setisphoneStartwithspace] = React.useState("");
    const [iscommentStartwithspace, setiscommentStartwithspace] = React.useState("");
    const [phoneLength, setPhoneLength] = React.useState(props.contact ? props.contact.length : 0);


    function isstartwithspace(value: string, section: "firstName" | "lastName" | "email" | "phonenum" | "comments"){
        if(value == " " ){
            if(section == "firstName"){
                setisfnameStartwithspace("please start first name with alphanumeric value ")
            }
            else if(section == "lastName"){
                setislnameStartwithspace("please start last name with alphanumeric value ")
            }
            else if(section == "email"){
                setisemailStartwithspace("please start email with alphanumeric value ")
            }
            else if(section == "phonenum"){
                setisphoneStartwithspace("please enter only numeric value for phone number")
            }
            else if(section == "comments"){
                setiscommentStartwithspace("please start comments with alphanumeric value ")
            }
            return(false)
        }
        else{
            setisfnameStartwithspace("")
            setislnameStartwithspace("")
            setisemailStartwithspace("")
            setisphoneStartwithspace("")
            setiscommentStartwithspace("")

            return(true)
        }
    }
    React.useEffect(() => {
        $("#leadAddModal").on("hidden.bs.modal", clearForm);
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, {section: "leads_add_config"}) as Promise<IFetchAddLeadConfig>;
        requestResponse.then((response) => {
            setUserIds(response.data.data.user_ids);
            setUserNames(response.data.data.user_names);
        })
        return () => {
            $("#leadAddModal").off("hidden.bs.modal");
        }
    }, [])

    React.useEffect(() => {
        let newDisable = true;
        if (email != "" && firstName != "" && lastName != "" && phoneNum != "" && contact != contactAllText.current &&
            referral != referralAllText.current && comments != "") {
            newDisable = false;
        }
        if (props.firstName != undefined && email != "" && firstName != "" && lastName != "" && phoneNum != "" ) {
            newDisable = false;
        }
        
        setDisable(newDisable);
    }, [email, firstName, lastName, phoneNum, contact, referral, comments]);
  
    function changeFormField(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> , section: "firstName" | "lastName" | "email" | "phonenum" | "comments" | "contact" | "referral" | "referralOther" | "referClient") {
        if (section == "firstName" && isstartwithspace(e.target.value, section)) {
            setFirstName(e.target.value);
        } else if (section == "lastName" && isstartwithspace(e.target.value, section)) {
            setLastName(e.target.value);
        } else if (section == "email" && isstartwithspace(e.target.value, section)) {
            setEmail(e.target.value);
        } else if (section == "phonenum" && isstartwithspace(e.target.value, section) && isValidPhoneNumber(e.target.value)) {
            setPhoneLength(phoneNum.length)
            setPhoneNum(e.target.value);
            if ((phoneNum.length == 3 || phoneNum.length == 7) && phoneLength < phoneNum.length){
                setPhoneLength(phoneNum.length)
                setPhoneNum(phoneNum + "-")
            }
        } else if (section == "comments" && isstartwithspace(e.target.value, section)) {
            setComments(e.target.value);
        } else if (section == "contact") {
            setContact(e.target.value);
        } else if (section == "referral") {
            setReferral(e.target.value);
            if (e.target.value != "Other") {
                setReferralOther("")
            }
            if (e.target.value == referralClientText.current) {
                setReferUserId(userIds[0]);
            } else {
                setReferUserId(-1);
            }
        } else if (section == "referralOther") {
            setReferralOther(e.target.value);
        } else if (section == "referClient") {
            setReferUserId(parseInt(e.target.value));
        }
    }

    function isValidPhoneNumber(value: string) {
        if (((value.match(/^[0-9 -]+$/)) || value == "") && value.length <= 12){
            return true
        }
        return false
    }

    function clearForm() {
        setFirstName("");
        setLastName("");
        setEmail("")
        setPhoneNum("");
        setComments("")
        setContact(contactAllText.current);
        setReferral(referralAllText.current);
        setReferralOther("");
        if (props.clearModal != undefined) {
            props.clearModal();

        }
       
    }

    function formSubmit() {
        let formData = new FormData();
        if (!isStringEmpty(leadId)){
            formData.append("lead_id", leadId);
        }
        if (!isStringEmpty(firstName)) {
            formData.append("first_name", firstName);
        }
        if (!isStringEmpty(lastName)) {
            formData.append("last_name", lastName);
        }
        if (!isStringEmpty(email)) {
            formData.append("email", email);
        }
        if (!isStringEmpty(phoneNum)) {
            formData.append("phonenum", phoneNum);
        }
        if (!isStringEmpty(comments)) {
            formData.append("comments", comments)
        }
        if (contact != contactAllText.current) {
            formData.append("pref_contact", contact);
        }
        if (referral != referralAllText.current) {
            if (referral == "Other") {
               formData.append("referral_method", referralOther);
            } else if (referral == referralClientText.current) {
                let userSelectedIndex = userIds.findIndex((ele) => {
                    return ele == referUserId;
                })
                formData.append("referral_method", userNames[userSelectedIndex]);
                formData.append("user_id", referUserId.toString());
            } else {
               formData.append("referral_method", referral);
            }
        }
        let queryParams
        if (leadId != "") {
            queryParams = {
                section: "edit_lead"
            }
        } else {
            queryParams = {
                section: "add_lead"
            }
        }
        
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostLeadAdd>;
        requestResponse.then((response) => {
            let [success, fail] = parseQueryResponseOnSuccess(response);
            props.saveCallback(response.data.data, success, fail);
        }).catch((error) => {
            let [success, fail] = parseQueryResponseOnFail(error);
            props.saveCallback(undefined, success, fail);
        })
        .finally(() => {
            $("#leadAddModal").modal("hide");
        })
    }

   
    function getOtherText() {
        if (referral == "Other") {
            return <ModalInputText title={"Referral Method"} value={referralOther} 
                onChange={(e) => changeFormField(e, "referralOther")} borderBottom />
        }
    }

    function getClientDropdown() {
        if (referral == referralClientText.current) {
            return <ModalDropDown options={userNames} values={userIds} value={referUserId}
                onChange={(e) => changeFormField(e, "referClient")} borderBottom />
        }
    }

    function getModalContent() {
        return (
            <React.Fragment>
                <ModalHeader title={props.leadId == "" ? "Add Lead" : "Edit Lead"} />
                <div className="modal-body">
                    <ModalInputText title={"First Name"} value={firstName} onChange={(e) => changeFormField(e, "firstName")} isinvalidmsg={isfnameStartwithspace} required borderBottom/><br />
                    <ModalInputText title={"Last Name"} value={lastName} onChange={(e) => changeFormField(e, "lastName")} isinvalidmsg={islnameStartwithspace} required borderBottom/><br />
                    <ModalInputText title={"Email"} value={email} onChange={(e) => changeFormField(e, "email")} isinvalidmsg={isemailStartwithspace} required borderBottom/><br />
                    <ModalInputText id={"phonenumber"} title={"Phone Number"} value={phoneNum} onChange={(e) => changeFormField(e, "phonenum")} isinvalidmsg={isphoneStartwithspace} required borderBottom maxlength={12}/><br />
                    {props.leadId == "" && <><ModalInputText title={"Comments"} value={comments} onChange={(e) => changeFormField(e, "comments")} isinvalidmsg={iscommentStartwithspace} required borderBottom/><br /></>}
                    <ModalDropDown options={contactOptions} value={contact} onChange={(e) => changeFormField(e, "contact")} borderBottom required/><br />
                    <ModalDropDown options={referralOptions} value={referral} onChange={(e) => changeFormField(e, "referral")} borderBottom required/><br />
                    {getOtherText()}
                    {getClientDropdown()}
                </div>
                <ModalFooter saveCallback={formSubmit} saveInactive={false} disable={disable}/>
            </React.Fragment>
        )
    }
    function relativeRender() {
        return <div className="modal fade custom-modal text-center" id="leadAddModal" tabIndex={-1}>
                <div className="modal-dialog text-left modal-lg">
                    <div className="modal-content">
                        {getModalContent()}
                    </div>
                </div>
            </div>
    }
    
    return ReactDOM.createPortal(relativeRender(), document.getElementById("modal") as Element);
}

export default ModalLeadAdd;