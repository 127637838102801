import React, { Component } from "react";

import ContentBox from '../constructs/elements/ContentBox';
import Table, { IDataRepr, ITableProps, ILinksRepr } from '../constructs/elements/Table';
import Tabs, { ITabRepr, IContentRepr, IHeaderRepr } from '../constructs/elements/Tabs';
import Loading from '../constructs/elements/Loading';
import SearchInput from '../constructs/elements/SearchInput';
import Button from '../constructs/elements/Button';
import ModalDropDown from "../constructs/modal/ModalDropDown";

export interface IContentBoxMultiTabTableTabMiscHeaderRepr {
    type: string
}

export interface IContentBoxMultiTabTableTabMiscDropdownRepr extends IContentBoxMultiTabTableTabMiscHeaderRepr {
    type: "dropdown",
    value: number | string,
    options: string[],
    values?: number[] | string[],
    callback: (value: string) => void
}

export interface IContentBoxMultiTabTableTabMiscButtonRepr extends IContentBoxMultiTabTableTabMiscHeaderRepr {
    type: "button",
    display: string,
    active: boolean,
    callback: (display: string) => void
}

export interface IContentBoxMultiTabTableTabRepr extends ITabRepr {
    accessKeys: string[][],
    columnNames: (string | JSX.Element)[][],
    data: (IDataRepr[] | undefined)[],
    children: ITabRepr[],
    links?: ILinksRepr[],
    miscHeaders?: IContentBoxMultiTabTableTabMiscHeaderRepr[],
    innerMiscHeaders?: IContentBoxMultiTabTableTabMiscHeaderRepr[][]
}

interface IContentBoxMultiTabTableProps {
    hasSearch: boolean,
    hasDownload?: boolean,
    title: string,
    tabs: IContentBoxMultiTabTableTabRepr[],
    shouldUpdate?: boolean,
    miscHeaders?: JSX.Element,
    inputCallback?: (absoluteIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) => void,
    tabCallback?: (prevTopLevelIndex: number, prevSecondLevelIndex: number, 
        topLevelIndex: number, secondLevelIndex: number, contentName: string) => void
}

function isIDropdownRepr(obj: IContentBoxMultiTabTableTabMiscHeaderRepr): obj is IContentBoxMultiTabTableTabMiscDropdownRepr {
    return obj.type == "dropdown";
}

function isIButtonRepr(obj: IContentBoxMultiTabTableTabMiscHeaderRepr): obj is IContentBoxMultiTabTableTabMiscButtonRepr {
    return obj.type == "button";
}

function ContentBoxMultiTabTable(props: IContentBoxMultiTabTableProps) {
    const [searchString, setSearchString] = React.useState("");
    const [firstLevelIndex, setFirstLevelIndex] = React.useState(() => {
        let startingIndex = 0;
        props.tabs.forEach((ele, index) => {
            if (ele.active) {
                startingIndex = index;
            }
        })
        return startingIndex;
    });
    const [secondLevelIndex, setSecondLevelIndex] = React.useState(() => {
        let startingIndex = new Array(props.tabs.length).fill(0);
        props.tabs.forEach((tabEle, topLevelIndex) => {
            tabEle.children.forEach((childEle, nextLevelIndex) => {
                if (childEle.active) {
                    startingIndex[topLevelIndex] = nextLevelIndex;
                }
            })
        })
        return startingIndex;
    });

    const tableCountRef = React.useRef(null);

    function getHeader() {
        if (props.hasSearch) {
            return (
                <div>
                    <div className="heading-box">
                        <h6 className="col-sm-6 f-left">
                            {props.title}
                            {props.miscHeaders}
                        </h6>
                        <div className=" col-sm-6 rightFlex pr-0">
                            <div className="ml-20">
                                <div className="Searchbg">
                                    <SearchInput searchString={searchString} onChange={(e) => setSearchString(e.target.value)} />
                                </div>
                            </div>
                            {/* JQUERY Handled UI start */}
                            <div className="ml-20" ref={tableCountRef}>
                                <span className="badge-danger bg-primary badge-cpasage-count">
                                    <span id="tableCount"></span>
                                </span>
                            </div>
                            {/* JQUERY Handled UI end */}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    {props.title}
                </div>
            )
        }
    }

    function firstLevelTabCallback(newFirstLevelIndex: number) {
        if (props.tabCallback != undefined) {
            props.tabCallback(firstLevelIndex, secondLevelIndex[firstLevelIndex], newFirstLevelIndex, secondLevelIndex[newFirstLevelIndex], props.tabs[newFirstLevelIndex].children[secondLevelIndex[newFirstLevelIndex]].target.toLowerCase());
        }
        setFirstLevelIndex(newFirstLevelIndex);
    }

    function secondLevelTabCallback(newSecondLevelIndex: number) {
        if (props.tabCallback != undefined) {
            props.tabCallback(firstLevelIndex, secondLevelIndex[firstLevelIndex], firstLevelIndex, newSecondLevelIndex, props.tabs[firstLevelIndex].children[newSecondLevelIndex].target.toLowerCase());
        }
        setSecondLevelIndex((prevSecondLevelIndex) => {
            let newSecondLevelIndexUpdate = [...prevSecondLevelIndex];
            newSecondLevelIndexUpdate[firstLevelIndex] = newSecondLevelIndex;
            return newSecondLevelIndexUpdate;
        });
    }

    function buildHeadersWithConfig(config: IContentBoxMultiTabTableTabMiscHeaderRepr[] | undefined, topLevelIndex: number) {
        if (config != undefined) {
            return config.map((ele, index) => {
                if (isIDropdownRepr(ele)) {
                    return (
                        <div key={topLevelIndex + "_" + index} className="rightFlex mt-1">
                            <ModalDropDown flag={1} value={ele.value} onChange={(e) => ele.callback(e.target.value)}
                                options={ele.options} values={ele.values} borderBottom={false}/>
                        </div>
                    )
                } else if (isIButtonRepr(ele)) {
                    return (
                        <Button key={topLevelIndex + "_" + index} buttonValue={ele.display} active={ele.active}
                            onClick={(e) => ele.callback(ele.display)} style={{fontSize: 12}} />
                    )
                } else {
                    return <div></div>
                }
            })
        }
        return undefined;
    }

    function buildParentMiscHeaders() {
        return props.tabs.map((ele, index) => {
            return {target: ele.target, content: buildHeadersWithConfig(ele.miscHeaders, index)}
        })
    }

    function getTable(childObject: IContentBoxMultiTabTableTabRepr, topLevelIndexMatch: boolean) {
        let tables: IContentRepr[] = [];
        childObject.data.forEach((ele, index) => {
            if (ele == undefined) {
                tables.push({content: <Loading />});
            } else {
                let tableParams: ITableProps = {
                    data: ele,
                    columnNames: childObject.columnNames[index],
                    accessKeys: childObject.accessKeys[index],
                    hasDownload: props.hasDownload,
                    searchString: searchString,
                    showRecords: false,
                    recordsRef: tableCountRef
                }
                if (childObject.links != undefined && childObject.links[index] != undefined) {
                    tableParams.links = childObject.links[index];
                }
                if (topLevelIndexMatch && secondLevelIndex[firstLevelIndex] == index) {
                    tableParams.showRecords = true;
                }
                if (props.inputCallback != undefined) {
                    tableParams.inputCallback = props.inputCallback;
                }
                let content: JSX.Element = <Table {...tableParams} />;
                tables.push({content: content});
            }
        })
        return tables;
    }

    function getChildTabs() {
        let childTabs: IContentRepr[] = [];
        props.tabs.forEach((ele, topLevelIndex) => {
            let miscContent: IHeaderRepr[] = [];
            ele.children.forEach((childEle, childIndex) => {
                if (ele.innerMiscHeaders != undefined) {
                    miscContent.push({
                        target: childEle.target,
                        content: buildHeadersWithConfig(ele.innerMiscHeaders[childIndex], childIndex)
                    })
                }
            })
            let topLevelIndexMatch = (topLevelIndex == firstLevelIndex);
            childTabs.push({
                content: <Tabs tabs={ele.children} flag={1} contents={getTable(ele, topLevelIndexMatch)}
                        tabCallback={(tabName, index) => secondLevelTabCallback(index)} miscHeaders={miscContent} />
            })
        })
        return childTabs;
    }

    function getBody() {
        return (
            <Tabs tabs={props.tabs} contents={getChildTabs()} miscHeaders={buildParentMiscHeaders()}
                tabCallback={(tabName, index) => firstLevelTabCallback(index)} />
        )
    }

    return <ContentBox header={getHeader()} body={getBody()} />
}

export default ContentBoxMultiTabTable;