import React from "react";
import $ from "jquery";
import { AxiosResponse } from "axios";
import { cloneDeep } from "lodash";


import ContentBoxTabTable, { IContentBoxTabTableProps } from '../../../intelws_portal/bundles/ContentBoxTabTable';
import { ICheckboxRepr, IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import Loading from '../../../intelws_portal/constructs/elements/Loading';
import AddButton from '../../../intelws_portal/constructs/elements/AddButton';
import ScrollTop from "../../../intelws_portal/constructs/elements/ScrollTop";
import { getCancelToken, get, post } from "../../../intelws_portal//utils/backendInterface";
import updateCache from "../../../intelws_portal//utils/updateCache";

import { IPageComponentProps } from "../../../declarations/common";
import { getBundledAlertsUI } from "../../../declarations/common_utils";
import * as urls from '../../Urls';
import ModalClient from "../modal/ModalClient";
import ModalClientBulk from "../modal/ModalClientBulk";

interface IPostPasswordManagement extends AxiosResponse {
    data: {
        data: IDataRepr
    }
}

export interface IFetchUserListResponseRepr extends AxiosResponse {
    data: {
        data: IDataRepr[],
        edit_mode: boolean,
        show_password_mgmt: boolean,
    }
}

function AdminClientAction(props: IPageComponentProps) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [isPasswordMgmt, setIsPasswordMgmt] = React.useState<boolean[]>([false, false, false]);
    const [currentTab, setCurrentTab] = React.useState("active");
    const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
    const [tableData, setTableData] = React.useState<(IDataRepr[] | undefined)[]>([undefined, undefined, undefined]);
    const [selectedDataId, setSelectedDataId] = React.useState<number[]>([]);
    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);
    const cancelTokenSource = React.useRef(getCancelToken());
    
    const ENDPOINT = "/api/userlist/";

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ClientAction");
        }
        let queryParams = { section: "active" };
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchUserListResponseRepr>;
        requestResponse.then((response) => {
            setIsLoaded(true);
            setIsEdit(response.data.edit_mode);
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                newTableData[0] = response.data.data;
                return newTableData;
            })
            setIsPasswordMgmt((prevIsPasswordManagement) => {
                let newIsPasswordManagement = [...prevIsPasswordManagement];
                newIsPasswordManagement[0] = response.data.show_password_mgmt;
                return newIsPasswordManagement;
            })
        })
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    function clearCheckbox(indexToClear: number, clearSelectedId?: boolean, clearTableUI?: boolean) {
        if (isEdit) {
            if ((clearSelectedId == undefined) || (typeof clearSelectedId == "boolean" && clearSelectedId)) {
                setSelectedDataId([]);
            }
            if ((clearTableUI == undefined) || (typeof clearTableUI == "boolean" && clearTableUI)) {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    let currTable = newTableData[indexToClear];
                    if (currTable != undefined) {
                        currTable.forEach((ele) => {
                            (ele.selectCheckbox as ICheckboxRepr).checked = false;
                        })
                    }
                    return newTableData;
                })
            }
        }
    }

    function tabCallback(tabName: string, index: number, prevIndex: number) {
        clearCheckbox(prevIndex);
        let shouldFetch = (tableData[index] == undefined);
        setCurrentTab(tabName);
        setCurrentTabIndex(index);
        if (shouldFetch) {
            let queryParams = { section: tabName };
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchUserListResponseRepr>;
            requestResponse.then((response) => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    newTableData[index] = response.data.data;
                    return newTableData;
                })
                setIsPasswordMgmt((prevIsPasswordMgmt) => {
                    let newIsPasswordManagement = [...prevIsPasswordMgmt];
                    newIsPasswordManagement[index] = response.data.show_password_mgmt;
                    return newIsPasswordManagement;
                })
            })
        }
    }

    function buttonCallback(buttonName: string) {
        let movingFrom: number = -1;
        let movingTo: number = -1;
        let queryParams: { section?: "active" | "inactive" | "archive" | "delete" } = {};
        let execute = false;
        if (buttonName == "Activate") {
            queryParams = { section: "active" };
            execute = true;
            movingFrom = 1;
            movingTo = 0;
        } else if (buttonName == "Inactivate") {
            queryParams = { section: "inactive" };
            if (currentTab == "active") {
                movingFrom = 0;
                movingTo = 1;
            } else {
                movingFrom = 2;
                movingTo = 1;
            }
            execute = true;
        } else if (buttonName == "Archive") {
            queryParams = { section: "archive" };
            movingFrom = 1;
            movingTo = 2;
            execute = true;
        } else if (buttonName == "Delete") {
            queryParams = { section: "delete" };
            movingFrom = 2;
            movingTo = -1;
            execute = true;
        } else if (buttonName == "Import") {
            $("#clientBulkAddModal").modal("show");
        } else {
            $("#clientAddModal").modal("show");
        }
        if (execute) {
            let formData = new FormData();
            formData.append("user_ids", JSON.stringify(selectedDataId));
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
            requestResponse.then(() => {
                clearCheckbox(currentTabIndex, false);
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    let currTable = newTableData[currentTabIndex];
                    if (currTable != undefined) {
                        let records = currTable.filter((ele) => {
                            return selectedDataId.includes(ele.id); 
                        })
                        newTableData = updateCache(newTableData, movingFrom, movingTo, records);
                    }
                    return newTableData;
                })
                clearCheckbox(currentTabIndex, true, false);
            })
        }
    }

    function inputCallback(rowIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (accessKey == "selectCheckbox" && typeof value == "boolean") {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                let currTable = newTableData[currentTabIndex];
                if (currTable != undefined) {
                    let rowObj = currTable[rowIndex];
                    (rowObj[accessKey] as ICheckboxRepr).checked = value;
                    setSelectedDataId((prevSelectedDataId) => {
                        let newSelectedDataId = [...prevSelectedDataId];
                        if (value) {
                            newSelectedDataId.push(rowObj.id);
                        } else {
                            newSelectedDataId.splice(newSelectedDataId.findIndex((ele) => {
                                return ele == rowObj.id;
                            }), 1);
                        }
                        return newSelectedDataId;
                    })
                }
                return newTableData;
            })
        } else if (accessKey == "actionButton" && typeof value == "string") {
            if (tableData != undefined && tableData[currentTabIndex] != undefined) {
                let queryParams = {
                    section: value,
                    user_id: (tableData[currentTabIndex] as IDataRepr[])[rowIndex].id
                }
                const requestResponse = post(ENDPOINT, new FormData(), cancelTokenSource.current.token, queryParams) as Promise<IPostPasswordManagement>;
                requestResponse.then((response) => {
                    setTableData((prevTableData) => {
                        let newTableData = cloneDeep(prevTableData);
                        if (newTableData != undefined && newTableData[currentTabIndex] != undefined) {
                            (newTableData[currentTabIndex] as IDataRepr[])[rowIndex] = response.data.data;
                        }
                        return newTableData;
                    })
                })
            }
        }
    }

    function clientAddCallback(data?: IDataRepr[], success?: string[], fail?: string[]) {
        if (data != undefined) {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                let activeTableData = newTableData[0];
                if (activeTableData != undefined) {
                    newTableData[0] = [...data, ...activeTableData];
                }
                return newTableData;
            })
        }
        if (success != undefined && fail != undefined) {
            setSuccess(success);
            setFail(fail);
        }
    }

    function getContent() {
        let columnName = ['First', 'Last', 'Email', 'Contact #', 'When Created', 'Last Login', 'Acquisition'];
        let accessKey = ['user_first_name', 'user_last_name', 'user_email', 'user_phone_num', 'user_create_time', 'user_last_login', 'user_referral_method'];
        if (isEdit) {
            columnName.unshift("");
            accessKey.unshift("selectCheckbox");
        }
        let link = {
            user_first_name: {
                baseUrl: urls.BASE_COMMON_CLIENT_DASHBOARD,
                urlParams: ['id'], id: ['user_email']
            }
        };
        let tabs = [{ target: "Active", active: true, display: "Active" },
        { target: "Inactive", active: false, display: "Inactive" },
        { target: "Archive", active: false, display: "Archive" }];
        let buttons = [{
            target: "Active", buttons: [{ buttonName: 'Inactivate' }, { buttonName: "Import"}
            ]
        },
        {
            target: "Inactive", buttons: [{ buttonName: 'Activate' },
            { buttonName: 'Archive' }]
        },
        {
            target: "Archive", buttons: [{ buttonName: 'Inactivate' },
            { buttonName: 'Delete' }]
        }];

        let columnNames = [columnName, columnName, columnName];
        let accessKeys = [accessKey, accessKey, accessKey];
        tabs.forEach((ele, index) => {
            if (isPasswordMgmt[index]) {
                columnNames[index] = [...columnNames[index], "Temp Password", "Action"];
                accessKeys[index] = [...accessKeys[index], "tempPassword", "actionButton"]
            }
        })
        let links = [link, link, link];
        let propObject: IContentBoxTabTableProps = {
            tabs: tabs,
            contents: tableData,
            columnNames: columnNames,
            accessKeys: accessKeys,
            title: "Client",
            hasSearch: true,
            hasDownload: true,
            hasRecords: true,
            links: links,
            inputCallback: inputCallback,
            tabCallback: tabCallback
        };
        if (isEdit) {
            propObject.buttons = buttons;
            propObject.buttonCallback = buttonCallback;
            propObject.miscHeaders = <AddButton buttonValue={<i className="fa fa-plus"></i>}
                onClick={() => { $('#clientAddModal').modal('show'); }} />;
        }
        return (<ContentBoxTabTable {...propObject} />)
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper clearfix">
                {getBundledAlertsUI(success, fail, setSuccess, setFail)}
                {getContent()}
                {(() => {
                    if (isEdit) {
                        return (
                            <React.Fragment>
                                <ModalClient mutator={clientAddCallback} />
                                <ModalClientBulk mutator={clientAddCallback} />
                            </React.Fragment>
                        )
                    }
                })()}
                <ScrollTop />
            </div>
        )
    }
}

export default AdminClientAction;
