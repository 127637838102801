import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from "axios";
import HelloSign from "hellosign-embedded";

import Button from "../../../intelws_portal/constructs/elements/Button";
import { post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";

import { IEsignSigners } from "../common/clientDashboard/ClientDocument";
import { HELLO_SIGN_CLIENT_ID } from "../../Urls"

interface IPostEsignRequest extends AxiosResponse {
    data: {
        claim_url: string
    }
}

export interface IModalRaiseEsignProps {
    defaultSigners: IEsignSigners[],
    userId: string,
    fileId: number
}

function ModalRaiseEsign(props: IModalRaiseEsignProps) {
    const ENDPOINT = "api/clientdashboard/";
    const [signers, setSigners] = React.useState(props.defaultSigners);

    const cancelTokenSource = React.useRef(getCancelToken());
    React.useEffect(() => {
        $("#raiseEsignModal").on("hidden.bs.modal", clearForm)
        return () => {
            $("#raiseEsignModal").off("hidden.bs.modal");
        }
    }, [props.defaultSigners])

    function clearForm() {
        setSigners(props.defaultSigners);
    }

    function formSubmit() {
        let queryParams = {
            section: "documents_raise_esign",
            user_id: props.userId
        }
        let formData = new FormData();
        formData.append("signers", JSON.stringify(signers));
        formData.append("file_id", props.fileId.toString());

        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostEsignRequest>;
        requestResponse.then((response) => {
            $("#raiseEsignModal").modal("hide");
            new HelloSign().open(response.data.claim_url, { clientId: HELLO_SIGN_CLIENT_ID});
        })
    }

    function formFieldChange(value: string, section: "name" | "email" | "add" | "delete", index?: number) {
        if ((section == "name" || section == "email") && index != undefined) {
            setSigners((prevSigners) => {
                let newSigners = [...prevSigners];
                if (section == "name") {
                    newSigners[index].name = value;
                } else {
                    newSigners[index].email_address = value;
                }
                return newSigners;
            })
        } else if (section == "add") {
            setSigners((prevSigners) => {
                let newSigners = [...prevSigners, {name: "", email_address: ""}];
                return newSigners;
            })
        } else if (section == "delete" && index != undefined) {
            setSigners((prevSigners) => {
                return prevSigners.filter((ele, eleIndex) => eleIndex !== index);
            })
        }
    }

    function getFormFields() {
        return (
            <div className="row">
                {signers.map((ele, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className="col-md-4 col-sm-12">
                                <ModalInputText title={"Name"} value={ele.name} 
                                    onChange={(e) => formFieldChange(e.target.value, "name", index)} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <ModalInputText title={"Email"} value={ele.email_address}
                                    onChange={(e) => formFieldChange(e.target.value, "email", index)} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <Button buttonValue={{"icon": "Delete"}} onClick={() => formFieldChange("", "delete", index)} />
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal custom-small text-center " id="raiseEsignModal" aria-labelledby="BusinessAddModal" aria-hidden="true">
                <div className="modal-dialog text-left modal-xl" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Enter Recipients"} />
                        <div className="modal-body">
                            {getFormFields()}
                            <ModalFooter saveCallback={formSubmit} saveInactive={false} 
                                buttonConfig={[{buttonName: "Add Recipient", inactive: false, callback: (e) => formFieldChange("", "add")}]} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return ReactDOM.createPortal(relativeRender(), document.getElementById("modal") as Element);
}

export default ModalRaiseEsign;