import React from "react";
import smallCircle from  "../../../../src/external/backPages/img/images/small-circle.png";

export interface IModalHeaderProps {
    title: string,
    miscHeader?: JSX.Element
}

function ModalHeader(props: IModalHeaderProps) {
        return (
            <div>
                <button data-dismiss="modal" className="modal-close"><i className="fa fa-close"></i>Close</button>
                <div className="modal-header" id="blueMain">
                    <div className="background-circle-animation">
                        <div className="bubbleBase">
                            <img src={smallCircle} alt="" className="circle-opacity" />
                        </div>

                        <div className="bubbleBase1">
                            <img src={smallCircle} alt="" className="circle-opacity circle2-wid120" />
                        </div>

                        <div className="bubbleBase2">
                            <img src={smallCircle} alt="" className="circle-opacity " />
                        </div>

                        <div className="bubbleBase3">
                            <img src={smallCircle} alt="" className="circle-opacity" />
                        </div>
                    </div>
                    <div className="modal-header-content">
                        <div className="left-search-text">
                            <p style={{fontWeight: 600}}> {props.title} </p>
                        </div>
                        {props.miscHeader}
                    </div>
                </div>
            </div>
        )
}

export default ModalHeader;