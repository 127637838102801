import React from "react";

import Table, { IDataRepr, ILinksRepr, ITableProps } from '../constructs/elements/Table';
import ContentBox from '../constructs/elements/ContentBox';
import Tabs, { IContentRepr, IHeaderRepr, ITabRepr } from '../constructs/elements/Tabs';
import Loading from '../constructs/elements/Loading';
import SearchInput from '../constructs/elements/SearchInput';
import Button from '../constructs/elements/Button';
import ModalDropDown from "../constructs/modal/ModalDropDown";

export interface IContentBoxTabTableButtonRepr {
    buttonName: string
}

export interface IContentBoxTabTableButtonPropRepr {
    target: string,
    buttons: IContentBoxTabTableButtonRepr[]
}

export interface IContentBoxTabTableDropdownRepr {
    value: number | string,
    options: string[],
    values?: number[] | string[],
    callback: (value: string) => void
}

export interface IContentBoxTabTableDropdownPropRepr {
    target: string,
    dropdowns: IContentBoxTabTableDropdownRepr[]
}

export interface IContentBoxTabTableProps {
    hasSearch: boolean,
    title: string,
    tabs: ITabRepr[],
    columnNames: string[][],
    accessKeys: string[][],
    contents: (IDataRepr[] | undefined)[],
    buttons?: IContentBoxTabTableButtonPropRepr[],
    dropdowns?: IContentBoxTabTableDropdownPropRepr[]
    miscHeaders?: JSX.Element,
    hasRecords?: boolean,
    hasDownload?: boolean,
    links?: ILinksRepr[],
    inputCallback?: (absoluteIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) => void,
    tabCallback?: (tabName: string, index: number, prevIndex: number) => void,
    buttonCallback?: (buttonName: string) => void
}


function ContentBoxTabTable(props: IContentBoxTabTableProps) {
    const [searchString, setSearchString] = React.useState("");
    const [currTabIndex, setCurrTabIndex] = React.useState(() => {
        return props.tabs.findIndex((ele) => {
            return ele.active;
        })
    });

    const tableCountRef = React.useRef(null);
    
    function buildContent() {
        let tables: IContentRepr[] = [];
        props.contents.forEach((ele, index) => {
            if (ele == undefined) {
                tables.push({content: <Loading />});
            } else {
                let tableParams: ITableProps = {
                    data: ele,
                    columnNames: props.columnNames[index],
                    accessKeys: props.accessKeys[index],
                    hasDownload: props.hasDownload,
                    searchString: searchString,
                    showRecords: false,
                    inputCallback: props.inputCallback
                }
                if (props.links != undefined && props.links[index] != undefined) {
                    tableParams.links = props.links[index];
                }

                if ((currTabIndex == index) && (props.hasRecords != undefined && props.hasRecords)) {
                    tableParams.recordsRef = tableCountRef;
                    tableParams.showRecords = true;
                }
                tables.push({content: <Table {...tableParams} />})
            }
        })
        return tables;
    }

    function buttonCallback(buttonName: string) {
        if (props.buttonCallback != undefined) {
            props.buttonCallback(buttonName);
        }
    }

    function jsxButtons(buttonArray: IContentBoxTabTableButtonRepr[]) {
        return buttonArray.map((ele, index) => {
            return <Button style={{fontSize: 12}} key={index} onClick={() => buttonCallback(ele.buttonName)} 
                buttonValue={ele.buttonName} />
        })
    }

    function jsxDropdowns(dropdownArray: IContentBoxTabTableDropdownRepr[]) {
        return dropdownArray.map((ele, index) => {
            return (
                <div key={index} className="rightFlex mt-1">
                    <ModalDropDown flag={1} value={ele.value} onChange={(e) => ele.callback(e.target.value)}
                        options={ele.options} values={ele.values} borderBottom={false} />
                </div>
            )
        })
    }

    function buildButtons() {
        let returnArr: IHeaderRepr[] = [];
        if (props.buttons != undefined) {
            returnArr = [...returnArr, ...props.buttons.map((ele) => {
                return {target: ele.target, content: jsxButtons(ele.buttons)}
            })];
        } else if (props.dropdowns != undefined) {
            returnArr = [...returnArr, ...props.dropdowns.map((ele) => {
                return {target: ele.target, content: jsxDropdowns(ele.dropdowns)}
            })]
        }
        return returnArr;
    }
    
    function tabCallback(tabName: string, index: number) {
        if (props.tabCallback != undefined) {
            props.tabCallback(tabName, index, currTabIndex);
        }
        setCurrTabIndex(index);
    }

    function getHeader() {
        if (props.hasSearch) {
            return (
                <div className="heading-box">
                    <h6 className="col-sm-6 f-left">{props.title}
                        {props.miscHeaders}
                    </h6>
                    <div className=" col-sm-6 rightFlex pr-0" ref={tableCountRef}>
                        <div className="ml-20">
                            <div className="Searchbg">
                                <SearchInput searchString={searchString}
                                    onChange={(e) => setSearchString(e.target.value)} />
                            </div>
                        </div>
                        {/* JQUERY Handled UI start */}
                        <div className="ml-20">
                            <span className="badge-danger bg-primary badge-cpasage-count">
                                <span id="tableCount"></span>
                            </span>
                        </div>
                        {/* JQUERY Handled UI end */}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="heading-box">
                    <h6 className="col-sm-12"> {props.title} {props.miscHeaders} </h6>
                </div>
            )
        }
    }
    
    function getBody() {
        return <Tabs tabs={props.tabs} contents={buildContent()}
            miscHeaders={buildButtons()} tabCallback={tabCallback} />
    }

    return <ContentBox header={getHeader()} body={getBody()} />

}

export default ContentBoxTabTable;