import React from 'react';

export interface IChartTableProps {
    data: number[][] | string[][],
    sideHeading: string[],
    topHeading?: string[] | undefined,
    chartTableCallback?: (rowIndex: number, colIndex: number, sideHeading: string[], topHeading: string[] | undefined) => void
}

function ChartTable(props: IChartTableProps) {
    function getTableHeader() {
        if (props.topHeading != undefined) {
            return (
                <thead>
                    <tr>
                        <th></th>
                        {props.topHeading.map((ele) =>
                            <th key={ele}>{ele}</th>
                        )}
                    </tr>
                </thead>
            )
        }
    }

    function chartTableCallback(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        rowIndex: number, colIndex: number, sideHeading: string[], topHeading: string[] | undefined)
    {
        if (props.chartTableCallback != undefined) {
            props.chartTableCallback(rowIndex, colIndex, sideHeading, topHeading);
        }
    }

    function getTableBody() {
        return (
            <tbody>
                {props.data.map((rowEle: number[] | string[], rowIndex: number) =>
                    <tr key={props.sideHeading[rowIndex]}>
                        <td key={0}> {props.sideHeading[rowIndex]}</td>
                        {rowEle.map((colEle: number | string, colIndex: number) =>
                            <td key={colIndex + 1}>
                                <a onClick={(e) => chartTableCallback(e, rowIndex, colIndex, props.sideHeading, props.topHeading)} href="#">{colEle}</a>
                            </td>
                        )}
                    </tr>
                )}
            </tbody>
        )
    }

    return (
        <table className="table table-hover" >
            {getTableHeader()}
            {getTableBody()}
        </table>

    )
}

export default ChartTable;