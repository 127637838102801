import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";

import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalDatePicker from "../../../intelws_portal/constructs/modal/ModalDatePicker";
import { usePickPropsIfNotNull } from "../../../declarations/common_utils";
import { getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";
import ModalCommetText from "../../../intelws_portal/constructs/modal/ModalCommentText";

export interface IModalLeadProps {
    saveCallback: (date: string) => void,
    leadId: number,
    currNotes: string
}

function ModalLeadNotes(props: IModalLeadProps) {
    const ENDPOINT = "/api/leads/";
    const [leadId, setLeadId] = usePickPropsIfNotNull(props.leadId, -1);
    const [notes, setNotes] = usePickPropsIfNotNull(props.currNotes, "");
    const [disable, setDisable] = React.useState(true);
    
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        $("#leadNotesModal").on("hidden.bs.modal", clearForm);
        return () => {
            $("#leadNotesModal").off("hidden.bs.modal");
        }
    }, [])

    React.useEffect(() => {
        let newDisable = true;
        if (notes != "") {
            newDisable = false;
        }
        setDisable(newDisable);
    }, [notes]);

    function clearForm() {
        setNotes("");
    }

    function submitForm() {
        let formData = new FormData();
        formData.append("lead_id", leadId.toString());
        formData.append("lead_notes", notes);
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, {section: "update_lead_notes"});
        requestResponse.then(() => {
            props.saveCallback(notes);
        }).finally(() => {
            $("#leadNotesModal").modal("hide");
        })
    }
    
    function getModalContent() {
        return (
            <React.Fragment>
                <ModalHeader title="Lead Follow Up Date" />
                <div className="modal-body">
                    <ModalCommetText title={"Edit Lead Notes"} placeholder={"Edit Lead Notes"} 
                        value={notes} onChange={(e) => setNotes(e.target.value)} />
                </div>
                <ModalFooter saveCallback={submitForm} saveInactive={false} disable={disable}/>
            </React.Fragment>
        )
    }
    function relativeRender() {
        return <div className="modal fade custom-modal text-center" id="leadNotesModal" tabIndex={-1}>
                <div className="modal-dialog text-left modal-lg">
                    <div className="modal-content">
                        {getModalContent()}
                    </div>
                </div>
            </div>
    }
    
    return ReactDOM.createPortal(relativeRender(), document.getElementById("modal") as Element);
}

export default ModalLeadNotes;