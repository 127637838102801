import React, { Component } from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from 'axios';

import {post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalDatePicker from "../../../intelws_portal/constructs/modal/ModalDatePicker";
import ModalCommetText from "../../../intelws_portal/constructs/modal/ModalCommentText";

import { ITodoListRepr } from "../common/clientDashboard/Dashboard/TodoList";

const CLIENT_DASHBOARD_ENDPOINT = "api/clientdashboard/";

export interface IModalTodoProps {
    userId: number,
    mutator: (data: ITodoListRepr) => void,
}

export interface IModalFormDataRepr {
    dueDate: string,
    task: string
}

export interface IFetchTodoListAdd extends AxiosResponse {
    data: {
        data: ITodoListRepr
    }
}

function ModalTodo(props: IModalTodoProps) {
    const [dueDate, setDueDate] = React.useState("");
    const [task, setTask] = React.useState("");
    const cancelTokenSource = React.useRef(getCancelToken());
    const [disable, setDisable] = React.useState(true);
    const [istaskStartwithspace, setistaskStartwithspace] = React.useState("");
   
    function isstartwithspace(value: string, section: "task"){
        if(value == " " ){
            if(section == "task"){
                setistaskStartwithspace("please start task details with alphanumeric value ")
            }
            return(false)
        }
        else{
            setistaskStartwithspace("")
            return(true)
        }
    }
    React.useEffect(() => {
        $("#todoAddModal").on("hidden.bs.modal", clearForm)
        return () => {
            $("#todoAddModal").off("hidden.bs.modal");
        }
    })

    React.useEffect(() => {
        let newDisable = true;
        if (dueDate != "" && task != "") {
            newDisable = false;
        }
        setDisable(newDisable);
    }, [dueDate, task]);
 
    function clearForm() {
        setDueDate("");
        setTask("");
    }
    function formChange(field: "dueDate" | "task", value: string) {
        if (field == "dueDate") {
            setDueDate(value);
        } else if (field == "task" && isstartwithspace(value, field)
        ){
            setTask(value);
        }
    }

    function formSubmit() {
        let queryParams = { section: "todo", user_id: props.userId };
        let formData = new FormData();
        formData.append("duedate", dueDate);
        formData.append("task", task);
        const requestResponse = post(CLIENT_DASHBOARD_ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IFetchTodoListAdd>;
        requestResponse.then((response) => {
            props.mutator(response.data.data);
            $('#todoAddModal').modal('hide');
        }).catch(() => {
            $('#todoAddModal').modal('hide');
        })
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="todoAddModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Add To Do"} />
                        <div className="modal-body">
                            <ModalDatePicker title={"Select Date"} value={dueDate}
                                onChange={(e) => formChange("dueDate", e.target.value)} required/><br /><br />
                            <ModalCommetText placeholder={"Enter a task here...."} title={"Task"} isinvalidmsg={istaskStartwithspace}
                                onChange={(e) => formChange("task", e.target.value)} value={task} required/>
                        </div>
                        <ModalFooter saveCallback={formSubmit} saveInactive={false} disable={disable}/>
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalTodo;
