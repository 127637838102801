import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from "axios";

import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { get, post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalDropDown from "../../../intelws_portal/constructs/modal/ModalDropDown";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import ModalMultiDropDown from "../../../intelws_portal/constructs/modal/ModalMultiDropDown";

import { isStringEmpty, parseQueryResponseOnFail, parseQueryResponseOnSuccess } from "../../../declarations/common_utils"

interface IBusinessRepr extends IDataRepr {
    id: number,
    bns_name: string
}

interface IFetchConfig extends AxiosResponse {
    data: {
        emps?: {
            id: number,
            emp_full_name: string
        }[],
        bns_data: IBusinessRepr[],
        freq_options?: string[],
        freq_values?: number[],
        months_options?: string[],
        months_values?: number[],
        days_values?: number[],
        days_options?: string[]
    }
}

interface IPostAddTask extends AxiosResponse {
    data: {
        data: IDataRepr,
        section: string
    }
}

export interface IModalManualTaskProps {
    userId: string,
    bnsValue: number[],
    bnsOptions: string[],
    bnsDocCallback: (data: IDataRepr[]) => void,
    mutator: (data?: IDataRepr, section?: string, success?: string[], fail?: string[]) => void
}

function ModalManualTask(props: IModalManualTaskProps) {
    const ENDPOINT = "/api/manualtasklist/";
    const [teamValues, setTeamValues] = React.useState<number[] | undefined>([]);
    const [teamDisplay, setTeamDisplay] = React.useState<string[] | undefined>([]);
    const [freqValues, setFreqValues] = React.useState<number[] | undefined>([]);
    const [freqOptions, setFreqOptions] = React.useState<string[] | undefined>([]);
    const [monthValues, setMonthValues] = React.useState<number[] | undefined>([]);
    const [monthOptions, setMonthOptions] = React.useState<string[] | undefined>([]);
    const [daysValues, setDaysValues] = React.useState<number[] | undefined>([]);
    const [daysOptions, setDaysOptions] = React.useState<string[] | undefined>([]);

    const [taskName, setTaskName] = React.useState("");
    const [businessValue, setBusinessValue] = React.useState(-1);
    const [teamValue, setTeamValue] = React.useState<number[]>([]);
    const [serviceValue, setServiceValue] = React.useState(-1);

    const [isRecurring, setIsRecurring] = React.useState(false);
    const [freqValue, setFreqValue] = React.useState(-1);
    const [monthValue, setMonthValue] = React.useState(-1);
    const [daysValue, setDaysValue] = React.useState(-1);

    const cancelTokenSource = React.useRef(getCancelToken());
    const [disable, setDisable] = React.useState(false);
    const [isprojectnameStartwithspace, setisprojectnameStartwithspace] = React.useState("");
    
    function isstartwithspace(value: string, section: "taskName"){
        if(value == " " ){
            if(section == "taskName"){
                setisprojectnameStartwithspace("please start task name with alphanumeric value ")
            }
            return(false)
        }
        else{
            setisprojectnameStartwithspace("")
            return(true)
        }
    }
    let serviceOptions = ["Misc", "Notice"];
    let serviceValues = [-1, 5];
    if (businessValue > -1) {
        serviceOptions = [...serviceOptions, "Book-Keeping", "Payroll", "Sales Tax", "Tax Planning"];
        serviceValues = [...serviceValues, 1, 2, 3, 4];

    }

    React.useEffect(() => {
        let queryParams = {
            section: "manual_task_add_config",
            user_id: props.userId
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchConfig>;
        requestResponse.then((response) => {
            let teamValues: number[] = [];
            let teamDisplay: string[] = [];
            if (response.data.emps != undefined) {
                response.data.emps.forEach((ele) => {
                    teamValues.push(ele.id);
                    teamDisplay.push(ele.emp_full_name);
                })
                setTeamValues(teamValues);
                setTeamDisplay(teamDisplay);
            } else {
                setTeamValues(undefined);
                setTeamDisplay(undefined);
            }
            if (teamValues.length == 1) {
                setTeamValue(teamValues);
            }
            setFreqOptions(response.data.freq_options);
            setFreqValues(response.data.freq_values);
            if (response.data.freq_values != undefined) {
                setFreqValue(response.data.freq_values[0]);
            }
            setMonthValues(response.data.months_values);
            setMonthOptions(response.data.months_options);
            setDaysValues(response.data.days_values);
            setDaysOptions(response.data.days_options);
            if (response.data.months_values != undefined) {
                setMonthValue(response.data.months_values[0]);
            }
            if (response.data.days_values != undefined) {
                setDaysValue(response.data.days_values[0]);
            }
            props.bnsDocCallback(response.data.bns_data);
        })
        $("#manualTaskAddModal").on("hidden.bs.modal", clearForm)
        return () => {
            $("#manualTaskAddModal").off("hidden.bs.modal");
            cancelTokenSource.current.cancel();
        }
    }, [])

    React.useEffect(() => {
        let newDisable = true;
        if (freqValid() && taskName != "" ) {
            newDisable = false;
        }
        setDisable(newDisable);
    }, [isRecurring, freqValue, monthValue, daysValue, taskName, businessValue])

    function freqValid() {
        if (isRecurring) {
            if (freqValue != -1) {
                if ((freqValue == 1 && daysValue != -1) || (freqValue != 1 && daysValue != -1 && monthValue != -1)) {
                    return true;
                }
            }
            return false;
        }
        else {
            return true;
        }
    }
   
    function clearForm() {
        setTaskName("");
        setTeamValue([]);
        setIsRecurring(false);
        setFreqValue(-1);
        setMonthValue(-1);
        setDaysValue(-1);
        setBusinessValue(-1);
    }

    function formChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, section: "taskName" | "client" | "team" | "business" | "recurring" | "freq" | "service") {
        if (section == "taskName" && isstartwithspace(e.target.value, section)) {
            setTaskName(e.target.value);
        } else if (section == "client") {
            setBusinessValue(-1);
        } else if (section == "business") {
            setBusinessValue(parseInt(e.target.value));
        } else if (section == "team") {
            setTeamValue(Array.from((e as React.ChangeEvent<HTMLSelectElement>).target.selectedOptions, options => parseInt(options.value)));
        } else if (section == "recurring") {
            if (parseInt(e.target.value) == 0) {
                setIsRecurring(false);
            } else {
                setIsRecurring(true);
            }
            setFreqValue(-1);
            setMonthValue(-1);
            setDaysValue(-1);
            setServiceValue(-1);
        } else if (section == "freq") {
            setFreqValue(parseInt(e.target.value));
            setMonthValue(-1);
            setDaysValue(-1);
        } else if (section == "service") {
            setServiceValue(parseInt(e.target.value));
        }
    }

    function submitForm() {
        let formData = new FormData();
        if (!isStringEmpty(taskName)) {
            formData.append("task_name", taskName);
        }

        if (props.userId != undefined) {
            formData.append("user_id", props.userId.toString());
        }
        formData.append("service", serviceValue.toString());

        if (businessValue > -1) {
            formData.append("bns_id", businessValue.toString());
        }

        if (teamValue.length > 0) {
            formData.append("emp_assigned", JSON.stringify(teamValue));
        }

        if (isRecurring) {
            if (freqValue != -1) {
                formData.append("freq_num", freqValue.toString());
            }
            if (monthValue != -1) {
                formData.append("month_num", monthValue.toString());
            }
            if (daysValue != -1) {
                formData.append("day_num", daysValue.toString());
            }
        }
        let queryParams = {
            section: "add_manual_task_list"
        }
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostAddTask>;
        requestResponse.then((response) => {
            let [success, fail] = parseQueryResponseOnSuccess(response);
            props.mutator(response.data.data, response.data.section, success, fail);
        }).catch((error) => {
            let [success, fail] = parseQueryResponseOnFail(error);
            props.mutator(undefined, undefined, success, fail);
        })
        .finally(() => {
            $("#manualTaskAddModal").modal("hide");
        })
    }

    function getBusinessDropdown() {
        let options = ["Personal"].concat(props.bnsOptions.slice(1));
        let values = [-1].concat(props.bnsValue.slice(1));
        if (options.length > 1) {
            return (
                <React.Fragment>
                    <ModalDropDown options={options} values={values} value={businessValue}
                        onChange={(e) => formChange(e, "business")} borderBottom/><br></br>
                </React.Fragment>
            )
        }
    }

    function getFormTypeDropdown() {
        if (freqOptions != undefined && freqValues != undefined && daysOptions != undefined && daysValues != undefined && monthOptions != undefined && monthValues != undefined) {
            let value = 0;
            if (isRecurring) {
                value = 1;
            }
            return (
                <React.Fragment>
                    <ModalDropDown options={["Project", "Project Template"]} values={[0, 1]} value={value}
                        onChange={(e) => formChange(e, "recurring")} borderBottom /> <br></br>
                </React.Fragment>
            )
        }
    }

    function getServiceDropdown() {
        if (!isRecurring) {
            return (
                <ModalDropDown options={serviceOptions} values={serviceValues} value={serviceValue}
                    onChange={(e) => formChange(e, "service")} borderBottom />
            )
        }
    }

    function getRecurringFormElements() {
        if (!isRecurring) {
            return undefined;
        }
        if (freqOptions != undefined && freqValues != undefined && daysOptions != undefined && daysValues != undefined && monthOptions != undefined && monthValues != undefined) {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <ModalDropDown options={freqOptions} values={freqValues} value={freqValue}
                                onChange={(e) => formChange(e, "freq")} borderBottom required />
                        </div>
                        {(() => {
                            if (freqValue == 1) {
                                // Monthly
                                return (
                                    <React.Fragment>
                                        <div className="col-md-4 col-sm-12">
                                            <ModalDropDown options={daysOptions} values={daysValues} value={daysValue}
                                                onChange={(e) => setDaysValue(parseInt(e.target.value))} borderBottom required />
                                        </div>
                                    </React.Fragment>
                                )
                            } else if (freqValue == 2 || freqValue == 3) {
                                // Yearly/Quarterly
                                return (
                                    <React.Fragment>
                                        <div className="col-md-4 col-sm-12">
                                            <ModalDropDown options={monthOptions} values={monthValues} value={monthValue}
                                                onChange={(e) => setMonthValue(parseInt(e.target.value))} borderBottom required />
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <ModalDropDown options={daysOptions} values={daysValues} value={daysValue}
                                                onChange={(e) => setDaysValue(parseInt(e.target.value))} borderBottom required />
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        })()}
                    </div>
                </React.Fragment>
            )
        }
    }

    function getTeamDropdown() {
        if (teamValues != undefined && teamDisplay != undefined) {
            if (teamValues.length > 1) {
                return (
                    <React.Fragment>
                        <ModalMultiDropDown options={teamDisplay} value={teamValue}
                            values={teamValues} onChange={(e) => formChange(e, "team")} borderBottom /><br></br>
                    </React.Fragment>
                )
            }
        }
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="manualTaskAddModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Create Project"} />
                        <div className="modal-body">
                            {getFormTypeDropdown()}
                            <ModalInputText title={"Project Name"} value={taskName} onChange={(e) => formChange(e, "taskName")} isinvalidmsg={isprojectnameStartwithspace} borderBottom required/><br></br>
                            {getBusinessDropdown()}
                            {getTeamDropdown()}
                            {getServiceDropdown()}
                            {getRecurringFormElements()}
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={false} disable={disable}/>
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalManualTask;