import React from "react";

import Table, { IDataRepr, ILinksRepr, ITableProps } from '../constructs/elements/Table';
import ContentBox from '../constructs/elements/ContentBox';
import Loading from '../constructs/elements/Loading';
import SearchInput from '../constructs/elements/SearchInput';

export interface IContextBoxTableProps {
    title: string | JSX.Element,
    hasSearch: boolean,
    columnNames: string[],
    accessKeys: string[],
    tableData?: IDataRepr[],
    links?: ILinksRepr,
    hasRecords?: boolean,
    hasDownload?: boolean,
    miscHeaders?: JSX.Element,
    inputCallback?: (absoluteIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) => void
}

function ContentBoxTable(props: IContextBoxTableProps) {
    const [searchString, setSearchString] = React.useState("");

    const tableCountRef = React.useRef(null);

    function getHeader() {
        if (props.hasSearch) {
            return (
                <div className="heading-box">
                    <h6 className="col-sm-6 f-left">{props.title}
                        {props.miscHeaders}
                    </h6>
                    <div className=" col-sm-6 rightFlex pr-0" ref={tableCountRef}>
                        <div className="ml-20">

                        </div>
                        <div className="ml-20">
                            <div className="Searchbg">
                                <SearchInput searchString={searchString}
                                    onChange={(e) => setSearchString(e.target.value)} />
                            </div>
                        </div>
                        {/* JQUERY Handled UI start */}
                        <div className="ml-20">
                            <span className="badge-danger bg-primary badge-cpasage-count">
                                <span id="tableCount"></span>
                            </span>
                        </div>
                        {/* JQUERY Handled UI end */}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="heading-box">
                    <h6 className="col-sm-6 f-left">{props.title}
                        {props.miscHeaders}
                    </h6>
                </div>
            )
        }
    }

    function getBody() {
        if (props.tableData != undefined) {
            let tableParams: ITableProps = {
                data: props.tableData,
                columnNames: props.columnNames,
                accessKeys: props.accessKeys,
                hasDownload: props.hasDownload,
                searchString: searchString,
                showRecords: false,
                inputCallback: props.inputCallback
            };
            if (props.hasRecords != undefined && props.hasRecords == true) {
                tableParams.recordsRef = tableCountRef;
                tableParams.showRecords = true;
            }
            if (props.links != undefined) {
                tableParams.links = props.links;
            }

            return <Table {...tableParams} />
        } else {
            return <Loading />
        }
    }

    return (
        <div>
            <ContentBox header={getHeader()} />
            <div className="col-sm-12 tabSec">
                <ContentBox body={getBody()} />
            </div>
        </div>
    )
}

export default ContentBoxTable