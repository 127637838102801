import { AxiosResponse } from "axios";
import React from "react";
import $ from "jquery";
import { cloneDeep } from "lodash";

import { IContentBoxTabTableProps } from "../../../../intelws_portal/bundles/ContentBoxTabTable";
import ContentBoxTabTable from "../../../../intelws_portal/bundles/ContentBoxTabTable";
import AddButton from "../../../../intelws_portal/constructs/elements/AddButton";
import Loading from "../../../../intelws_portal/constructs/elements/Loading";
import { IDataRepr } from "../../../../intelws_portal/constructs/elements/Table";
import { get, getCancelToken, post } from "../../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../../declarations/common";
import { getBundledAlertsUI } from "../../../../declarations/common_utils";
import ModalInvoice from "../../modal/ModalInvoice";
import PayInvoice from "../../client/PayInvoice";
import Button from "../../../../intelws_portal/constructs/elements/Button";
import ManagePaymentMethods from "../../client/ManagePaymentMethods";

interface IMatchProps {
    userId: string
}

export type TPaymentMethods = "card" | "ach_debit";

export interface IPaymentMethodsRepr {
    card: boolean,
    ach_debit: boolean
}

interface IFetchInvoice extends AxiosResponse {
    data: {
        data: IDataRepr[],
        allow_add: boolean,
        is_user: boolean,
        default_payment_methods: IPaymentMethodsRepr
    }
}

interface IFetchInvoiceDetails extends AxiosResponse {
    data: {
        data: {
            id: number,
            invoice_date: string,
            invoice_due_date: string,
            invoice_sales_tax: string,
            invoice_notes: string,
            invoice_payment_notes: string,
            invoice_payment_method: string,
            invoice_allow_edit: boolean,
            invoice_is_paid: boolean,
            description: string[],
            qty: number[],
            price: number[],
            total_price: number[],
            payment_methods: IPaymentMethodsRepr
        }
    }
}

interface IPostInvoiceMarkPaid extends AxiosResponse {
    data: {
        data: IDataRepr
    }
}

function ClientInvoice(props: IPageComponentProps<IMatchProps>) {
    const ENDPOINT = "api/clientdashboard/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isAddAllow, setIsAddAllow] = React.useState(false);
    const [isUser, setIsUser] = React.useState(false);
    const [defaultPaymentMethods, setDefaultPaymentMethods] = React.useState<IPaymentMethodsRepr>();
    const [currTab, setCurrTab] = React.useState(0);
    const [tableData, setTableData] = React.useState<(IDataRepr[] | undefined)[]>([undefined, undefined]);

    const [payInvoiceId, setPayInvoiceId] = React.useState(-1);

    const [invoiceDate, setInvoiceDate] = React.useState<string | undefined>(undefined);
    const [invoiceDueDate, setInvoiceDueDate] = React.useState<string | undefined>(undefined);
    const [invoiceTitle, setInvoiceTitle] = React.useState<string | undefined>(undefined);
    const [invoiceId, setInvoiceId] = React.useState<number | undefined>(undefined);
    const [notes, setNotes] = React.useState<string | undefined>(undefined);
    const [paymentNotes, setPaymentNotes] = React.useState<string | undefined>(undefined);
    const [paymentMethod, setPaymentMethod] = React.useState<string | undefined>(undefined);
    const [paymentMethods, setPaymentMethods] = React.useState<IPaymentMethodsRepr | undefined>(undefined);
    const [allowEdit, setAllowEdit] = React.useState<boolean | undefined>(undefined);
    const [isPaid, setIsPaid] = React.useState<boolean | undefined>(undefined);
    const [salesTax, setSalesTax] = React.useState<number | undefined>(undefined);
    const [description, setDescription] = React.useState<string[] | undefined>(undefined);
    const [qty, setQty] = React.useState<number[] | undefined>(undefined);
    const [price, setPrice] = React.useState<number[] | undefined>(undefined);
    const [totalPrice, setTotalPrice] = React.useState<number[] | undefined>(undefined);
    const [isEdit, setIsEdit] = React.useState<boolean | undefined>(undefined);
    const [isAddView, setIsAddView] = React.useState(true);
    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ClientInvoice");
        }
        let queryParams = {
            section: "invoice_fetch",
            type: "open",
            user_id: props.match.params.userId
        }
        const responseRequest = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchInvoice>;
        responseRequest.then((response) => {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                newTableData[0] = [...response.data.data];
                return newTableData;
            })
            setIsAddAllow(response.data.allow_add);
            setIsUser(response.data.is_user);
            setIsLoaded(true);
            setDefaultPaymentMethods(response.data.default_payment_methods);
        })
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    function modalOnClose() {
        setInvoiceDate(undefined);
        setInvoiceDueDate(undefined);
        setDescription(undefined);
        setInvoiceId(undefined);
        setNotes(undefined);
        setPaymentNotes(undefined);
        setPaymentMethod(undefined);
        setPaymentMethods(undefined);
        setAllowEdit(undefined);
        setIsPaid(undefined);
        setSalesTax(undefined);
        setQty(undefined);
        setPrice(undefined);
        setTotalPrice(undefined);
        setInvoiceTitle(undefined);
        setIsEdit(undefined);
        setIsAddView(true);
    }

    function tabCallback(tabName: string, index: number, prevIndex: number) {
        setCurrTab(index);
        let queryParams = {
            section: "invoice_fetch",
            type: tabName,
            user_id: props.match.params.userId
        }
        if (tableData[index] == undefined) {
            const responseRequest = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchInvoice>;
            responseRequest.then((response) => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    newTableData[index] = [...response.data.data];
                    return newTableData;
                })
            })
        }
    }

    function updateTable(data?: IDataRepr, transferToPaid?: boolean, success?: string[], fail?: string[]) {
        if (data != undefined && transferToPaid != undefined) {
            setTableData((prevTableData) => {
                let newTableData = cloneDeep(prevTableData);
                let indexData = {
                    topLevelIndex: -1,
                    secondLevelIndex: -1
                }
                newTableData.forEach((ele, index) => {
                    if (ele != undefined) {
                        let secondLevelIndex = ele.findIndex((ele) => ele.id == data.id);
                        if (secondLevelIndex != -1) {
                            indexData.topLevelIndex = index;
                            indexData.secondLevelIndex = secondLevelIndex;
                        }
                    }
                })
                if (indexData.topLevelIndex != -1 && indexData.secondLevelIndex != -1) {
                    if (transferToPaid) {
                        let popTable = newTableData[indexData.topLevelIndex];
                        if (popTable != undefined) {
                            popTable.splice(indexData.secondLevelIndex, 1);
                        }
                        let currTable = newTableData[1];
                        if (currTable != undefined) {
                            newTableData[1] = [...currTable, data];
                        }
                    } else {
                        let currTable = newTableData[indexData.topLevelIndex];
                        if (currTable != undefined) {
                            currTable[indexData.secondLevelIndex] = data;
                        }
                    }
                } else {
                    let currTable = newTableData[0];
                    if (currTable != undefined) {
                        newTableData[0] = [...currTable, data];
                    }
                }
                return newTableData;
            })
        }
        if (success != undefined && fail != undefined) {
            setSuccess(success);
            setFail(fail);
        }
    }

    function inputCallback(index: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (typeof value == "string" && value == "markPaid") {
            let formData = new FormData();
            let currTable = tableData[0];
            if (currTable != undefined) {
                formData.append("invoice_id", currTable[index].id.toString());
            }
            let queryParams = {
                section: "invoice_mark_paid",
                user_id: props.match.params.userId
            }
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostInvoiceMarkPaid>;
            requestResponse.then((response) => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    newTableData[0] = newTableData[0]?.filter((ele, loopIndex) => index !== loopIndex);
                    let addTable = newTableData[1];
                    if (addTable != undefined) {
                        newTableData[1] = [...addTable, response.data.data];
                    }
                    return newTableData;
                })
            })
        } else if (typeof value == "string" && (value == "viewDetails")) {
            let currTable = tableData[currTab];
            if (currTable != undefined) {
                let queryParams = {
                    section: "invoice_details_fetch",
                    user_id: props.match.params.userId,
                    invoice_id: currTable[index].id.toString()
                }
                const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchInvoiceDetails>;
                requestResponse.then((response) => {
                    setInvoiceDate(response.data.data.invoice_date);
                    setInvoiceDueDate(response.data.data.invoice_due_date);
                    setDescription(response.data.data.description);
                    setQty(response.data.data.qty);
                    setPrice(response.data.data.price);
                    setTotalPrice(response.data.data.total_price);
                    setInvoiceTitle("Invoice #" + queryParams.invoice_id);
                    setIsEdit(false);
                    setIsAddView(false);
                    setInvoiceId(parseInt(queryParams.invoice_id));
                    setSalesTax(parseFloat(response.data.data.invoice_sales_tax));
                    setNotes(response.data.data.invoice_notes);
                    setPaymentNotes(response.data.data.invoice_payment_notes);
                    setPaymentMethod(response.data.data.invoice_payment_method);
                    setPaymentMethods(response.data.data.payment_methods);
                    setAllowEdit(response.data.data.invoice_allow_edit);
                    setIsPaid(response.data.data.invoice_is_paid);
                    $('#invoiceModal').modal('show');
                })
            }
        } else if (typeof value == "string" && (value == "payInvoice")) {
            let currTable = tableData[currTab];
            if (currTable != undefined) {
                setPayInvoiceId(currTable[index].id);
            }
        }
    }

    function getContent() {
        let columnName = ['Invoice Number', 'When Created', 'Due On', 'Total Invoice Amount', 'Action'];
        let accessKey = ['invoice_number', 'iv_raised', 'iv_due_date', 'iv_grand_total', 'actionButton'];
        let tabs = [{ target: "open", active: true, display: "Payment Due" },
                    { target: "closed", active: false, display: "Payment Processed" }];
        let columnNames = [columnName, columnName, columnName];
        let accessKeys = [accessKey, accessKey, accessKey];
        let propObject: IContentBoxTabTableProps = {
            tabs: tabs,
            contents: tableData,
            columnNames: columnNames,
            accessKeys: accessKeys,
            title: "Invoice",
            hasSearch: true,
            hasRecords: true,
            tabCallback: tabCallback
        };
        if (isAddAllow) {
            propObject.miscHeaders = <AddButton buttonValue={<i className="fa fa-plus"></i>}
                onClick={() => { $('#invoiceModal').modal('show'); }} />;
        } else if (isUser) {
            propObject.miscHeaders = <Button buttonValue={"Manage Payment Methods"} onClick={() => $('#managePaymentMethodModal').modal('show')} />
        }
        propObject.inputCallback = inputCallback;
        return (<ContentBoxTabTable {...propObject} />)
    }

    function payInvoiceOnSuccess(success?: string[], fail?: string[]) {
        if (success != undefined) {
            setSuccess(success);
        }
        if (fail != undefined) {
            setFail(fail);
        }
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper clearfix" style={{ paddingTop: "16px" }}>
                {getBundledAlertsUI(success, fail, setSuccess, setFail)}
                {getContent()}
                {(() => {
                    if (defaultPaymentMethods != undefined) {
                        return (
                            <ModalInvoice userId={props.match.params.userId} defaultPaymentMethods={defaultPaymentMethods} mutator={updateTable} onEditClick={() => setIsEdit(true)}
                                invoiceDate={invoiceDate} invoiceDueDate={invoiceDueDate} salesTax={salesTax} description={description}
                                notes={notes} paymentNotes={paymentNotes} qty={qty} price={price} totalPrice={totalPrice}
                                invoiceId={invoiceId} isEdit={isEdit} invoiceTitle={invoiceTitle} onClose={modalOnClose}
                                allowEdit={allowEdit} isPaid={isPaid} asPaid={isPaid} isAddView={isAddView}
                                paymentMethod={paymentMethod} paymentMethods={paymentMethods} isUser={isUser} />
                        )
                    }
                })()}
                {(() => {
                    if (isUser) {
                        return (
                            <React.Fragment>
                                <PayInvoice invoiceId={payInvoiceId} onSuccess={payInvoiceOnSuccess} onClose={() => setPayInvoiceId(-1)} />
                                <ManagePaymentMethods />
                            </React.Fragment>
                        )
                    }
                })()} 
            </div>
        )
    }
}

export default ClientInvoice;