import React from 'react';
import Chart, { ChartDataSets } from 'chart.js';

export interface ILineChartProps {
    legend: boolean,
    dataset: ChartDataSets[],
    labels?: string[],
}

function LineChart(props: ILineChartProps) {
    const chart = React.useRef<Chart>();
    const chartRef = React.useRef();
    React.useEffect(() => {
        chart.current = new Chart(chartRef.current as any, {
            type: "line",
            data: {
                labels: props.labels,
                datasets: props.dataset
            },
            options: {
                legend: {
                    display: props.legend
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        })
    }, [])

    React.useEffect(() => {
        if (chart.current != undefined) {
            if (props.labels != undefined) {
                chart.current.data.labels = props.labels;
            }
            chart.current.data.datasets = props.dataset;
            chart.current.update();
        }
    }, [props.labels, props.dataset])

    return (
        <canvas className="custom-charts" ref={chartRef as any} />
    )
}

export default LineChart;