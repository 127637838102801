import React from "react";

export interface IStatusCodeProps {
    status?: string,
}

function StatusCode(props: IStatusCodeProps) {
    const [color, setColor] = React.useState("");
    const [isCode, setIsCode] = React.useState(true);
    const dot = {
        height: "7px",
        width: "7px",
        backgroundColor: color,
        borderRadius: "50%",
        display: "inline-block",
        marginRight: "10px"
    }

    switch(props.status) {
        case "Initial":
            if (isCode) {
            setColor("#bababa");
            setIsCode(false);
        }
        break;
        case "Assigned":
            if (isCode) {
            setColor("#c895e6");
            setIsCode(false);
        }
        break;
        case "In Progress":
            if (isCode) {
            setColor("#e6e095");
            setIsCode(false);
        }
        break;
        case "Mgr Review":
            if (isCode) {
            setColor("#e6d095");
            setIsCode(false);
        }
        break;
        case "Admin Review":
            if (isCode) {
            setColor("#fabb0f");
            setIsCode(false);
        }
        break;
        case "Info Pending":
            if (isCode) {
            setColor("#e37256");
            setIsCode(false);
        }
        break;
        case "Client Review":
            if (isCode) {
            setColor("#56b6e3");
            setIsCode(false);
        }
        break;
        case "Efile Auth":
            if (isCode) {
            setColor("#567ee3");
            setIsCode(false);
        }
        break;
        case "Efiled":
            if (isCode) {
            setColor("#1ba86d");
            setIsCode(false);
        }
        break;
        case "Closed":
            if (isCode) {
            setColor("#000000");
            setIsCode(false);
        }
        break;
        case "needsAction":
            if (isCode) {
            setColor("yellow");
            setIsCode(false);
        }
        break;
        case "Unconfirmed":
            if (isCode) {
            setColor("red");
            setIsCode(false);
        }
        break;
        case "Confirmed":
            if (isCode) {
            setColor("green");
            setIsCode(false);
        }
        break;
        default:
        return <React.Fragment/>
    }
    return (<span style={dot}></span>)  
}

export default StatusCode;
