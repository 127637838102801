import React from "react";

import ContentBox from '../constructs/elements/ContentBox';
import ChartTable from '../constructs/elements/ChartTable';
import BarChart from "../constructs/chart/BarChart";
import LineChart from "../constructs/chart/LineChart";
import PieChart from '../constructs/chart/PieChart';

export interface IContentBoxChartDataRepr {
    data: number[],
    label?: string,
    backgroundColor?: string,
    borderColor?: string,
    fill?: boolean
}


export interface IContentBoxChartTableProps {
    title: string,
    chartType: string,
    sideHeading: string[],
    topHeading: string[],
    data: number[][],
    dataset: IContentBoxChartDataRepr[],
    chartTableCallback?: (chartTitle: string, rowIndex: number, colIndex: number, sideHeading: string[], topHeading:string[]|undefined) => void
}

function ContentBoxChartTable (props: IContentBoxChartTableProps) {

    function getHeader() {
        return (
            <div className="chart-header">
                <h6 className="chart-title">{props.title}</h6>
            </div>
        )
    }

    function getChart() {
        if (props.chartType == "barChart1") {
            return (
                <BarChart dataset={props.dataset} legend={true} />
            )
        }
        else if (props.chartType == "barChart2") {
            return (
                <BarChart dataset={props.dataset} labels={props.sideHeading} legend={true} />
            )
        }
        else if (props.chartType == "lineChart1") {
            return (
                <LineChart dataset={props.dataset} legend={true} />
            )
        }
        else if (props.chartType == "lineChart2") {
            return (
                <LineChart dataset={props.dataset} labels={props.sideHeading} legend={true} />
            )
        }
        else if (props.chartType == "pieChart") {
            return (
                <PieChart dataset={props.dataset} labels={props.sideHeading} legend={true} />
            )
        }
    }

    function chartTableCallback(rowIndex: number, colIndex: number, sideHeading: string[], topHeading: string[]|undefined) {
        if (props.chartTableCallback != undefined) {
            props.chartTableCallback(props.title, rowIndex, colIndex, sideHeading, topHeading);
        }
    }

    function getChartTable() {
        return (
            <ChartTable topHeading={props.topHeading}
                sideHeading={props.sideHeading} data={props.data} chartTableCallback={chartTableCallback} />
        )
    }

    function getBody() {
        return (
            <div>
                <div className="chart-body">
                    {getChart()}
                </div>
                {getChartTable()}
            </div>
        )
    }

    return (
        <div className="col-lg-6 col-md-6 col-sm-12 pt-50">
            <div className="chart-item top-line active">
                <ContentBox header={getHeader()} body={getBody()} />
            </div>
        </div>
    )
}

export default ContentBoxChartTable;