import React from "react";
import { AxiosResponse } from "axios";
import { AdminNavBar } from "./AdminNavBar";
import AdminImg from "../../../../../external/backPages/img/images/AdminHomeImgs/AdminDefaultImg.png";
import { IPageComponentProps } from "../../../../../declarations/common";
import Loading from "../../../../../intelws_portal/constructs/elements/Loading";
import { get, getCancelToken} from "../../../../../intelws_portal/utils/backendInterface";
import AdminFooter from "./AdminFooter";

interface IUrlParams {
  adminCode: string
}

interface IAdminInfoFetch extends AxiosResponse {
  data: {
      data: {
          admin_company: string,
          admin_company_url: string
      }
  }
}
function AdminAboutUs(props: IPageComponentProps<IUrlParams>) {
  const ENDPOINT = "/api/authentication/";
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [companyName, setCompanyName] = React.useState("");
  const [companyUrl, setCompanyUrl] = React.useState("");
  const cancelTokenSource = React.useRef(getCancelToken());

  React.useEffect(() => {
      let queryParams = {
          section: "admin_code_info",
          admin_code: props.match.params.adminCode
      }
      const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IAdminInfoFetch>;
      requestResponse.then((response) => {
          setIsLoaded(true);
          setCompanyName(response.data.data.admin_company);
          setCompanyUrl(response.data.data.admin_company_url);
          document.title = response.data.data.admin_company;
      }).catch(() => {
          setIsLoaded(true);
      })
  }, [])


  if (!isLoaded) {
      return <Loading />
  }

    return (
        <React.Fragment>
          <AdminNavBar adminCode={props.match.params.adminCode} companyName={companyName}/>
        <section id="aboutUs" className="aboutUs" style={{marginTop:"50px"}}>
            <div className="container">
            <div className="section-title">
                <h2>About Us</h2>
                <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
            </div>
        <div className="row content">
        <div className="col-md-5 order-1 order-md-2" >
          <img src={AdminImg} className="img-fluid" alt="" />
        </div>
        <div className="col-md-7 pt-5 order-2 order-md-1" >
          <h3>Corporis temporibus maiores provident</h3>
          <p style={{color:"#444444"}}>Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
        </div>
      </div>
      </div>
      </section>
      <AdminFooter adminCode={props.match.params.adminCode} />
      </React.Fragment>

    )
}

export default AdminAboutUs;