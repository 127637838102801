import React, { Component } from "react";

import ContentBoxChartTable, { IContentBoxChartDataRepr } from '../bundles/ContentBoxChartTable'
import ContentBox from '../constructs/elements/ContentBox';
import Tabs, { IContentRepr, ITabRepr } from '../constructs/elements/Tabs';
import Loading from '../constructs/elements/Loading'

export interface IContentBoxChartTableRepr {
    cbChartTitle: string,
    chartType: string,
    sideHeading: string[],
    topHeading: string[],
    tableData: number[][],
    chartData: IContentBoxChartDataRepr[]
}

export interface IContentBoxChartTableTabEleRepr {
    header?: ITabRepr,
    content?: IContentBoxChartTableRepr[]
}

export interface IContentBoxTabChartTableProps {
    title: string | JSX.Element,
    tabs: IContentBoxChartTableTabEleRepr[],
    tabCallback?: (tabName: string, index: number) => void,
    chartTableCallback?: (chartTitle: string, rowIndex: number, colIndex: number, sideHeading: string[], topHeading:string[]|undefined) => void
}

function ContentBoxTabChartTable(props: IContentBoxTabChartTableProps) {
    function getHeader() {
        return (
            <div className="heading-box">
                <h6 className="col-sm-6"> {props.title}</h6>
            </div>
        )
    }

    function buildTabHeading(tabEle: IContentBoxChartTableTabEleRepr, tabHeadingList: ITabRepr[]) {
        if (tabEle.header != undefined) {
            tabHeadingList.push(tabEle.header)
        }
    }

    function buildTabContent(tabEle: IContentBoxChartTableTabEleRepr, tabContentList: IContentRepr[] ) {
        let tabContentChartList: IContentRepr = { content: [] };
        let rowRawData: JSX.Element[][] = [];
        let rowCountIndex = -1;
        if (tabEle.content != undefined) {
            tabEle.content.map((cbChartEle: IContentBoxChartTableRepr, cbChartIndex: number) => {
                if (cbChartIndex % 2 == 0) {
                    rowRawData.push([]);
                    rowCountIndex += 1;
                }
                let contentObj = <ContentBoxChartTable key={cbChartIndex}
                    title={cbChartEle.cbChartTitle} topHeading={cbChartEle.topHeading}
                    sideHeading={cbChartEle.sideHeading} chartType={cbChartEle.chartType}
                    data={cbChartEle.tableData} dataset={cbChartEle.chartData} chartTableCallback={props.chartTableCallback} />
                rowRawData[rowCountIndex].push(contentObj);
            }
            )
            tabContentChartList.content = rowRawData.map((ele, eleIndex) => {
                return (
                    <div key={eleIndex}>
                        <div className="dashboard-chart">
                            <div className="chart-container row">
                                {ele}
                            </div>
                        </div>
                        <br />
                    </div>
                )
            })
            tabContentList.push(tabContentChartList);
        }
        else {
            tabContentList.push({content: <Loading />});
        }
    }

    function getBody() {
        let tabHeading: ITabRepr[] = []
        let tabContent: IContentRepr[] = []
        {
            props.tabs.map((tabEle) => {
                buildTabHeading(tabEle, tabHeading);
                buildTabContent(tabEle, tabContent);
            }
            )
        }
        return (
            <Tabs tabs={tabHeading} contents={tabContent}
                tabCallback={props.tabCallback} />
        )
    }

    return (
        <ContentBox header={getHeader()} body={getBody()} />
    )
}

export default ContentBoxTabChartTable;
