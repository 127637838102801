import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";

import ModalFooter from "../modal/ModalFooter";
import ModalHeader from "../modal/ModalHeader";

interface IWindowConfirmProps {
    message: string,
    modalStatus: (status: boolean) => void
}

function Confirm(props: IWindowConfirmProps) {

    React.useLayoutEffect(() => {
        $("#confirmAlert").modal("show");
    }, [])
    
    let domNode = document.body.appendChild(document.createElement("div"));

    function abort() {
        $("#confirmAlert").modal("hide");
        props.modalStatus(false);
        domNode.remove();
    }

    function confirm() {
        $("#confirmAlert").modal("hide");
        props.modalStatus(true);
        domNode.remove();
    }

    function getModalContent() {
        return (
            <React.Fragment>
                <ModalHeader title="Alert" />
                <div className="modal-body">
                    <p>{props.message}</p>
                </div>
                <ModalFooter saveCallback={confirm} saveInactive={false} 
                    buttonConfig={[{buttonName: "Cancel", inactive: false, callback: abort}]} />
            </React.Fragment>
        )
    }
    function relativeRender() {
        return <div className="modal fade custom-modal text-center" id="confirmAlert" tabIndex={-1}>
                <div className="modal-dialog text-left modal-lg">
                    <div className="modal-content">
                        {getModalContent()}
                    </div>
                </div>
            </div>
    }
    return ReactDOM.createPortal(relativeRender(), domNode as Element);
}

export function confirm(message: string) {
    return new Promise((resolve, reject) => {
        let domNode = document.body.appendChild(document.createElement("div"));
        function modalStatus(status: boolean) {
            if (status) {
                domNode.remove();
                resolve(true);
            } else {
                domNode.remove();
                reject(undefined);
            }
        }
        ReactDOM.render(<Confirm message={message} modalStatus={modalStatus} />, domNode);
    })
}