import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeCardElement, StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { AxiosResponse } from "axios";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import $ from "jquery"
import Loading from "../../../intelws_portal/constructs/elements/Loading";
import { get, getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";
import { IPageComponentProps } from "../../../declarations/common";
import { stripePromise, STRIPE_KEY } from "../../Urls";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import { confirmPayment, fetchPaymentIntentID } from "../../../intelws_portal/utils/payments/payments";
import { getBundledAlertsUI, isStringEmpty, parseQueryResponseOnFail } from "../../../declarations/common_utils";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import { TPaymentMethods } from "../common/clientDashboard/ClientInvoice";
import ModalRadio from "../../../intelws_portal/constructs/modal/ModalRadio";
import ModalDropDown from "../../../intelws_portal/constructs/modal/ModalDropDown";
import Table, { IButtonRepr, ICheckboxRepr, IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { IBankAccountPaymentMethodRepr, IPaymentMethodRepr, isBankAccount, isCard } from "../../../intelws_portal/utils/payments/paymentTypes";
import ModalCheckbox from "../../../intelws_portal/constructs/modal/ModalCheckbox";

import bank from "../../card_brand_logo/bank.png";
import visa from "../../card_brand_logo/visa.png";
import mastercard from "../../card_brand_logo/mastercard.png";
import discover from "../../card_brand_logo/discover.jpeg";
import americanExpress from "../../card_brand_logo/american_express.png";
import unionPay from "../../card_brand_logo/union_pay.svg";
import dinersClub from "../../card_brand_logo/diners_club.svg";
import jcb from "../../card_brand_logo/jcb.svg";
import creditCard from "../../card_brand_logo/credit_card.png";

interface IUserInfo {
    user_name: string,
    user_email: string
}

interface IFetchInvoiceDetails extends AxiosResponse {
    data: {
        invoice: {
            id: number,
            invoice_date: string,
            invoice_due_date: string,
            invoice_sales_tax: string,
            invoice_notes: string,
            invoice_payment_notes: string,
            invoice_payment_method: string,
            invoice_allow_edit: boolean,
            invoice_is_paid: boolean,
            invoice_payment_methods: IPaymentMethodsInternalRepr,
            invoice_processing: boolean,
            invoice_paid: boolean,
            description: string[],
            qty: number[],
            price: number[],
            total_price: number[],
            payment_price: number,
        },
        l_payment_method: IPaymentMethodRepr[]
        connected_account: string,
        user_info: IUserInfo
    }
}

interface IPostPaymentIntent extends AxiosResponse {
    data: {
        client_secret?: string
    }
}

export interface IFetchPaymentMethodDetails extends AxiosResponse {
    data: {
        data: IPaymentMethodRepr
    }
}

interface IPaymentMethodsInternalRepr {
    display: string[],
    values: TPaymentMethods[]
}

interface IBankAccountInfoInternalRepr {
    type: "bank_account",
    bank_name: string,
    last4: string
}

interface IMatchProps {
    invoiceId: string
}

export interface IPayInvoiceProps {
    invoiceId: number,
    onSuccess: (success?: string[], fail?: string[]) => void,
    onClose: () => void
}

export interface IPayInvoicePropsInternal extends IPayInvoiceProps {
    paymentTotal: number,
    paymentMethods: IPaymentMethodsInternalRepr,
    paymentMethodList: IPaymentMethodRepr[],
    paymentProcessing: boolean,
    paymentPaid: boolean,
    userInfo: IUserInfo,
    invoiceID: number,
    closeModal: () => void
}

function LogicPayInvoice(props: IPayInvoicePropsInternal) {
    const ENDPOINT = "api/clientdashboard/";

    const [currPaymentMethod, setCurrPaymentMethod] = React.useState<TPaymentMethods | string>(props.paymentMethods.values[0]);
    const [addPaymentMethod, setAddPaymentMethod] = React.useState(props.paymentMethodList.length <= 0);
    const [isSaveLater, setIsSaveLater] = React.useState(true);
    const [disableSaveBtnName, setDisableSaveBtnName] = React.useState("Pay");
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [isShowEndingScreen, setIsShowEndingScreen] = React.useState(false);

    //Card Information
    const [addressObj, setAddressObj] = React.useState(getEmptyAddressObj());
    const [cardHolderName, setCardHolderName] = React.useState("");
    const [isCardComplete, setIsCardComplete] = React.useState(false);
    const [cardErrorObj, setCardErrorObj] = React.useState(getEmptyCardErrorObj);

    //Ach Information
    const [selectedBankInfo, setSelectedBankInfo] = React.useState<IBankAccountPaymentMethodRepr>();
    const [clientSecret, setClientSecret] = React.useState("");
    const [currentErrorMessage, setCurrentErrorMessage] = React.useState<string>();

    //Payment Method Information/Selection
    const [currSelectedPaymentMethodID, setCurrSelectedPaymentMethodID] = React.useState(-1);

    const cancelTokenSource = React.useRef(getCancelToken());

    //Stripe Elements
    const stripeSDK = useStripe();
    const stripeElements = useElements();

    React.useEffect(() => {
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    function getEmptyCardErrorObj() {
        return {
            line1: {
                isError: false,
                errorText: ""
            },
            line2: {
                isError: false,
                errorText: ""
            },
            city: {
                isError: false,
                errorText: ""
            },
            state: {
                isError: false,
                errorText: ""
            },
            postalCode: {
                isError: false,
                errorText: ""
            },
            card: {
                isError: false,
                errorText: ""
            }
        }
    }

    function getEmptyAddressObj() {
        return {
            line1: "",
            line2: "",
            city: "",
            state: "",
            country: "US",
            postal_code: "",
        }
    }

    function formChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | string | number,
        section: "line1" | "line2" | "city" | "state" | "postalCode" | "cardHolderName" | "paymentMethod" | "accFullName" | "accEmail" | "isSaveLater" | "selectedPaymentMethodID" | "toggleSavedPaymentMethodScreen" | "clearSelectedPaymentMethod",
        extraData?: string) {
        if (typeof e != "string" && typeof e != "number") {
            setAddressObj((prevAddressObj) => {
                let newAddressObj = { ...prevAddressObj };
                if (section == "line1") {
                    newAddressObj.line1 = e.target.value;
                } else if (section == "line2") {
                    newAddressObj.line2 = e.target.value;
                } else if (section == "city") {
                    newAddressObj.city = e.target.value;
                } else if (section == "state") {
                    newAddressObj.state = e.target.value;
                } else if (section == "postalCode") {
                    newAddressObj.postal_code = e.target.value;
                }
                return newAddressObj;
            })
            if (section == "cardHolderName") {
                setCardHolderName(e.target.value);
            }
            if (section == "isSaveLater") {
                setIsSaveLater((prevSaveLater) => {
                    return !prevSaveLater;
                })
            }
        } else if (typeof e == "string") {
            if (section == "paymentMethod") {
                setCurrPaymentMethod(e as TPaymentMethods);
            } else if (section == "toggleSavedPaymentMethodScreen") {
                setAddPaymentMethod((prevAddPaymentMethod) => {
                    if (prevAddPaymentMethod) {
                        setAddressObj(getEmptyAddressObj());
                        setCardHolderName("");
                        setIsCardComplete(false);
                        setCardErrorObj(getEmptyCardErrorObj());
                        setSelectedBankInfo(undefined);
                        setClientSecret("");
                        setCurrentErrorMessage(undefined);
                        setCurrPaymentMethod(props.paymentMethods.values[0])
                        return false;
                    } else {
                        setCurrSelectedPaymentMethodID(-1); 
                        return true;
                    }
                })
            } else if (section == "clearSelectedPaymentMethod") {
                setCurrSelectedPaymentMethodID(-1);
            }
        } else if (typeof e == "number") {
            if (section == "selectedPaymentMethodID" && extraData != undefined) {
                setCurrSelectedPaymentMethodID(e);
                setCurrPaymentMethod(extraData);
            }
        }
    }

    function createCharge() {
        setIsProcessing(true);
        setDisableSaveBtnName("Saving...");
        if (currPaymentMethod == "card") {
            createCardCharge();
        } else if (currPaymentMethod == "ach_debit") {
            createACHCharge();
        }
    }

    function createCardCharge() {
        let cardData: any = undefined;
        if (!(currSelectedPaymentMethodID > -1)) {
            cardData = {
                name: cardHolderName,
                address: { ...addressObj }
            }
            if (addressObj.line2 != undefined) {
                cardData.address.line2 = addressObj.line2;
            }
        }
        setCardErrorObj(getEmptyCardErrorObj());
        let formData = new FormData();
        formData.append("invoice_id", props.invoiceId.toString());
        formData.append("is_save_later", JSON.stringify(isSaveLater));
        formData.append("payment_method", "card");
        if (currSelectedPaymentMethodID > -1) {
            formData.append("payment_method_id", currSelectedPaymentMethodID.toString());
        }
        let queryParams = {
            section: "invoice_create_payment_intent"
        }
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostPaymentIntent>;
        if (stripeSDK != undefined && stripeElements != undefined) {
            requestResponse.then((response) => {
                if (stripeSDK != undefined && response.data.client_secret != undefined) {
                    confirmPayment("stripe", "card", response.data.client_secret, stripeSDK, stripeElements, cardData).then((paymentResponse) => {
                        if (paymentResponse != undefined) {
                            if (paymentResponse.success == false && paymentResponse.reason != undefined) {
                                let errorObj = getEmptyCardErrorObj();
                                if (paymentResponse.reason == "expired_card" ||
                                    paymentResponse.reason == "invalid_month" ||
                                    paymentResponse.reason == "invalid_year") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "This card has expired"
                                    setCardErrorObj(errorObj);
                                } else if (paymentResponse.reason == "incorrect_address") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "The address is incorrect"
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "incorrect_cvc" ||
                                    paymentResponse.reason == "invalid_cvc") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "The CVC is invalid"
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "incorrect_zip") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "The Zip Code is incorrect"
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "invalid_number") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "The card number is invalid"
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "auth_failure") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "The card authentication failed"
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "card_declined") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "This card cannot be used. Please try another card."
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "unexpected_error") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "There is an unexpected error. Please try again"
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "processing_error") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "This card cannot be processed. Please try another card."
                                    setCardErrorObj(errorObj)
                                }
                            } else {
                                /*
                                props.closeModal();
                                props.onSuccess(["Payment is being processed"]);
                                */
                                setIsShowEndingScreen(true);
                            }
                        }
                    })
                } else {
                    props.closeModal();
                    props.onSuccess(["Payment is being processed"]);
                }
            }).finally(() => {
                setIsProcessing(false);
            })
        }
    }

    function confirmACHCharge(clientSecret: string) {
        fetchPaymentIntentID("stripe", clientSecret, stripeSDK).then((paymentResponse) => {
            if (paymentResponse != undefined) {
                if (paymentResponse.success && paymentResponse.payment_intent_id != undefined) {
                    let queryParams = {
                        section: "invoice_update_payment_intent"
                    }
                    let formData = new FormData();
                    formData.append("payment_intent_id", paymentResponse.payment_intent_id.toString());
                    formData.append("is_save_later", JSON.stringify(isSaveLater));
                    const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
                    requestResponse.then(() => {
                        confirmPayment("stripe", "bank_account", clientSecret, stripeSDK, stripeElements, undefined, undefined, 2).then((paymentResponse) => {
                            if (paymentResponse != undefined && paymentResponse.success) {
                                /*
                                props.closeModal();
                                props.onSuccess(["Payment is being processed"]);
                                */
                                setIsShowEndingScreen(true);
                            } else {
                                setCurrentErrorMessage("Something went wrong. Please try again");
                            }
                        });
                    }).catch(() => {
                        setIsProcessing(false);
                    })
                }
            }
        })
    }

    function createACHCharge() {
        if (currSelectedPaymentMethodID != -1) {
            let formData = new FormData();
            formData.append("invoice_id", props.invoiceId.toString());
            formData.append("is_save_later", JSON.stringify(false));
            formData.append("payment_method_id", currSelectedPaymentMethodID.toString());
            formData.append("payment_method", "ach_debit");
            let queryParams = {
                section: "invoice_create_payment_intent"
            }
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostPaymentIntent>;
            requestResponse.then((response) => {
                if (response.data.client_secret != undefined) {
                    confirmACHCharge(response.data.client_secret)
                }
            })
        } else {
            confirmACHCharge(clientSecret);
        }
    }

    function addBankAccountCallback() {
        let bankAccBilling = {
            name: props.userInfo.user_name,
            email: props.userInfo.user_email
        }
        let formData = new FormData();
        formData.append("invoice_id", props.invoiceId.toString());
        formData.append("is_save_later", JSON.stringify(isSaveLater));
        formData.append("payment_method", "ach_debit");
        let queryParams = {
            section: "invoice_create_payment_intent"
        }
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostPaymentIntent>;
        requestResponse.then((response) => {
            if (stripeSDK != undefined && response.data.client_secret != undefined) {
                confirmPayment("stripe", "bank_account", response.data.client_secret, stripeSDK, stripeElements, undefined, bankAccBilling, 1).then((paymentResponse) => {
                    if (paymentResponse != undefined && paymentResponse.success) {
                        let queryParams = {
                            section: "invoice_payment_details_fetch",
                            payment_backend: "stripe",
                            payment_method_id: paymentResponse.payment_method_id
                        }
                        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchPaymentMethodDetails>
                        requestResponse.then((paymentMethodDetailsResponse) => {
                            if (isBankAccount(paymentMethodDetailsResponse.data.data)) {
                                setSelectedBankInfo(paymentMethodDetailsResponse.data.data);
                                setClientSecret(response.data.client_secret as string);
                            }
                        })
                    } else {
                        if (paymentResponse != undefined) {
                            if (paymentResponse.reason != "cancelled_modal") {
                                setCurrentErrorMessage("Something went wrong. Please try again");
                            }
                        }
                    }
                })
            }
        })
    }

    function cardElementChange(e: StripeCardElementChangeEvent) {
        if (currPaymentMethod == "card") {
            if (e.complete) {
                setIsCardComplete(true);
            } else {
                setIsCardComplete(false);
            }
        }
    }

    function getCardDisplay() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12 mt-3">
                        <ModalInputText title={"Card Holder Name"} value={cardHolderName} onChange={(e) => formChange(e, "cardHolderName")}
                            borderBottom required />
                    </div>
                    <div className="col-12 mt-3 form-group">
                        <div className="form-control" style={{ borderBottom: "1px solid black" }}>
                            <CardElement onChange={cardElementChange} />
                            {(() => {
                                if (cardErrorObj.card.isError != undefined && cardErrorObj.card.isError) {
                                    return (
                                        <div className="invalid-feedback">{cardErrorObj.card.errorText}</div>
                                    )
                                }
                            })()}
                            <div className="mt-0"></div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <ModalInputText title={"Address Line 1"} value={addressObj.line1} onChange={(e) => formChange(e, "line1")}
                            isError={cardErrorObj.line1.isError} errorText={cardErrorObj.line1.errorText}
                            borderBottom required />
                    </div>
                    <div className="col-12 mt-3">
                        <ModalInputText title={"Address Line 2"} value={addressObj.line2 as string} onChange={(e) => formChange(e, "line2")}
                            isError={cardErrorObj.line2.isError} errorText={cardErrorObj.line2.errorText}
                            borderBottom />
                    </div>
                    <div className="col-12 mt-3">
                        <ModalInputText title={"City"} value={addressObj.city} onChange={(e) => formChange(e, "city")}
                            isError={cardErrorObj.city.isError} errorText={cardErrorObj.city.errorText}
                            borderBottom required />
                    </div>
                    <div className="col-12 mt-3">
                        <ModalInputText title={"State"} value={addressObj.state} onChange={(e) => formChange(e, "state")}
                            isError={cardErrorObj.state.isError} errorText={cardErrorObj.state.errorText}
                            borderBottom required />
                    </div>
                    <div className="col-12 mt-3">
                        <ModalInputText title={"Zip Code"} value={addressObj.postal_code} onChange={(e) => formChange(e, "postalCode")}
                            isError={cardErrorObj.postalCode.isError} errorText={cardErrorObj.postalCode.errorText}
                            borderBottom required />
                    </div>
                    <div className="col-12 mt-1">
                        {getSaveLaterCheckbox()}
                    </div>
                    <div className="col-12 mt-4">
                        <small><p className="text-secondary text-justify">
                            All payments processed by Intelligent Workspace Inc follow the PCI DSS security compliances, ensuring for secure payments and storage of card information
                        </p></small>
                    </div>
                </div>
                {(() => {
                    if (cardErrorObj.card.isError) {
                        return (
                            <div style={{ height: 20, padding: 4, color: "#dc3545" }}>
                                {cardErrorObj.card.errorText}
                            </div>
                        )
                    }
                })()}
            </React.Fragment>
        )
    }

    function getBankAccountDisplay(bankAccount: IBankAccountInfoInternalRepr) {
        let borderStyle = "2px solid rgba(0, 0, 0, .075)";
        return (
            <div className="col-12">
                <div className="card" style={{ borderRadius: "0.50rem", border: borderStyle }}>
                    <div className="row card-body">
                        <div className="col-2">
                            <img className={"img-fluid"} src={bank} alt="" />
                        </div>
                        <div className="col-5 d-flex align-items-center">XXXX{bankAccount.last4}</div>
                        <div className="col-5 d-flex align-items-center">{bankAccount.bank_name}</div>
                    </div>
                </div>
            </div>
        )
    }

    function getAchDisplay() {
        return (
            <React.Fragment>
                {(() => {
                    if (selectedBankInfo == undefined) {
                        return (
                            <React.Fragment>
                                {(() => {
                                    if (isStringEmpty(clientSecret)) {
                                        return (
                                            <div className="row">
                                                {(() => {
                                                    if (currentErrorMessage != undefined) {
                                                        return (
                                                            <div className="col-12 mt-2">
                                                                <p style={{ color: "red" }}>{currentErrorMessage}</p>
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                                <div className="col-12 mt-5">
                                                    <button className="btn btn-secondary btn-sm btn-block" onClick={addBankAccountCallback}>Link Bank Account</button>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <div className="row mt-5">
                                {getBankAccountDisplay(selectedBankInfo)}
                                <div className="col-12 mt-3">
                                    {getSaveLaterCheckbox()}
                                </div>
                                <div className="col-12 mt-4">
                                    <small><p className="text-secondary text-justify">
                                        By clicking [Pay], you authorize Intelligent Workspace Inc to debit the bank account specified above for any amount owed for charges arising from your use of Intelligent Workspace Inc’ services and/or purchase of products from Intelligent Workspace Inc, pursuant to Intelligent Workspace Inc’ website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to Intelligent Workspace Inc with 30 (thirty) days notice.
                                    </p></small>
                                </div>
                            </div>
                        )
                    }
                })()}

            </React.Fragment>
        )
    }

    function getPaymentMethodListDisplay() {
        if (props.paymentMethodList.length > 0 && !addPaymentMethod) {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-8">
                            <p>Select Payment Method:</p>
                        </div>
                            {(() => {
                                if (currSelectedPaymentMethodID > -1) {
                                    return (
                                        <React.Fragment>
                                            <div className="col-2"></div>
                                            <div className="col-2">
                                                <a href="#" className="text-muted" onClick={(e) => formChange("", "clearSelectedPaymentMethod")}>Clear</a>
                                            </div>
                                        </React.Fragment>
                                    )
                                } else {
                                    return (
                                        <div className="col-4">
                                            <small><a href="#" onClick={(e) => formChange("", "toggleSavedPaymentMethodScreen")}>Pay another way</a></small>
                                        </div>
                                    )
                                }
                            })()}
                    </div>
                    <div className="row">
                        {props.paymentMethodList.map((paymentMethod) => {
                            let borderStyle = "2px solid rgba(0, 0, 0, .075)";
                            if (currSelectedPaymentMethodID == paymentMethod.id) {
                                borderStyle = "2px solid rgba(75, 92, 169, .80)";
                            }
                            return (
                                <div className="col-12 mt-3" onClick={() => formChange(paymentMethod.id, "selectedPaymentMethodID", paymentMethod.payment_method_type)}>
                                    <div className="card" style={{ borderRadius: "0.50rem", border: borderStyle }}>
                                        <div className="row card-body">
                                            {(() => {
                                                if (isCard(paymentMethod)) {
                                                    return (
                                                        <React.Fragment>
                                                            <div className="col-1 d-flex align-items-center">
                                                                <input type="radio" checked={currSelectedPaymentMethodID == paymentMethod.id} />
                                                            </div>
                                                            <div className="col-2 d-flex align-items-center">
                                                                {(() => {
                                                                    let className = "img-fluid"
                                                                    if (paymentMethod.brand == "visa") {
                                                                        return <img className={className} src={visa} alt="" />
                                                                    } else if (paymentMethod.brand == "mastercard") {
                                                                        return <img className={className} src={mastercard} alt="" />
                                                                    } else if (paymentMethod.brand == "discover") {
                                                                        return <img className={className} src={discover} alt="" />
                                                                    } else if (paymentMethod.brand == "amex") {
                                                                        return <img className={className} src={americanExpress} alt="" />
                                                                    } else if (paymentMethod.brand == "unionpay") {
                                                                        return <img className={className} src={unionPay} alt="" />
                                                                    } else if (paymentMethod.brand == "diners") {
                                                                        return <img className={className} src={dinersClub} alt="" />
                                                                    } else if (paymentMethod.brand == "jcb") {
                                                                        return <img className={className} src={jcb} alt="" />
                                                                    } else {
                                                                        return <img className={className} src={creditCard} alt="" />
                                                                    }
                                                                })()}
                                                            </div>
                                                            <div className="col-4 d-flex align-items-center">XXXX{paymentMethod.last4}</div>
                                                            <div className="col-5 d-flex align-items-center">{paymentMethod.exp_month}/{paymentMethod.exp_year}</div>
                                                        </React.Fragment>
                                                    )
                                                } else if (isBankAccount(paymentMethod)) {
                                                    return (
                                                        <React.Fragment>
                                                            <div className="col-1 d-flex align-items-center">
                                                                <input type="radio" checked={currSelectedPaymentMethodID == paymentMethod.id} />
                                                            </div>
                                                            <div className="col-2 d-flex align-items-center">
                                                                <img className="img-fluid" src={bank} alt="" />
                                                            </div>
                                                            <div className="col-4 d-flex align-items-center">XXXX{paymentMethod.last4}</div>
                                                            <div className="col-5 d-flex align-items-center">{paymentMethod.bank_name}</div>
                                                        </React.Fragment>
                                                    )
                                                }
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="row">
                        {(() => {
                            if (currPaymentMethod == "card") {
                                if (cardErrorObj.card.isError != undefined && cardErrorObj.card.isError) {
                                    return (
                                        <div className="invalid-feedback">{cardErrorObj.card.errorText}</div>
                                    )
                                }
                            } else {
                                return (
                                    <div className="invalid-feedback">{currentErrorMessage}</div>
                                )
                            }
                            })()}
                    </div>
                    {/*(() => {
                        if (currSelectedPaymentMethodID > -1) {
                            return (
                                <div className="row mt-1">
                                    <div className="col-10"></div>
                                    <div className="col-2">
                                        <a href="#" className="text-muted">Clear</a>
                                    </div>
                                </div>
                            )
                        }
                    })()*/}
                </React.Fragment>
            )
        }
    }

    function getPaymentMethodAddDisplay() {
        return (
            <React.Fragment>
                {(() => {
                    if (addPaymentMethod) {
                        let rowClassName = "row";
                        return (
                            <React.Fragment>
                                {(() => {
                                    if (props.paymentMethodList.length > 0) {
                                        rowClassName = "row mt-3";
                                        return (
                                            <div className="row">
                                                <div className="col">
                                                    <small><a href="#" onClick={(e) => formChange("", "toggleSavedPaymentMethodScreen")}>&#8592; Pay with saved Payment Methods</a></small>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                                <div className={rowClassName}>
                                    {props.paymentMethods.display.map((paymentMethodDisplay, index) => {
                                        let borderStyle = "2px solid rgba(0, 0, 0, .075)";
                                        if (currPaymentMethod == props.paymentMethods.values[index]) {
                                            borderStyle = "2px solid rgba(75, 92, 169, .80)";
                                        }
                                        return (
                                            <div className="col-6" role="button" onClick={(e) => formChange(props.paymentMethods.values[index], "paymentMethod")}>
                                                <div className="card" style={{ borderRadius: "0.50rem", border: borderStyle }}>
                                                    <div className="card-body">{paymentMethodDisplay}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </React.Fragment>
                        )
                    }
                })()}
                {(() => {
                    if (addPaymentMethod) {
                        if (currPaymentMethod == "card") {
                            return getCardDisplay();
                        } else if (currPaymentMethod == "ach_debit") {
                            return getAchDisplay();
                        }
                    }
                })()}
            </React.Fragment>
        )
    }

    function getEndingScreen() {
        return (
            <div className="row">
                <div className="col-12">
                    Thank you for you payment.
                </div>
                <div className="col-12 mt-2">
                    The charge will appear on your statement as ACCWSCPA INV{props.invoiceID}
                </div>
            </div>
        )
    }

    function isPayButtonActivate() {
        if (currPaymentMethod == "card") {
            return (
                (currSelectedPaymentMethodID > -1) ||
                (isCardComplete && !isStringEmpty(cardHolderName) && !isStringEmpty(addressObj.line1) && !isStringEmpty(addressObj.city) && !isStringEmpty(addressObj.state) && !isStringEmpty(addressObj.postal_code))
            )
        } else if (currPaymentMethod == "ach_debit") {
            return (
                (currSelectedPaymentMethodID > -1) ||
                (!isStringEmpty(clientSecret))
            )
        }
        return false;
    }

    function getActionButton() {
        return undefined;
        if (props.paymentMethodList.length > 0) {
            if (currSelectedPaymentMethodID == -1) {
                return (
                    <button className="btn btn-secondary-add-cpasage" style={{ marginTop: "-2px", marginBottom: "30px" }}
                        onClick={() => { setAddPaymentMethod(!addPaymentMethod); setCurrPaymentMethod("Select" as TPaymentMethods) }}>
                        {(() => {
                            if (addPaymentMethod) {
                                return "Clear Payment Method"
                            } else {
                                return "Add Payment Method"
                            }
                        })()}
                    </button>
                )
            } else if (currSelectedPaymentMethodID > -1) {
                return (
                    <button className="btn btn-secondary-add-cpasage" style={{ marginTop: "-2px", marginBottom: "30px" }}
                        onClick={() => {
                            setCurrSelectedPaymentMethodID(-1);
                        }}>Clear Selection</button>
                )
            }
        }
    }

    function getSaveLaterCheckbox() {
        if (addPaymentMethod && (currPaymentMethod == "card" || currPaymentMethod == "ach_debit")) {
            if (currPaymentMethod == "ach_debit" && isStringEmpty(clientSecret)) {
                return undefined;
            }
            return <ModalCheckbox checked={isSaveLater} label={"Save payment method for faster future payments"} checkboxCallback={(e) => formChange(e, "isSaveLater")} />
        }
    }

    return (
        <React.Fragment>
            <div className="modal-body">
                {(() => {
                    if (!props.paymentProcessing && !props.paymentPaid) {
                        if (!isShowEndingScreen) {
                            return (
                                <React.Fragment>
                                    {getPaymentMethodListDisplay()}
                                    {getPaymentMethodAddDisplay()}
                                </React.Fragment>
                            )
                        } else {
                            return getEndingScreen();
                        }
                    } else {
                        if (props.paymentPaid) {
                            return (
                                <h1>Invoice is Already Paid.</h1>
                            )
                        } else if (props.paymentProcessing) {
                            return (
                                <h1>Payment is Processing. You cannot pay at this time</h1>
                            )
                        }
                    }
                })()}
            </div>
            {/*<ModalFooter saveCallback={createCharge} saveInactive={!isPayButtonActivate() || isProcessing} saveBtnName={"Pay"} disableSaveBtnName={disableSaveBtnName} />*/}
            {(() => {
                if (!isShowEndingScreen) {
                    return (
                        <div className="modal-footer d-flex justify-content-center">
                            <button type="button" className="btn btn-secondary btn-block" onClick={createCharge} disabled={!isPayButtonActivate() || isProcessing}>Pay ${props.paymentTotal}</button>
                        </div>
                    )
                }
            })()}
        </React.Fragment>
    )
}

function PayInvoice(props: IPayInvoiceProps) {
    const ENDPOINT = "api/clientdashboard/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [connectedAccount, setConnectedAccount] = React.useState("");
    const [paymentTotal, setPaymentTotal] = React.useState(-1);
    const [paymentMethods, setPaymentMethods] = React.useState<IPaymentMethodsInternalRepr>({display: [], values: []});
    const [paymentMethodList, setPaymentMethodList] = React.useState<IPaymentMethodRepr[]>([]);
    const [paymentProcessing, setPaymentProcessing] = React.useState(false);
    const [paymentPaid, setPaymentPaid] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState<IUserInfo>({user_email: "", user_name: ""});
    const [invoiceID, setInvoiceID] = React.useState(-1);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        $("#payModal").on("hidden.bs.modal", clearForm)
        return () => {
            $("#payModal").off("hidden.bs.modal");
            cancelTokenSource.current.cancel();
        }
    }, [])

    React.useEffect(() => {
        setIsLoaded(false);
        if (props.invoiceId > -1) {
            $('#payModal').modal('show');
            let queryParams = {
                section: "invoice_pay_details_fetch",
                invoice_id: props.invoiceId
            }
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchInvoiceDetails>;
            requestResponse.then((response) => {
                setPaymentTotal(response.data.invoice.payment_price);
                setConnectedAccount(response.data.connected_account);
                setPaymentMethods(response.data.invoice.invoice_payment_methods);
                setPaymentMethodList(response.data.l_payment_method);
                setPaymentProcessing(response.data.invoice.invoice_processing);
                setPaymentPaid(response.data.invoice.invoice_paid);
                setUserInfo(response.data.user_info);
                setInvoiceID(response.data.invoice.id);
                setIsLoaded(true);
            })
        }

    }, [props.invoiceId])

    function clearForm() {
        props.onClose();
    }

    function closeModal() {
        $("#payModal").modal("hide");
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="payModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-md" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Pay Invoice"} />
                        {(() => {
                            if (isLoaded) {
                                return (
                                    <Elements stripe={loadStripe(STRIPE_KEY, { stripeAccount: connectedAccount })}>
                                        <LogicPayInvoice {...props} paymentTotal={paymentTotal} paymentMethods={paymentMethods}
                                            paymentMethodList={paymentMethodList} paymentProcessing={paymentProcessing} paymentPaid={paymentPaid}
                                            userInfo={userInfo} closeModal={closeModal} invoiceID={invoiceID} />
                                    </Elements>
                                )
                            } else {
                                return <Loading />
                            }
                        })()}
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default PayInvoice;