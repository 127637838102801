import React from "react";
import * as urls from "../../../../Urls";
import { get, getCancelToken } from "../../../../../intelws_portal/utils/backendInterface";
import { AxiosResponse } from "axios";
import footerLogo from "../../../../../external/backPages/img/images/auth/footer-Logo.png";
import { rearg } from "lodash";

export interface IAdminInfoFetch extends AxiosResponse {
    data: {
        data: {
            admin_company: string,
			admin_company_url: string
			admin_sm_links: {
                go_link: string,
                ye_link: string,
                fb_link: string,
                ig_link: string,
                li_link: string,
                tw_link: string,
            },
            admin_company_logo: string
        }
    }
}

interface IAdminFooterProps {
    adminCode: string,
    fetchedAdminInfo?: (isSuccess: boolean, response?: IAdminInfoFetch) => void
}

function AdminFooter(props:IAdminFooterProps) {
	const ENDPOINT = "/api/authentication/";
    const [homePageUrl, setHomePageUrl] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [isFetchError, setIsFetchError] = React.useState(false);
	const [GOLink, setCompanyGOLink] = React.useState("");
	const [YELink, setCompanyYELink] = React.useState("");
	const [FBLink, setCompanyFBLink] = React.useState("");
	const [IGLink, setCompanyIGLink] = React.useState("");
	const [LILink, setCompanyLILink] = React.useState("");
	const [TWLink, setCompanyTWLink] = React.useState("");
    const [logoUrl, setLogoUrl] = React.useState("");
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        let queryParams = {
            section: "admin_code_info",
            admin_code: props.adminCode
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IAdminInfoFetch>;
        requestResponse.then((response) => {
            if (props.fetchedAdminInfo != undefined) {
                props.fetchedAdminInfo(true, response);
            }            
            setHomePageUrl(response.data.data.admin_company_url);
            setCompanyName(response.data.data.admin_company);
            setCompanyGOLink(response.data.data.admin_sm_links.go_link)
            setCompanyYELink(response.data.data.admin_sm_links.ye_link)
            setCompanyFBLink(response.data.data.admin_sm_links.fb_link)
            setCompanyIGLink(response.data.data.admin_sm_links.ig_link)
            setCompanyLILink(response.data.data.admin_sm_links.li_link)
            setCompanyTWLink(response.data.data.admin_sm_links.tw_link)
            setLogoUrl(response.data.data.admin_company_logo)
        }).catch(() => {
            if (props.fetchedAdminInfo != undefined) {
                props.fetchedAdminInfo(false);
            }
            setIsFetchError(true);
        })
    }, [])

    function renderGOLink() {
        if (GOLink != undefined && GOLink !== "") {
            return <a href={GOLink} className="media_btn" target="_blank"><i className="fab fa-google" /></a>
        }
    }

    function renderYELink() {
        if (YELink != undefined && YELink !== "") {
            return <a href={YELink} className="media_btn" target="_blank"><i className="fab fa-yelp" /></a>
        }
    }

    function renderFBLink() {
        if (FBLink != undefined && FBLink !== "") {
            return <a href={FBLink} className="media_btn" target="_blank"><i className="fab fa-facebook" /></a>
        }
    }

    function renderIGLink() {
        if (IGLink != undefined && IGLink !== "") {
            return <a href={IGLink} className="media_btn" target="_blank"><i className="fab fa-instagram" /></a>
        }
    }

    function renderLILink() {
        if (LILink != undefined && LILink !== "") {
            return <a href={LILink} className="media_btn" target="_blank"><i className="fab fa-linkedin-in" /></a>
        }
    }

    function renderTWLink() {
        if (TWLink != undefined && TWLink !== "") {
            return <a href={TWLink} className="media_btn" target="_blank"><i className="fab fa-twitter" /></a>
        }
    }

    function getLogo(){
        if (logoUrl != undefined && logoUrl !== ""){
            return (
                <div className="footer_logo_img">
                    <a href="#" target="_blank">
                        <img className="headerLogo" src={logoUrl} alt="" style={{ width: '50%', margin: '-15% 0%', marginLeft: '-25px' }} />
                    </a>
                </div>
            )
        }
    }

    if (isFetchError) {
        return <h1>Invalid request</h1>
    }
    let isAdminHomePage = homePageUrl !== "";

    function getAdminHomeUrl() {
        if (!isAdminHomePage) {
            return urls.BASE_ADMIN_HOME + props.adminCode + "/"
        } else {
            return homePageUrl
        }
    }

    return (
        <div className="footer_container pb-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-sm-12 footer_logo">
                        {getLogo()}
                        <div className="footer_media_links">
                            {renderGOLink()}
                            {renderYELink()}
                            {renderFBLink()}
                            {renderIGLink()}
                            {renderLILink()}
                            {renderTWLink()}
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 footer_links">
                        <h6>Quick Links</h6>
                        <p><a href={getAdminHomeUrl()}>Home</a></p>
                        {(() => {
                            if (!isAdminHomePage) {
                                return (
                                    <React.Fragment>
                                        <p><a href={urls.BASE_ADMIN_SERVICES + props.adminCode + "/"}>Services</a></p>
                                        <p><a href={urls.BASE_ADMIN_ABOUTUS + props.adminCode + "/"}>About Us</a></p>
                                    </React.Fragment>
                                )
                            }
                        })()}
                        <p><a href={urls.BASE_ADMIN_SIGNIN + props.adminCode + "/"}>Sign In</a></p>
                        <p><a href={urls.BASE_CLIENT_SIGNUP + props.adminCode + "/"}>Sign Up</a></p>
                       
                    </div>
                    <div className="col-md-3 col-sm-12 footer_links">
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 col-sm-12 footer_logo">
                    </div>
                    <div className="col-md-6 col-sm-12 footer_links">
                        <p>© Copyright <a target="_blank" href={getAdminHomeUrl()}><strong><span>{companyName}</span></strong></a> and/or <a href="https://www.accountantws.com" target="_blank"><strong><span>Accountant WS</span></strong></a>. All Rights Reserved</p>
                    </div>
                    <div className="col-md-3 col-sm-12 footer_links">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminFooter;