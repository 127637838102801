import React from "react";
import { Link } from "react-router-dom";
import { getCancelToken, post } from "../../../../../intelws_portal/utils/backendInterface";
import { parseQueryResponseOnFail, parseQueryResponseOnFailResponse } from "../../../../../declarations/common_utils";
import * as urls from "../../../../Urls";
import AuthFooter from "./UIComponents/AuthFooter";
import AuthSidePanel from "./UIComponents/AuthSidePanel";
import headerLogo from "../../../../../external/backPages/img/images/auth/login-logo.svg";
import smallBubble from "../../../../../external/backPages/img/images/small-circle.png";
function AdminSignup() {
    const ENDPOINT = "/api/authentication/";
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [companyUrl, setCompanyUrl] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [rePassword, setRePassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState([false, false]);
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
    const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);

    const cancelTokenSource = React.useRef(getCancelToken());

    function getEmptyErrorObj() {
        return {
            first_name: {
                isError: false,
                errorText: ""
            },
            last_name: {
                isError: false,
                errorText: ""
            },
            company_name: {
                isError: false,
                errorText: ""
            },
            company_url: {
                isError: false,
                errorText: ""
            },
            email: {
                isError: false,
                errorText: ""
            },
            password: {
                isError: false,
                errorText: ""
            },
            re_password: {
                isError: false,
                errorText: ""
            }
        }
    }

    function clearForm() {
        setFirstName("");
        setLastName("");
        setCompanyName("");
        setCompanyUrl("");
        setEmail("");
        setPassword("")
        setRePassword("");
        setShowPassword([false, false]);
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement>,
        section: "firstName" | "lastName" | "companyName" | "companyUrl" | "email" | "password" | "rePassword") {
        if (section == "firstName") {
            setFirstName(e.target.value);
        } else if (section == "lastName") {
            setLastName(e.target.value);
        } else if (section == "companyName") {
            setCompanyName(e.target.value);
        } else if (section == "companyUrl") {
            setCompanyUrl(e.target.value);
        } else if (section == "email") {
            setEmail(e.target.value);
        } else if (section == "password") {
            setPassword(e.target.value);
        } else if (section == "rePassword") {
            setRePassword(e.target.value);
        }
    }

    function clientSideValidation() {
        let errorObj = getEmptyErrorObj();
        let returnValue = true;;
        if (firstName == "") {
            errorObj.first_name.isError = true;
            errorObj.first_name.errorText = "First Name is required";
            returnValue = false;
        }
        if (lastName == "") {
            errorObj.last_name.isError = true;
            errorObj.last_name.errorText = "Last Name is required";
            returnValue = false;
        }
        if (companyName == "") {
            errorObj.company_name.isError = true;
            errorObj.company_name.errorText = "Company Name is required";
            returnValue = false;
        }
        if (email == "") {
            errorObj.email.isError = true;
            errorObj.email.errorText = "Email is required";
            returnValue = false;
        }
        if (!holisticValidatePassword()) {
            errorObj.password.isError = true;
            errorObj.password.errorText = "Password doesn't match all criterias";
            returnValue = false;
        }
        if (!companyUrlValidate()) {
            errorObj.company_url.isError = true;
            errorObj.company_url.errorText = "Url format is invalid. Make sure your url input is as follows: http://www.example.com or https://www.example.com"
            returnValue = false;
        }
        setErrorObj(errorObj);
        return returnValue;
    }

    function toggleVisibilityPassword(index: number) {
        setShowPassword((prevShowPassword) => {
            let newShowPassword = [...prevShowPassword];
            if (newShowPassword[index]) {
                newShowPassword[index] = false;
            } else {
                newShowPassword[index] = true;
            }
            return newShowPassword;
        })
    }

    function formSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setShowSuccessMessage(false);
        if (clientSideValidation()) {
            let queryParams = {
                section: "admin_signup"
            }
            let formData = new FormData();
            formData.append("first_name", firstName);
            formData.append("last_name", lastName);
            formData.append("company_name", companyName);
            formData.append("company_url", companyUrl);
            formData.append("email", email);
            formData.append("password", password);
            formData.append("re_password", rePassword);
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
            requestResponse.then(() => {
                //Success
                clearForm();
                setShowSuccessMessage(true);
            }).catch((error) => {
                let [success, fail, failResponse] = parseQueryResponseOnFail(error);
                let errorObj = getEmptyErrorObj();
                parseQueryResponseOnFailResponse(errorObj, failResponse);
                setErrorObj(errorObj);
            })
        }
    }

    function validatePassword(section: "charLimitCase" | "upperCase" | "numberCase" | "specialCharCase" | "passMatchCase") {
        let returnValue = false;
        if (section == "charLimitCase") {
            returnValue = (password.length >= 8 && password.length <= 20);
        } else if (section == "upperCase") {
            returnValue = (/[A-Z]/.test(password));
        } else if (section == "numberCase") {
            returnValue = (/[0-9]/.test(password));
        } else if (section == "specialCharCase") {
            returnValue = (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password));
        } else if (section == "passMatchCase") {
            returnValue = (password !== "" && password == rePassword);
        }
        if (returnValue) {
            return "visible";
        }
        return "hidden";
    }

    function holisticValidatePassword() {
        let arrCases = ["charLimitCase", "upperCase", "numberCase", "specialCharCase", "passMatchCase"];
        let returnValue = true;
        arrCases.forEach((currCase) => {
            if (validatePassword(currCase as any) == "hidden") {
                returnValue = false;
            }
        })
        return returnValue;
    }

    function companyUrlValidate() {
        let regex = new RegExp("((http|https)://)(www.)?"
            + "[a-zA-Z0-9@:%._\\+~#?&//=]"
            + "{2,256}\\.[a-z]"
            + "{2,6}\\b([-a-zA-Z0-9@:%"
            + "._\\+~#?&//=]*)");
        if (companyUrl !== "") {
            if (regex.test(companyUrl)) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    function signupFormElements() {
        return (
            <ul className="typeInput clearfix">
                <div className="right_contact_form">
                    <div className="form-group" style={{ paddingBottom: '0px', marginBottom: '0px' }}>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                if (errorObj.first_name.isError) {
                                    className += " is-invalid";
                                }
                                return (
                                    <React.Fragment>
                                        <input type="text" name="f_name" className={className}
                                            placeholder="First Name" 
                                            value={firstName} onChange={(e) => formFieldChange(e, "firstName")} />
                                        {(() => {
                                            if (errorObj.first_name.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.first_name.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                if (errorObj.last_name.isError) {
                                    className += " is-invalid";
                                }
                                return (
                                    <React.Fragment>
                                        <input type="text" name="l_name" className={className}
                                            placeholder="Last name" 
                                            value={lastName} onChange={(e) => formFieldChange(e, "lastName")} />
                                        {(() => {
                                            if (errorObj.last_name.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.last_name.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                if (errorObj.company_name.isError) {
                                    className += " is-invalid";
                                }
                                return (
                                    <React.Fragment>
                                        <input type="text" name="company_name" className={className}
                                            placeholder="Company Name" 
                                            value={companyName} onChange={(e) => formFieldChange(e, "companyName")} />
                                        {(() => {
                                            if (errorObj.company_name.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.company_name.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                        <li>
                            {(() => {
                                let className = "form-control";
                                if (errorObj.company_url.isError) {
                                    className += " is-invalid";
                                }
                                return (
                                    <React.Fragment>
                                        <input id="cmpurl" type="text" name="company_url" className={className}
                                            placeholder="Company URL" 
                                            value={companyUrl} onChange={(e) => formFieldChange(e, "companyUrl")} />
                                        {(() => {
                                            if (errorObj.company_url.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.company_url.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                if (errorObj.email.isError) {
                                    className += " is-invalid";
                                }
                                return (
                                    <React.Fragment>
                                        <input type="email" name="user" className={className}
                                            placeholder="Email" 
                                            value={email} onChange={(e) => formFieldChange(e, "email")} />
                                        {(() => {
                                            if (errorObj.email.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.email.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                let inputType = "password";
                                let eyeClassName = "fa fa-eye fa-lg";
                                if (errorObj.password.isError) {
                                    className += " is-invalid";
                                }
                                if (showPassword[0]) {
                                    inputType = "text";
                                    eyeClassName = "fa fa-eye-slash fa-lg"
                                }
                                return (
                                    <React.Fragment>
                                        <input type={inputType} name="password" id="password" className={className}
                                            placeholder="Password" 
                                            value={password} onChange={(e) => formFieldChange(e, "password")} />
                                        {(() => {
                                            if (errorObj.password.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.password.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                        <a href="#" onClick={() => toggleVisibilityPassword(0)}>
                                            <span className="eyeIcon">
                                                <i id="eye" className={eyeClassName} />
                                            </span>
                                        </a>
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                let inputType = "password";
                                let eyeClassName = "fa fa-eye fa-lg";
                                if (errorObj.re_password.isError) {
                                    className += " is-invalid";
                                }
                                if (showPassword[1]) {
                                    inputType = "text";
                                    eyeClassName = "fa fa-eye-slash fa-lg"
                                }
                                return (
                                    <React.Fragment>
                                        <input type={inputType} name="r_password" id="repassword" className={className}
                                            placeholder="Re-Enter password" title="" 
                                            value={rePassword} onChange={(e) => formFieldChange(e, "rePassword")} />
                                        {(() => {
                                            if (errorObj.re_password.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.re_password.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                        <a href="#" onClick={() => toggleVisibilityPassword(1)}>
                                            <span className="eyeIcon">
                                                <i id="eye" className={eyeClassName} />
                                            </span>
                                        </a>
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                    </div>
                </div>
            </ul>
        )
    }

    function passwordRequirements() {
        return (
            <div className="card shadow clearfix">
                <div className="col-12 passwordReq" style={{maxHeight:"27.5vh"}}>
                    <p className="italicFont mt-0 mb-0" style={{fontSize:"80%"}}>Password Requirements:</p>
                    <ul className="pl-0">
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("charLimitCase")
                            }}>✓</h3>
                            <p style={{display:"inline"}}>8-20 characters</p>
                        </div>
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("upperCase")
                            }}>✓</h3>
                            <p  style={{display:"inline"}}>At least one uppercase letter</p>
                            
                        </div>
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("numberCase")
                            }}>✓</h3>
                            <p style={{display:"inline"}}>At least one number</p>
                        </div>
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("specialCharCase")
                            }}>✓</h3>
                            <p style={{display:"inline"}}>At least one special character</p>
                        </div>
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("passMatchCase")
                            }}>✓</h3>
                            <p style={{display:"inline"}}>Password Match</p>
                        </div>
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className="footer_section bg_with_circle">
            {(() => {
                let className = "alert alert-success w-50 fixed-bottom col-md-4 col-md-offset-4 alert-dismissible fade";
                if (showSuccessMessage) {
                    className += " show";
                }
                return (
                    <div className="row">
                        <div className={className} role="alert">
                            Thank You! Your account has been created. We will review your request and let you know once it is ready for use
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )
            })()}
            <div className="banner_content_area">
                <section id="mainsigin">
                    <section className="loginpageSec clearfix" style={{height:"auto"}}>
                        <div className="row">
                            
                            <div className="col-md-6 col-sm-12 pr-0" >
                                <div className="leftBgClr clearfix adjustBlueside" style={{width:"100%"}}>
                                    <div className="loginLogo" >
                                        <img src={headerLogo} alt="login"/>
                                    </div>
                                    <h2 className="signupMobileTitle">Tax Office Sign Up</h2>
                                    <div className="background-circle-animation">
                                        <div className="bubbleBase">
                                            <img src={smallBubble} alt="" className="circle-opacity" />
                                        </div>
                                        <div className="bubbleBase2">
                                            <img src={smallBubble} alt="" className="circle-opacity" />
                                        </div>
                                        <div className="bubbleBase3">
                                            <img src={smallBubble} alt="" className="circle-opacity" />
                                        </div>
                                        <div className="bubbleBase4">
                                            <img src={smallBubble} alt="" className="circle-opacity" />
                                        </div>
                                        <div className="bubbleBase5">
                                            <img src={smallBubble} alt="" className="circle-opacity" />
                                        </div>
                                        <div className="bubbleBase6">
                                            <img src={smallBubble} alt="" className="circle-opacity" />
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className="col-md-6 col-sm-12 pl-0">
                                <div className="rightBgClr clearfix" style={{ height: '110vh' ,width:"100%"}}>
                                <div className="loginCont clearfix" style={{ height: '100%', marginTop: '10px' }}>

                                    <h2 className="signupWebTitle">Tax Office Sign Up</h2>
                                    <p className="italicFont" >Please enter your details</p>

                                    <form style={{ height: '50%'}} onSubmit={formSubmit}>
                                        {signupFormElements()}
                                        <div className="rememberSec clearfix pt-10">
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                <button id="signup_btn" type="submit" className="btn-secondary-add-cpasage mt-0 ml-0"
                                                style={{  padding: '3% 10%' }} >Sign up</button>
                                                </div>
                                                <div className="col-md-6 col-sm-12 p">
                                                <Link to={urls.G_SIGNIN} className="exitUs pt-10"><span>Existing User? Login</span></Link>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <br />
                                        {passwordRequirements()}
                                        <br /><br />
                                    </form>
                                </div>
                                </div>
                            </div>
                        </div>
                        

                        
                    </section>
                </section>
                <AuthFooter />
            </div>
        </div>
    )

}

export default AdminSignup;