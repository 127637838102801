import React from "react";
import { useState } from "react";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";

const override = css`
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 33vh;
    margin-right: 40vw;
`;
function Loading() {   
            return (
              <div className="sweet-loading">
                <ScaleLoader color={"#4b5ca9"} loading={true} css={override} height={80} width={6} margin={5} radius={4} />
              </div>
            );
}

export default Loading;