import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from "axios";

import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalFilePicker from "../../../intelws_portal/constructs/modal/ModalFilePicker";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";

import { parseQueryResponseOnFail, parseQueryResponseOnSuccess } from "../../../declarations/common_utils";

interface IPostBulkClientAdd extends AxiosResponse {
    data: {
        data: IDataRepr[]
    }
}

export interface IModalClientBulk {
    mutator: (data: IDataRepr[], success: string[], fail: string[]) => void
}

function ModalClientBulk(props: IModalClientBulk) {
    const ADD_ENDPOINT = "api/add/";
    const [csvFile, setCsvFile] = React.useState<File | undefined>(undefined);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        $("#clientBulkAddModal").on("hidden.bs.modal", clearForm)
        return () => {
            cancelTokenSource.current.cancel();
            $("#clientBulkAddModal").off("hidden.bs.modal");
        }
    }, [])

    function clearForm() {
        setCsvFile(undefined);
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files != null) {
            setCsvFile(e.target.files[0]);
        }
    }

    function submitForm() {
        let formData = new FormData();
        if (csvFile != undefined) {
            formData.append("file", csvFile);
        }
        let queryParams = {
            type: "client_bulk"
        }
        const requestResponse = post(ADD_ENDPOINT, formData, cancelTokenSource.current.token, queryParams, true) as Promise<IPostBulkClientAdd>;
        requestResponse.then((response) => {
            let [success, fail] = parseQueryResponseOnSuccess(response);
            props.mutator(response.data.data, success, fail);
        }).catch((error) => {
            let [success, fail] = parseQueryResponseOnFail(error);
            props.mutator([], success, fail);
        }).finally(() => {
            $("#clientBulkAddModal").modal("hide");
        })
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="clientBulkAddModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Add Bulk Clients"} />
                        <div className="modal-body">
                            <ModalFilePicker onChange={formFieldChange} value={csvFile} />
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={false} />
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalClientBulk;