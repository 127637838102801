import React from 'react';
import * as urls from '../../Urls';
import { Link } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { get, getCancelToken } from "../../../intelws_portal/utils/backendInterface";

import cpaLogo from "../../../external/backPages/img/images/aws_brand_logo.svg";
import Notification, { INotificationProps } from './Notification';

const WS_NOTIF_ENDPOINT = urls.WS_HOSTNAME + "api/notifications/";

export interface IAdminMenuMainProps {
    currentPage: string,
    initials?: string,
    calendar?: boolean,
    dashboard?: boolean,
    workflow?: boolean,
    manual_task?: boolean,
    client?: boolean,
    recep_client?: boolean,
    team?: boolean,
    insight?: boolean,
    notification_table?: boolean,
    settings?: boolean,
    tickets?: boolean,
    client_settings?: boolean,
    client_dashboard_option?: boolean,
    client_communication_option?: boolean,
    client_documents_option?: boolean,
    client_status_option?: boolean,
    client_business_option?: boolean,
    client_organizer_option?: boolean,
    client_invoice_option?: boolean,
    client_project_option?: boolean,
    client_dependent_option?: boolean,
    lead_tracking?: boolean,
    password_management?: boolean,
    user_referral_review?: boolean,
    admin_payment_freeze?: boolean,
    cpaLogoUrl?: string,
    setCpaLogoUrl: React.Dispatch<React.SetStateAction<string | undefined>>
}

export interface IFetchNotifResponseRepr extends AxiosResponse {
    data: {
        notif: INotificationProps[]
    }
}

interface IFetchCPALogo extends AxiosResponse {
    data: {
        logo_url?: string
    }
}

function AdminMenuMain(props: IAdminMenuMainProps) {
    const SETTINGS_ENDPOINT = "api/settings/";
    const [notif, setNotif] = React.useState<INotificationProps[] | undefined>(undefined);

    React.useEffect(() => {
        const cancelTokenSource = getCancelToken();
        const notifRequestResponse = get(urls.CONFIG_ENDPOINT, cancelTokenSource.token, { section: "notifications" }) as Promise<IFetchNotifResponseRepr>;
        const cpaLogoReqResp = get(SETTINGS_ENDPOINT, cancelTokenSource.token, { "section": "admin_logo" }) as Promise<IFetchCPALogo>;
        Promise.all([notifRequestResponse, cpaLogoReqResp]).then(([notifResponse, cpaLogoResponse]) => {
            setNotif(notifResponse.data.notif);
            props.setCpaLogoUrl(cpaLogoResponse.data.logo_url);
        })
        const ws = new WebSocket(WS_NOTIF_ENDPOINT);
        ws.onmessage = (streamedData) => {
            const parsedData: INotificationProps = JSON.parse(streamedData.data);
            setNotif((prevNotif) => {
                if (prevNotif != undefined) {
                    let newNotif = [...prevNotif];
                    let updateIndex = newNotif.findIndex((ele) => {
                        return ele != undefined && ele.type == parsedData.type
                    })
                    if (updateIndex != -1) {
                        newNotif[updateIndex] = parsedData;
                    }
                    return newNotif;
                }
                return undefined;
            })
        }
        return () => {
            ws.close();
            cancelTokenSource.cancel();
        }
    }, [])

    function getClassName(menuOption: string) {
        if (menuOption == props.currentPage) {
            return "flecx active";
        } else {
            return "flecx";
        }
    }

    function getMiscClassName() {
        if (props.currentPage == "TeamAction" || props.currentPage == "Insight" || 
            props.currentPage == "LeadTracking" || props.currentPage == "Notifications" ||
            props.currentPage == "ReferReview") {
                return "flecx active";
            }
        return "flecx";
    }

    function getNotifications() {
        if (notif == undefined) {
            return undefined;
        } else {
            return notif.map((ele, index) => {
                return (
                    <Notification key={index} type={ele.type} dtype={ele.dtype} records={ele.records} global_count={ele.global_count}
                        disabled={ele.disabled} />
                )
            })
        }
    }

    function getIsShowMisc() {
        return (
            (props.team == undefined || props.team) ||
            (props.insight == undefined || props.insight) ||
            (props.lead_tracking == undefined || props.lead_tracking) ||
            (props.notification_table == undefined || props.notification_table) ||
            (props.user_referral_review == undefined || props.user_referral_review)
        )
    }

    return (
        <nav className="navbar navbar-light navbar-expand topbar static-top">
            <img src={cpaLogo} width={60} height={60} style={{ marginRight: "15px" }} />
            {(() => {
                if (props.cpaLogoUrl != undefined) {
                    return <img src={props.cpaLogoUrl} width={25} height={25} style={{ borderRadius: "50%" }} />

                }
            })()}
            {(() => {
                if (props.admin_payment_freeze == undefined || (props.admin_payment_freeze != undefined && !props.admin_payment_freeze)) {
                    return (
                        <div className="container-fluid">
                            <ul className="top-left-menu-item-container" style={{ marginBottom: "0rem" }}>
                                {(() => {
                                    if (props.dashboard == undefined || props.dashboard) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.ADMIN_DASHBOARD} id="admin_main_dashboard" className={getClassName("Dashboard")}>
                                                    <span>Dashboard </span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (props.workflow == undefined || props.workflow) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.COMMON_TASK_LIST} id="taskWorkflow" className={getClassName("Task")}>
                                                    <span>TaxFlow</span>
                                                </Link>
                                            </div>
                                        )
                                    }

                                })()}
                                {(() => {
                                    if (props.manual_task == undefined || props.manual_task) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.COMMON_MANUAL_TASK_LIST} id="taskWorkflow" className={getClassName("ManualTask")}>
                                                    <span>ProjectFlow</span>
                                                </Link>
                                            </div>
                                        )
                                    }

                                })()}
                                {(() => {
                                    if (props.client == undefined || props.client) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.ADMIN_CLIENT_ACTION} id="clientActions" className={getClassName("ClientAction")}>
                                                    <span>Client</span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (props.recep_client == undefined || props.recep_client) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.RECEP_CLIENT_LIST} id="clientActions" className={getClassName("ClientAction")}>
                                                    <span>Client</span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (props.calendar == undefined || props.calendar) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.ADMIN_SCHEDULER} className={getClassName("Calendar")}>
                                                    <span>Calendar</span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (getIsShowMisc()) {
                                        return (
                                            <div className={"dropdown " + getMiscClassName()} style={{ marginTop: 3 }}>
                                                <div className={"top-left-menu-item dropdown "}>
                                                    <a data-toggle="dropdown" type='button' style={{ color: "white", fontWeight: 399 }} href="#"> Misc
                                                        <i className="chevron bottom" ></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-center dropdown-list dropdown-menu-center animated-grow-in shadow" style={{ marginTop: "1.0rem" }} >
                                                        <h6 className="dropdown-header shadow-sm" style={{ marginBottom: "5px" }} > Misc
                                                        </h6>
                                                        {(() => {
                                                            if (props.team == undefined || props.team) {
                                                                return (
                                                                    <Link className="contHover" style={{ color: "#495057fc" }} to={urls.ADMIN_EMP_ACTION}>
                                                                        <div className="secoWidth">
                                                                            <span style={{ fontSize: "12px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "0px", }}> Team</span>
                                                                        </div>
                                                                    </Link>
                                                                )
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (props.insight == undefined || props.insight) {
                                                                return (
                                                                    <Link className="contHover" style={{ color: "#495057fc" }} to={urls.ADMIN_INSIGHT}>
                                                                        <div className="secoWidth">
                                                                            <span style={{ fontSize: "12px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "0px", }}>Insight</span>
                                                                        </div>
                                                                    </Link>
                                                                )
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (props.lead_tracking == undefined || props.lead_tracking) {
                                                                return (
                                                                    <Link className="contHover" style={{ color: "#495057fc" }} to={urls.LEADS}>
                                                                        <div className="secoWidth">
                                                                            <span style={{ fontSize: "12px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "0px", }}>Lead</span>
                                                                        </div>
                                                                    </Link>
                                                                )
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (props.notification_table == undefined || props.notification_table) {
                                                                return (
                                                                    <Link className="contHover" style={{ color: "#495057fc" }} to={urls.ADMIN_NOTIFICATIONS}>
                                                                        <div className="secoWidth">
                                                                            <span style={{ fontSize: "12px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "0px", }}>Event</span>
                                                                        </div>
                                                                    </Link>
                                                                )
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (props.user_referral_review == undefined || props.user_referral_review) {
                                                                return (
                                                                    <Link className="contHover" style={{ color: "#495057fc" }} to={urls.USER_REFER_REVIEW}>
                                                                        <div className="secoWidth">
                                                                            <span style={{ fontSize: "12px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "0px", }}>Referral &amp; Review</span>
                                                                        </div>
                                                                    </Link>
                                                                )
                                                            }
                                                        })()}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}

                                {(() => {
                                    if (props.client_dashboard_option == undefined || props.client_dashboard_option) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.CLIENT_DASHBOARD} id="clientDashboardNav" className={getClassName("ClientDashboard")}>
                                                    <span>Dashboard</span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (props.client_communication_option == undefined || props.client_communication_option) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.CLIENT_COMMUNICATION} id="clientCommunicationNav" className={getClassName("ClientCommunication")}>
                                                    <span>Communication</span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (props.client_documents_option == undefined || props.client_documents_option) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.CLIENT_DOCUMENT} id="clientDocumentNav" className={getClassName("ClientDocuments")}>
                                                    <span>Tax Docs</span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (props.client_status_option == undefined || props.client_status_option) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.CLIENT_STATUS} id="clientStatusNav" className={getClassName("ClientStatus")}>
                                                    <span>Tax Status</span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (props.client_business_option == undefined || props.client_business_option) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.CLIENT_BUSINESS} id="clientBusinessNav" className={getClassName("ClientBusiness")}>
                                                    <span>Business</span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (props.client_organizer_option == undefined || props.client_organizer_option) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.CLIENT_ORGANIZER} id="clientOrganizer" className={getClassName("ClientOrganizer")}>
                                                    <span>Organizer</span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (props.client_invoice_option == undefined || props.client_invoice_option) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.CLIENT_INVOICE} id="clientInvoice" className={getClassName("ClientInvoice")}>
                                                    <span>Invoice</span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (props.client_project_option == undefined || props.client_project_option) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.CLIENT_PROJECT} id="clientProject" className={getClassName("ClientProject")}>
                                                    <span>Projects</span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (props.client_dependent_option == undefined || props.client_dependent_option) {
                                        return (
                                            <div className="top-left-menu-item">
                                                <Link to={urls.CLIENT_DEPENDENT} id="clientDependent" className={getClassName("ClientProject")}>
                                                    <span>Dependent</span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                })()}

                            </ul>
                        </div>
                    )
                }
            })()}
           
            <div className="top-right-menu-item-container">
                {getNotifications()}
                <div className="nav-item dropdown no-arrow show" style={{ paddingLeft: "18px" }}>
                    <div className="nav-item dropdown no-arrow">
                        <a data-toggle="dropdown" id="profileDropdown" style={{ color: "white" }} href="#"> {props.initials} </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-list dropdown-menu-right animated-grow-in shadow" style={{ marginTop: "0.7rem" }}>
                            <div><h6 className="dropdown-header shadow-sm" style={{ marginBottom: "5px" }}>Profile</h6></div>
                            {(() => {
                                if (props.client_settings != null && props.client_settings) {
                                    return (
                                        <Link className="contHover" style={{ color: "#495057fc" }} id="settings" to={urls.CLIENT_SETTINGS}>
                                            <div className="secoWidth">
                                                <span style={{ fontSize: "12px", paddingTop: "5px", paddingBottom: "5px", paddingLeft: "1px", }}> Settings</span>
                                            </div>
                                        </Link>

                                    )
                                }
                            })()}
                            {(() => {
                                if (props.settings != null && props.settings) {
                                    return (
                                        <Link className="contHover" style={{ color: "#495057fc" }} id="settings" to={urls.ADMIN_SETTINGS}>
                                            <div className="secoWidth">
                                                <span style={{ fontSize: "12px", paddingTop: "5px", paddingBottom: "5px", paddingLeft: "1px", }}> Settings</span>
                                            </div>
                                        </Link>

                                    )
                                }
                            })()}

                            {(() => {
                                if (props.tickets != null && props.tickets) {
                                    return (
                                        <Link className="contHover" style={{ color: "#495057fc" }} id="supportTickets" to={urls.TICKETS}>
                                            <div className="secoWidth">
                                                <span style={{ fontSize: "12px", paddingTop: "5px", paddingBottom: "5px", paddingLeft: "2px" }}> Support Tickets</span>
                                            </div>
                                        </Link>
                                    )
                                }
                            })()}

                            <a className="contHover" style={{ color: "#495057fc" }} id="logout" href="/logout/">
                                <div className="secoWidth">
                                    <span style={{ fontSize: "12px", paddingTop: "5px", paddingBottom: "5px", paddingLeft: "3px" }}> Logout</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav >
    )
}

export default AdminMenuMain;
