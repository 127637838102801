import React from "react";
import search from "../../../external/backPages/img/images/search.svg"
export interface ISearchInputProps {
    searchString: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

function SearchInput(props: ISearchInputProps) {
    return (
        <div className="Searchbg">
            <div className="input-group input-group-sm" style={{border:"2px solid rgb(206 212 218)", borderRadius: "5px"}}>
                <img src={search} alt="searchIcon" style={{margin: "5px 0px 5px 5px"}} />
                <input className="form-control"
                    value={props.searchString}
                    type="text"
                    placeholder="Search...."
                    aria-label="Search" id="myInput"
                    onChange={props.onChange}
                    style={{border:"none"}}>
                </input>
            </div>
        </div>
    )
}

export default SearchInput;