import React from "react";
import * as urls from "../../../../Urls";
import { Link } from "react-router-dom";
import { IPageComponentProps } from "../../../../../declarations/common";
import AuthFooter from "./UIComponents/AuthFooter";
import AuthSidePanel from "./UIComponents/AuthSidePanel";
import { get, getCancelToken, post } from "../../../../../intelws_portal/utils/backendInterface";
import { parseQueryResponseOnFail, parseQueryResponseOnFailResponse } from "../../../../../declarations/common_utils";
import { AxiosResponse } from "axios";
import AdminFooter from "../admin_home_pages/AdminFooter";
import { IRedirectResponse } from "./Signin";

interface IAdminInfoFetch extends AxiosResponse {
    data: {
        data: {
            admin_company: string
        }
    }
}

interface IUrlParams {
    adminCode?: string,
    accessMark: string,
    accessId: string
}

function ResetPassword(props: IPageComponentProps<IUrlParams>) {
    const ENDPOINT = "/api/authentication/";
    const [password, setPassword] = React.useState("");
    const [rePassword, setRePassword] = React.useState("");
    const [accessCode, setAccessCode] = React.useState("");
    const [passwordShow, setPasswordShow] = React.useState([false, false]);
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
    const [countdownTimer, setCountdownTimer] = React.useState(5);
    const [companyName, setCompanyName] = React.useState("");
    const [isAdminPage, setIsAdminPage] = React.useState(false);
    const [isFetchError, setIsFetchError] = React.useState(false);
    const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    function fetchedAdminInfo(isSuccess: boolean, response?: IAdminInfoFetch) {
        if (isSuccess && response != undefined) {
            setCompanyName(response.data.data.admin_company);
        } else {
            setIsFetchError(true);
        }
        setIsAdminPage(true);
    }

    function getEmptyErrorObj() {
        return {
            password: {
                isError: false,
                errorText: ""
            },
            re_password: {
                isError: false,
                errorText: ""
            },
            access_code: {
                isError: false,
                errorText: ""
            }
        }
    }

    function clearForm() {
        setPassword("");
        setRePassword("");
        setAccessCode("");
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement>,
        section: "password" | "rePassword" | "accessCode") {
        if (section == "password") {
            setPassword(e.target.value);
        } else if (section == "rePassword") {
            setRePassword(e.target.value);
        } else if (section == "accessCode") {
            setAccessCode(e.target.value);
        }
    }

    function toggleVisibilityPassword(index: number) {
        setPasswordShow((prevPasswordShow) => {
            let newPasswordShow = [...prevPasswordShow];
            if (newPasswordShow[index]) {
                newPasswordShow[index] = false;
            } else {
                newPasswordShow[index] = true;
            }
            return newPasswordShow;
        })
    }

    function clientSideValidation() {
        let errorObj = getEmptyErrorObj();
        let returnValue = true;;
        if (!holisticValidatePassword()) {
            errorObj.password.isError = true;
            errorObj.password.errorText = "Password doesn't match all criterias";
            returnValue = false;
        }
        if (accessCode == "") {
            errorObj.access_code.isError = true;
            errorObj.access_code.errorText = "Please enter access code";
        }
        setErrorObj(errorObj);
        return returnValue;
    }

    function onSuccess(response: IRedirectResponse) {
        setShowSuccessMessage(true);
        clearForm();
        setInterval(() => {
            setCountdownTimer((prevCountdownTimer) => {
                let newCountdownTimer = prevCountdownTimer - 1;
                if (newCountdownTimer == 0) {
                    if (typeof response.data.path == "string") {
                        window.location.pathname = response.data.path;
                    } else {
                        window.location.hash = response.data.path[1];
                        window.location.pathname = response.data.path[0];
                    }
                }
                return newCountdownTimer;
            })
        }, 1000);
    }

    function formSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (clientSideValidation()) {
            let queryParams = {
                section: "reset_password"
            }
            let formData = new FormData();
            formData.append("access_mark", props.match.params.accessMark);
            formData.append("access_id", props.match.params.accessId);
            formData.append("password", password);
            formData.append("re_password", rePassword);
            formData.append("access_code", accessCode);
            if (isAdminPage && props.match.params.adminCode != undefined) {
                formData.append("admin_code", props.match.params.adminCode);
            }
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IRedirectResponse>;
            requestResponse.then((response) => {
                onSuccess(response);
            }).catch((error) => {
                let [success, fail, failResponse] = parseQueryResponseOnFail(error);
                let errorObj = getEmptyErrorObj();
                parseQueryResponseOnFailResponse(errorObj, failResponse);
                setErrorObj(errorObj);
            })
        }
    }

    function validatePassword(section: "charLimitCase" | "upperCase" | "numberCase" | "specialCharCase" | "passMatchCase") {
        let returnValue = false;
        if (section == "charLimitCase") {
            returnValue = (password.length >= 8 && password.length <= 20);
        } else if (section == "upperCase") {
            returnValue = (/[A-Z]/.test(password));
        } else if (section == "numberCase") {
            returnValue = (/[0-9]/.test(password));
        } else if (section == "specialCharCase") {
            returnValue = (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password));
        } else if (section == "passMatchCase") {
            returnValue = (password !== "" && password == rePassword);
        }
        if (returnValue) {
            return "visible";
        }
        return "hidden";
    }

    function holisticValidatePassword() {
        let arrCases = ["charLimitCase", "upperCase", "numberCase", "specialCharCase", "passMatchCase"];
        let returnValue = true;
        arrCases.forEach((currCase) => {
            if (validatePassword(currCase as any) == "hidden") {
                returnValue = false;
            }
        })
        return returnValue;
    }

    function signupFormElements() {
        return (
            <ul className="typeInput clearfix">
                <div className="right_contact_form">
                    <div className="form-group" style={{ paddingBottom: '0px', marginBottom: '0px' }}>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                let inputType = "password";
                                let eyeClassName = "fa fa-eye fa-lg";
                                if (errorObj.password.isError) {
                                    className += " is-invalid";
                                }

                                if (passwordShow[0]) {
                                    inputType = "text";
                                    eyeClassName = "fa fa-eye-slash fa-lg";
                                }
                                return (
                                    <React.Fragment>
                                        <input type={inputType} name="password" id="password" className={className}
                                            placeholder="Password" style={{ paddingTop: '15px', paddingBottom: '15px' }}
                                            value={password} onChange={(e) => formFieldChange(e, "password")} />
                                        {(() => {
                                            if (errorObj.password.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.password.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                        <a href="#" onClick={(e) => toggleVisibilityPassword(0)}>
                                            <span className="eyeIcon">
                                                <i id="eye" className={eyeClassName} />
                                            </span>
                                        </a>
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                let inputType = "password";
                                let eyeClassName = "fa fa-eye fa-lg";
                                if (errorObj.re_password.isError) {
                                    className += " is-invalid";
                                }
                                if (passwordShow[1]) {
                                    inputType = "text";
                                    eyeClassName = "fa fa-eye-slash fa-lg"
                                }
                                return (
                                    <React.Fragment>
                                        <input type={inputType} name="r_password" id="repassword" className={className}
                                            placeholder="Re-Enter password" title="" style={{ paddingTop: '15px', paddingBottom: '15px' }}
                                            value={rePassword} onChange={(e) => formFieldChange(e, "rePassword")} />
                                        {(() => {
                                            if (errorObj.re_password.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.re_password.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                        <a href="#" onClick={(e) => toggleVisibilityPassword(1)}>
                                            <span className="eyeIcon">
                                                <i id="eye" className={eyeClassName} />
                                            </span>
                                        </a>
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                        <li>
                            <span className="asterisk_input" style={{ float: "right" }}>  </span>
                            {(() => {
                                let className = "form-control";
                                if (errorObj.access_code.isError) {
                                    className += " is-invalid";
                                }
                                return (
                                    <React.Fragment>
                                        <input type="text" name="access_code" id="access_code" className={className}
                                            placeholder="Enter six digit code" title="" style={{ paddingTop: '15px', paddingBottom: '15px' }}
                                            value={accessCode} onChange={(e) => formFieldChange(e, "accessCode")} />
                                        {(() => {
                                            if (errorObj.access_code.isError) {
                                                return (
                                                    <div className="invalid-feedback">
                                                        {errorObj.access_code.errorText}
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </React.Fragment>
                                )
                            })()}
                        </li>
                    </div>
                </div>
            </ul>
        )
    }

    function passwordRequirements() {
        return (
            <div className="card shadow clearfix">
                <div className="col-12 ">
                    <p className="italicFont mt-0 mb-0">Password Requirements:</p>
                    <ul className="pl-0">
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("charLimitCase")
                            }}>✓</h3>
                            8-20 characters
                        </div>
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("upperCase")
                            }}>✓</h3>
                            At least one uppercase letter
                        </div>
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("numberCase")
                            }}>✓</h3>
                            At least one number
                        </div>
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("specialCharCase")
                            }}>✓</h3>
                            At least one special character: !@#$%^&amp;*()
                        </div>
                        <div className="italicFont mt-0 mb-0">
                            <h3 style={{
                                display: 'inline', marginTop: '-10px', color: 'green',
                                visibility: validatePassword("passMatchCase")
                            }}>✓</h3>
                            Password Match
                        </div>
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className="footer_section bg_with_circle">
            {(() => {
                let className = "alert alert-success w-50 fixed-bottom col-md-4 col-md-offset-4 alert-dismissible fade";
                if (showSuccessMessage) {
                    className += " show";
                }
                return (
                    <div className="row">
                        <div className={className} role="alert">
                            Thank You! Your password has been reset. Redirecting to login in {countdownTimer} ...
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )
            })()}
            <div className="banner_content_area">
                <section id="mainsigin">
                    <section className="loginpageSec clearfix">
                        <AuthSidePanel />
                        <div className="rightBgClr f-right clearfix" style={{ height: '110vh' }}>
                            <div className="loginCont clearfix">
                                {(() => {
                                    if (isAdminPage) {
                                        if (isFetchError) {
                                            return <a href="#"><b></b>Invalid Request</a>
                                        } else {
                                            return <a href="#"><b></b>{companyName}</a>
                                        }
                                    }
                                })()}
                                <h2>Reset Password</h2>
                                <p className="italicFont">Please enter your details</p>
                                <form style={{ height: '50%', paddingBottom: '20%' }} onSubmit={formSubmit}>
                                    {signupFormElements()}
                                    <div className="rememberSec clearfix pt-10">
                                        <button id="signup_btn" type="submit" className="f-left btn-secondary-add-cpasage mt-0 ml-0"
                                            style={{ width: '40%', padding: '3% 2%' }} >Submit</button>
                                        <Link to={urls.G_SIGNIN} className="f-right exitUs pt-10"><span>Existing User? Login</span></Link>
                                    </div>
                                    <br />
                                    {passwordRequirements()}
                                    <br /><br />
                                </form>
                            </div>
                        </div>
                    </section>
                </section>
                {(() => {
                    if (props.match.params.adminCode != undefined) {
                        return <AdminFooter adminCode={props.match.params.adminCode} fetchedAdminInfo={fetchedAdminInfo} />
                    } else {
                        return <AuthFooter />
                    }
                })()}
            </div>
        </div>
    )
}

export default ResetPassword;