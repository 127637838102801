import React from "react";
import { AxiosResponse } from "axios";

import ContextBoxTable from "../../../intelws_portal/bundles/ContentBoxTable";
import Loading from '../../../intelws_portal/constructs/elements/Loading';
import ScrollTop from "../../../intelws_portal/constructs/elements/ScrollTop";
import { IButtonRepr, IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { get, getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../declarations/common";

interface IFetchAdminList extends AxiosResponse {
    data: {
        data: IDataRepr[],
        column_names: string[],
        access_keys: string[]
    }
}

interface IPostAdminAccess extends AxiosResponse {
    data: {
        data: {
            active_status: string,
            display: string
        }
    }
}

function SuperAdminDashboard(props: IPageComponentProps) {
    const ENDPOINT = "api/superadmin/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [tableData, setTableData] = React.useState<IDataRepr[]>([]);
    const [columnNames, setColumnNames] = React.useState<string[]>([]);
    const [accessKeys, setAccessKeys] = React.useState<string[]>([]);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("saDashboard");
        }
        let queryParams = {
            section: "dashboard"
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchAdminList>;
        requestResponse.then((response) => {
            setIsLoaded(true);
            setTableData(response.data.data);
            setColumnNames(response.data.column_names);
            setAccessKeys(response.data.access_keys);
        })
    }, [])

    function inputCallback(rowIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        
        if (value == "deleteAdmin") {
            let queryParams = {
                section: "admin_delete"
            }
            let formData = new FormData();
            formData.append("admin_id", tableData[rowIndex].id.toString());
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
            requestResponse.then(() => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    newTableData = newTableData.filter((ele) => ele.id !== newTableData[rowIndex].id);
                    return newTableData;
                })
            })
        } else {
            let formData = new FormData();
            formData.append("admin_id", tableData[rowIndex].id.toString());
            if (value != undefined) {
                formData.append("admin_active_status", value.toString());
            }
            let queryParams = {
                section: "admin_active_config"
            }
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostAdminAccess>;
            requestResponse.then((response) => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    let currActionButton = newTableData[rowIndex]["actionButton"] as IButtonRepr[];
                    currActionButton[0].identifier = response.data.data.active_status;
                    currActionButton[0].display = response.data.data.display;
                    return newTableData;
                })
            })
        }
    }

    function getContent() {
        return (
            <div className="container-fluid bottom-sec shadow">
                <ContextBoxTable tableData={tableData}
                    columnNames={columnNames} accessKeys={accessKeys} title="Dashboard"
                    hasSearch={true} hasRecords={true} inputCallback={inputCallback}
                />
            </div>
        )
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div>
                {getContent()}
                <ScrollTop />
            </div>
        )
    }

}

export default SuperAdminDashboard;
