import React from 'react';

import * as urls from "../../Urls";

import clientInactiveSvg from "../../../external/backPages/img/images/client-inactive.svg";

export interface INotifcationRecordRepr {
    id: number,
    initials: string,
    time: string,
    msg: string,
    is_client?: boolean,
    name?: string,
    count?: number,
    user_id?: number
}

export interface INotificationProps {
    global_count: number,
    type: string,
    dtype: string,
    records: INotifcationRecordRepr[],
    disabled?: boolean
}


function Notification(props: INotificationProps) {
    function getIconClassName() {
        if (props.type == "client") {
            return "fa fa-comment-o fa-inverse";
        } else if (props.type == "team") {
            return "fa fa-comments-o fa-inverse";
        } else if (props.type == "todo") {
            return "fa fa-bell fa-inverse";
        } else if (props.type == "alert") {
            return "fa fa-bell fa-inverse";
        }
    }

    function getIconJsx() {
        if (props.records.length != 0) {
            return (
                <span>
                    <i className={getIconClassName()}></i>
                    <span className="badge badge-danger badge-cpasage-notif">{props.global_count}</span>
                </span>
            )
        } else {
            
            return (
                <span>
                    <i className={getIconClassName()}></i>
                </span>
            )
        }
    }

    function displayNotifRecords() {
        if (props.records.length == 0) {
            return (
                <a className="d-flex align-items-center dropdown-item pb-3">
                    <div>
                        <p className="cpasage-para-style">No {props.dtype}</p>
                    </div>
                </a>
            )
        } else {
            return props.records.map((ele, index) => {
                let linked_url = urls.BASE_COMMON_CLIENT_DASHBOARD + ele.user_id + "/";
                if (ele.is_client == true) {
                    if (props.type == "client") {
                        linked_url = urls.CLIENT_COMMUNICATION;
                    } else {
                        linked_url = urls.CLIENT_DASHBOARD;
                    }
                }
                return (
                    <a key={index} className="d-flex align-items-center dropdown-item pb-3" style={{paddingTop: 19}} href={linked_url}>
                        <div className="mr-3">
                            <span className="icon-circle">
                            <img src={clientInactiveSvg} className="inactive" alt=""/>
                                {/* <span className="pl-2" style={{ color: "#ffff" }}>{ele.initials}</span>*/}
                                <span className="badge badge-danger badge-cpasage-dropdownnotif">{ele.count}</span> 
                            </span>
                        </div>
                  
                        <div>
                            <span className="small text-gray-500 float-left">{ele.name}</span>
                            <span className="small text-gray-500 float-right">{ele.time}</span>
                            <p className="cpasage-para-style">{ele.msg}</p>
                        </div>
                    </a>
                )
            })
        }
    }

    function getSeeMoreUrl() {
        let hash = props.type;
        if (props.type == "alert") {
            hash = "user_tracking";
        }
        return "/notifications/#" + hash;
    }

    function getNotifBody() {
        if (props.records.length != 0 && (props.disabled == undefined || (props.disabled != undefined && !props.disabled))) {
            return (
                <div className="dropdown-menu dropdown-menu-right dropdown-list dropdown-menu-right animated-grow-in shadow" style={{paddingBottom:"0%"}}>
                    <div>
                        <h6 className="dropdown-header shadow-sm">{props.dtype}</h6>
                        <a id="seeAllNotifiMsgs" href="#">
                        </a>
                    </div>
                    {displayNotifRecords()}
                   {(()=>{
                       if(!("is_client" in props.records[0] && props.records[0].is_client === true )){
                           return(
                           <a href={getSeeMoreUrl()}>
                           <h6 className="dropdown-header shadow-sm">See More </h6></a>)
                       }
                   })()}
                </div>
            )
        }
    }

    return (
        <div className="nav-item dropdown">
            <div className="nav-item dropdown no-arrow show">
                <a className="dropdown-togggle nav-link" data-toggle="dropdown" id={props.type + "Messages"} href="#">
                    {getIconJsx()}
                </a>
                {getNotifBody()}
            </div>
        </div>
    )
}

export default Notification;