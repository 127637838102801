import React from "react";

import ContentBoxTabTable from '../../../intelws_portal/bundles/ContentBoxTabTable';
import Loading from '../../../intelws_portal/constructs/elements/Loading';
import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import { get } from "../../../intelws_portal/utils/backendInterface";

import * as urls from '../../Urls';
import { IFetchUserListResponseRepr } from "../admin/AdminClientAction";
import { IPageComponentProps } from "../../../declarations/common";

function ClientList(props: IPageComponentProps) {
    const ENDPOINT = "api/userlist/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState("active");
    const [tableData, setTableData] = React.useState<(IDataRepr[] | undefined)[]>([undefined, undefined, undefined]);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ClientAction");
        }
        let queryParams = { section: "active" };
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchUserListResponseRepr>;
        requestResponse.then((response) => {
            setIsLoaded(true);
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                newTableData[0] = response.data.data;
                return newTableData;
            })
        })
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    function tabCallback(tabName: string, index: number) {
        let shouldFetch = (tableData[index] == undefined);
        setCurrentTab(tabName);
        if (shouldFetch) {
            let queryParams = { section: tabName };
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchUserListResponseRepr>;
            requestResponse.then((response) => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    newTableData[index] = response.data.data;
                    return newTableData;
                })
            })
        }
    }

    function getContent() {
        let columnName = ['First', 'Last', 'Email', 'Contact #', 'When Created'];
        let accessKey = ['user_first_name', 'user_last_name', 'user_email', 'user_phone_num', 'user_create_time'];
        let link = { user_first_name: { baseUrl: urls.BASE_COMMON_CLIENT_DASHBOARD, urlParams: ['id'], id: ['user_email'] } };
        let tabs = [{ target: "Active", id: "activeTab", active: true, display: "Active" },
        { target: "Inactive", id: "inactiveTab", active: false, display: "Inactive" },
        { target: "Archive", id: "archiveTab", active: false, display: "Archive" }];
        let columnNames = [columnName, columnName, columnName];
        let accessKeys = [accessKey, accessKey, accessKey];
        let links = [link, link, link];
        return (<ContentBoxTabTable tabs={tabs} contents={tableData} columnNames={columnNames}
            accessKeys={accessKeys} title="Client" hasSearch={true} tabCallback={tabCallback}
            links={links} hasRecords={true} />)
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper clearfix">
                {getContent()}
            </div>
        )
    }

}


export default ClientList;
