import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from 'axios';


import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { get, post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import ModalDropDown from "../../../intelws_portal/constructs/modal/ModalDropDown";

import { parseQueryResponseOnFail, parseQueryResponseOnFailResponse, parseQueryResponseOnSuccess } from "../../../declarations/common_utils";

export interface IModalTeamEmpInfo extends AxiosResponse {
    data: {
        emp_info: {
            id: number,
            emp_full_name: string
        }[],
        admin_info: {
            admin_full_name: string
        }
    }
}

export interface IFetchEmpResponseRepr extends AxiosResponse {
    data: {
        data: IDataRepr,
        mgr_details?: {
            id: number,
            emp_full_name: string
        }
    }
}

export interface IModalTeamProps {
    mutator: (data?: IDataRepr, success?: string[], fail?: string[]) => void
}

function isString(possString: any): possString is string {
    return typeof possString == "string";
}

function ModalTeam(props: IModalTeamProps) {
    const ADD_ENDPOINT = "api/add/";
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [empType, setEmpType] = React.useState(1);
    const [mgr, setMgr] = React.useState(0);
    const [mgrValues, setMgrValues] = React.useState<number[]>([]);
    const [mgrOptions, setMgrOptions] = React.useState<string[]>([]);
    const [disable, setDisable] = React.useState(true);
    const [isfnameStartwithspace, setisfnameStartwithspace] = React.useState("");
    const [islnameStartwithspace, setislnameStartwithspace] = React.useState("");
    const [isemailStartwithspace, setisemailStartwithspace] = React.useState("");
    const [errorObj, setErrorObj] = React.useState(getEmptyErrorObj);
    const cancelTokenSource = React.useRef(getCancelToken());

    function isstartwithspace(value: string, section: "firstName" | "lastName" | "email" | "phoneNumber"){
        if(value == " " ){
            if(section == "firstName"){
                setisfnameStartwithspace("please start first name with alphanumeric value ")
            }
            else if(section == "lastName"){
                setislnameStartwithspace("please start last name with alphanumeric value ")
            }
            else if(section == "email"){
                setisemailStartwithspace("please start email with alphanumeric value ")
            }
            return(false)
        }
        else{
            setisfnameStartwithspace("")
            setislnameStartwithspace("")
            setisemailStartwithspace("")
            return(true)
        }
    }
    

    React.useEffect(() => {
        const requestResponse = get(ADD_ENDPOINT, cancelTokenSource.current.token, { type: "team" }) as Promise<IModalTeamEmpInfo>;
        $("#teamAddModal").on("hidden.bs.modal", clearForm)
        requestResponse.then((response) => {
            let values = [0];
            let options = [response.data.admin_info.admin_full_name];
            response.data.emp_info.forEach((ele) => {
                values.push(ele.id);
                options.push(ele.emp_full_name);
            })
            setMgrValues(values);
            setMgrOptions(options);
        })
        return () => {
            cancelTokenSource.current.cancel();
            $("#teamAddModal").off("hidden.bs.modal");
        }
    }, [])

    React.useEffect(() => {
        let newDisable = true;
        if (email != "" && firstName != "" && lastName != "") {
            newDisable = false;
        }
        setDisable(newDisable);
    }, [email, firstName, lastName]);

    function clearForm() {
        setFirstName("");
        setLastName("");
        setEmail("");
        setEmpType(1);
        setMgr(0);
        setErrorObj(getEmptyErrorObj);
    }

    function getEmptyErrorObj() {
        return {
            f_name: {
                isError: false,
                errorText: ""
            },
            l_name: {
                isError: false,
                errorText: ""
            },
            email: {
                isError: false,
                errorText: ""
            },
            emp_type: {
                isError: false,
                errorText: ""
            },
            mgr: {
                isError: false,
                errorText: ""
            }
        }
    }

    function formFieldChange(section: "firstName" | "lastName" | "email" | "empType" | "mgr", value: string | number) {
        if (section == "firstName" && isString(value) && isstartwithspace(value, section)) {
            setFirstName(value);
        } else if (section == "lastName" && isString(value) && isstartwithspace(value, section)) {
            setLastName(value);
        } else if (section == "email" && isString(value) && isstartwithspace(value, section)) {
            setEmail(value);
        } else if (section == "empType" && !isString(value)) {
            setEmpType(value);
        } else if (section == "mgr" && !isString(value)) {
            setMgr(value);
        }
    }

    function submitForm() {
        let formData = new FormData();
        formData.append("f_name", firstName);
        formData.append("l_name", lastName);
        formData.append("email", email);
        formData.append("emp_type", empType.toString());
        formData.append("mgr", mgr.toString());
        const requestResponse = post(ADD_ENDPOINT, formData, cancelTokenSource.current.token, { type: "team" }) as Promise<IFetchEmpResponseRepr>;
        requestResponse.then((response) => {
            let [success, fail] = parseQueryResponseOnSuccess(response);
            props.mutator(response.data.data, success, fail);
            $("#teamAddModal").modal("hide");
            setMgrValues((prevMgrValues) => {
                if (response.data.mgr_details != undefined) {
                    return [...prevMgrValues, response.data.mgr_details?.id]
                }
                return prevMgrValues;
            })
            setMgrOptions((prevMgrOptions) => {
                if (response.data.mgr_details != undefined) {
                    return [...prevMgrOptions, response.data.mgr_details.emp_full_name];
                }
                return prevMgrOptions;
            })
            $("#teamAddModal").modal("hide");
        }).catch((error) => {
            let [success, fail, failResponse] = parseQueryResponseOnFail(error);
            let errorObj = getEmptyErrorObj(); 
            parseQueryResponseOnFailResponse(errorObj, failResponse);
            setErrorObj(errorObj);
        })
    }

    function showReportingManager() {
        if (empType == 1) {
            return <ModalDropDown onChange={(e) => formFieldChange("mgr", parseInt(e.target.value))} value={mgr} 
                values={mgrValues} options={mgrOptions} borderBottom
                isError={errorObj.mgr.isError} errorText={errorObj.mgr.errorText} />
        }
    }
    
    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="teamAddModal" 
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Add Team Member"} />
                        <div className="modal-body">
                        <ModalInputText onChange={(e) => formFieldChange("firstName", e.target.value)} value={firstName} title={"First Name"} 
                            isinvalidmsg={isfnameStartwithspace} borderBottom required 
                            isError={errorObj.f_name.isError} errorText={errorObj.f_name.errorText} /><br />
                        <ModalInputText onChange={(e) => formFieldChange("lastName", e.target.value)} value={lastName} title={"Last Name"} 
                            isinvalidmsg={islnameStartwithspace} borderBottom required
                            isError={errorObj.l_name.isError} errorText={errorObj.l_name.errorText} /> <br />
                        <ModalInputText onChange={(e) => formFieldChange("email", e.target.value)} value={email} title={"Email"} 
                            isinvalidmsg={isemailStartwithspace} borderBottom required
                            isError={errorObj.email.isError} errorText={errorObj.email.errorText} /><br />
                        <ModalDropDown onChange={(e) => formFieldChange("empType", parseInt(e.target.value))} value={empType} 
                            options={["Team Member", "Team Manager", "Front Office"]} values={[1, 2, 3]} borderBottom
                            isError={errorObj.emp_type.isError} errorText={errorObj.emp_type.errorText} /> <br />
                        {showReportingManager()}
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={false} disable={disable}/>
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById("modal") as Element);
}

export default ModalTeam;
