import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { AxiosResponse } from "axios";
import React from "react";
import $ from "jquery";
import ReactDOM from "react-dom";
import Loading from "../../../intelws_portal/constructs/elements/Loading";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import { get, getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";
import { IBankAccountPaymentMethodRepr, IPaymentMethodRepr, isBankAccount, isCard } from "../../../intelws_portal/utils/payments/paymentTypes";
import { STRIPE_KEY } from "../../Urls";
import { TPaymentMethods } from "../common/clientDashboard/ClientInvoice";

import bank from "../../card_brand_logo/bank.png";
import visa from "../../card_brand_logo/visa.png";
import mastercard from "../../card_brand_logo/mastercard.png";
import discover from "../../card_brand_logo/discover.jpeg";
import americanExpress from "../../card_brand_logo/american_express.png";
import unionPay from "../../card_brand_logo/union_pay.svg";
import dinersClub from "../../card_brand_logo/diners_club.svg";
import jcb from "../../card_brand_logo/jcb.svg";
import creditCard from "../../card_brand_logo/credit_card.png";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import { isStringEmpty } from "../../../declarations/common_utils";
import { confirmSetup } from "../../../intelws_portal/utils/payments/payments";
import { IFetchPaymentMethodDetails } from "./PayInvoice";
import Checkbox from "../../../intelws_portal/constructs/elements/Checkbox";

interface IPaymentMethodsInternalRepr {
    display: string[],
    values: TPaymentMethods[]
}

interface IManagePaymentMethodDetailsFetch extends AxiosResponse {
    data: {
        connected_account: string,
        l_payment_method: IPaymentMethodRepr[],
        payment_methods: IPaymentMethodsInternalRepr,
        user_info: {
            user_name: string,
            user_email: string
        }
    }
}

interface IManagePaymentMethodSetupIntentPost extends AxiosResponse {
    data: {
        client_secret: string
    }
}

interface IManagePaymentMethodPropsInternal {
    paymentMethods: IPaymentMethodsInternalRepr,
    paymentMethodList: IPaymentMethodRepr[],
    userInfo: {
        user_name: string,
        user_email: string
    }
}

function ManagePaymentMethodLogic(props: IManagePaymentMethodPropsInternal) {
    const ENDPOINT = "api/clientdashboard/";

    //List of Payment Methods
    const [paymentMethodList, setPaymentMethodList] = React.useState<IPaymentMethodRepr[]>(props.paymentMethodList);
    const [currSelectedPaymentMethodIDs, setCurrSelectedPaymentMethodIDs] = React.useState<number[]>([]);

    const [isProcessing, setIsProcessing] = React.useState(false);
    const [addPaymentMethod, setAddPaymentMethod] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);

    const [currPaymentMethod, setCurrPaymentMethod] = React.useState(props.paymentMethods.values[0]);
    
    //Card Information
    const [addressObj, setAddressObj] = React.useState(getEmptyAddressObj);
    const [cardHolderName, setCardHolderName] = React.useState("");
    const [isCardComplete, setIsCardComplete] = React.useState(false);
    const [cardErrorObj, setCardErrorObj] = React.useState(getEmptyCardErrorObj);

    const [selectedBankInfo, setSelectedBankInfo] = React.useState<IBankAccountPaymentMethodRepr>();
    const [clientSecret, setClientSecret] = React.useState("");
    const [currentErrorMessage, setCurrentErrorMessage] = React.useState<string>();

    const cancelTokenSource = React.useRef(getCancelToken());
    
    //Stripe Elements
    const stripeSDK = useStripe();
    const stripeElements = useElements();
    
    React.useEffect(() => {
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    function getEmptyCardErrorObj() {
        return {
            line1: {
                isError: false,
                errorText: ""
            },
            line2: {
                isError: false,
                errorText: ""
            },
            city: {
                isError: false,
                errorText: ""
            },
            state: {
                isError: false,
                errorText: ""
            },
            postalCode: {
                isError: false,
                errorText: ""
            },
            card: {
                isError: false,
                errorText: ""
            }
        }
    }

    function getEmptyAddressObj() {
        return {
            line1: "",
            line2: "",
            city: "",
            state: "",
            country: "US",
            postal_code: "",
        }
    }

    function formChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | string | number, section: "line1" | "line2" | "city" | "state" | "postalCode" | "cardHolderName" | "paymentMethod" | "toggleSavedPaymentMethodScreen" | "toggleIsEdit" | "currPaymentMethodID" | "deletePaymentMethodID") {
        if (typeof e == "string") {
            if (section == "paymentMethod") {
                setCurrPaymentMethod(e as TPaymentMethods);
            } else if (section == "toggleSavedPaymentMethodScreen") {
                setAddPaymentMethod((prevAddPaymentMethod) => {
                    if (prevAddPaymentMethod) {
                        return false;
                    } else {
                        return true;
                    }
                })
            } else if (section == "toggleIsEdit") {
                setIsEdit((prevIsEdit) => {
                    if (prevIsEdit) {
                        return false;
                    } else {
                        return true;
                    }
                })
            } else if (section == "deletePaymentMethodID") {
                let queryParams = {
                    section: "delete_payment_method"
                }
                let formData = new FormData();
                formData.append("payment_method_ids", JSON.stringify(currSelectedPaymentMethodIDs));
                const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
                requestResponse.then(() => {
                    setPaymentMethodList((prevPaymentMethodList) => {
                        let newPaymentMethodList = [...prevPaymentMethodList];
                        newPaymentMethodList = newPaymentMethodList.filter((ele) => !currSelectedPaymentMethodIDs.includes(ele.id));
                        return newPaymentMethodList;
                    })
                    setCurrSelectedPaymentMethodIDs([]);
                })
            }
        } else if (typeof e == "number") {
            if (section == "currPaymentMethodID") {
                if (isEdit) {
                    setCurrSelectedPaymentMethodIDs((prevCurrSelectedPaymentMethodIDs) => {
                        let newCurrSelectedPaymentMethodIDs = [...prevCurrSelectedPaymentMethodIDs];
                        let currIndex = newCurrSelectedPaymentMethodIDs.indexOf(e);
                        if (currIndex > -1) {
                            newCurrSelectedPaymentMethodIDs = newCurrSelectedPaymentMethodIDs.filter((ele) => ele != e);
                        } else {
                            newCurrSelectedPaymentMethodIDs = [...newCurrSelectedPaymentMethodIDs, e];
                        }
                        return newCurrSelectedPaymentMethodIDs;
                    })
                }
            }
        } else {
            setAddressObj((prevAddressObj) => {
                let newAddressObj = { ...prevAddressObj };
                if (section == "line1") {
                    newAddressObj.line1 = e.target.value;
                } else if (section == "line2") {
                    newAddressObj.line2 = e.target.value;
                } else if (section == "city") {
                    newAddressObj.city = e.target.value;
                } else if (section == "state") {
                    newAddressObj.state = e.target.value;
                } else if (section == "postalCode") {
                    newAddressObj.postal_code = e.target.value;
                }
                return newAddressObj;
            })
            if (section == "cardHolderName") {
                setCardHolderName(e.target.value);
            }
        }
    } 

    function getPaymentMethodAddDisplay() {
        if (addPaymentMethod) {
            return (
                <React.Fragment>
                    {(() => {
                        let rowClassName = "row mt-3";
                        return (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col">
                                        <small><a href="#" onClick={(e) => formChange("", "toggleSavedPaymentMethodScreen")}>&#8592; Saved Payment Methods</a></small>
                                    </div>
                                </div>
                                <div className={rowClassName}>
                                    {props.paymentMethods.display.map((paymentMethodDisplay, index) => {
                                        let borderStyle = "2px solid rgba(0, 0, 0, .075)";
                                        if (currPaymentMethod == props.paymentMethods.values[index]) {
                                            borderStyle = "2px solid rgba(75, 92, 169, .80)";
                                        }
                                        return (
                                            <div className="col-6" role="button" onClick={(e) => formChange(props.paymentMethods.values[index], "paymentMethod")}>
                                                <div className="card" style={{ borderRadius: "0.50rem", border: borderStyle }}>
                                                    <div className="card-body">{paymentMethodDisplay}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </React.Fragment>
                        )
                    })()}
                    {(() => {
                    if (addPaymentMethod) {
                        if (currPaymentMethod == "card") {
                            return getCardDisplay();
                        } else if (currPaymentMethod == "ach_debit") {
                            return getAchDisplay();
                        }
                    }
                })()}
                </React.Fragment>
            )
        }
    }

    function cardElementChange(e: StripeCardElementChangeEvent) {
        if (currPaymentMethod == "card") {
            if (e.complete) {
                setIsCardComplete(true);
            } else {
                setIsCardComplete(false);
            }
        }
    }

    function getCardDisplay() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12 mt-3">
                        <ModalInputText title={"Card Holder Name"} value={cardHolderName} onChange={(e) => formChange(e, "cardHolderName")}
                            borderBottom required />
                    </div>
                    <div className="col-12 mt-3 form-group">
                        <div className="form-control" style={{ borderBottom: "1px solid black" }}>
                            <CardElement onChange={cardElementChange} />
                            {(() => {
                                if (cardErrorObj.card.isError != undefined && cardErrorObj.card.isError) {
                                    return (
                                        <div className="invalid-feedback">{cardErrorObj.card.errorText}</div>
                                    )
                                }
                            })()}
                            <div className="mt-0"></div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <ModalInputText title={"Address Line 1"} value={addressObj.line1} onChange={(e) => formChange(e, "line1")}
                            isError={cardErrorObj.line1.isError} errorText={cardErrorObj.line1.errorText}
                            borderBottom required />
                    </div>
                    <div className="col-12 mt-3">
                        <ModalInputText title={"Address Line 2"} value={addressObj.line2 as string} onChange={(e) => formChange(e, "line2")}
                            isError={cardErrorObj.line2.isError} errorText={cardErrorObj.line2.errorText}
                            borderBottom />
                    </div>
                    <div className="col-12 mt-3">
                        <ModalInputText title={"City"} value={addressObj.city} onChange={(e) => formChange(e, "city")}
                            isError={cardErrorObj.city.isError} errorText={cardErrorObj.city.errorText}
                            borderBottom required />
                    </div>
                    <div className="col-12 mt-3">
                        <ModalInputText title={"State"} value={addressObj.state} onChange={(e) => formChange(e, "state")}
                            isError={cardErrorObj.state.isError} errorText={cardErrorObj.state.errorText}
                            borderBottom required />
                    </div>
                    <div className="col-12 mt-3">
                        <ModalInputText title={"Zip Code"} value={addressObj.postal_code} onChange={(e) => formChange(e, "postalCode")}
                            isError={cardErrorObj.postalCode.isError} errorText={cardErrorObj.postalCode.errorText}
                            borderBottom required />
                    </div>
                    <div className="col-12 mt-4">
                        <small><p className="text-secondary text-justify">
                            All payments processed by Intelligent Workspace Inc follow the PCI DSS security compliances, ensuring for secure payments and storage of card information
                        </p></small>
                    </div>
                </div>
                {(() => {
                    if (cardErrorObj.card.isError) {
                        return (
                            <div style={{ height: 20, padding: 4, color: "#dc3545" }}>
                                {cardErrorObj.card.errorText}
                            </div>
                        )
                    }
                })()}
            </React.Fragment>
        )
    }

    function getBankAccountDisplay(bankAccount: IBankAccountPaymentMethodRepr) {
        let borderStyle = "2px solid rgba(0, 0, 0, .075)";
        return (
            <div className="col-12">
                <div className="card" style={{ borderRadius: "0.50rem", border: borderStyle }}>
                    <div className="row card-body">
                        <div className="col-2">
                            <img className={"img-fluid"} src={bank} alt="" />
                        </div>
                        <div className="col-5 d-flex align-items-center">XXXX{bankAccount.last4}</div>
                        <div className="col-5 d-flex align-items-center">{bankAccount.bank_name}</div>
                    </div>
                </div>
            </div>
        )
    }

    function getAchDisplay() {
        return (
            <React.Fragment>
                {(() => {
                    if (selectedBankInfo == undefined) {
                        return (
                            <React.Fragment>
                                {(() => {
                                    if (isStringEmpty(clientSecret)) {
                                        return (
                                            <div className="row">
                                                {(() => {
                                                    if (currentErrorMessage != undefined) {
                                                        return (
                                                            <div className="col-12 mt-2">
                                                                <p style={{ color: "red" }}>{currentErrorMessage}</p>
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                                <div className="col-12 mt-5">
                                                    <button className="btn btn-secondary btn-sm btn-block" onClick={addBankAccountCallback}>Link Bank Account</button>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <div className="row mt-5">
                                {getBankAccountDisplay(selectedBankInfo)}
                                <div className="col-12 mt-3">
                                    <small><p className="text-secondary text-justify">
                                        By clicking [Save], you authorize Intelligent Workspace Inc to debit the bank account specified above for any amount owed for charges arising from your use of Intelligent Workspace Inc’ services and/or purchase of products from Intelligent Workspace Inc, pursuant to Intelligent Workspace Inc’ website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to Intelligent Workspace Inc with 30 (thirty) days notice.
                                    </p></small>
                                </div>
                            </div>
                        )
                    }
                })()}

            </React.Fragment>
        )
    }

    function getPaymentMethodListDisplay() {
        if (!addPaymentMethod) {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-3">
                            {(() => {
                                if (paymentMethodList.length > 0) {
                                    if (isEdit && currSelectedPaymentMethodIDs.length > 0) {
                                        return (
                                            <small><a href="#" onClick={(e) => formChange("", "deletePaymentMethodID")}>Delete</a></small>
                                        )
                                    } else {
                                        return (
                                            <small><a href="#" onClick={(e) => formChange("", "toggleIsEdit")}>
                                                {(() => {
                                                    if (isEdit && currSelectedPaymentMethodIDs.length == 0) {
                                                        return "Done"
                                                    } else {
                                                        return "Edit"
                                                    }
                                                })()}
                                            </a></small>
                                        )
                                    }
                                }
                            })()}
                            
                        </div>
                        <div className="col-4"></div>
                            <div className="col-5">
                                <small><a href="#" onClick={(e) => formChange("", "toggleSavedPaymentMethodScreen")}>Add Payment Method</a></small>
                            </div>
                    </div>
                    {(() => {
                        if (paymentMethodList.length > 0) {
                            return (
                                <div className="row">
                                    {paymentMethodList.map((paymentMethod) => {
                                        let borderStyle = "2px solid rgba(0, 0, 0, .075)";
                                        if (currSelectedPaymentMethodIDs.includes(paymentMethod.id)) {
                                            borderStyle = "2px solid rgba(75, 92, 169, .80)";
                                        }
                                        return (
                                            <div className="col-12 mt-3" onClick={() => formChange(paymentMethod.id, "currPaymentMethodID")}>
                                                <div className="card" style={{ borderRadius: "0.50rem", border: borderStyle }}>
                                                    <div className="row card-body">
                                                        {(() => {
                                                            if (isCard(paymentMethod)) {
                                                                return (
                                                                    <React.Fragment>
                                                                        {(() => {
                                                                            if (isEdit) {
                                                                                return (
                                                                                    <div className="col-1 d-flex align-items-center">
                                                                                        <Checkbox checked={currSelectedPaymentMethodIDs.includes(paymentMethod.id)}
                                                                                            onChange={() => { }} />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        <div className="col-2 d-flex align-items-center">
                                                                            {(() => {
                                                                                let className = "img-fluid"
                                                                                if (paymentMethod.brand == "visa") {
                                                                                    return <img className={className} src={visa} alt="" />
                                                                                } else if (paymentMethod.brand == "mastercard") {
                                                                                    return <img className={className} src={mastercard} alt="" />
                                                                                } else if (paymentMethod.brand == "discover") {
                                                                                    return <img className={className} src={discover} alt="" />
                                                                                } else if (paymentMethod.brand == "amex") {
                                                                                    return <img className={className} src={americanExpress} alt="" />
                                                                                } else if (paymentMethod.brand == "unionpay") {
                                                                                    return <img className={className} src={unionPay} alt="" />
                                                                                } else if (paymentMethod.brand == "diners") {
                                                                                    return <img className={className} src={dinersClub} alt="" />
                                                                                } else if (paymentMethod.brand == "jcb") {
                                                                                    return <img className={className} src={jcb} alt="" />
                                                                                } else {
                                                                                    return <img className={className} src={creditCard} alt="" />
                                                                                }
                                                                            })()}
                                                                        </div>
                                                                        <div className="col-4 d-flex align-items-center">XXXX{paymentMethod.last4}</div>
                                                                        <div className="col-5 d-flex align-items-center">{paymentMethod.exp_month}/{paymentMethod.exp_year}</div>
                                                                    </React.Fragment>
                                                                )
                                                            } else if (isBankAccount(paymentMethod)) {
                                                                return (
                                                                    <React.Fragment>
                                                                        {(() => {
                                                                            if (isEdit) {
                                                                                return (
                                                                                    <div className="col-1 d-flex align-items-center">
                                                                                        <Checkbox checked={currSelectedPaymentMethodIDs.includes(paymentMethod.id)}
                                                                                            onChange={() => { }} />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        <div className="col-2 d-flex align-items-center">
                                                                            <img className="img-fluid" src={bank} alt="" />
                                                                        </div>
                                                                        <div className="col-4 d-flex align-items-center">XXXX{paymentMethod.last4}</div>
                                                                        <div className="col-5 d-flex align-items-center">{paymentMethod.bank_name}</div>
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        })()}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        } else {
                            return (
                                <div className="row d-flex align-items-center justify-content-center mt-3">
                                    <p className="text-secondary">No Payment Methods</p>
                                </div>
                            )
                        }
                    })()}
                    
                </React.Fragment>
            )
        }
    }

    function isSaveButtonActivate() {
        if (currPaymentMethod == "card") {
            return (
                (isCardComplete && !isStringEmpty(cardHolderName) && !isStringEmpty(addressObj.line1) && !isStringEmpty(addressObj.city) && !isStringEmpty(addressObj.state) && !isStringEmpty(addressObj.postal_code))
            )
        } else if (currPaymentMethod == "ach_debit") {
            return (
                (!isStringEmpty(clientSecret))
            )
        }
        return false;
    }

    function addBankAccountCallback() {
        let bankAccBilling = {
            name: props.userInfo.user_name,
            email: props.userInfo.user_email
        }

        let queryParams = {
            section: "invoice_manage_payment_method_create_setup_intent"
        }
        const requestResponse = post(ENDPOINT, new FormData(), cancelTokenSource.current.token, queryParams) as Promise<IManagePaymentMethodSetupIntentPost>;
        requestResponse.then((response) => {
            if (stripeSDK != undefined && stripeElements != undefined) {
                confirmSetup("stripe", "bank_account", response.data.client_secret, stripeSDK, stripeElements, undefined, bankAccBilling, 1).then((paymentResponse) => {
                    if (paymentResponse != undefined && paymentResponse.success) {
                        let queryParams = {
                            section: "invoice_payment_details_fetch",
                            payment_backend: "stripe",
                            payment_method_id: paymentResponse.payment_method_id
                        }
                        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchPaymentMethodDetails>
                        requestResponse.then((paymentMethodDetailsResponse) => {
                            if (isBankAccount(paymentMethodDetailsResponse.data.data)) {
                                setSelectedBankInfo(paymentMethodDetailsResponse.data.data);
                                setClientSecret(response.data.client_secret as string);
                            }
                        })
                    } else {
                        if (paymentResponse != undefined) {
                            if (paymentResponse.reason != "cancelled_modal") {
                                setCurrentErrorMessage("Something went wrong. Please try again");
                            }
                        }
                    }
                })
            }
        })
    }

    function saveCard() {
        let cardData: any = undefined;
        cardData = {
            name: cardHolderName,
            address: { ...addressObj }
        }
        if (addressObj.line2 != undefined) {
            cardData.address.line2 = addressObj.line2;
        }
        setCardErrorObj(getEmptyCardErrorObj());
        let queryParams = {
            section: "invoice_manage_payment_method_create_setup_intent"
        }

        const requestResponse = post(ENDPOINT, new FormData(), cancelTokenSource.current.token, queryParams) as Promise<IManagePaymentMethodSetupIntentPost>;
        requestResponse.then((response) => {
            if (stripeSDK != undefined && stripeElements != undefined && response.data.client_secret != undefined) {
                    confirmSetup("stripe", "card", response.data.client_secret, stripeSDK, stripeElements, cardData).then((paymentResponse) => {
                        if (paymentResponse != undefined) {
                            if (paymentResponse.success == false && paymentResponse.reason != undefined) {
                                setIsProcessing(false);
                                let errorObj = getEmptyCardErrorObj();
                                if (paymentResponse.reason == "expired_card" ||
                                    paymentResponse.reason == "invalid_month" ||
                                    paymentResponse.reason == "invalid_year") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "This card has expired"
                                    setCardErrorObj(errorObj);
                                } else if (paymentResponse.reason == "incorrect_address") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "The address is incorrect"
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "incorrect_cvc" ||
                                    paymentResponse.reason == "invalid_cvc") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "The CVC is invalid"
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "incorrect_zip") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "The Zip Code is incorrect"
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "invalid_number") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "The card number is invalid"
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "auth_failure") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "The card authentication failed"
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "card_declined") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "This card cannot be used. Please try another card."
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "unexpected_error") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "There is an unexpected error. Please try again"
                                    setCardErrorObj(errorObj)
                                } else if (paymentResponse.reason == "processing_error") {
                                    errorObj.card.isError = true;
                                    errorObj.card.errorText = "This card cannot be processed. Please try another card."
                                    setCardErrorObj(errorObj)
                                }
                            } else {
                                let queryParams = {
                                    section: "invoice_payment_details_fetch",
                                    payment_backend: "stripe",
                                    payment_method_id: paymentResponse.payment_method_id
                                }
                                const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchPaymentMethodDetails>;
                                requestResponse.then((response) => {
                                    setPaymentMethodList((prevPaymentMethodList) => {
                                        let newPaymentMethodList = [...prevPaymentMethodList, response.data.data];
                                        return newPaymentMethodList;
                                    })
                                    setAddressObj(getEmptyAddressObj);
                                    setCardHolderName("");
                                    setIsCardComplete(false);
                                    setCardErrorObj(getEmptyCardErrorObj);
                                    setCurrPaymentMethod(props.paymentMethods.values[0]);
                                    formChange("", "toggleSavedPaymentMethodScreen");
                                    setIsProcessing(false);
                                })
                            }
                        }
                    })
                }
        })
    }

    function saveAchDebit() {
        if (stripeSDK != undefined && stripeElements != undefined) {
            confirmSetup("stripe", "bank_account", clientSecret, stripeSDK, stripeElements, undefined, undefined, 2).then((paymentResponse) => {
                if (paymentResponse != undefined && paymentResponse.success) {
                    setPaymentMethodList((prevPaymentMethodList) => {
                        return [...prevPaymentMethodList, selectedBankInfo as IPaymentMethodRepr];
                    })
                    setSelectedBankInfo(undefined);
                    setClientSecret("");
                    setCurrentErrorMessage(undefined);
                    setCurrPaymentMethod(props.paymentMethods.values[0]);
                    formChange("", "toggleSavedPaymentMethodScreen");
                    setIsProcessing(false);
                } else {
                    setCurrentErrorMessage("Something went wrong. Please try again");
                }
            });
        }
    }

    function savePaymentMethod() {
        setIsProcessing(true);
        if (currPaymentMethod == "card") {
            saveCard();
        } else if (currPaymentMethod == "ach_debit") {
            saveAchDebit();
        }
    }

    return (
        <React.Fragment>
            <div className="modal-body">
                {getPaymentMethodListDisplay()}
                {getPaymentMethodAddDisplay()}
            </div>
            <div className="modal-footer d-flex justify-content-center">
                {(() => {
                    if (addPaymentMethod) {
                        return (
                            <button type="button" className="btn btn-secondary btn-block" onClick={savePaymentMethod} disabled={!isSaveButtonActivate() || isProcessing}>
                                {(() => {
                                    if (isProcessing) {
                                        return "Saving..."
                                    } else {
                                        return "Save"
                                    }
                                })()}
                            </button>
                        )
                    }
                })()}
            </div>
        </React.Fragment>
    )
}

function ManagePaymentMethods() {
    const ENDPOINT = "api/clientdashboard/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [connectedAccount, setConnectedAccount] = React.useState("");
    const [paymentMethods, setPaymentMethods] = React.useState<IPaymentMethodsInternalRepr>({"display": [], "values": []});
    const [paymentMethodList, setPaymentMethodList] = React.useState<IPaymentMethodRepr[]>([]);
    const [userInfo, setUserInfo] = React.useState({user_name: "", user_email: ""});

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        $("#managePaymentMethodModal").on("hidden.bs.modal", clearForm)
        let queryParams = {
            section: "invoice_manage_payment_method_details_fetch"
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IManagePaymentMethodDetailsFetch>
        requestResponse.then((response) => {
            setConnectedAccount(response.data.connected_account);
            setPaymentMethods(response.data.payment_methods);
            setPaymentMethodList(response.data.l_payment_method);
            setUserInfo(response.data.user_info);
            setIsLoaded(true);
        })
        return () => {
            $("#managePaymentMethodModal").off("hidden.bs.modal");
            cancelTokenSource.current.cancel();
        }
    }, [])

    function clearForm() {
    }

    function closeModal() {
        $("#managePaymentMethodModal").modal("hide");
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="managePaymentMethodModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-md" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Manage Payment Methods"} />
                        {(() => {
                            if (isLoaded) {
                                return (
                                    <Elements stripe={loadStripe(STRIPE_KEY, { stripeAccount: connectedAccount })}>
                                        <ManagePaymentMethodLogic paymentMethods={paymentMethods} paymentMethodList={paymentMethodList}
                                            userInfo={userInfo} />
                                    </Elements>
                                )
                            } else {
                                return <Loading />
                            }
                        })()}
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ManagePaymentMethods;