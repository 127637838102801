import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";

import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalDatePicker from "../../../intelws_portal/constructs/modal/ModalDatePicker";

export interface IModalLeadProps {
    saveCallback: (date: string) => void
}

function ModalLead(props: IModalLeadProps) {
    const [dueDate, setDueDate] = React.useState("");
    const [disable, setDisable] = React.useState(true);

    React.useEffect(() => {
        $("#leadDueDateModal").on("hidden.bs.modal", clearForm);
        return () => {
            $("#leadDueDateModal").off("hidden.bs.modal");
        }
    }, [])

    React.useEffect(() => {
        let newDisable = true;
        if (dueDate != "") {
            newDisable = false;
        }
        setDisable(newDisable);
    }, [dueDate]);

    function clearForm() {
        setDueDate("");
    }

    function submitForm() {
        props.saveCallback(dueDate);
    }
    
    function getModalContent() {
        return (
            <React.Fragment>
                <ModalHeader title="Lead Follow Up Date" />
                <div className="modal-body">
                    <ModalDatePicker value={dueDate} onChange={(e) => setDueDate(e.target.value)} required/>
                </div>
                <ModalFooter saveCallback={submitForm} saveInactive={false} disable={disable}/>
            </React.Fragment>
        )
    }
    function relativeRender() {
        return <div className="modal fade custom-modal text-center" id="leadDueDateModal" tabIndex={-1}>
                <div className="modal-dialog text-left modal-lg">
                    <div className="modal-content">
                        {getModalContent()}
                    </div>
                </div>
            </div>
    }
    
    return ReactDOM.createPortal(relativeRender(), document.getElementById("modal") as Element);
}

export default ModalLead;