import React from "react";
import { AxiosResponse } from "axios";

import TicketDisplay, { ITicketDisplayProps } from "../../../../intelws_portal/bundles/TicketDisplay";
import Loading from "../../../../intelws_portal/constructs/elements/Loading";
import { get, getCancelToken, post } from "../../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../../declarations/common";
import * as urls from "../../../Urls";

interface IMatchProps {
    ticketId: number
}

export interface IFetchTicketDetails extends AxiosResponse {
    data: ITicketDisplayProps
}

export interface IPostTicketReply extends AxiosResponse {
    data: ITicketDisplayProps
}

function isStringEmpty(value: string) {
    return (value.length == 0 || !value.trim());
}

function TicketsDetails(props: IPageComponentProps<IMatchProps>) {
    const ENDPOINT = "/api/tickets/";
    const [ticketDetails, setTicketDetails] = React.useState<ITicketDisplayProps | boolean>(false);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        let queryParams = {
            section: "tickets_details",
            ticket_id: props.match.params.ticketId
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchTicketDetails>;
        requestResponse.then((response) => {
            setTicketDetails(response.data);
        })
        return () => {
            return cancelTokenSource.current.cancel()
        }
    }, [])

    function formSubmitCallback(message: string, files: (File | undefined)[]) {
        let formData = new FormData();
        let queryParams = {
            section: "tickets_reply",
            ticket_id: props.match.params.ticketId
        }
        if (!isStringEmpty(message)) {
            formData.append("message", message)
        }
        files.forEach((ele) => {
            if (ele != undefined) {
                formData.append("file", ele);
            }
        })
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostTicketReply>;
        requestResponse.then((response) => {
            setTicketDetails(response.data);
        })
    }

    if (typeof ticketDetails == "boolean") {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper clearfix">
                <TicketDisplay {...ticketDetails} backUrl={urls.TICKETS} formSubmitCallback={formSubmitCallback} />
            </div>
        )
    }
}

export default TicketsDetails; 