import { AxiosResponse } from "axios";
import React from "react";
import * as urls from "../../../Urls";
import $ from "jquery";

import ContentBoxTabTable, { IContentBoxTabTableDropdownPropRepr } from "../../../../intelws_portal/bundles/ContentBoxTabTable";
import Loading from "../../../../intelws_portal/constructs/elements/Loading";
import ScrollTop from "../../../../intelws_portal/constructs/elements/ScrollTop";
import { IDataRepr } from "../../../../intelws_portal/constructs/elements/Table";
import { ITabRepr } from "../../../../intelws_portal/constructs/elements/Tabs";
import { get, getCancelToken } from "../../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../../declarations/common";
import { isStringEmpty } from "../../../../declarations/common_utils";

interface IFetchManualConfig extends AxiosResponse {
    data: {
        tab_config: ITabRepr[],
        status_config: string[][],
        is_add: boolean,
        access_keys: string[][],
        column_names: string[][]
    }
}

interface IFetchManualData extends AxiosResponse {
    data: {
        data: IDataRepr[]
    }
}

function ManualTaskList(props: IPageComponentProps) {
    const ENDPOINT = "/api/manualtasklist/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [currTab, setCurrTab] = React.useState(0);
    const [selectedStatus, setSelectedStatus] = React.useState<string[]>([]);
    const [tabConfig, setTabConfig] = React.useState<ITabRepr[]>([]);
    const [tableData, setTableData] = React.useState<(IDataRepr[] | undefined)[]>([]);
    const [tableStatusData, setTableStatusData] = React.useState<string[][]>([]);
    const [accessKeys, setAccessKeys] = React.useState<string[][]>([]);
    const [columnNames, setColumnNames] = React.useState<string[][]>([]);
    const cancelTokenSource = React.useRef(getCancelToken());
    const statusAllText = React.useRef("All Status");
    
    const displayTableData = React.useMemo(filter, [tableData, selectedStatus]);

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ManualTask");
            fetchConfig((fetchTabConfig, isEdit, accessKeys, columnNames, statusList) => {
                setAccessKeys(accessKeys);
                setColumnNames(columnNames);
                setTabConfig(fetchTabConfig);
                setTableData((prevTableData) => {
                    return new Array(fetchTabConfig.length).fill(undefined);
                });
                setIsEdit(isEdit);
                let newStatusList = statusList.map((ele) => [statusAllText.current, ...ele]);
                setTableStatusData(newStatusList);
                fetchData(fetchTabConfig[0].target, (data) => {
                    setTableData((prevTableData) => {
                        let newTableData = [...prevTableData];
                        newTableData[0] = [...data];
                        return newTableData;
                    })
                    setSelectedStatus((prevSelectedStatus) => {
                        let newSelectedStatus = [...prevSelectedStatus];
                        fetchTabConfig.forEach(() => {
                            newSelectedStatus.push("");
                        })
                        newSelectedStatus[0] = statusAllText.current;
                        return newSelectedStatus;
                    })
                    setIsLoaded(true);
                })
            })
        }
    }, [])

    function filter() {
        let returnTableData = [...tableData];
        return returnTableData.map((ele, index) => {
            if (selectedStatus[index] != undefined && !isStringEmpty(selectedStatus[index]) && selectedStatus[index] != statusAllText.current && ele != undefined) {
                return ele.filter((value) => {
                    return (value["tt_current_status_name"] as any)[1]["value"] == selectedStatus[index];
                })
            }
            return ele;
        })
    }

    function fetchConfig(successCallback: (tabConfig: ITabRepr[], isEdit: boolean, accessKeys: string[][], columnNames: string[][], statusList: string[][]) => void) {
        let queryParams = {
            section: "config"
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchManualConfig>;
        requestResponse.then((response) => {
            successCallback(response.data.tab_config, response.data.is_add, response.data.access_keys, response.data.column_names, response.data.status_config);
        })
    }

    function fetchData(internal_bucket: string, successCallback: (data: IDataRepr[]) => void) {
        let queryParams = {
            section: "manual_task_list",
            internal_bucket_name: internal_bucket
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchManualData>;
        requestResponse.then((response) => {
            successCallback(response.data.data);
        })
    }

    function tabCallback(tabName: string, index: number, prevIndex: number) {
        if (tableData[index] == undefined) {
            fetchData(tabName, (data) => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    newTableData[index] = [...data];
                    return newTableData;
                })
                setSelectedStatus((prevSelectedStatus) => {
                    let newSelectedStatus = [...prevSelectedStatus];
                    newSelectedStatus[index] = statusAllText.current;
                    return newSelectedStatus;
                })
            })
        }
        setCurrTab(index);
    }

    function getContent() {
        let link = {
            tt_task_name: {
                baseUrl: urls.BASE_MANUAL_TASK_DETAILS,
                urlParams: ['tt_user_id', 'id'],
            }
        };
        let links = new Array(tabConfig.length).fill(link);
        let dropdownConfig: IContentBoxTabTableDropdownPropRepr[] = [];
        tabConfig.forEach((ele, index) => {
            if (tableStatusData[index].length > 2) {
                dropdownConfig.push({
                    target: ele.target, dropdowns: [
                        {
                            options: tableStatusData[index],
                            value: selectedStatus[index],
                            callback: (value) => {
                                setSelectedStatus((prevSelectedStatus) => {
                                    let newSelectedStatus = [...prevSelectedStatus];
                                    newSelectedStatus[index] = value;
                                    return newSelectedStatus;
                                })
                            }
                        }
                    ]
                })
            } else {
                dropdownConfig.push({
                    target: ele.target,
                    dropdowns: []
                })
            }
        })


        return <ContentBoxTabTable hasSearch={true} title="ProjectFlow" tabs={tabConfig}
            columnNames={columnNames} accessKeys={accessKeys} contents={displayTableData}
            tabCallback={tabCallback} hasRecords={true} links={links} dropdowns={dropdownConfig}  />
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper clearfix">
                {getContent()}
                <ScrollTop />
            </div>
        )
    }
}

export default ManualTaskList;